import { memo } from 'react'
import { useRouter } from 'next/router'
import { Figure } from '@dy/commons/components'
import { currencyFormatter, parserMedia } from '@dy/commons/utils'
import { vw } from '@dy/commons/styles'
import { ModalListItem, _Price } from '.'
import { itemVariants } from './cartVariants'

interface ICartItem {
  product: any,
  id: number | string,
  onClick?: () => void,
  type?: string
}

const cartItemSizes = `(min-width: 768px) ${vw(55, 'desktop')}, ${vw(55, 'mobile')}`
export const CartItem = memo<ICartItem>(({ product, id, onClick, type = 'all' }) => {
  const { locale } = useRouter()
  const { name, quantity, image_url, price } = product
  const _media = {
    type: 'image',
    alt: 'Product: ',
    default: { ['2x']: { regular: image_url } }
  }
  const _priceTotal = price * quantity

  return (
    <ModalListItem variants={itemVariants} id={id} {...(onClick && { ...onClick })} $type={type}>
      <article>
        {image_url && <Figure className={'product_image'} media={parserMedia(_media)} lazy={false} sizes={cartItemSizes} quality={50}/>}
        <div className='info'>
          <div>
            <h5>{quantity} x {name}</h5>
            <span className='total'>
              {currencyFormatter({ price: _priceTotal, localeRegion: locale, round: false, trailingZeros: true })}
            </span>
          </div>
          <_Price>
            {currencyFormatter({ price, localeRegion: locale, round: false, trailingZeros: true })}/ud.
          </_Price>
        </div>
      </article>
    </ModalListItem>
  )
})
