import { memo, useMemo, useState } from 'react'
import { updateActiveFilters } from '@dy/commons/utils'
import { SliderFlickity } from '@dy/commons/components'
import { useModal } from '@dy/commons/hooks'
import { MODALS } from '@dy/commons/context'
import { ModalFilters } from '../modals'
import { ButtonFilterCheckbox } from './ButtonFilterCheckbox'
import { ButtonFilterAccordion } from './ButtonFilterAccordion'
import { FiltersSection, FiltersButton } from './shopStyles'
import { useRouter } from 'next/router'

type TypeGetFilterButtons = {
  readonly filters: {
    [key: string]: string | string[]
  },
  readonly target: 'filters-bar'|'filters-modal',
  readonly onClickParam?: (param) => void,
  readonly clickedParam?: string | null,
  readonly onClick: (filter, activeFilter) => void
  readonly activeFilters: {
    [key: string]: any
  },
  readonly toggle?: () => void
}

interface ISectionFilters {
  readonly filters: {
    [key: string]: string | string[]
  },
  readonly activeFilters: {
    [key: string]: any
  },
  resultsLength: number | null
  setActiveFilters: any,
  productDetailVisible: boolean,
  isScrolling?: boolean
}

const getFilterButtons = ({ filters, target, onClickParam, clickedParam, onClick, activeFilters, toggle }:TypeGetFilterButtons) => {
  const params = Object.keys(filters)
  const item = {
    'filters-bar': (param, filter, idx) => {
      const paramMultipleActive = filter.type === 'multiple' && typeof activeFilters[param] === 'object' && Object.keys(activeFilters[param]).length > 0
      const isActive = filter.type === 'multiple' ? paramMultipleActive : activeFilters[param]
      return (
        <li key={`target-${idx}`}>
          <FiltersButton onClick={filter.type === 'multiple' ? () => { onClickParam(param) } : () => { onClick({...filter, param}, activeFilters) }} isActive={param !== 'sort' && isActive}>{filter.name}{paramMultipleActive && param !== 'sort' && ` (${Object.keys(activeFilters[param]).length})`}</FiltersButton>
        </li>
      )
    },
    'filters-modal': (param, filter, idx) => (
      <li key={`target-${idx}`}>
        {filter.type === 'simple' ?
          <ButtonFilterCheckbox data={filter} onClick={() => { onClick({...filter, param}, activeFilters); toggle() }} isActive={activeFilters[param]}/>
          :
          <ButtonFilterAccordion data={{...filter, param}} activeValues={activeFilters[param]} clickedParam={clickedParam}>
            {filter?.values?.map((value, idx) => <ButtonFilterCheckbox key={`filter-val-${idx}`} data={{ ...value, param, value, type: 'multiple'}} onClick={() => { onClick({ ...value, param }, activeFilters)}} isActive={activeFilters[param]?.[value.value]}/>)}
          </ButtonFilterAccordion>
        }
      </li>
    )
  }

  return params.map((param, idx) => item[target](param, filters[param], idx))
}

const scrollToTopGrid = () => {
  if(typeof window !== 'undefined') {
    const main = document.getElementById('main-top')
    if(main) setTimeout(() => { main.scrollIntoView({ behavior: 'smooth' }) }, 300)
  }
}

export const SectionFilters = memo(({ filters = {}, activeFilters, resultsLength = 0, setActiveFilters, productDetailVisible = false, isScrolling = null}:ISectionFilters) => {
  const { toggle } = useModal(MODALS.FILTER)
  const [clickedParam, setClickedParam] = useState(null)
  const { pathname } = useRouter()
  //dev hardcoded
  const isBrandPage = pathname === '/brand/[...slug]'

  const onClick = (filter, actives) => {
    const incomingActives = updateActiveFilters(actives, filter)
    setActiveFilters(incomingActives)
    scrollToTopGrid()
  }

  const onClickParam = (param) => {
    setClickedParam(param)
    toggle()
  }

  const clearFilters = () => {
    //dev hardcoded
    setActiveFilters((isBrandPage ? { sort: {recommended: true}, marca: {'fattoria-poggiopiano': true } } : { sort: {recommended: true}}))
    scrollToTopGrid()
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const FilterButtons = useMemo(() => getFilterButtons({ filters, target: 'filters-bar', onClickParam, activeFilters, onClick }), [filters, activeFilters])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const FilterButtonsModal = useMemo(() => getFilterButtons({ filters, target: 'filters-modal', activeFilters, onClick, clickedParam, toggle }), [filters, activeFilters, clickedParam])

  const length = useMemo(() => Object.keys(activeFilters).length, [activeFilters])

  return (
    <>
      <FiltersSection $productDetailVisible={productDetailVisible} isScrolling={isScrolling}>
        <ul>
          <li>
            <FiltersButton onClick={toggle} isActive={length > 0}>{'Order and filter'}{length > 0 && ` (${length})`}</FiltersButton>
          </li>
          {FilterButtons.length < 8 ?
            <>{FilterButtons}</>
            :
            <li className='slider-bar-wrapper'>
              <SliderFlickity className={'slider--filters-bar'} loop={false} pageDots={false} buttons={false} freeScroll={true} cellAlign={'left'}>{FilterButtons}</SliderFlickity>
            </li>
          }
        </ul>
      </FiltersSection>
      <ModalFilters filters={FilterButtonsModal} activeFiltersLength={length} resultsLength={resultsLength} clearFilters={clearFilters} />
    </>
  )
})
