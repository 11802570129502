import { memo, useEffect, useMemo, useRef } from 'react'
import { useScroll, useTransform, useSpring } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import { Figure, SliderFlickity, ProductCard } from '@dy/commons/components'
import { pushAnalyticsEvent } from '@dy/commons/utils'
import { useTranslator } from '@/hooks'
import { BestsellerSection, PastaWrapper, ImageOverlay, ProductWrapper } from './homeStyles'

interface ISectionBestseller {
  data: any,
  readonly setProductDetailed: any,
  displayProductDetail: () => void,
}

const mediaPastaLeft = {
  type: 'image' as const,
  alt: 'Icon pasta 1',
  default: {
    src: '/images/svg/pasta-left.svg',
    size: { width: 507, height: 844 }
  }
}

const mediaPastaRight = {
  type: 'image' as const,
  alt: 'Icon pasta 2',
  default: {
    src: '/images/svg/pasta-right.svg',
    size: { width: 608, height: 693 }
  }
}



const ga_list = { id: 'list_bestsellers', name: 'List Bestsellers (slider)' }

const getSlides = (products, onClick, translations) => products.map((product, idx) => (
  <div className='cart-wrapper' key={idx}>
    <ProductCard data={product} pricePer={translations('product_page.product.price_per.unit')} onClick={e=> {
      e.preventDefault()
      onClick(product,idx)
    }}/>
  </div>
))

export const SectionBestseller = memo<ISectionBestseller>(({ data, setProductDetailed, displayProductDetail }) => {
  const { t } = useTranslator()
  const containerRef = useRef(null)
  const [ref, inView] = useInView({
    rootMargin: '20px', // TOP  RIGHT
    threshold: 0,
    triggerOnce: true
  })

  const { scrollYProgress } = useScroll({
    target: containerRef,
    offset: ['start end', 'end end']
  })

  const spring = useSpring(scrollYProgress, {
    stiffness: 100,
    damping: 30,
    restDelta: 0.001
  })
  const translateY = useTransform(spring, [0, 1], [-250, 0])
  const translateX = useTransform(spring, [0, 1], [70, -180])
  const y = useTransform(spring, [0, 1], [-340, -120])
  const x = useTransform(spring, [0, 1], [-200, 250])

  const onClick = (product, idx) => {
    setProductDetailed({
      variant_id: product?.variant?.bigcommerce_id,
      slug: product?.slug,
      ga_list,
      idx
    })
    displayProductDetail()
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const Slides = useMemo(() => getSlides([{}, ...data, {}], onClick, t), [data])

  useEffect(() => {
    if(inView) {
      pushAnalyticsEvent('view_item_list', { products: data, ga_list })
    }
  }, [inView, data])

  return (
    <BestsellerSection ref={ref} className='bestseller-wrapper'>
      <PastaWrapper ref={containerRef} className='pasta-wrapper'>
        <ImageOverlay style={{ translateY, translateX }} className='bestseller-left'>
          <Figure media={mediaPastaLeft}/>
        </ImageOverlay>
        <ImageOverlay style={{ y, x }} className='bestseller-right'>
          <Figure media={mediaPastaRight}/>
        </ImageOverlay>
      </PastaWrapper>
      <ProductWrapper>
        <div className='headline-wrapper'><h2>{t('home.bestseller.new')}</h2></div>
        <SliderFlickity className={'slider--product-card'} loop={false} pageDots={false} groupCells={'90%'}>
          {Slides}
        </SliderFlickity>
      </ProductWrapper>
      <ProductWrapper>
        <div className='headline-wrapper'><h2>{t('home.bestseller.offer')}</h2></div>
        <SliderFlickity className={'slider--product-card'} loop={false} pageDots={false} groupCells={'90%'}>
          {Slides}
        </SliderFlickity>
      </ProductWrapper>
    </BestsellerSection>
  )
})
