"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parsePlaceHolder = exports.hideScrollbar = exports.hex2Rgba = exports.createTooltip = exports.createUnderline = void 0;
const styled_components_1 = require("styled-components");
const _1 = require(".");
const createUnderline = ({ height = 1, reverse = false, color = 'black' } = {}) => (0, styled_components_1.css) `
  position: relative;

  span {
    position: absolute;
    bottom: 0;
    height: ${height}px;
    width: 0;
    background-color: ${({ theme }) => theme.colors[color]};
    left: unset;
    right: 0;
    transition: width 300ms ease, left 300ms ease, right 300ms ease;
  }

  ${reverse && `
    span {
      left: 0;
      right: unset;
      width: 100%;
    }
  `}

  &:hover,
  &.active {
    ${reverse ? `
      span {
        left: unset;
        right: 0;
        width: 0;
      }` : `
      span {
        left: 0;
        right: unset;
        width: 100%;
      }`}
  }
`;
exports.createUnderline = createUnderline;
const createTooltip = ({ color = '#F6A300' }) => (0, styled_components_1.css) `

  &:before {
    content: '';
    display: block;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 100%;
    width: 0;
    height: 0;
    border: ${(0, _1.vw)(9, 'mobile')} solid transparent;
    border-bottom-color: ${color};

    ${_1.mq.greaterThan('tablet') `
      border: ${(0, _1.vw)(9, 'desktop')} solid transparent;
      border-bottom-color: ${color};
    `}
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 100%;
    width: 0;
    height: 0;
    border: ${(0, _1.vw)(4, 'mobile')} solid transparent;
    border-bottom-color: ${color};

    ${_1.mq.greaterThan('tablet') `
      border: ${(0, _1.vw)(4, 'desktop')} solid transparent;
      border-bottom-color: ${color};
    `}
  }
`;
exports.createTooltip = createTooltip;
function hex2Rgba(hex, alpha) {
    const r = parseInt(hex.substring(1, 3), 16);
    const g = parseInt(hex.substring(3, 5), 16);
    const b = parseInt(hex.substring(5, 7), 16);
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}
exports.hex2Rgba = hex2Rgba;
const hideScrollbar = () => (0, styled_components_1.css) `
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */

  &::-webkit-scrollbar {
    background-color: rgba(0, 0, 0, 0);
    display: none;
  }
`;
exports.hideScrollbar = hideScrollbar;
const defaultPlaceHolderStyles = (color) => (0, styled_components_1.css) `
  transition: opacity 300ms ${({ ease }) => ease};
  color: ${color !== null && color !== void 0 ? color : 'rgba(0, 0, 0, 0)'};
`;
const parsePlaceHolder = (styles, color = null) => ((0, styled_components_1.css) `
  ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    ${defaultPlaceHolderStyles(color)}
    ${styles}
  }
  ::-moz-placeholder { /* Firefox 19+ */
    ${defaultPlaceHolderStyles(color)}
    ${styles}
  }
  :-ms-input-placeholder { /* IE 10+ */
    ${defaultPlaceHolderStyles(color)}
    ${styles}
  }
  :-moz-placeholder { /* Firefox 18- */
    ${defaultPlaceHolderStyles(color)}
    ${styles}
  }
`);
exports.parsePlaceHolder = parsePlaceHolder;
