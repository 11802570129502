import type { AppProps, NextWebVitalsMetric } from 'next/app'
import type { GetServerSidePropsContext } from 'next'
import { SessionProvider } from 'next-auth/react'
import { ThemeProvider }  from 'styled-components'
import { TranslationsProvider } from '@/context'
import { ModalsProvider, CartProvider } from '@dy/commons/context'
import { theme, Reset } from '@dy/commons/styles'
import { Layout } from '../components/layout/Layout'
import 'flickity/css/flickity.css'
import { ApolloProvider } from '@apollo/client'
import { useApollo } from 'api/graphql'

interface iProps {
  pageProps: any,
  context: any
  // apollo: ApolloClient<NormalizedCacheObject>
}

export function reportWebVitals(metric: NextWebVitalsMetric) {
  if(!metric.label) return
  const { label, name, startTime, value } = metric

  if (label === 'web-vital' && name === 'LCP') {
    // The metric object ({ id, name, startTime, value, label }) is logged to the console
    console.info(`${label} ${name} | startTime: ${startTime} - value: ${value}`)
  }
}

const MyApp = ({ Component, pageProps: { session, ...pageProps }, context }: AppProps & iProps) => {
  const apolloClient = useApollo(pageProps.initialApolloState, context as GetServerSidePropsContext)
  const { translations, layout } = pageProps

  return (
    <>
      <ApolloProvider client={apolloClient}>
        <SessionProvider session={session}>
          <ModalsProvider>
            <CartProvider>
              <ThemeProvider theme={theme}>
                <Reset />
                <TranslationsProvider localization={translations}>
                  <Layout data={layout} >
                    <Component {...pageProps}/>
                  </Layout>
                </TranslationsProvider>
              </ThemeProvider>
            </CartProvider>
          </ModalsProvider>
        </SessionProvider>
      </ApolloProvider>
    </>
  )
}

//@ts-ignore
export default MyApp
