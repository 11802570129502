"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.faqs = void 0;
/* eslint-disable quotes */
// https://staging.cms.mammafiore.eu/api/v1/ES/es/faqs/
const faqs = (data) => {
    if (!data)
        return null;
    return JSON.stringify({
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": data.faqs.map((faq) => ({
            "@type": "Question",
            "name": `${faq.question}`,
            "acceptedAnswer": {
                "@type": "Answer",
                "text": `${faq.answer}`
            }
        }))
    });
};
exports.faqs = faqs;
