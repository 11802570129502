import { memo, useState, useEffect } from 'react'
import type { FC } from 'react'
import { useRouter } from 'next/router'
import { css } from 'styled-components'
import { useModal } from '@dy/commons/hooks'
import { MODALS } from '@dy/commons/context'
import { mq, vw, getP15_1 } from '@dy/commons/styles'
import { Modal, ButtonHeader } from '.'
import { Link, Figure } from '@dy/commons/components'
import { useTranslator } from '@/hooks'
import { getLocaleCountry, cookie } from '@dy/commons/utils'
import { getCountry, getSubdivisions }  from '@/components'
type MenuProps = {}

const dialogStyles = css`
  position: absolute;
  background-color: ${({ theme }) => theme.colors.darkred};
  border-radius:  ${vw(20, 'mobile')};
  height: ${vw(130, 'mobile')};
  bottom: 0;
  z-index: 10;
  left: 0;
  width: 100%;
  margin: 0 auto 0;

  ${mq.greaterThan('tablet')`
    border-radius:  ${vw(20, 'desktop')};
    height: ${vw(150, 'desktop')};
    width: ${vw(460, 'desktop')};
  `}

  ${mq.greaterThan('desktop')`
    border-radius: 20px;
    height: 150px;
    width: 450px;
  `}
  
  .modal__body {
    padding:  ${vw(20, 'mobile')} 0  ${vw(30, 'mobile')}  ${vw(30, 'mobile')};
   
    ${mq.greaterThan('tablet')`
      padding:  ${vw(20, 'desktop')} 0  ${vw(30, 'desktop')}  ${vw(30, 'desktop')};
    `}

    ${mq.greaterThan('desktop')`
      padding: 20px 0 30px 30px ;
    `}  

  header {
    height: auto;

    button {
      height: ${vw(20, 'mobile')};

      ${mq.greaterThan('tablet')`
        height: ${vw(20, 'desktop')};
      `}

      ${mq.greaterThan('desktop')`
        height: 20px;
      `}  
    }
    
    figure {
      height: ${vw(10, 'mobile')};

      ${mq.greaterThan('tablet')`
        height: ${vw(10, 'desktop')};
      `}

      ${mq.greaterThan('desktop')`
        height: 10px;
      `}  
    }
  }

  div {
    padding-right: ${vw(50, 'mobile')};

    ${mq.greaterThan('tablet')`
      padding-right: ${vw(50, 'desktop')};
    `}

    ${mq.greaterThan('desktop')`
      padding-right: 50px;
    `}  
  }
  
  }

  p, button, a {
    ${getP15_1()}
    color: ${({ theme }) => theme.colors.white};

    ${mq.greaterThan('desktop')`
      font-size: 15px;
      line-height: 20px;
    `}  
  }
 
`

const closeIconWhite = {
  type: 'svg' as const,
  src: '/images/svg/close-white.svg',
  alt: 'Close modal icon',
  size: { width: 12, height: 12 }
} 

const dialogVariants = (isActive: boolean) => ({
  initial: { x: '-100%' },
  animate: { x: isActive ? 0 : '-100%' },
  exit: { x: '-100%' },
  transition: { duration: .3 }
})

export const ModalCookies: FC<MenuProps> = memo(() => {
  const { isActive, toggle } = useModal(MODALS.COOKIES)
  const { toggle:modalRegion } = useModal(MODALS.REGION_LANG)
  const { locale } = useRouter()
  const [storeLocation, setStoreLocation] = useState('')
  const [country, setCountry] = useState([])
  const [subdivisions, setSubdivisions] = useState([])  
  const { t } = useTranslator()

  const handleRegion = () => {
    modalRegion()
    toggle()
  }
  useEffect(() => {
    if(country?.length === 0) {
      getCountry().then((res)=>{
        setCountry(Array.from(res))
      })
    }
  }, [country])
  
  useEffect(() => {
    getSubdivisions('ES').then((res) => {
      setSubdivisions(Array.from(res))
    })
  
  }, [country])

  useEffect(()=> {
    const region = getLocaleCountry(locale).split('-')[1]
    const countryLocalization = country.find((el)=> el.value === region)
    setStoreLocation(countryLocalization?.name)
   
  }, [locale, country, subdivisions])

  const onClick = () => {
    //MISSING GETTING GUEST USER, AND ONCE YOU GO TO LINK, HOW DO WE SET THE COOKIE? FLOW
    cookie.set('acceptedCookie', 'true', 1)
    toggle()
  }

  return (
    <Modal isActive={isActive} close={toggle} title={''} xAlign='right' variants={dialogVariants} dialogStyles={dialogStyles} hasHeader={false} >
      <header>
        <ButtonHeader type='button' data-dismiss='modal' aria-label={t('commons.actions.close') as string} onClick={onClick}>
          <Figure media={closeIconWhite} fill={false} />
        </ButtonHeader>
      </header>
      <div>
        <p>{t('commons.modal_cookies.ubication')} {storeLocation}. {t('commons.modal_cookies.ubication_2')} <button onClick={handleRegion}> {t('commons.modal_cookies.here')}.</button></p>
        <p>{t('commons.modal_cookies.cookies')} 
          <Link href='/page/politica-de-cookies'> {t('commons.modal_cookies.here')}.</Link>
        </p>
      </div>
    </Modal>
  )
})
