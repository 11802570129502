import { memo, useMemo } from 'react'
import { motion } from 'framer-motion'
import { Button, Figure } from '@dy/commons/components'
import { useDeviceType } from '@dy/commons/hooks'
import { parserMedia } from '@dy/commons/utils'
import { img_variants_single, img_variants_left, img_variants_right, img_variants_center, text_variants_title, text_variants_subtitle, text_variants_button } from './homeVariants'
import { HomeHeader, TextWrapper } from './homeStyles'
interface IHeaderHome {
  data: any
}


const getImages = (images, main_type, isMobile) => images.map((img, idx) => {
  const _variants = main_type === 'media' ? img_variants_single : img?.position == 'left' ? img_variants_left : img?.position == 'right' ? img_variants_right : img_variants_center

  return (
    <motion.div key={idx} className={'homeheaderimage-' + (main_type === 'media' ? 'center' : img?.position)} variants={_variants} animate={'active'} initial='inactive' custom={isMobile}>
      <Figure lazy={false} media={parserMedia(img)} oldVersion />
    </motion.div>
  )
})

export const HeaderHome = memo<IHeaderHome>(({ data }) => {
  const { title, subtitle, link, left_image, center_image, right_image, text_position: position, text_align: alignment, media, main_type, text_valign, title_size, subtitle_size } = data

  const images = useMemo(() => {
    if (main_type === 'product') {
      return [left_image, center_image, right_image]
    } else {
      return [media]
    }
  }, [left_image, center_image, right_image, media, main_type])

  const { isMobile } = useDeviceType()
  const ImageList = useMemo(() => getImages(images, main_type, isMobile), [images, main_type, isMobile])
  const filteredImages = images.filter(el => el != null)
  const singleImage = filteredImages?.length === 1

  return (
    <HomeHeader data-length={filteredImages.length}>
      {ImageList}
      <TextWrapper className={'home-header-pos-' + position} $title_size={title_size} $subtitle_size={subtitle_size} $text_valign={text_valign}>
        <motion.h2 custom={singleImage} variants={text_variants_title} animate={'active'} initial='inactive' className={'home-header-align-' + alignment + ' home-header-text-title'}>{title}</motion.h2>
        <motion.p custom={singleImage} variants={text_variants_subtitle} animate={'active'} initial='inactive' className={'home-header-align-' + alignment + ' home-header-text-subtitle'}>{subtitle}</motion.p>
        <a href={link.path}>
          <Button custom={singleImage} variants={text_variants_button} animate={'active'} initial='inactive' className={'home-header-align-' + alignment + ' home-header-text-button'}>
            {link.title}
          </Button>
        </a>
      </TextWrapper>
    </HomeHeader>
  )
})
