import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'
import { mq, vw, hideScrollbar, hex2Rgba, getP15_3, getP20_1, getP15_2, getP30, getP20, getP15_5, getP18_1 } from '@dy/commons/styles'

export const NavSection = styled(motion.section)<any>`
  display: none;
  ${hideScrollbar()}

  ${mq.greaterThan<any>('tablet')`
    height: calc(100vh - 80px);
    display: flex;
    grid-column: 1 / span 3;
    grid-row: 1 / span 4;
    margin-bottom: ${vw(100, 'desktop')};
    overflow-y: auto;
    padding: 0 ${vw(30, 'desktop')};
    position: sticky;
    top: ${vw(90, 'desktop')};
    transform: translateX(${({ $productDetailVisible }) => $productDetailVisible ? `-${vw(120 * 3, 'desktop')}` : 0});
    transition: 200ms transform ${({ theme }) => theme.ease};
    z-index: 50;
  `}

  ${mq.greaterThan<any>('desktop')`
    margin-bottom: 100px;
    padding: 0 30px;
    top: 90px;
    transform: translateX(${({ $productDetailVisible }) => $productDetailVisible ? `-${120 * 3}px` : 0});
  `}
`

export const FiltersSection = styled.section<any>`
  grid-column: 1 / span 6;
  grid-row: 1 / span 1;
  height: ${vw(60, 'mobile')};
  position: sticky;
  top: ${vw(60, 'mobile')};
  transition: 300ms width ${({ theme }) => theme.ease}, 300ms top ${({ theme }) => theme.ease};
  z-index: 20;
  will-change: top;

  ${mq.greaterThan<any>('tablet')`
    grid-column: 4 / span 9;
    height: auto;
    margin-top: -${vw(10, 'desktop')};
    top: ${vw(90, 'desktop')};
    z-index: 105;
    width: ${({ $productDetailVisible }) => $productDetailVisible ? 'calc(100% + 25vw)' : '100%'};
  `}

  ${mq.greaterThan<any>('desktop')`
    margin-top: -10px;
    top: 90px;
  `}

  ul {
    align-items: center;
    background-color: ${({ theme }) => theme.colors.background};
    box-shadow: ${({ isScrolling, theme: { colors } }) => isScrolling ? `0 4px 40px 0 ${hex2Rgba(colors.black, .1)}` : 'none'};
    display: flex;
    height: ${vw(60, 'mobile')};
    justify-content: center;
    transition: 300ms width ease-in-out, 200ms transform ${({ theme }) => theme.ease};
    z-index: 2;
    width: 100vw;

    ${mq.greaterThan<any>('tablet')`
      box-shadow: none;
      height: ${vw(60, 'desktop')};
      justify-content: flex-start;
      transform: translate(${({ $productDetailVisible }) => $productDetailVisible ? `-${vw(120 * 3, 'desktop')}` : 0}, 0);
      width: 100%;
    `}

    ${mq.greaterThan<any>('desktop')`
      height: 60px;
      transform: translate(${({ $productDetailVisible }) => $productDetailVisible ? `-${120 * 3}px` : 0}, 0);
    `}

    li {
      display: none;

      ${mq.greaterThan('tablet')`
        display: inline-block;
      `}

      &:not(:nth-child(2)) {
        ${mq.greaterThan('tablet')`
          margin-left: ${vw(10, 'desktop')};
        `}

        ${mq.greaterThan('desktop')`
          margin-left: 10px;
        `}
      }

      &:first-child {
        display: inline-block;
        height: ${vw(50, 'mobile')};

        ${mq.greaterThan('tablet')`
          display: none;
        `}

        button {
          ${getP15_3()}
          height: 100%;
          justify-content: center;
          min-width: ${vw(250, 'mobile')};
        }
      }

      &.slider-bar-wrapper {
        width: 100%;
      }
    }
  }
`

export const FiltersButton = styled.button<any>`
  align-items: center;
  background-color: ${({ theme, isActive }) => hex2Rgba(theme.colors.darkred, isActive ? 1 : 0)};
  border: 1px solid ${({ theme, isActive }) => hex2Rgba(theme.colors.grey, isActive ? 1 : .3)};
  border-radius: 90px;
  color: ${({ theme, isActive }) => isActive ? theme.colors.background : hex2Rgba(theme.colors.darkred, .5)};
  display: flex;
  ${getP15_5()}
  font-family: ${({ theme }) => theme.fonts.basier};
  font-weight: 500;
  height: ${vw(40, 'mobile')};
  transition: 200ms background-color ${({ ease }) => ease}, 200ms border-color ${({ ease }) => ease}, 200ms color ${({ ease }) => ease};
  padding: 0 ${vw(20, 'mobile')};

  ${mq.greaterThan('tablet')`
    height: ${vw(40, 'desktop')};
    padding: 0 ${vw(20, 'desktop')};
  `}

  ${mq.greaterThan('desktop')`
    height: 40px;
    padding: 0 20px;
  `}

  &:hover {
    background-color: ${({ theme, isActive }) => hex2Rgba(theme.colors.darkred, isActive ? 1 : 0)};
    border: 1px solid ${({ theme }) => hex2Rgba(theme.colors.darkred, 1)};
    color: ${({ theme, isActive }) => isActive ? theme.colors.background : hex2Rgba(theme.colors.darkred, .5)};
  }
`

export const FeaturedSection = styled<any>(motion.section)`
  grid-column: 1 / span 6;
  grid-row: 2 / span 1;

  ${mq.greaterThan('tablet')`
    grid-column: 4 / span 9;
  `}

  > div {
    ${mq.greaterThan<any>('tablet')`
      padding: ${vw(60, 'desktop')} 0 0 ${vw(10, 'desktop')};
      transform: translateX(${({ $productDetailVisible }) => $productDetailVisible ? `-${vw(120 * 3, 'desktop')}` : 0});
      transition: 300ms width ease-in-out, 200ms transform ${({ theme }) => theme.ease};
      width: 100%;
    `}

    ${mq.greaterThan<any>('desktop')`
      padding: 60px 0 0 10px;
      transform: translateX(${({ $productDetailVisible }) => $productDetailVisible ? `-${120 * 3}px` : 0});
    `}

    li {
      padding: ${vw(40, 'mobile')} ${vw(20, 'mobile')};
      width: ${vw(414, 'mobile')};

      ${mq.greaterThan('tablet')`
        padding: 0 ${vw(158, 'desktop')} 0 0;
        width: ${vw(120 * 7, 'desktop')};
      `}

      ${mq.greaterThan('desktop')`
        padding: 0 158px 0 0;
        width: ${120 * 7}px;
      `}
    }
  }
`

export const ProductListSection = styled(motion.section)<any>`
  grid-column: 1 / span 6;
  grid-row: 3 / span 1;
  margin-bottom: ${vw(100, 'mobile')};
  padding-top: ${({ $packsGrid }) => $packsGrid ? 0 : `${vw(20, 'mobile')}`};
  transition: 300ms width ease-in-out;

  ${mq.greaterThan<{ $packsGrid: boolean}>('tablet')`
    grid-column: ${({ $packsGrid }) => $packsGrid ? '2 / span 10' : '4 / span 9'};
    grid-row: 2 / span 4;
    margin-bottom: ${vw(100, 'desktop')};
    padding-top: ${({ $packsGrid }) => $packsGrid ? 0 : `${vw(40, 'desktop')}`};
  `}

  ${mq.greaterThan<{ $packsGrid: boolean }>('desktop')`
    margin-bottom: 100px;
    padding-top: ${({ $packsGrid }) => $packsGrid ? 0 : '40px'};
  `}
`

export const ProductList = styled(motion.ul)<any>`
  display: grid;
  grid-gap: ${vw(10, 'mobile')};
  grid-template-columns: repeat(2, 1fr);
  padding: 0 ${vw(10, 'mobile')};

  ${mq.greaterThan<{ $productDetailVisible: boolean, $packsGrid: boolean}>('tablet')`
    grid-gap: ${({ $packsGrid, $productDetailVisible }) => ($packsGrid && !$productDetailVisible) ? `${vw(60, 'desktop')}` : `${vw(10, 'desktop')}`};
    grid-template-columns: repeat(${({ $packsGrid }) => $packsGrid ? 3 : 4}, 1fr);
    padding: 0 ${({ $productDetailVisible }) => $productDetailVisible ? 0 : `${vw(10, 'desktop')}`} 0 0;
    transform: translateX(${({ $productDetailVisible, $packsGrid }) => $productDetailVisible ? `-${vw(120 * ($packsGrid ? 1 : 3), 'desktop')}` : 0});
    transition: 200ms width ${({ theme }) => theme.ease}, 200ms transform ${({ theme }) => theme.ease};
    width: ${({ $productDetailVisible, $packsGrid }) => 100 - ($productDetailVisible ? $packsGrid ? 29.166666666666668 : 22.22222222222222 : 0)}%;
  `}

  article {
    .product-card-image {
      + div {
        max-width: ${vw(188, 'mobile')};

        ${mq.greaterThan<{ $productDetailVisible: boolean, $packsGrid: boolean}>('tablet')`
          display: grid;
          max-width: ${({ $packsGrid, $productDetailVisible }) => vw($productDetailVisible ? $packsGrid ? 260 : 195 : $packsGrid ? 360 : 260, 'desktop')};
        `}
      }
    }

    .stacked,
    .product-card_info {
      ${mq.greaterThan<{ $productDetailVisible: boolean }>('tablet')`
        ${({ $productDetailVisible }) => { if($productDetailVisible) { return css`
          padding-left: ${vw(15, 'desktop')};
          padding-right: ${vw(15, 'desktop')}
        `}}}
      `}
    }

    &.pack {
      ${({ $productDetailVisible }) => { if(!$productDetailVisible) { return css`width: auto;`}}}
    }
  }

  li {
    ${mq.greaterThan('tablet')`
      height: ${vw(360, 'desktop')};
      width: auto;
    `}

    article {
      margin: 0;
      width: auto;
    }

    article:not(.pack) {
      width: auto;

      .product-card-image {
        ${mq.greaterThan('tablet')`
          max-width: ${vw(260, 'desktop')};
          min-width: ${vw(180, 'desktop')};
          width: 100%;
        `}
      }
    }
  }
`

export const NoProductsMessage = styled(motion.p)<any>`
  ${getP30()}
  font-weight: 400;
  opacity: .4;
  padding: ${vw(40, 'mobile')} ${vw(20, 'mobile')};
  text-align: center;

  ${mq.greaterThan('tablet')`
    grid-column: 4 / span 9;
    padding: ${vw(40, 'desktop')} 0;
    margin: 0 auto;
    max-width: ${vw(120 * 6, 'desktop')};
  `}
`

export const ProductDetailSection = styled<any>(motion.section)`
  background-color: ${({ theme }) => theme.colors.white};
  height: calc(100vh - 60px);
  grid-column: 1 / span 6;
  left: 0;
  margin: auto;
  overflow-y: auto;
  position: fixed;
  top: 60px;
  transform: translateX(${({ $productDetailVisible }) => $productDetailVisible ? 0 : '100vw'});
  transition: 400ms transform ${({ theme }) => theme.ease}, 400ms margin ${({ theme }) => theme.ease}, 300ms top ${({ theme }) => theme.ease}, 300ms height ${({ theme }) => theme.ease};
  right: -10px;
  ${hideScrollbar()}
  width: 90vw;
  z-index: 100;
  will-change: translate, top, height;

  ${mq.greaterThan<{ $productDetailVisible: boolean }>('tablet')`
    border-radius: 20px;
    grid-column: 8 / span 5;
    grid-row: 2 / auto;
    left: auto;
    margin-top: ${vw(40, 'desktop')};
    padding-bottom: ${vw(60, 'desktop')};
    position: sticky;
    top: ${vw(145, 'desktop')};
    transform: translateX(${({ $productDetailVisible }) => $productDetailVisible ? 0 : `${vw(600, 'desktop')}`});
    z-index: 100;
    width: ${vw(580, 'desktop')};
    height: calc(100vh - ${vw(160, 'desktop')});
    margin-bottom: 15px;
  `}

  &.plp-klk {
    height: calc(100vh - 60px);
    top: ${vw(85, 'mobile')};

    ${mq.greaterThan<{ $showLargeProductDetail: boolean, $scrollUp: boolean }>('tablet')`
      height: calc(100vh - ${({ $showLargeProductDetail, $scrollUp }) => $showLargeProductDetail ? $scrollUp ? vw(140, 'desktop') : vw(60, 'desktop') : vw(160, 'desktop')});
      top: ${({ $showLargeProductDetail, $scrollUp }) => $showLargeProductDetail && $scrollUp ? vw(140, 'desktop') : vw(60, 'desktop')};
      z-index: 90;
    `}
  }

  &.packs-page-grid {
    ${mq.greaterThan('tablet')`
      grid-row: 1 / auto;
      margin-top: 0;
      height: calc(100vh - ${vw(100, 'desktop')});
      top: 85px;

      > button {
        top: ${vw(30, 'desktop')};
      }
    `}
  }

  &.not-in-grid {
    ${mq.greaterThan<{ $scrollTop: boolean }>('tablet')`
      height: calc(100vh - 100px);
      position: fixed;
      right: ${vw(10, 'desktop')};
      top: 85px;
      margin-top: ${({ $scrollTop }) => $scrollTop ? `${vw(40, 'desktop')}` : 0 };

      > div {
        padding-bottom: ${vw(10, 'desktop')};
      }

      > button {
        top: ${vw(30, 'desktop')};
      }
    `}
  }

  > div {
    margin-top: -${vw(40, 'mobile')};
    margin-bottom: 0;
    pointer-events: auto;
    background-color: transparent;

    ${mq.lessThan('tablet')`
      padding-bottom: ${vw(60, 'desktop')};
    `}

    ${mq.greaterThan('tablet')`
      margin-top: -${vw(40, 'desktop')};
    `}
  }
`

export const CloseDetailsButton = styled.button<any>`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.background};
  border-radius: 50%;
  display: flex;
  height: ${vw(40, 'mobile')};
  justify-content: center;
  pointer-events: auto;
  position: sticky;
  right: ${vw(20, 'mobile')};
  top: ${vw(20, 'mobile')};
  width: ${vw(40, 'mobile')};
  z-index: 200;
  transform: translateX(${({ $productDetailVisible }) => $productDetailVisible ? 0 : '100vw'});
  transition: 200ms transform ${({ theme }) => theme.ease}, 200ms margin ${({ theme }) => theme.ease};
  margin-right: 0;
  margin-left: auto;

  ${mq.greaterThan<{ $productDetailVisible: boolean }>('tablet')`
    height: ${vw(40, 'desktop')};
    right: ${vw(30, 'desktop')};
    top: ${vw(40, 'desktop')};
    width: ${vw(40, 'desktop')};
    transform: translateX(${({ $productDetailVisible }) => $productDetailVisible ? 0 : `${vw(600, 'desktop')}`});
  `}

  figure {
    height: ${vw(16, 'mobile')};
    width: ${vw(16, 'mobile')};

    ${mq.greaterThan('tablet')`
      height: ${vw(16, 'desktop')};
      width: ${vw(16, 'desktop')};
    `}
  }
`

export const Chevron = styled(motion.span)`
  display: inline-block;
  position: relative;
  user-select: none;
  width: ${vw(25, 'mobile')};

  ${mq.greaterThan('tablet')`
    width: ${vw(25, 'desktop')};
  `}

  figure {
    height: ${vw(8, 'mobile')};
    width: ${vw(6, 'mobile')};

    ${mq.greaterThan('tablet')`
      height: ${vw(8, 'desktop')};
      width: ${vw(6, 'desktop')};
    `}
  }
`

export const AccordionWrapper = styled<any>(motion.div)`
  overflow: hidden;

  &[aria-expanded=false] {
    button {
      pointer-events: none;
      user-select: none;
    }
  }

  button {
    ${getP18_1()}
    margin-top: ${vw(2, 'mobile')};

    ${mq.greaterThan('tablet')`
      margin-top: ${vw(2, 'desktop')};
    `}
  }
`

export const AccordionButton = styled.button<any>`
  align-items: center;
  border: 0;
  color: ${({ theme }) => theme.colors.darkred};
  display: flex;
  ${getP20()}
  height: ${vw(50, 'mobile')};
  justify-content: space-between;
  padding: 0 ${vw(40, 'mobile')};
  position: relative;
  width: 100%;
  white-space: normal;

  ${mq.greaterThan('tablet')`
    height: ${vw(50, 'desktop')};
    padding: 0 ${vw(40, 'desktop')};
  `}

> div {
    position: relative;

    &.txt-wrapper {
      display: flex;
      flex-direction: column;
      flex-shrink: 1;
      justify-content: space-between;
      text-align: left;
      width: calc(100% - 40px);
    }

    &:last-child {
      flex-shrink: 0;

      span {
        width: 27px;
      }
    }
  }

  .values {
    bottom: -${vw(20, 'mobile')};
    color: ${({ theme }) => theme.colors.darkred};
    ${getP15_5()}
    font-family: ${({ theme }) => theme.fonts.basier};
    font-weight: 500;
    letter-spacing: 0;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    text-overflow: ellipsis;
    transition: 200ms opacity ${({ theme }) => theme.ease};
    user-select: none;
    width: 100%;
    white-space: nowrap;

    ${mq.greaterThan('tablet')`
      bottom: -${vw(21, 'desktop')};
      letter-spacing: 0;
    `}

    &.visible {
      opacity: .5;
      user-select: auto;
    }
  }
`

export const CheckboxCircle = styled.div<any>`
  background-color: ${({ theme }) => theme.colors.lightred};
  border-radius: 100px;
  height: ${vw(9, 'mobile')};
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%) scale(${({ isActive }) => isActive ? 1 : 0});
  transition: transform 200ms ${({ theme }) => theme.ease};
  user-select: none;
  width: ${vw(9, 'mobile')};

  ${mq.greaterThan('tablet')`
    height: ${vw(9, 'desktop')};
    width: ${vw(9, 'desktop')};
  `}
`

export const CheckboxButton = styled.button<any>`
  align-items: center;
  border: 0;
  color: ${({ theme }) => theme.colors.darkred};
  display: flex;
  ${getP20()}
  height: ${vw(50, 'mobile')};
  justify-content: space-between;
  opacity: ${({ isActive, isMultiple }) => isMultiple && !isActive ? .5 : 1};
  padding: 0 ${vw(40, 'mobile')};
  width: 100%;
  white-space: normal;

  ${mq.greaterThan('tablet')`
    height: ${vw(50, 'desktop')};
    padding: 0 ${vw(40, 'desktop')};
  `}

  > div {
    position: relative;
  }

  svg {
    display: block;
    opacity: ${({ isActive }) => isActive ? 1 : .4};
    transition: opacity 200ms ${({ theme }) => theme.ease};
    width: ${vw(25, 'mobile')};

    ${mq.greaterThan('tablet')`
      width: ${vw(25, 'desktop')};
    `}

    path {
      transition: fill 200ms ${({ theme }) => theme.ease};
    }
  }

  figure {
    display: flex;
    height: ${vw(12, 'mobile')};
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%) scale(${({ isActive }) => isActive ? 1 : 0});
    transition: transform 200ms ${({ theme }) => theme.ease};
    user-select: none;
    width: ${vw(12, 'mobile')};

    ${mq.greaterThan('tablet')`
      height: ${vw(12, 'desktop')};
      width: ${vw(12, 'desktop')};
    `}
  }
`

export const FeaturedFiltersSection = styled<any>(motion.section)`
  grid-column: 1 / span 6;
  grid-row: 2 / span 1;

  ${mq.greaterThan('tablet')`
    grid-column: 4 / span 9;
    grid-row: 1;
  `}

  > div {
    ${mq.greaterThan<any>('tablet')`
      padding: ${vw(60, 'desktop')} 0 0 ${vw(10, 'desktop')};
      transform: translateX(${({ $productDetailVisible }) => $productDetailVisible ? `-${vw(350, 'desktop')}` : 0});
      transition: 300ms width ease-in-out, 200ms transform ${({ theme }) => theme.ease};
      width: 100%;
    `}

    li {
      padding: ${vw(40, 'mobile')} ${vw(20, 'mobile')};
      width: ${vw(414, 'mobile')};

      ${mq.greaterThan('tablet')`
        padding: 0 ${vw(158, 'desktop')} 0 0;
        width: 80%;
      `}
    }
  }
`

export const Article = styled.article`
  align-items: center;
  display: flex;
  flex-direction: column;

  ${mq.greaterThan('tablet')`
    align-items: flex-start;
    flex-direction: row;
  `}

  figure {
    flex-shrink: 0;
    margin-bottom: ${vw(20, 'mobile')};
    user-select: none;
    width: ${vw(125, 'mobile')};

    ${mq.greaterThan('tablet')`
      margin-bottom: 0;
      margin-right: ${vw(38, 'desktop')};
      width: ${vw(165, 'desktop')};
    `}
  }

  &.param-marca,
  &.param-brand,
  &.param-marque {
    figure {
      align-items: center;
      background: ${({ theme }) => theme.colors.darkred};
      border-radius: 50%;
      display: flex;
      height: ${vw(125, 'mobile')};
      justify-content: center;
      padding: ${vw(12, 'mobile')};

      ${mq.greaterThan('tablet')`
        height: ${vw(165, 'desktop')};
        padding: ${vw(20, 'desktop')};
      `}

      ${mq.greaterThan('desktop')`
        height: 165px;
        padding: 20px;
      `}

      img {
        filter: invert(.95);
        height: 100%;
      }
    }
  }

  .text-wrapper {
    padding-top: ${vw(20, 'mobile')};

    ${mq.greaterThan('tablet')`
      padding-top: ${vw(20, 'desktop')};
    `}

    ${mq.greaterThan('desktop')`
      padding-top: 20px;
    `}
  }

  h2 {
    color: ${({ theme }) => theme.colors.darkred};
    ${getP20_1()}
    font-weight: bold;
    letter-spacing: -.5;
    margin-bottom: ${vw(5, 'mobile')};

    ${mq.greaterThan('tablet')`
      margin-bottom: ${vw(5, 'desktop')};
    `}

    ${mq.greaterThan('desktop')`
      margin-bottom: 5px;
    `}
  }

  p {
    color: ${({ theme }) => theme.colors.darkred};
    ${getP15_2()}
    text-align: center;
  }

  h2, p {
    ${mq.greaterThan('tablet')`
      text-align: left;
    `}
  }
`
