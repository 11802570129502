import styled from 'styled-components'
import { mq, vw, hex2Rgba, getP53, getP30_1, getP15_1 } from '@dy/commons/styles'


export const NewsletterForm = styled.form`
  button {
    &[type='submit'] {
      margin-left: ${vw(14, 'mobile')};

      ${mq.greaterThan('tablet')`
        margin-left: ${vw(16, 'desktop')};
      `}
    }
  }

  > div {
    align-items: flex-end;
    display: flex;
  }

  legend > h2 {
    margin-bottom: ${vw(30, 'desktop')};
  }

  .input--newsletter {
    margin-bottom: 0;
    padding: 0;

    input {
      color: ${({ theme }) => theme.colors.white};
    }
  }
`

export const WrapperSubscribe = styled.div`
  margin: ${vw(14, 'mobile')};
  padding: ${vw(14, 'mobile')};
  background-color: ${({ theme }) => hex2Rgba(theme.colors.creme, .4)};
  display: flex;
  flex-direction: column;
  align-items: center;
  
  ${mq.greaterThan('tablet')`
      margin: ${vw(50, 'desktop')};
      padding: ${vw(30, 'desktop')};
  `}

  >:first-child { 
    ${getP53()}
    color: ${({ theme }) => theme.colors.red};
  }

  >:nth-child(2) {
    ${getP30_1()}
    color: ${({ theme }) => theme.colors.red};
  }

  >:last-child {
    margin: ${vw(50, 'mobile')};

    ${mq.greaterThan('tablet')`
       margin: ${vw(40, 'desktop')};
    `}
  }

  h1,h2 {
    padding: 20px;
  }

  ul {
    text-align: center;

    ${mq.greaterThan('tablet')`
      display: grid;
      grid-template-columns: ${vw(315, 'desktop')} auto;
      text-align: start;
    `}
    
    li {
      ${getP15_1()}
      padding: ${vw(14, 'mobile')};
      color: ${({ theme }) => theme.colors.red};

      ${mq.greaterThan('tablet')`
        padding: ${vw(14, 'desktop')};
      `}

      &:before {
      content: "✓";
      display: inline-block;
      margin-left: -1.3em;
      width: 1.3em;
      font-weight: 700;
      color: ${({ theme }) => theme.colors.creme};
    }
  }
}
`