'use-client'
import type { FC } from 'react'
import { memo } from 'react'
import type { SubmitHandler } from 'react-hook-form'
import { useForm } from 'react-hook-form'
import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'
import { useTranslator } from '@/hooks'
import { Form, Fieldset, DisplayError, Input } from '@dy/commons/components'

import { CURRENT_USER_QUERY } from '@/api'

const RESET_PASSWORD_MUTATION = gql`
  mutation RESET_PASSWORD_MUTATION($resetToken: String!, $password: String!, $confirmPassword: String!) {
    resetPassword(
      resetToken: $resetToken
      password: $password
      confirmPassword: $confirmPassword
      ) {
      id
      email
      name
    }
  }
`

type Props = {
  resetToken: string
}

type TypeFormInputs = {
  password: string
  confirmPassword: string
}

export const FormUserResetPassword:FC<Props> = memo(({ resetToken }) => {
  const { register, handleSubmit, watch, formState: { errors } } = useForm<TypeFormInputs>()
  const [reset, { error, loading, called }] = useMutation(RESET_PASSWORD_MUTATION)
  const { t } = useTranslator()
  console.log('FormUserResetPassword:', watch, errors, called)

  const onSubmit: SubmitHandler<TypeFormInputs> = async data => {
    console.log('FormUserResetPassword | onSubmit, data:', data)
    const { password, confirmPassword } = data

    await reset({
      variables: {
        resetToken,
        password,
        confirmPassword
      },
      refetchQueries: [
        { query: CURRENT_USER_QUERY }
      ]
    })

    // PENDING Reset inputs
  }

  return (
    <Form method='post' onSubmit={handleSubmit(onSubmit)}>
      <Fieldset disabled={loading} ariaBusy={loading} legend={t('commons.modals.auth.resetPassword.text') as string}>
        <DisplayError error={error} />
        <Input type='password' name='password' label='New password' register={register} autoComplete='new-password' placeholder='password' bgColor={'white'} />
        <Input type='password' name='confirmPassword' label='Confirm password' register={register} autoComplete='new-password' placeholder='password' bgColor={'white'} />
        <button type='submit'>Reset password</button>
      </Fieldset>
    </Form>
  )
})
