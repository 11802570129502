"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AddToCart = exports.NewCartItemsContext = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const router_1 = require("next/router");
const framer_motion_1 = require("framer-motion");
// import useCart from '@custom-bigcommerce/storefront-data-hooks/cart/use-cart'
// import useAddItem from '@custom-bigcommerce/storefront-data-hooks/cart/use-add-item'
// import useUpdateItem from '@custom-bigcommerce/storefront-data-hooks/cart/use-update-item'
// import useRemoveItem from '@custom-bigcommerce/storefront-data-hooks/cart/use-remove-item'
const _1 = require("./");
const hooks_1 = require("../hooks");
const AddToCartStyles_1 = require("./AddToCartStyles");
const hooks_2 = require("../hooks");
const context_1 = require("../context");
exports.NewCartItemsContext = (0, react_1.createContext)(null);
const ButtonDelete = ({ productCount, backToSingleUnit, updateUnit, isPDP }) => {
    const { isMobile } = (0, hooks_1.useDeviceType)();
    const animate = (0, react_1.useMemo)(() => isPDP ? 'expandedPDP' : isMobile ? 'expandedMobile' : 'expandedDesktop', [isMobile, isPDP]);
    const initialAndExit = (0, react_1.useMemo)(() => isPDP ? 'collapsedPDP' : 'collapsed', [isPDP]);
    const onClick = () => {
        updateUnit(false);
    };
    return ((0, jsx_runtime_1.jsx)(framer_motion_1.motion.div, Object.assign({ className: 'btn-bin', initial: initialAndExit, variants: AddToCartStyles_1.deleteVariants, exit: initialAndExit, animate: animate }, { children: (0, jsx_runtime_1.jsxs)(_1.Button, Object.assign({ circle: true, noHoverEffect: true, bgType: 'red', onClick: onClick, name: 'Remove unit', initial: 'collapsed', variants: AddToCartStyles_1.deleteButtonVariants, animate: 'expanded', exit: 'collapsed' }, { children: [(0, jsx_runtime_1.jsx)(framer_motion_1.motion.figure, Object.assign({ initial: 'binCollapsed', variants: AddToCartStyles_1.figureVariants, animate: backToSingleUnit ? 'fadeIn' : productCount === 1 ? 'binExpanded' : 'fadeOut' }, { children: (0, jsx_runtime_1.jsx)("img", { src: '/images/svg/btn-bin.svg', alt: 'Icon bin', width: '16', height: '19' }) }), `one-${productCount === 1}`), (0, jsx_runtime_1.jsx)(framer_motion_1.motion.figure, Object.assign({ initial: { opacity: 0, scale: 0, rotate: 0 }, animate: productCount > 1 ? { opacity: 1, scale: 1, rotate: 0 } : { opacity: 0, scale: 0, rotate: 90 }, transition: { duration: .3 }, exit: { opacity: 0, rotate: 90 }, className: 'figure-minus' }, { children: (0, jsx_runtime_1.jsx)("img", { src: '/images/svg/btn-minus.svg', alt: 'Icon minus sign', width: '20', height: '3', className: 'img-minus' }) }), `two-${productCount > 1}`)] })) })));
};
const AddButton = ({ onClick, className = '' }) => {
    return ((0, jsx_runtime_1.jsx)(framer_motion_1.motion.div, Object.assign({ className: className, initial: 'collapsed', variants: AddToCartStyles_1.defaultVariants, exit: 'collapsed', animate: 'expanded' }, { children: (0, jsx_runtime_1.jsx)(_1.Button, Object.assign({ circle: true, bgType: 'red', onClick: onClick }, { children: (0, jsx_runtime_1.jsx)("figure", { children: (0, jsx_runtime_1.jsx)("img", { src: '/images/svg/btn-sign-icon.svg', alt: 'Icon add to cart', width: '16', height: '16' }) }) })) })));
};
const TRANSLATIONS = {
    'add_to_cart': 'Add to cart',
    'no_stock': 'Out of stock'
};
let timer;
let cartItemTimer;
let productCount = 0;
exports.AddToCart = (0, react_1.memo)(({ cartItemData, bigcommerceId = null, variant_id = null, stock = 4, setHasUnitsInCart = null, isPDP = false, selectedCard, isCart = false }) => {
    var _a, _b;
    const { locale } = (0, router_1.useRouter)();
    const { isMobile } = (0, hooks_1.useDeviceType)();
    const { toggle: triggerModalAuth } = (0, hooks_2.useModal)(context_1.MODALS.AUTH);
    const { toggle: openModalComercial } = (0, hooks_2.useModal)(context_1.MODALS.COMERCIAL);
    const { toggle: openModalRegionRegister } = (0, hooks_2.useModal)(context_1.MODALS.ADD_TO_CART);
    //dev hardcoded
    const isPublic = true;
    const isCommercialRegion = false;
    const isRegionWithoutAuth = true;
    const animate = (0, react_1.useMemo)(() => isPDP ? 'expandedPDP' : isMobile ? 'expandedMobile' : 'expandedDesktop', [isMobile, isPDP]);
    const [backToSingleUnit, setBackToSingleUnit] = (0, react_1.useState)(false);
    //DEV HARDCODED
    // console.log('AddToCart', cartItemData, bigcommerceId, variant_id)
    // const addItem = useAddItem()
    // const updateItem = useUpdateItem()
    // const removeItem = useRemoveItem()
    // const { data: dataCart } = useCart()
    // const cartItems = useMemo(() => dataCart?.line_items?.physical_items?.filter((item) => item.variant_id === variant_id) ?? [], [dataCart, variant_id])
    // const cartUnits = useMemo(() => getCartUnits(cartItems), [cartItems])
    // const [productCount, setProductCount] = useState(cartUnits[variant_id] || 0)
    // const { cartItems:displayedCartItems, setCartItems } = useContext(NewCartItemsContext) || {}
    const { Container, addToast, toasts } = (0, hooks_1.useToast)();
    const onErrorHandler = (message) => {
        console.log('AddToCart tempLog', cartItemData, bigcommerceId, variant_id);
        addToast({ children: message, type: 'error' });
    };
    // const updateCartUnit = (units) => {
    //   let cartItem = cartItems.find((item) => item.variant_id === variant_id)
    //   let item = { productId: bigcommerce_id, variantId: variant_id, quantity: units }
    //   addToCart(cartItem, item, addItem, updateItem, removeItem, onErrorHandler)
    //     .then(res => {
    //       if(res?.error !== true) {
    //         setProductCount(units)
    //         // Nw cart items added to dropdown
    //         if(units > 0 && !cartUnits[variant_id] || units > cartUnits?.[variant_id] ) {
    //           const newCartItem = {...cartItemData, units: units }
    //           const index = displayedCartItems.findIndex(obj => obj.variant.bigcommerce_id === cartItemData.variant.bigcommerce_id)
    //           if(index === -1) {
    //             setCartItems && setCartItems([...displayedCartItems, newCartItem])
    //           } else {
    //             const clonedDisplayedCartItems = [...displayedCartItems]
    //             clonedDisplayedCartItems[index] = newCartItem
    //             setCartItems && setCartItems(clonedDisplayedCartItems)
    //           }
    //           clearTimeout(cartItemTimer)
    //           cartItemTimer = setTimeout(() => { setCartItems([]) }, 2000)
    //         }
    //       }
    //     })
    // }
    const updateUnit = (increase = true) => {
        const incomingValue = increase ? (productCount + 1) : (productCount - 1);
        setBackToSingleUnit(productCount === 2 && !increase);
        setHasUnitsInCart && setHasUnitsInCart(incomingValue > 0);
        //hardcoded dev TODO- DELETE
        productCount = incomingValue;
        // updateCartUnit(incomingValue)
    };
    // useEffect(() => {
    //   if(cartUnits) {
    //     setProductCount(cartUnits[variant_id] ?? 0)
    //     setHasUnitsInCart && setHasUnitsInCart(cartUnits[variant_id] > 0)
    //   }
    // }, [cartUnits])
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsxs)(AddToCartStyles_1.ButtonWrapper, Object.assign({ className: `${isPDP ? 'is-pdp' : isCart ? 'is-cart' : ''}` }, { children: [!isCart &&
                        (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: isPDP ?
                                (0, jsx_runtime_1.jsx)(_1.Button, Object.assign({ className: `gtm_add-to-cart btn-add--pdp${stock < 1 ? ' no-stock' : ''}`, bgType: 'red', onClick: isCommercialRegion ? openModalComercial : isRegionWithoutAuth ? openModalRegionRegister : isPublic ? triggerModalAuth : updateUnit }, { children: isCommercialRegion ? 'Como comprar' : isPublic ? 'Accede para comprar' : 'Add to cart' }))
                                :
                                    (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: stock > 0 ?
                                            (0, jsx_runtime_1.jsx)(_1.Button, Object.assign({ className: `gtm_add-to-cart btn-add${productCount + ((_a = cartItemData.variant.min_sale_units) !== null && _a !== void 0 ? _a : 1) > stock ? ' insufficient-stock' : ''}`, circle: true, bgType: 'red', onClick: isCommercialRegion ? openModalComercial : isRegionWithoutAuth ? openModalRegionRegister : isPublic ? triggerModalAuth : updateUnit, name: 'Add to cart' }, { children: (0, jsx_runtime_1.jsx)("figure", { children: (0, jsx_runtime_1.jsx)("img", { src: '/images/svg/btn-sign-icon.svg', alt: 'Icon add to cart', width: '16', height: '16' }) }) }))
                                            :
                                                (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: selectedCard ?
                                                        (0, jsx_runtime_1.jsx)(_1.Button, Object.assign({ className: `gtm_add-to-cart btn-add${productCount + ((_b = cartItemData.variant.min_sale_units) !== null && _b !== void 0 ? _b : 1) > stock ? ' insufficient-stock' : ''}`, circle: true, bgType: 'red', onClick: isCommercialRegion ? openModalComercial : isRegionWithoutAuth ? openModalRegionRegister : isPublic ? triggerModalAuth : updateUnit, name: 'Add to cart' }, { children: (0, jsx_runtime_1.jsx)("figure", { children: (0, jsx_runtime_1.jsx)("img", { src: '/images/svg/btn-sign-icon.svg', alt: 'Icon add to cart', width: '16', height: '16' }) }) }))
                                                        :
                                                            (0, jsx_runtime_1.jsx)(_1.Button, Object.assign({ className: `gtm_add-to-cart btn-add insufficient-stock ${!selectedCard ? 'not-selected-card' : ''}`, circle: false, bgType: 'red', onClick: updateUnit, name: 'Add to cart' }, { children: TRANSLATIONS['no_stock'] })) }) }) }), (0, jsx_runtime_1.jsx)(framer_motion_1.AnimatePresence, Object.assign({ mode: 'wait' }, { children: productCount > 0 &&
                            (0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(AddToCartStyles_1.Counter, Object.assign({ className: `counter ${isPDP ? ' is-pdp' : ''}`, isPDP: isPDP, initial: isPDP ? 'collapsedPDP' : isMobile ? 'collapsedMobile' : 'collapsedDesktop', variants: AddToCartStyles_1.counterVariants, exit: isPDP ? 'collapsedPDP' : isMobile ? 'collapsedMobile' : 'collapsedDesktop', animate: animate }, { children: (0, jsx_runtime_1.jsx)(AddToCartStyles_1.Units, Object.assign({ className: 'units', initial: { y: -15, opacity: 0 }, animate: { y: 0, opacity: 1 }, transition: { duration: .3 } }, { children: productCount }), productCount) })), (0, jsx_runtime_1.jsx)(ButtonDelete, { productCount: productCount, updateUnit: updateUnit, isPDP: isPDP, backToSingleUnit: backToSingleUnit }), isPDP && (0, jsx_runtime_1.jsx)(AddButton, { className: `gtm_add-to-cart btn-add${productCount + 1 > stock ? ' insufficient-stock' : ''}`, onClick: updateUnit })] }) }))] })), (0, jsx_runtime_1.jsx)(Container, { toasts: toasts })] }));
});
