import { memo } from 'react'
import { useTranslator } from '@/hooks'
import { Div } from './footerStyles'

export const CallUs = memo(() => {
  const { t } = useTranslator()
  
  return (
    <Div>
      <div>{t('layout.footer.questions')} <br /><a href={`mailto:${t('layout.footer.email')}`}>{t('layout.footer.write_us')}<span></span></a> {t('layout.footer.call_us')}<br /></div>
      <a href={`tel:+${t('layout.footer.phone_link')}`} className='phone'>{t('layout.footer.phone_number')}<span></span></a>
    </Div>
  )
})
