import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'
import { mq, vw, getP113, getP27_P18, getP18_1, getP26 } from '@dy/commons/styles'

export const Ul = styled(motion.ul) <{ $levelDepth: number }>`
  align-items: flex-start;
  color: ${({ theme }) => theme.colors.creme};
  font-family: ${({ theme }) => theme.fonts.primaryFont};
  display: flex;
  flex-direction: column;

  a {
    text-decoration: none;
  }

  a,
  button,
  summary {
    ${({ $levelDepth }) => $levelDepth === 0 ? getP113 : getP27_P18}
    align-items: center;
    color: inherit;
    display: flex;
    max-height: ${vw(80, 'mobile')};
    justify-content: flex-start

    ${mq.greaterThan('tablet')`
      height: ${vw(60, 'desktop')};
    `}
  }

  div {
    > dl,
    > ol,
    > ul {
      padding-left: ${vw(20 - 4.25, 'mobile')};

      ${mq.greaterThan('tablet')`
        padding-left: ${vw(20 - 12.5, 'desktop')};
      `}

      ${mq.greaterThan('desktop')`
        padding-left: 7.5px;
      `}

      button,
      summary {
        font-weight: 500;
      }
      a {
        ${getP18_1()}
        text-decoration: none;
        font-family: ${({ theme }) => theme.fonts.secondaryFont};
        color: ${({ theme }) => theme.colors.darkred};
      }

    }
    li:first-child {
      margin-top: ${vw(30, 'mobile')};
    }
    
    li:last-child {
      margin-bottom: 0px;
    }
  }
`

export const Li = styled(motion.li)`
  display: flex;
  width: 100%;
  margin-bottom: ${vw(26, 'mobile')};
`

export const Details = styled(motion.div)<any>`
  width: 100%;

  &[open] {
    height: auto;

    summary {
      figure {
        transform: rotate(180deg);
      }
    }
  }

  summary {
    justify-content: space-between;

    figure {
      max-height: 28px;
      max-width: 28px;
      transition: 200ms transform ease-out;
      transform-origin: center;
      width: ${vw(10, 'mobile')};

      ${mq.greaterThan('tablet')`
        width: ${vw(10, 'desktop')};
      `}

      ${mq.greaterThan('desktop')`
        width: 10px;
      `}
    }
  }
`

export const ButtonMenuBack = styled.button`
  align-items: center;
  display: flex;
  flex-shrink: 0;
  height: ${vw(48, 'mobile')};
  justify-content: center;
  max-width: 48px;
  transform: translateX(-30%);
  width: ${vw(48, 'mobile')};
  z-index: 2;

  ${mq.greaterThan('tablet')`
    height: ${vw(48, 'desktop')};
    width: ${vw(48, 'desktop')};
  `}

  ${mq.greaterThan('desktop')`
    height: 48px;
    width: 48px;
  `}

  figure {
    max-height: 28px;
    max-width: 28px;
    width: ${vw(10, 'mobile')};

    ${mq.greaterThan('tablet')`
      width: ${vw(10, 'desktop')};
    `}

    ${mq.greaterThan('desktop')`
      width: 10px;
    `}
  }
`

export const dialogStyles = css`
  background: ${({ theme }) => theme.colors.red};
  margin: 0;
  min-height: 100%;
  width: 100%;
  
  ${mq.greaterThan('tablet')`
    max-width: ${vw(400, 'desktop')};
    width: auto;
  `}

  ${mq.greaterThan('desktop')`
    max-width: 400px;
  `}

  .modal__body {
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: space-between;
    padding: ${vw(120, 'mobile')} ${vw(40 - 4.25, 'mobile')} ${vw(75, 'mobile')};

    .store_localization {
      ${getP26()}
      align-items: center;
      color: ${({ theme }) => theme.colors.white};
      display:flex;
      flex-direction: row;
      
      figure {
        width: ${vw(14, 'mobile')};
        height: ${vw(18, 'mobile')};
        margin-right:${vw(5, 'mobile')};
       }
    }
  }
`