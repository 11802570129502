import { memo, useMemo } from 'react'
import { Content } from './Content'
import { ContentSection } from './contentStyles'

interface IContent {
  type: 'text' | 'empty' | 'media' | 'spacer',
  col: { from: number, expand: number },
  row: { from: number, expand: number },
  text?: string,
  media?: any,
  color?: string,
  align?: { v: string, h: string },
  [key: string]: any
}

interface ISectionContents {
  section: {
    rows: number,
    columns: IContent[]
  }
}

const getContentsByColumns = (columns) => columns.map((content, idx) => (
  <Content key={idx + 'column'} content={content} />
))

export const SectionContents = memo(({ section }:ISectionContents) => {
  const { columns } = section
  const ContentsByColumns = useMemo(() => getContentsByColumns(columns), [columns])

  return (
    <ContentSection>
      {ContentsByColumns}
    </ContentSection>
  )
})
