import { FC, useMemo } from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { useTranslator } from '@/hooks'
import { parserMedia } from '@dy/commons/utils'
import type { TypeSearchResults } from '@dy/commons/schema'
import { Link, Figure } from '@dy/commons/components'
import { mq, vw, hex2Rgba, getP15 } from '@dy/commons/styles'

type Props = {
  data: TypeSearchResults
}

const List = styled.dl`
  grid-column: 1 / span 6;
  grid-row: 2 / span 1;
  overflow: auto;
  padding: ${vw(20, 'mobile')} 0;
  margin-bottom: 0;

  ${mq.greaterThan('tablet')`
    grid-column: 2 / span 5;
    padding: ${vw(50, 'desktop')} 0;
  `}

  ${mq.greaterThan('desktop')`
    padding: 50px 0;
  `}

  dt {
    color: ${({ theme }) => hex2Rgba(theme.colors.darkred, .5)};
    font-weight: 400;
    margin-bottom: ${vw(20, 'mobile')};

    ${mq.greaterThan('tablet')`
      margin-bottom: ${vw(20, 'desktop')};
    `}

    ${mq.greaterThan('desktop')`
      margin-bottom: 20px;
    `}

    button {
      color: ${({ theme }) => hex2Rgba(theme.colors.darkred, .5)};
      text-decoration: underline;
      transition: 300ms color ease-out;

      &:hover {
        color: ${({ theme }) => hex2Rgba(theme.colors.darkred, 1)};
      }
    }
  }
`

const ListItem = styled(motion.dd)`
  color: ${({ theme }) => hex2Rgba(theme.colors.darkred, 1)};
  font-weight: 500;
  margin-bottom: ${vw(10, 'mobile')};

  ${mq.greaterThan('tablet')`
    margin-bottom: ${vw(10, 'desktop')};
  `}

  ${mq.greaterThan('desktop')`
    margin-bottom: 10px;
  `}

  a {
    ${getP15}
    align-items: center;
    color: inherit;
    display: flex;
    text-decoration: none;
    width: 100%;

    &:hover {
      span {
        opacity: 1;
      }
    }

    span {
      opacity: .8;
      overflow: hidden;
      text-overflow: ellipsis;
      transition: 300ms opacity ease-out;
      white-space: nowrap;
    }
  }

  figure {
    align-items: center;
    display: flex;
    height: ${vw(40, 'mobile')};
    justify-content: center;
    margin-right: ${vw(15, 'mobile')};
    width: ${vw(40, 'mobile')};

    ${mq.greaterThan('tablet')`
      height: ${vw(40, 'desktop')};
      margin-right: ${vw(15, 'desktop')};
      width: ${vw(40, 'desktop')};
    `}

    ${mq.greaterThan('desktop')`
      height: 40px;
      margin-right: 15px;
      width: 40px;
    `}
  }
`

// This const resultIconByType keys set the order of the results by type
const resultIconByType = {
  categories: () => ({
    type: 'svg' as const,
    src: '/images/svg/i--search-result-category.svg',
    alt: 'Search result - category',
    size: { width: 16, height: 16 }
  }),
  brands: (item) => item?.logo,
  articles: () => ({
    type: 'svg' as const,
    src: '/images/svg/i--search-result-article.svg',
    alt: 'Search result - blog article',
    size: { width: 16, height: 16 }
  }),
  products: (item) => item?.media ?? parserMedia(item?.variant?.media?.[0]?.grid)
}

const getListItems = (data) => {
  const items = []
  console.log('data', data)
  for (let x of Object.keys(resultIconByType)) {
    const arr = data[x]
    if (!Array.isArray(arr)) continue

    for (const [idx, item] of arr.entries()) {
      const media = resultIconByType[x](item)

      items.push(<ListItem key={`${idx}-result`}>
        <Link href={item.href ?? item.slug['es']}>
          <Figure className={x} media={media} />
          <span>{item.name}</span>
        </Link>
      </ListItem>)
    }
  }

  return items
}

export const SearchResults: FC<Props> = ({ data = {} }) => {
  const ListItems = useMemo(() => getListItems(data), [data])
  const { t } = useTranslator()

  const onClick = () => {
    console.log('Borrar historial búsquedas en localStorage')
  }

  return (
    <List>
      <dt key={'dt-search-result'}>{t('layout.search.history')}. <button onClick={onClick}>{t('commons.actions.delete')}</button></dt>
      {ListItems}
    </List>
  )
}
