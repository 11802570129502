import { memo, useEffect, useState, useMemo } from 'react'
import { fetchAPI } from '@/api'
import { Figure } from '@dy/commons/components'
import { parserMedia } from '@dy/commons/utils'
import { WrapperProducts, MyProductsList } from '../homeStyles'

const getMyProducts = (products) => products.map((product, idx)=> {
  return (
    <MyProductsList key={idx}>
      {product.variant?.media?.[0]?.grid ?
        <Figure key={`grid-img-${product.variant?.bigcommerce_id}`} className='product-grid-image' media={parserMedia(product.variant?.media?.[0]?.grid)} quality={65} />
        :
        <p>No image</p>
      }
    </MyProductsList>
  )
})


export const GridMyProducts = memo(() => {
  //dev hardcoded
  const customerData= false
  const [currentProducts, setCurrentProducts] = useState({
    all: Array.from([]),
    current: Array.from([])
  })

  useEffect(() => {
    //devHardcoded
    const customerId = 3
    async function getUsualPurchases() {
      const [data, error] = await fetchAPI('user-usual-purchases', { customerId })
      if(!error) setCurrentProducts({ all: data.data, current: data.data })
    }

    if (customerId) getUsualPurchases()
  }, [customerData])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const GridProducts = useMemo(() => getMyProducts(currentProducts.current), [currentProducts.current])
  return (
    <WrapperProducts>
      <ul>
        {GridProducts}
      </ul>
    </WrapperProducts>
  )})
