"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AccordionItem = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const router_1 = require("next/router");
const useAccordionItem_1 = require("./useAccordionItem");
const accordionStyles_1 = require("./accordionStyles");
const __1 = require("../");
const utils_1 = require("../../utils");
const transition = {
    ease: [.25, .1, .25, 1],
    duration: .3,
    type: 'tween'
};
const expandVariants = {
    expanded: {
        height: 'auto',
        display: 'block',
        opacity: 1,
        transition
    },
    collapsed: {
        height: 0,
        opacity: 0,
        transitionEnd: {
            display: 'none',
        },
        transition
    },
};
const getFaqs = (faqs = [], slug, locale, asPath, activeQuestion) => faqs.map((faq, idx) => {
    return ((0, jsx_runtime_1.jsx)(__1.Link, Object.assign({ href: `/faqs/${slug[locale]}#${faq.hash}` }, { children: (0, jsx_runtime_1.jsx)(accordionStyles_1.LinkAsTitle, Object.assign({ active: faq.hash === asPath.split('#')[1] || faq.hash === activeQuestion }, { children: faq.question })) }), idx));
});
const AccordionItem = ({ data: { id, name, faqs, slug }, index, activeCategory, activeQuestion }) => {
    var _a;
    const { locale: localeRouter, query, asPath } = (0, router_1.useRouter)();
    const locale = (0, utils_1.getLang)(localeRouter);
    const [trigger, accordion, expanded, toggleExpanded, onKeyDown] = (0, useAccordionItem_1.useAccordionItem)(index, slug, locale);
    const triggerId = `${id}__trigger`;
    const regionId = `${id}__region`;
    const href = `/faqs/${slug[locale]}`;
    // @ts-ignore
    const active = (0, react_1.useMemo)(() => (slug[locale] === (query === null || query === void 0 ? void 0 : query.slug) || slug[locale] === activeCategory), [query, activeCategory]);
    const onClick = () => {
        toggleExpanded();
    };
    (0, react_1.useEffect)(() => {
        if (active && !expanded)
            toggleExpanded();
    }, [active]);
    const Faqs = (0, react_1.useMemo)(() => getFaqs(faqs, slug, locale, asPath, activeQuestion), [faqs, slug, locale, asPath, activeQuestion]);
    const firstQuestionHash = (_a = faqs === null || faqs === void 0 ? void 0 : faqs[0]) === null || _a === void 0 ? void 0 : _a.hash;
    if (Faqs.length === 0)
        return (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, {});
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsxs)(accordionStyles_1.AccordionTitle, Object.assign({ ref: trigger, id: triggerId, role: 'button', onClick: onClick, tabIndex: 0, "aria-expanded": expanded, "aria-controls": regionId, onKeyDown: onKeyDown }, { children: [(0, jsx_runtime_1.jsx)(__1.Link, Object.assign({ href: `${href}#${firstQuestionHash}` }, { children: (0, jsx_runtime_1.jsx)(accordionStyles_1.LinkAsTitle, Object.assign({ active: active }, { children: name })) })), (0, jsx_runtime_1.jsx)(accordionStyles_1.Chevron, Object.assign({ "aria-hidden": true, animate: { rotate: expanded ? 90 : 0 }, transition: transition }, { children: (0, jsx_runtime_1.jsx)("img", { src: '/images/svg/i--chevron.svg', alt: 'Icon chevron', width: '6', height: '8' }) }))] })), (0, jsx_runtime_1.jsx)(accordionStyles_1.AccordionContent, Object.assign({ ref: accordion, role: 'region', id: regionId, "aria-labelledby": triggerId, tabIndex: -1, animate: expanded ? 'expanded' : 'collapsed', initial: 'collapsed', variants: expandVariants }, { children: Faqs }))] }));
};
exports.AccordionItem = AccordionItem;
