import { memo, useMemo } from 'react'
import { useRouter } from 'next/router'
import { Figure, Price, AddToCart } from '@dy/commons/components'
import { vw } from '@dy/commons/styles'
import { getAllPricesCurrency } from '../account'
import { Section, CartListItem } from '.'
import { useTranslator } from '@/hooks'
import { getLang } from '@dy/commons/utils'
interface ICartList {
  data: any
}

const mediaThumbnailProduct = {
  type: 'image' as const,
  alt: 'Icon pasta',
  default: {
    src: '/images/pasta.jpeg',
    size: { width: 2698, height: 2698 }
  }
}
const mediaThumbnailProductSize = `(min-width: 768px) ${vw(100, 'desktop')}, ${vw(30, 'mobile')}`

const getProducts = (products,locale,t) => products.map((product,idx)=> {
  const { name, brand, categories } = product
  let amount = 0
  const priceObject = {
    'pricePerWeight': 45,
    'price': 25
  }
  const currencyPrices:any = getAllPricesCurrency(priceObject, locale)
  const {pricePerWeightCurrency, priceCurrency} = currencyPrices
  return(
    <>
      <CartListItem key={`${idx}-${'variantId'}`}>
        <div className='product'>
          <Figure media={mediaThumbnailProduct} quality={50} sizes={mediaThumbnailProductSize}/>
          <div className={'info'}>
            <span>{amount} x {name}</span>
            <span>{brand} | {pricePerWeightCurrency}/Kg</span>
            <span>{priceCurrency}/u</span>
          </div>
        </div>
        <div className='counter'>
          <AddToCart cartItemData={{ name, brand, categories}} variant_id={123} bigcommerceId={123} stock={4} isCart={true} isPDP={true} />
        </div>
        <div className='price_wrapper'>
          <Price type='card' price={{ retailPrice: { exTax: 999, incTax: 9990 }, salePrice: { exTax: 999, incTax: 999 } }} displayRetailPrice={false}></Price>
          <button>{t('commons.actions.remove')}<span></span></button>
        </div>
      </CartListItem>
    </>
  )
})

export const CartList = memo<ICartList>(({ data }) => {
  const { locale: localeRouter } = useRouter()
  const locale = getLang(localeRouter)
  const { t } = useTranslator()

  const Products = useMemo(() => getProducts(data, locale, t),[data,locale, t])

  return (
    <Section>
      <ul>
        {Products}
      </ul>
    </Section>
  )
})


