"use strict";
// PENDING improve locale on base file instead of here since its a general type
// type TypeLocale = 'en-EU' | 'es-ES' | 'es-CT' | 'es-CN' | 'es-MD' | 'en-GB' | 'fr-FR' | 'de-DE'
// type TypeLocaleCountry = 'es-ES' | 'en-GB' | 'fr-FR' | 'de-DE'
Object.defineProperty(exports, "__esModule", { value: true });
exports.getRegion = exports.getLang = exports.getLocaleCountry = void 0;
// export function getLocaleCountry(locale: TypeLocale): TypeLocaleCountry {
function getLocaleCountry(locale) {
    switch (locale) {
        case 'es-CN':
        case 'es-MD':
        case 'es-CT':
            return 'es-ES';
        case 'en-EU':
            return 'en-GB';
        default:
            return locale;
    }
}
exports.getLocaleCountry = getLocaleCountry;
function getLang(str) {
    if (typeof str !== 'string')
        throw new Error('getLang parameter must be typeof "string"');
    return str.substring(2, 0);
}
exports.getLang = getLang;
function getRegion(str) {
    if (typeof str !== 'string')
        throw new Error('getRegion parameter must be typeof "string"');
    return str.split('-')[1];
}
exports.getRegion = getRegion;
