import { memo, useMemo } from 'react'
import { useRouter } from 'next/router'
import { Link } from '@dy/commons/components'
import { AccountNav, AccountNavListItem } from './accountStyles'

//dev hardcoded
export const navItems = [
  {
    'name': 'Datos de acceso',
    'url': '/account'
  },
  {
    'name': 'Mis pedidos',
    'url': '/account/orders'
  },
  {
    'name': 'Facturación',
    'url': '/account/invoices'
  },
  {
    'name': 'Cerrar sesión',
    'url': '/'
  }
]

export const getNavItems = (items, asPath, onClick?:()=> void) => items.map((item, idx) => (
  <AccountNavListItem key={idx} $active={asPath === item.url} onClick={onClick}>
    <Link href={item.url}>{item.name}</Link>
  </AccountNavListItem>
))

export const NavAccount = memo(() => {
  const { asPath } = useRouter()

  const NavItems = useMemo(() => getNavItems(navItems, asPath), [asPath])

  return (
    <AccountNav>
      <ul>
        {NavItems}
      </ul>
    </AccountNav>
  )
})
