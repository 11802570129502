import { memo, useEffect, useState, useMemo } from 'react'
import { useRouter } from 'next/router'
import { useForm } from 'react-hook-form'
import type { SubmitHandler } from 'react-hook-form'
import styled from 'styled-components'
import { mq, vw, getP18_1, getP18_4 } from '@dy/commons/styles'
import { Button, Select } from '@dy/commons/components'
import { removeIndexFromArray } from '@dy/commons/utils'
import type { TypeThemeColors } from '@dy/commons/components'
import { useTranslator } from '@/hooks'

// TESTING APOLLO
import { useQuery } from '@apollo/client'
import { GET_PRODUCTS } from '@/api'
import type { iQueryProducts } from '@/api'


interface IStorePicker {
  bgInputColor?: TypeThemeColors,
  textColorInput?: TypeThemeColors,
  colorWrapper?: TypeThemeColors,
  textColorSubdividions?: TypeThemeColors,
  isModal?: boolean,
  toggle?: () => void
  dropdownTextColor?: TypeThemeColors,
}

type TypeFormStoresZoneInputs = {
  country: string
  subdivision?: string,
}

const Wrapper = styled.div<any>`
  color: ${({ theme , $colorWrapper }) =>  $colorWrapper ? theme.colors[$colorWrapper] : theme.colors.white};
  margin: 0 auto;
  max-width: ${vw(480, 'mobile')};
  padding: ${vw(40, 'mobile')} ${vw(40, 'mobile')} 0;
  width: 100%;

  ${mq.greaterThan('tablet')`
    max-width: ${vw(480, 'desktop')};
    padding: 0;
  `}

  form {
    > div {
      margin: 0 auto;

      &:first-child {
        z-index: 5;
      }

      &:last-of-type {
        opacity: ${({ $selectedCountry }) => $selectedCountry === 'ES' ? 1 : 0 };
        pointer-events: ${({ $selectedCountry }) => $selectedCountry === 'ES' ? 'auto' : 'none' };
        user-select: ${({ $selectedCountry }) => $selectedCountry === 'ES' ? 'auto' : 'none' };
      }
    }
  }
  .disabled {
    opacity: 0.3;
    pointer-events: none;
    user-select: none;
  }
  button[type='submit'] {
    width: ${({ $isModal }) => $isModal ? '100%' : 'auto' };
    margin-top: ${({ $isModal }) => $isModal ? vw(100, 'desktop') : 'unset' };
  }
`

const Div = styled.div<any>`
  opacity: ${({ $active }) => $active ? 1 : 0 };
  padding: ${vw(30, 'mobile')} ${vw(4.25, 'mobile')};;
  text-align: center;

  ${mq.greaterThan('tablet')`
    padding: ${vw(40, 'desktop')} ${vw(12.5, 'desktop')};;
  `}

  dl {
    margin: ${vw(25, 'mobile')} 0 ;

    ${mq.greaterThan('tablet')`
      margin: ${vw(25, 'desktop')} 0;
    `}
  }

  dt {
    ${getP18_1()}
    color: ${({ theme, $textColorSubdividions }) => theme.colors[$textColorSubdividions ?? 'white']};
    padding-bottom: ${vw(10, 'mobile')};
    text-align:  ${({ $isModal }) =>  $isModal ? 'left' : 'center'};

    ${mq.greaterThan('tablet')`
      padding-bottom: ${vw(10, 'desktop')};
    `}
  }
`


const ListItem = styled.dd<any>`
  color: ${({ theme, $textColorSubdividions }) => theme.colors[$textColorSubdividions ?? 'white']};
  text-align:  ${({ $isModal }) =>  $isModal ? 'left' : 'center'};

  > span {
    text-align:  ${({ $isModal }) =>  $isModal ? 'left' : 'center'};
    display: block;
    padding-bottom: ${vw(10, 'mobile')};

    ${mq.greaterThan('tablet')`
      padding-bottom: ${vw(10, 'desktop')};
    `}

    &:first-child {
      ${getP18_4()}
    }

    &:last-child {
      padding-bottom: ${vw(30, 'mobile')};

      ${mq.greaterThan('tablet')`
        padding-bottom: ${vw(40, 'desktop')};
      `}
    }
  }
  a {
    display: block;
  }
`

//dev Hardcoded
const FAKE_DATA = [
  {
    name: 'Comercial Catchot',
    tel: {
      label: 'T 971 363 052',
      value: '0034971363052'
    },
    email: 'pedidos@comercialcatchot'
  }
]

const getComercials = (data, textColorSubdividions, isModal) => data.map((comercial, idx) => {
  const { name, tel, email } = comercial
  return (
    <ListItem key={`comercial-${idx}`} $textColorSubdividions={textColorSubdividions} $isModal={isModal}>
      <span>{name}</span>
      <a href={`tel:${tel.value}`}>{tel.label}</a>
      <a href={`mailto:${email}`}>{email}</a>
    </ListItem>
  )
})

let displayComercial = false
let buttonDisabled = true

const getStore = (selectedOptions, subdivisions) => {
  const [regionCode, subdivisionCode] = selectedOptions
  if((regionCode?.length > 0)) {
    buttonDisabled = false
  }
  let store = null
  if(regionCode === 'ES') {
    buttonDisabled = true
    if (!subdivisionCode) return store
    const subdivisionData = subdivisions.filter(o => o.value === subdivisionCode)[0]
    if (subdivisionData?.store) {
      const val = subdivisionData.value
      const locale = `${val.split('-')[0].toLowerCase()}-${val.slice(-2)}`
      store = { locale, query: { zone: 'ES' } }
      buttonDisabled= false
    } else {
      displayComercial = true
      buttonDisabled= false
    }
  } else if (regionCode === 'GB') {
    store = { locale: 'en-GB', query: { zone: 'GB' }}
  } else if (regionCode === 'DE') {
    store = { locale: 'de-DE', query: { zone: 'DE' }}
  } else if (regionCode === 'FR') {
    store = { locale: 'fr-FR', query: { zone: 'FR' }}
  }

  return store
}

 type TypeRegionItem = {
  value: string
  name: string,
}
export type TypeRegionJson = TypeRegionItem[]

export async function getCountry() {
  // @ts-ignore
  let data = await import('/public/locales/countries/ALL-countries.json')
  return data
}

export async function getSubdivisions (countryCode) {
  const data:TypeRegionJson = await import(`/public/locales/countries/${countryCode}-subdivisions.json`, { assert: { type: 'json' } })
  const dataArr = Array.from(data)
  const subdivisionWithData = []

  if(countryCode === 'ES') {
    const dataStores:TypeRegionJson = await import(`/public/locales/countries/${countryCode}-subdivisions-with-stores.json`, { assert: { type: 'json' } })
    const subdivisionsWithStores = Array.from(dataStores)

    for(let item of subdivisionsWithStores) {
      // @ts-ignore
      const idx = dataArr.find(o => o.value === item.value) as number

      if(idx) {
        subdivisionWithData.push({ ...dataArr[idx] as object, ...item as object })
        removeIndexFromArray(dataArr, idx)
      }
    }
  }

  return subdivisionWithData.concat(dataArr)
}

export const FormStore = memo<IStorePicker>(({ bgInputColor = 'red', textColorInput = 'darkred', colorWrapper, textColorSubdividions = 'white', isModal = false, toggle, dropdownTextColor = null }) => {
  const { data, loading, error } = useQuery<iQueryProducts>(GET_PRODUCTS, {
    variables: { countryCode: 'ES', lang: 'es' }
  })

  const { data:dataBC, loading:loadingBC, error:errorBC } = useQuery<iQueryProducts>(GET_PRODUCTS, {
    variables: { countryCode: 'ES', lang: 'es', first: 5 }, context: { endpoint: 'BC' }
  })

  console.log('dataBC', dataBC)
  console.log('loadingBC', loadingBC)
  console.log('errorBC', errorBC)
  const { t } = useTranslator()
  const { locale, push } = useRouter()
  const { register, handleSubmit, setValue, watch } = useForm<TypeFormStoresZoneInputs>({ mode: 'onChange' })

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const Comercials = useMemo(() => getComercials(FAKE_DATA, textColorSubdividions, isModal), [FAKE_DATA, textColorSubdividions, isModal])

  let selectedOptions = watch(['country', 'subdivision'])
  const [country, setCountry] = useState([])
  const [subdivisions, setSubdivisions] = useState([])
  const storeData = useMemo(() => getStore(selectedOptions, subdivisions), [selectedOptions, subdivisions])

  const onClickStore = (storeData) => {
    console.log('onClickStore storeData:', storeData)
    push({ pathname: '/', query: storeData.query }, null, { locale: storeData.locale })
  }

  const onClickCatalogue = (regionCode) => {
    const nextLocale = `${regionCode.toLowerCase()}-${regionCode}`
    push({ pathname: '/catalog', query: { zone: nextLocale } }, null, { locale: nextLocale })
  }

  const onSubmit: SubmitHandler<TypeFormStoresZoneInputs> = async (data, e) => {
    console.log('formStorePicker onSubmit - data:', data)
    console.log('formStorePicker onSubmit - e:', e)
    toggle && toggle()
  }

  const onError = (errors, e) => { console.log(errors, e) }

  useEffect(() => {
    if(country?.length === 0) {
      getCountry().then((res)=>{
        setCountry(Array.from(res))
      })
    }
  }, [country])

  useEffect(() => {
    const subscription = watch((value) => {
      const { country , subdivision } = value

      if (country) {
        getSubdivisions(country).then((res)=> {
          setSubdivisions(Array.from(res))
        })
      }
      if ((country !== '' || !country) && (subdivision !== '' || !subdivision)) {
        return () => subscription.unsubscribe()
      }
    })

  }, [watch])

  if(loading) return <Wrapper><p>Loading...</p></Wrapper>
  if(error)   return <Wrapper> <p>Error: {error.message}</p> </Wrapper> 
  console.log('data',data, locale)
  return (
    <>
      <Wrapper $selectedCountry={selectedOptions[0]} $colorWrapper={colorWrapper} className='wrapper_store_picker' $isModal={isModal}>
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <Select name='country'  placeholder={t('commons.form.fields.country') as string} placeholderVisible options={country} register={register} setFormValue={setValue} textColor={textColorInput} bgColor={bgInputColor} dropdownTextColor={dropdownTextColor}/>
          <Select name='subdivision' placeholder={t('commons.form.fields.province') as string}  placeholderVisible options={subdivisions} register={register} setFormValue={setValue} textColor={textColorInput} bgColor={bgInputColor} dropdownTextColor={dropdownTextColor}/>
          {/* <Link href={{ pathname: '/', query: { zone: 'es-CT' }}} locale={'es-CT'}>PRUEBA WITH NEXT/LINK instead of push</Link> */}
          {displayComercial ? 
            <Div $active={selectedOptions[1]} $textColorSubdividions={textColorSubdividions} $isModal={isModal} className='subdivisions'>
              <dl>
                <dt>{t('commons.region_picker.in')} {subdivisions.filter(o => o.value === selectedOptions[1])[0]?.name} {t('commons.region_picker.work_with')}</dt>
                {Comercials}
              </dl>
              <Button type='submit' onClick={() => onClickCatalogue(selectedOptions[0])} bgType={isModal? 'red' : 'creme'} className='creme'> {t('commons.region_picker.see_catalogue')} </Button>
            </Div>
            : 
            <Button type='submit' onClick={() => { onClickStore(storeData) }} bgType={isModal? 'red' : 'creme'}className={(buttonDisabled && !isModal ) ? 'disabled' : ''}>{t('commons.actions.go')}</Button>
          }
        </form>
      </Wrapper>
    </>
  )
})
