import { memo, useMemo } from 'react'
import { useRouter } from 'next/router'
import { Button, Figure } from '@dy/commons/components'
import { currencyFormatter, getLang } from '@dy/commons/utils'
import { useDeviceType } from '@dy/commons/hooks'
import { Section, CardListItem, ProductListItem, Wrapper } from './accountStyles'
import { useTranslator } from '@/hooks'

interface IOrderDetails {
  data: any
}

type priceObject = {
  [key:string]: number
}

const mediaPastaLeft = {
  type: 'image' as const,
  alt: 'Icon pasta 1',
  default: {
    src: '/images/pasta.jpeg',
    size: { width: 30, height: 20 }
  }
}


const userDetails = (item)  => {
  const { city, countryCode, firstName, lastName, phone, postalCode, province, street1, street2 } = item
  return(
    <>
      <span>{firstName} {lastName}</span>
      <span>{street1} {street2}, {city}</span>
      <span>{province}, {countryCode}</span>
      <span>{postalCode}</span>
      <span>{phone}</span>
    </>
  )
}

const getUserDetailOrder= (item, t) => {
  const { billingAddress, paymentMethod, shippingAddress } = item
  const Shipping = userDetails(shippingAddress)
  console.log('shippingAddress', shippingAddress)
  const Billing = userDetails(billingAddress)
  return (
    <CardListItem className='big'>
      <div className={'detail'}>
        <h1>{t('account.order.order_details.shipment')}</h1>
        <div>
          {Shipping}
        </div>
        <h1> {t('account.order.order_details.billing')} </h1>
        <div>
          {Billing}
        </div>
        <h1> {t('account.order.order_details.payment')} </h1>
        <Figure media={mediaPastaLeft}/>
        <span>{paymentMethod}</span>
      </div>
    </CardListItem>
  )
}

//comented until we know for sure how its the schema for orderStatus
// const getOrderStatus = (item) =>{
//   const { date, sended, delivering } = item
//   return(
//     <OrderLine>
//       <div className='progress_bar'>
//         <div className='completed'>
//           <span className='completed'></span>
//         </div>
//         <div className={sended ? 'completed': 'inactive' }>
//           <span className={sended ? 'completed': 'inactive'}></span>
//         </div>
//         <div className={delivering ? 'completed': 'inactive' }>
//           <span className={delivering ? 'completed': 'inactive'}></span>
//         </div>
//         <div className={item.delivering_date ? 'completed': 'inactive' }>
//           <span className={item.delivering_date ? 'completed': 'inactive' }></span>
//         </div>
//       </div>
//       <div className={'order_status'}>
//         <span>Ordered <span>{date}</span></span>
//         <span>Shipped <span>{sended}</span></span>
//         <span>En reparto <span>{''}</span></span>
//         <span>Llegada <span>{item.delivering_date? item.delivering_date : ''}</span></span>
//       </div>
//     </OrderLine>
//   )
// }

export const getDetailProducts = (products, locale) => products.map((product, idx) => {
  const { variantId, amount, brand, name, price, pricePerWeight } = product
  const priceObject = {
    'pricePerWeight': pricePerWeight,
    'price': price
  }
  const currencyPrices:any = getAllPricesCurrency(priceObject, locale)
  const {pricePerWeightCurrency, priceCurrency} = currencyPrices
  return (
    <ProductListItem key={`${idx}-${variantId}`}>
      <Figure media={mediaPastaLeft}/>
      <div className={'info'}>
        <span>{amount} x {name}</span>
        <span>{brand} | {pricePerWeightCurrency} /Kg</span>
        <span>{priceCurrency}</span>
      </div>
    </ProductListItem>
  )
})

export const getAllPricesCurrency = (priceObject:priceObject, locale) =>{
  const newObj = {}
  Object.entries(priceObject).forEach(([key, value])=> {
    newObj[key+'Currency'] = currencyFormatter({price: value, localeRegion:locale})
  })

  return newObj
}

export const getOrderSumary = (item, locale, t) => {
  const { subtotalIncTax, totalIncTax, coupon, discountsApplied, shippingCost } = item
  const priceObject = {
    'subtotalIncTax': subtotalIncTax,
    'totalIncTax': totalIncTax,
    'coupon': coupon.value,
    'discountsApplied': discountsApplied,
    'shippingCost': shippingCost
  }
  const currencyPrices:any = getAllPricesCurrency(priceObject, locale)
  const { couponCurrency, subtotalIncTaxCurrency,totalIncTaxCurrency, discountsAppliedCurrency, shippingCostCurrency } = currencyPrices
  return (
    <CardListItem className='small'>
      <div className='order_title'>
        <h1>{t('account.order.order_details.payment_summary')}</h1>
      </div>
      <table>
        <tbody>
          <tr>
            <td>{t('account.order.order_details.total_products')}</td>
            <td> {subtotalIncTaxCurrency} </td>
          </tr>
          <tr>
            <td>{t('account.order.order_details.voucher')} </td>
            <td>{couponCurrency} </td>
          </tr>
          <tr>
            <td> {t('account.order.order_details.discount')}</td>
            <td>{discountsAppliedCurrency}</td>
          </tr>
          <tr>
            <td>{t('account.order.order_details.shipment')}</td>
            <td>{shippingCostCurrency}</td>
          </tr>
        </tbody>
      </table>
      <div className='summary_total'>
        <span>{t('account.order.order_details.total_inc_tax')}</span>
        <span>{totalIncTaxCurrency}</span>
      </div>
    </CardListItem>
  )

}
export const OrderDetails = memo<IOrderDetails>(({ data }) => {
  const { locale:localeRouter } = useRouter()
  const locale = getLang(localeRouter)
  const { t } = useTranslator()
  const { orderLines, dateCreated, id, summary } = data
  const UserDetailOrder = useMemo(() => getUserDetailOrder(data, t), [data, t])
  // const OrderStatus = useMemo(() => getOrderStatus(data, t), [data, t])
  const OrderSummary = useMemo(() => getOrderSumary(summary, locale, t), [summary, locale, t])
  const ProductList = useMemo(() => getDetailProducts(orderLines, locale), [orderLines,locale])
  const { isMobile } = useDeviceType()


  return(
    <Section>
      <p>{t('account.order.number')} {id}</p>
      {/* Comented until we know schema for orderStatus <>
        {!delivered && OrderStatus }
      </> */}
      <Wrapper className='detail'>
        {UserDetailOrder}
        {OrderSummary}
      </Wrapper>
      {!isMobile &&
        <CardListItem>
          <div className={'main-info'}>
            <div className={'products'}>
              <p className={'headline'}>{dateCreated}</p>
              <ul>
                {ProductList}
              </ul>
            </div>
            <div className={'cta'}>
              <Button bgType={'red'} outline>{t('account.repeat_order')}</Button>
            </div>
          </div>
        </CardListItem>
      }
    </Section>
  )
})
