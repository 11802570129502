import { useMemo, memo } from 'react'
import { useRouter } from 'next/router'
import { AnimatePresence } from 'framer-motion'
import { Button } from '@dy/commons/components'
import { currencyFormatter, getLang } from '@dy/commons/utils'
import { useScrollTracker } from '@dy/commons/hooks'
import { ModalAllOverlay, ModalAllListWrapper, ModalAllList, ModalSummary, _Price } from '.'
import { CartItem } from './CartItem'
import { itemVariants, listVariants } from './cartVariants'
// import { CartUrlContext } from '@dy/commons/hooks'
// import useCart from '@custom-bigcommerce/storefront-data-hooks/cart/use-cart'

interface ICartItemsAll {
  showCart: boolean,
  setShowCart: any,
  hasBanners: boolean
}

const TRANSLATIONS = {
  'en': {
    product_added: 'Total',
    cart: 'Show cart',
    checkout: 'Go to checkout'
  },
  'es': {
    product_added: 'Total',
    cart: 'Ver cesta',
    checkout: 'Tramitar pedido'
  },
  'fr': {
    product_added: 'Total',
    cart: 'Voir le panier',
    checkout: 'Aller à la caisse'
  },
  'de': {
    product_added: 'Totale',
    cart: 'Mostra carrello',
    checkout: 'Vai alla cassa'
  }
}

const getProducts = (products, push, urlHref) => products.map((product, idx) => (
  <CartItem key={`cartitem-${idx}`} product={product} id={`cartitem-${idx}`} onClick={() => push(urlHref)} />
))

// const getCartUrl = async (id, setUrl) => {
//   const _isStaging = isStaging()
//   const BC_URL = ROUTES[REGION](_isStaging)
//   console.log('getCartUrl id:ALL CART ITEMS', id)
//   if (id) {
//     const [res] = await callBigcommerce({ type: 'checkout-redirect', params: { checkout_id: id } })
//     console.log('🟠 res:', res)
//     setUrl(res.data?.cart_url ?? BC_URL + '/cart.php')
//     if (!res.data?.cart_url) console.warn('Error getCartUrl', res)
//   } else {
//     setUrl(BC_URL + '/cart.php')
//   }
// }

// TODO: remove fake data
const dataCart = {
  cart_amount: 10,
  line_items: {
    physical_items: [
      {
        name: 'Test Product', 
        quantity: 1, 
        image_url: 'https://cms.hellomamma.eu/rails/active_storage/representations/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBNEtrQWc9PSIsImV4cCI6bnVsbCwicHVyIjoiYmxvYl9pZCJ9fQ==--f3baa1404d66bf4f4b4d71fce67199e35035c261/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdCem9MWm05eWJXRjBTU0lJYW5CbkJqb0dSVlE2RW5KbGMybDZaVjkwYjE5bWFYUmJCMmtDdWdGcEFwQUIiLCJleHAiOm51bGwsInB1ciI6InZhcmlhdGlvbiJ9fQ==--1354cc82b2730267a5c770e087038e98ca9a46b3/piennolo.jpg', 
        price: 10
      }
    ]
  }
}
const cartUrl = {
  cart_url: 'https://localhost:3000/cart',
  checkout_url: 'https://localhost:3000/checkout'
}

export const CartItemsAll = memo<ICartItemsAll>(({ showCart, setShowCart, hasBanners }) => {
  const scrollState = useScrollTracker(1)
  const { locale:localeRouter, push } = useRouter()
  const locale = getLang(localeRouter)
  // const { data: dataCart } = useCart()
  // const { cartUrl } = useContext(CartUrlContext)

  // useEffect(() => {
  //   getCartUrl(dataCart?.id, setUrl)
  // }, [dataCart])

  const Products = useMemo(() => dataCart?.line_items?.physical_items && getProducts(dataCart.line_items.physical_items, push, cartUrl.cart_url), [dataCart, push])
  if(!Products) return null

  return (
    <AnimatePresence mode={'wait'}>
      {showCart && (
        <>
          <ModalAllOverlay $active={showCart} onMouseEnter={() => setShowCart(false)} />
          <ModalAllListWrapper $top={scrollState === 'TOP'} $hasBanners={hasBanners}>
            <ModalAllList key='itemsincart' variants={listVariants} initial='inactive' animate={'enter'} exit={'exit'}>
              {Products}
              <ModalSummary key={'summary'} variants={itemVariants}>
                <span>{TRANSLATIONS[locale].product_added} ({Products.length})</span>
                <span>{currencyFormatter({ price: dataCart?.cart_amount, localeRegion: locale, round: false, trailingZeros: true })}</span>
                <Button outline onClick={() => push(cartUrl.cart_url)} bgType={'red'}>{TRANSLATIONS[locale].cart}</Button>
                <Button onClick={() => push(cartUrl.checkout_url)} bgType={'red'}>{TRANSLATIONS[locale].checkout}</Button>
              </ModalSummary>
            </ModalAllList>
          </ModalAllListWrapper>
        </>
      )}
    </AnimatePresence>
  )
})
