import { memo } from 'react'
import dynamic from 'next/dynamic'
import type { IMarqueeSlider } from '@dy/commons/components'
import { Aside } from './footerStyles'
const DynamicMarqueeSlider = dynamic<IMarqueeSlider>(() => import('@dy/commons/components').then((mod)=> mod.MarqueeSlider), { ssr: false })

export const PreFooter = memo<any>(({ services }) => {
  return (services ?
    <Aside>
      <DynamicMarqueeSlider data={services.replace(/&nbsp;/g, ' ')} uniqueClass='marquee-item-footer' />
    </Aside> : <></>
  )
})
