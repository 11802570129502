import styled, { css } from 'styled-components'
import { mq, vw, hex2Rgba, designGrid, getP12_2, getP26_1, getP30, getP15, getP20_1, getP141, getP113, getP93_1, getP53, getP26, getP30_1, getP20_2, getP15_1 } from '@dy/commons/styles'
import { motion } from 'framer-motion'

export const MyProductsList = styled.li`
  background-color: white;
  border-radius: 10%;
  height:${vw(70, 'mobile')};
  width: ${vw(81, 'mobile')};

  ${mq.greaterThan('tablet')`
    height:${vw(115, 'desktop')};
    width: ${vw(130, 'desktop')};
  `}

  figure {
    align-items: center;
    width: 80% !important;
    height: 100% !important;
    margin: 0 auto;
    img {
      width:100% !important
    }
  }
`

export const WrapperProducts = styled.div`
  padding:${vw(40, 'mobile')} 0 ${vw(50, 'mobile')};

  ul{
    display: grid;
    column-gap: ${vw(5, 'mobile')};
    row-gap: ${vw(6, 'mobile')};
    grid-template-columns: repeat(4, 1fr);
  }
  ${mq.greaterThan('tablet')`
    padding:${vw(40, 'desktop')} 0 ${vw(50, 'desktop')};

    ul {
      column-gap: ${vw(5, 'desktop')};
      row-gap: ${vw(6, 'desktop')};
    }
  `}
`


export const ProductInfoWrapper = styled.div`
  display: block;

  ${mq.greaterThan('tablet')`
  display:grid;
  grid-template-columns: ${vw(350, 'desktop')} 1fr;
  `}

  span {
    ${getP12_2()}
    color: ${({ theme }) => theme.colors.darkred};
    display: block;
  }
`

export const SectionWrapper = styled.section`
  background-color: ${({ theme }) => theme.colors.creme};
  color: ${({ theme }) => theme.colors.red};
  display: block;
  text-align: center;
  justify-content: unset;
  margin: ${vw(14, 'mobile')};
  padding: ${vw(40, 'mobile')} ${vw(25, 'mobile')};


  ${mq.greaterThan('tablet')`
    display: flex;
    justify-content: space-between;
    padding: ${vw(50, 'desktop')} ${vw(70, 'desktop')};
    margin: ${vw(25, 'desktop')};
  `}

  h1 {
    ${getP30()}
    text-align:left;
  }

  a {
    ${getP15()}
  }

  .cta {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  .left {
    width:unset;
    ul {
        display:block;
    }
    ${mq.greaterThan('tablet')`
      width: 48%;
      ul {
        display:inline;
      }
    `}
  }
  .right{
    width: unset;
    padding: ${vw(50, 'mobile')} 0;

    ${mq.greaterThan('tablet')`
      width: 50%;
      padding-top: 0;
      padding-bottom: 0;
      padding-left:${vw(120, 'desktop')};
      padding-right:0;
      `}
      p {
        ${getP15_1()}
      }
  }
`

export const ListOrders = styled.li`
  display:grid;
  grid-template-columns: ${vw(185, 'mobile')} 1fr;
  padding: ${vw(25, 'mobile')} 0;
  text-align: left;

  ${mq.greaterThan('tablet')`
    grid-template-columns: ${vw(350, 'desktop')} 1fr;
    padding: ${vw(30, 'desktop')} ${vw(20, 'desktop')};
  `}

  :first-child {
    padding-top: ${vw(30, 'mobile')};

    ${mq.greaterThan('tablet')`
      padding-top: ${vw(40, 'desktop')};
    `}
  }

  :not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => hex2Rgba(theme.colors.darkred, .1)};
  }

  h3 {
    ${getP26_1()}
    padding-bottom: ${vw(8, 'desktop')};
    text-align: left;
  }
`

export const BrandSection = styled.section`
  margin-bottom: ${vw(73, 'mobile')};

  + .pack-highlighted-section {
    margin-top: -${vw(73, 'mobile')};
  }

  h2 {
    ${getP20_1()}
    grid-area: 1 / 1 / span 1 / span 6;
    text-align: center;
    margin-bottom: ${vw(28, 'mobile')};
  }

  ${mq.greaterThan('tablet')`
    ${designGrid({})}
    margin-bottom: ${vw(84, 'desktop')};

    + .pack-highlighted-section {
      margin-top: -${vw(84, 'desktop')};
    }

    h2 {
      grid-area: 1 / 1 / span 1 / span 12;
      margin-bottom: ${vw(40, 'desktop')};
    }
  `}
`

export const BrandWrapper = styled(motion.div)<any>`
  grid-area: 1 / 1 / span 1 / span 6;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  height: ${vw(57, 'mobile')};
  margin: 0 ${vw(20, 'mobile')};

  article, figure { height: inherit; }

  article {
    padding: ${vw(5, 'mobile')} ${vw(15, 'mobile')};
  }

  img {
    height: 100%;
    object-fit: contain;
    filter: invert(49%) sepia(37%) saturate(331%) hue-rotate(69deg) brightness(92%) contrast(97%);
  }

  ${mq.greaterThan('tablet')`
    grid-area: 2 / 2 / span 1 / span 10;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 50px;
    height: ${vw(108, 'desktop')};

    article {
      padding: 0;
      margin: 0 ${vw(30, 'desktop')};
    }
  `}
`

export const HomeHeader = styled.header<any>`
  ${designGrid({})}
  place-items: center;
  grid: auto-flow / repeat(8, ${vw(64, 'mobile')});
  overflow: hidden;
  margin-top: 60px;

  div[class*='homeheaderimage'] > figure {
    user-select: none;
    pointer-events: none;
    filter: drop-shadow(8px 13px 1px rgb(78 57 14 / 7%));
    height: 100%;
  }

  &[data-length='1'] {
    margin-bottom: ${vw(90, 'mobile')};
    margin-top: 60px;
    color: #FFF;

    div[class*='homeheaderimage'] {
      grid-area: 1 / 2 / span 1 / span 4;
      transform: none;
      width: 100%;
      z-index: -1;

      &.figure--video {
        grid-area: 1 / 2 / span 1 / span 6;
        filter: none;
        z-index: -1;
        height: ${vw(447, 'mobile')};
        overflow: hidden;

        iframe {
          transform: ${({ $videoAspectRatio }) => 'scale(' + $videoAspectRatio + ')'} !important;
          width: 100% !important;

          @media (min-aspect-ratio: 16/9) {
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
          }
        }

        div[data-vimeo-initialized='true'] {
          @media (min-aspect-ratio: 16/9) {
            height: 0;
            overflow: hidden;
            padding-bottom: 56.25%;
            position: relative;
            transform: ${({ $videoAspectRatio }) => 'scale(' + $videoAspectRatio + ')'} !important;
          }
        }
  

        & + [class*='home-header-pos'] {
          grid-area: 1 / 3 / span 2 / span 4;
          margin-top: 0;
        }
      }

      &.homeheaderimage-center {
        grid-area: 1 / 2 / span 1 / span 6;
        z-index: -1;
        height: ${vw(447, 'mobile')};

        > figure {
          filter: none;
        }

        img {
          object-fit: cover;
          height: 100%;
        }

        & + [class*='home-header-pos'] {
          grid-area: 1 / 3 / span 2 / span 4;
          margin-top: 0;
          padding-bottom: 0;
        }
      }
    }
  }

  &[data-length='2'] {
    div[class*='homeheaderimage']:nth-of-type(1) { grid-area: 1 / 1 / span 1 / span 4; }

    div[class*='homeheaderimage']:nth-of-type(2) { grid-area: 1 / 5 / span 1 / span 4; }
  }

  &[data-length='3'] {
    div[class*='homeheaderimage']:nth-of-type(1) { grid-area: 1 / 1 / span 1 / span 4; }

    div[class*='homeheaderimage']:nth-of-type(2) { grid-area: 1 / 3 / span 1 / span 4; }

    div[class*='homeheaderimage']:nth-of-type(3) { grid-area: 1 / 5 / span 1 / span 4; }
  }

  ${mq.greaterThan('tablet')`
    height: ${vw(750, 'desktop')};
    margin-bottom: ${vw(50, 'desktop')};
    margin-top: ${vw(120, 'desktop')};
    overflow-x: unset;

    div[class*='homeheaderimage'] {
      width: ${vw(720, 'desktop')};
      height: ${vw(750, 'desktop')};
    }

    &[data-length] div[class*='homeheaderimage'] {
      > figure {
        filter: drop-shadow(25px 40px 4px rgb(78 57 14 / 7%));
      }

      &.homeheaderimage-left {
        grid-area: 1 / 1 / span 1 / span 6;
        margin: 0;
      }
      &.homeheaderimage-center { grid-area: 1 / 4 / span 1 / span 6; }
      &.homeheaderimage-right { grid-area: 1 / 7 / span 1 / span 6; }
    }

    &[data-length='1'] {
      margin-top: ${vw(120, 'desktop')};
      margin-bottom: ${vw(50, 'desktop')};

      div[class*='homeheaderimage'] {
        grid-area: 1 / 1 / span 3 / span 12;

        &.homeheaderimage-center {
          grid-area: 1 / 1 / span 1 / span 12;
          padding: 0 ${vw(50, 'desktop')};
          transform: none;
          width: 100%;
          height: inherit;

          & + [class*='home-header-pos'] {
            &.home-header-pos-left { grid-area: 1 / 2 / span 1 / span 5; }
            &.home-header-pos-center { grid-area: 1 / 3 / span 1 / span 8; }
            &.home-header-pos-right { grid-area: 1 / 7 / span 1 / span 5; }
          }
        }
      }

      figure {
        &.figure--video {
          grid-area: 1 / 1 / span 3 / span 12;
          height: ${vw(715, 'desktop')};

          & + [class*='home-header-pos'] {
            &.home-header-pos-left { grid-area: 1 / 2 / span 3 / span 5; }
            &.home-header-pos-center { grid-area: 1 / 3 / span 3 / span 8; }
            &.home-header-pos-right { grid-area: 1 / 7 / span 3 / span 5; }
          }
        }
      }
    }
  `}
`

export const TextWrapper = styled.div<any>`
  grid-area: 2 / 3 / span 1 / span 4;
  margin-top: -${vw(40, 'mobile')};
  padding-bottom: ${vw(80, 'mobile')}; // fix for animations
  width: 100%;
  ${({ $text_valign }) => $text_valign === 'top' ? css`padding-top: ${vw(20, 'mobile')}; align-self: start;` : $text_valign === 'bottom' ? css`padding-bottom: ${vw(30, 'mobile')}; align-self: end;` : css`align-self: center;` };

  ${mq.greaterThan<any>('tablet')`
    grid-area: 1 / 5 / span 1 / span 4;
    margin-top: 0;
    padding-bottom: 0;
    ${({ $text_valign }) => $text_valign === 'top' ? css`padding-top: ${vw(60, 'desktop')}; align-self: start;` : $text_valign === 'bottom' ? css`padding-bottom: ${vw(70, 'desktop')}; align-self: end;` : css`align-self: center;` };
  `}

  h2 {
    ${({ $title_size }) => $title_size === 'xl' ? getP141() : $title_size === 'l' ? getP113() : $title_size === 'm' ? getP93_1() : getP53() };
  }

  p {
    font-family: ${({ theme }) => theme.fonts.primaryFont};
    ${({ $subtitle_size }) => $subtitle_size === 'l' ? css`${getP30_1()} font-weight: 100;` : $subtitle_size === 'm' ? css`${getP30_1()} font-weight: 100;` : getP15_1() };
    margin: ${vw(15, 'mobile')} 0 ${vw(20, 'mobile')};

    ${mq.greaterThan<any>('tablet')`
      ${({ $subtitle_size }) => $subtitle_size === 'l' ? css`${getP30_1()} font-weight: 100;` : $subtitle_size === 'm' ? css`${getP26()} font-weight: 100;` : getP20_2() };

      &.home-header-text-subtitle {
        ${({ $subtitle_size }) => $subtitle_size === 'm' ? css`letter-spacing: -1.5px;` : ''};
      }
    `}
  }

  .home-header-align-left,
  .home-header-align-center,
  .home-header-align-right {
    text-align: center;
  }

  .home-header-align-left {
    ${mq.greaterThan('tablet')`
      text-align: left;
    `}
  }

  .home-header-align-right {
    ${mq.greaterThan('tablet')`
      text-align: right;
    `}
  }

  ${mq.greaterThan<any>('tablet')`
    &.home-header-pos-left {
      grid-area: 1 / 2 / span 1 / span 5;

      p {
        &.home-header-align-left {
          margin: ${vw(15, 'desktop')} calc(100vw / 12) ${vw(30, 'desktop')} 0;
        }
        &.home-header-align-center {
          margin: ${vw(15, 'desktop')} calc(100vw / 24) ${vw(30, 'desktop')};
        }
        &.home-header-align-right {
          margin: ${vw(15, 'desktop')} 0 ${vw(30, 'desktop')} calc(100vw / 12);
        }
      }
    }

    &.home-header-pos-center {
      grid-area: 1 / 3 / span 1 / span 8;

      p {
        &.home-header-align-left {
          margin: ${vw(15, 'desktop')} calc(100vw / 12 * 4) ${vw(30, 'desktop')} 0;
        }
        &.home-header-align-center {
          margin: ${vw(15, 'desktop')} calc(100vw / 6) ${vw(30, 'desktop')};
        }
        &.home-header-align-right {
          margin: ${vw(15, 'desktop')} 0 ${vw(30, 'desktop')} calc(100vw / 12 * 4);
        }
      }
    }

    &.home-header-pos-right {
      grid-area: 1 / 7 / span 1 / span 5;

      p {
        &.home-header-align-left {
          margin: ${vw(15, 'desktop')} calc(100vw / 12) ${vw(30, 'desktop')} 0;
        }
        &.home-header-align-center {
          margin: ${vw(15, 'desktop')} calc(100vw / 24) ${vw(30, 'desktop')};
        }
        &.home-header-align-right {
          margin: ${vw(15, 'desktop')} 0 ${vw(30, 'desktop')} calc(100vw / 12);
        }
      }
    }

    button.home-header-align-left { margin: 0 auto 0 0; }
    button.home-header-align-right { margin: 0 0 0 auto; }
  `}
`

export const BestsellerSection = styled.section`
  ${designGrid({})}
  align-items: center;
  position: relative;
  padding: 0;
  margin-top: ${vw(70, 'mobile')};
  margin-bottom: ${vw(100, 'mobile')};
  padding-right: 0;

  ${mq.greaterThan('tablet')`
    margin-top: ${vw(150, 'desktop')};
    margin-bottom: ${vw(200, 'desktop')};
  `}
`

export const PastaWrapper = styled.div`
  position: absolute;
  overflow: hidden;
  transform: translateY(-17%);
  user-select: none;
  pointer-events: none;
  z-index: 1;
  left: 0;
  right: 0;
  height: ${vw(882, 'mobile')};

  ${mq.greaterThan('tablet')`
    height: ${vw(1356, 'desktop')};
    transform: translateY(-8%);
  `}
`

export const ImageOverlay = styled(motion.div)`
  z-index: 1;
  position: absolute;
  user-select: none;
  pointer-events: none;

  &.bestseller-left {
    left: ${vw(-130, 'mobile')};
    top: ${vw(650, 'mobile')};

    figure {
      height: ${vw(418, 'mobile')};
      width: ${vw(240, 'mobile')};
    }
  }

  &.bestseller-right {
    right: ${vw(-40, 'mobile')};
    top: ${vw(380, 'mobile')};

    figure {
      height: ${vw(391, 'mobile')};
      width: ${vw(288, 'mobile')};
    }
  }

  ${mq.greaterThan('tablet')`
    &.bestseller-left {
      left: ${vw(-120, 'desktop')};
      top: ${vw(685, 'desktop')};

      figure {
        height: ${vw(844, 'desktop')};
        width: ${vw(507, 'desktop')};
      }
    }

    &.bestseller-right {
      right: ${vw(-70, 'desktop')};
      top: ${vw(487, 'desktop')};

      figure {
        height: ${vw(842, 'desktop')};
        width: ${vw(621, 'desktop')};
      }
    }
  `}
`

export const ProductWrapper = styled.div`
  ${designGrid({})}
  padding: 0;

  &:nth-last-child(2) {
    grid-area: 1 / 1 / span 1 / span 6;

    ${mq.greaterThan('tablet')`
      grid-area: 1 / 1 / span 1 / span 12;
    `}
  }

  &:last-of-type {
    grid-area: 2 / 1 / span 1 / span 6;
    margin-top: ${vw(100, 'mobile')};

    ${mq.greaterThan('tablet')`
      grid-area: 2 / 1 / span 1 / span 12;
      margin-top: ${vw(100, 'desktop')};
    `}
  }

  .headline-wrapper {
    color: ${({ theme }) => theme.colors.red};
    align-items: center;
    display: flex;
    grid-area: 1 / 1 / span 1 / span 3;
    margin: 0 ${vw(20, 'mobile')};

    ${mq.greaterThan('tablet')`
      grid-area: 1 / 2 / span 1 / span 3;
      margin: 0;
    `}
  }

  h2 {
    ${getP53()}
    text-align: center;
  }

  .slider--flickity {
    grid-area: 1 / 1 / span 1 / span 6;

    ${mq.greaterThan('tablet')`
      grid-area: 1 / 1 / span 1 / span 12;
    `}

    .cart-wrapper:first-of-type article {
      width: 50vw;
      margin-right: 0;
      background-color: transparent;
      border: none;
      opacity: 0;
      pointer-events: none;

      &:hover {
        border-color: transparent;
      }

      ${mq.greaterThan('tablet')`
        width: calc(100vw / 12 * 5);
      `}
    }

    .cart-wrapper:last-of-type article {
      background-color: transparent;
      opacity: 0;
      width: 0;

      &:hover {
        border-color: transparent;
      }

      ${mq.greaterThan('tablet')`
        width: ${vw(300, 'desktop')};
      `}
    }
  }
`

export const HighlightsSection = styled.section`
  margin-bottom: ${vw(50, 'mobile')};
  height: ${vw(447, 'mobile')};

  > div:first-of-type {
    height: inherit;
  }

  .contentWrapper {
    ${designGrid({})}
    height: inherit;

    figure {
      user-select: none;
      pointer-events: none;
      filter: drop-shadow(8px 13px 1px rgb(78 57 14 / 7%));

      img {
        object-fit: cover;
        height: 100%;
        width: 100%;
      }
    }

    &.media {
      color: #FFF;
    }

    &[data-length='1'] {

      figure {
        grid-area: 1 / 2 / span 1 / span 4;
        filter: none;

        &.figure--video {
          grid-area: 1 / 1 / span 2 / span 6;
          filter: none;

          iframe {
            width: 200% !important;
            transform: translateX(-50%);
          }
        }

        &.product-highlight-image-center {
          grid-area: 1 / 1 / span 2 / span 6;
          filter: none;
        }
      }

      .product-highlight-pos-center {
        grid-area: 1 / 2 / span 2 / span 4;
        align-self: center;
        margin: 0;
      }
    }

    &[data-length='2'] {
      figure:nth-of-type(1) { grid-area: 1 / 1 / span 1 / span 4; }

      figure:nth-of-type(2) { grid-area: 1 / 3 / span 1 / span 4; }
    }

    &[data-length='3'] {
      figure:nth-of-type(1) { grid-area: 1 / 1 / span 1 / span 4; }

      figure:nth-of-type(2) { grid-area: 1 / 2 / span 1 / span 4; }

      figure:nth-of-type(3) { grid-area: 1 / 3 / span 1 / span 4; }
    }
  }


  ${mq.greaterThan('tablet')`
    margin-bottom: ${vw(200, 'desktop')};
    height: ${vw(715, 'desktop')};

    .contentWrapper {
      height: unset;

      &[data-length] figure {
        height: ${vw(700, 'desktop')};

        &.product-highlight-image-left {
          width: ${vw(672, 'desktop')};
          grid-area: 1 / 1 / span 1 / span 6;
          filter: drop-shadow(25px 40px 4px rgb(78 57 14 / 7%));
        }
        &.product-highlight-image-center {
          width: ${vw(672, 'desktop')};
          grid-area: 1 / 4 / span 1 / span 6;
          filter: drop-shadow(25px 40px 4px rgb(78 57 14 / 7%));
        }
        &.product-highlight-image-right {
          width: ${vw(672, 'desktop')};
          grid-area: 1 / 7 / span 1 / span 6;
          filter: drop-shadow(25px 40px 4px rgb(78 57 14 / 7%));
        }
      }

      &[data-length='1'] {
        figure {
          &.product-highlight-image-center {
            grid-area: 1 / 1 / span 1 / span 12;
            margin: 0 ${vw(50, 'desktop')};
            width: auto;
            filter: none;
          }

          &.figure--video {
            grid-area: 1 / 1 / span 1 / span 12;

            iframe {
              height: 200% !important;
              width: calc(100% - ${vw(100, 'desktop')}) !important;
              transform: translate(${vw(50, 'desktop')}, -25%);
            }
          }
        }

        .product-highlight-pos-center {
          grid-area: 1 / 3 / span 1 / span 8;
        }
      }
    }
  `}
`

export const HighlightsTextWrapper = styled.div<any>`
  grid-area: 1 / 2 / span 2 / span 4;
  width: 100%;
  ${({ text_valign }) => text_valign === 'top' ? css`padding-top: ${vw(20, 'mobile')}; align-self: start;` : text_valign === 'bottom' ? css`padding-bottom: ${vw(30, 'mobile')}; align-self: end;` : css`align-self: center;` };
  z-index: 1;

  h2 {
    ${({ title_size }) => title_size === 'xl' ? getP141() : title_size === 'l' ? getP113() : title_size === 'm' ? getP93_1() : getP53() };
    color: ${({ theme }) => theme.colors.red};
  }

  p {
    font-family: ${({ theme }) => theme.fonts.secondaryFont};
    ${({ subtitle_size }) => subtitle_size === 'l' ? css`${getP30_1()} font-weight: 100;` : subtitle_size === 'm' ? css`${getP30_1()} font-weight: 100;` : getP15_1() };
    color: ${({ theme }) => theme.colors.red};
    margin: ${vw(15, 'mobile')} 0 ${vw(20, 'mobile')};
  }

  .product-highlight-align-left { text-align: center; }
  .product-highlight-align-center { text-align: center; }
  .product-highlight-align-right { text-align: center; }

  ${mq.greaterThan<any>('tablet')`
    grid-area: 1 / 5 / span 1 / span 4;
    ${({ text_valign }) => text_valign === 'top' ? css`padding-top: ${vw(60, 'desktop')}; align-self: start;` : text_valign === 'bottom' ? css`padding-bottom: ${vw(70, 'desktop')}; align-self: end;` : css`align-self: center;` };

    &.product-highlight-pos-left {
      grid-area: 1 / 2 / span 1 / span 5;

      p {
        &.product-highlight-align-left {
          margin: ${vw(15, 'desktop')} calc(100vw / 12) ${vw(30, 'desktop')} 0;
        }
        &.product-highlight-align-center {
          margin: ${vw(15, 'desktop')} calc(100vw / 24) ${vw(30, 'desktop')};
        }
        &.product-highlight-align-right {
          margin: ${vw(15, 'desktop')} 0 ${vw(30, 'desktop')} calc(100vw / 12);
        }
      }
    }
    &.product-highlight-pos-center {
      grid-area: 1 / 3 / span 1 / span 8;

      p {
        &.product-highlight-align-left {
          margin: ${vw(15, 'desktop')} calc(100vw / 12 * 4) ${vw(30, 'desktop')} 0;
        }
        &.product-highlight-align-center {
          margin: ${vw(15, 'desktop')} calc(100vw / 6) ${vw(30, 'desktop')};
        }
        &.product-highlight-align-right {
          margin: ${vw(15, 'desktop')} 0 ${vw(30, 'desktop')} calc(100vw / 12 * 4);
        }
      }
    }
    &.product-highlight-pos-right {
      grid-area: 1 / 7 / span 1 / span 5;

      p {
        &.product-highlight-align-left {
          margin: ${vw(15, 'desktop')} calc(100vw / 12) ${vw(30, 'desktop')} 0;
        }
        &.product-highlight-align-center {
          margin: ${vw(15, 'desktop')} calc(100vw / 24) ${vw(30, 'desktop')};
        }
        &.product-highlight-align-right {
          margin: ${vw(15, 'desktop')} 0 ${vw(30, 'desktop')} calc(100vw / 12);
        }
      }
    }

    .product-highlight-align-left { text-align: left; }
    .product-highlight-align-center { text-align: center; }
    .product-highlight-align-right { text-align: right; }

    button.product-highlight-align-left { margin: 0 auto 0 0; }
    button.product-highlight-align-right { margin: 0 0 0 auto; }

    p {
      ${({ subtitle_size }) => subtitle_size === 'l' ? css`${getP30_1()} font-weight: 100;` : subtitle_size === 'm' ? css`${getP26()} font-weight: 100;` : getP20_2() };

      &.home-header-text-subtitle {
        ${({ subtitle_size }) => subtitle_size === 'm' ? css`letter-spacing: -1.5px;` : ''};
      }
    }
  `}
`

export const TimelineContainer = styled.div`
  margin-top: ${vw(20, 'mobile')};

  > span {
    margin: 0 ${vw(82, 'mobile')};
    background-color: ${({ theme }) => hex2Rgba(theme.colors.red, 0.3)};
    position: relative;
    display: block;
    height: ${vw(2, 'mobile')};
    z-index: -1;
    border-radius: ${vw(2, 'mobile')};

    span {
      background-color: ${({ theme }) => theme.colors.red};
      position: absolute;
      display: block;
      height: ${vw(2, 'mobile')};
      border-radius: ${vw(2, 'mobile')};
    }
  }

  ${mq.greaterThan('tablet')`
    margin-top: ${vw(20, 'desktop')};

    > span {
      margin: 0 ${vw(360, 'desktop')};
      height: ${vw(3, 'desktop')};
      border-radius: ${vw(3, 'desktop')};

      span {
        height: ${vw(3, 'desktop')};
        border-radius: ${vw(3, 'desktop')};
      }
    }
  `}
`

export const Main = styled.main`
  background-color: ${({ theme }) => theme.colors.red};
  height: 100vh;
  width: 100vw;
  overflow-x: clip;

  .marquee {
    background-color: unset !important;
  }
`