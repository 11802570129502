import { useState } from 'react'
import type { FC } from 'react'
import type { SubmitHandler } from 'react-hook-form'
import { useForm } from 'react-hook-form'
import { css } from 'styled-components'
import { AnimatePresence } from 'framer-motion'
import { fetchAPI } from '@/api'
import { MODALS } from '@dy/commons/context'
import { useModal } from '@dy/commons/hooks'
import { Modal, SearchResults, SearchByCategory } from '@/components'
import { Link, Figure, Form, Input } from '@dy/commons/components'
import { mq, vw, designGrid, grid } from '@dy/commons/styles'
import { TypeSearchResults } from '@dy/commons/schema'

type ModalSearchProps = {}

type emptySearchResult = {
  articles: [],
  brands: [],
  categories: [],
  products: [],
}

const emptySearchResult = {
  articles: [],
  brands: [],
  categories: [],
  products: [],
}

const searchClearIcon = {
  type: 'svg' as const,
  src: '/images/svg/i--search-clear.svg',
  alt: 'Clear search icon',
  size: { width: 12, height: 12 }
}

const searchGoPageIcon = {
  type: 'svg' as const,
  src: '/images/svg/i--search-go-page.svg',
  alt: 'Search go page icon',
  size: { width: 12, height: 12 }
}

// const searchVariants = {
//   active: {
//     opacity: 1,
//     y: 0,
//     userSelect: 'initial',
//     pointerEvents: 'initial',
//     transition: {
//       ...getTransition()
//     }
//   },
//   inactive: {
//     opacity: 0,
//     y: '-50%',
//     userSelect: 'none',
//     pointerEvents: 'none',
//     transition: {
//       ...getTransition()
//     }
//   }
// }

// const searchListVariants = {
//   active: {
//     x: '0px',
//     opacity: 1,
//     transition: {
//       ...getTransition()
//     }
//   },
//   exit: {
//     x: '10px',
//     opacity: 0,
//     transition: {
//       ...getTransition()
//     }
//   },
//   start: {
//     x: '-10px',
//     opacity: 0,
//     transition: {
//       ...getTransition()
//     }
//   }
// }

// const searchUlVariants = {
//   active: {
//     transition: {
//       ...getTransition(),
//       staggerChildren: .02,
//     }
//   }
// }

const dialogStyles = css`
  background: ${({ theme }) => theme.colors.white};
  height: 100vh;
  margin: 0;
  width: 100vw;

  ${mq.greaterThan('tablet')`
    height: ${vw(625, 'desktop')};
    margin-bottom: 0;
    max-height: 95vh;
    width: 100vw;
  `}

  ${mq.greaterThan('desktop')`
    height: 625px;
  `}

  header {
    border-bottom: 0;
    position: absolute;
    right: 0;

    h2 {
      display: none;
    }

    button {
      &[data-dismiss='modal'] {
        height: ${vw(62, 'mobile')};
        width: ${vw(62, 'mobile')};
        max-width: unset;

        ${mq.greaterThan('tablet')`
          height: ${vw(62, 'desktop')};
          padding-top: ${vw(40, 'desktop')};
          width: ${vw(62, 'desktop')};
        `}

        ${mq.greaterThan('desktop')`
          height: 62px;
          padding-top: 40px;
          width: 62px;
        `}

        figure {
           width: ${vw(18, 'mobile')};

          ${mq.greaterThan('tablet')`
            width: ${vw(18, 'desktop')};
          `}

          ${mq.greaterThan('desktop')`
            width: 18px;
          `}
        }
      }
    }
  }

  > .modal__body {
    ${designGrid({})}
    grid-template-rows: auto 1fr;
    height: 100%;
    padding: 0;
    width: 100%;
  }

  form {
    grid-column: 1 / span 6;
    /* margin-top: ${vw(20, 'mobile')}; */

    ${mq.greaterThan('tablet')`
      grid-column: 2 / span 10;
      margin-top: ${vw(20, 'desktop')};
      position: relative;
    `}

    ${mq.greaterThan('desktop')`
      margin-top: 20px;
    `}

    input[type='search'] {
      padding-bottom: ${vw(16, 'mobile')};
      padding-top: ${vw(15, 'mobile')};

      ${mq.lessThan('tablet')`
        margin-right: auto;
        width: 80vw;
      `}

      ${mq.greaterThan('tablet')`
        padding-bottom: ${vw(30, 'desktop')};
        padding-top: ${vw(15, 'desktop')};
      `}


      ${mq.greaterThan('desktop')`
        padding-bottom: 30px;
        padding-top: 15px;
      `}
    }

    button {
      align-items: center;
      display: flex;
      height: ${vw(50, 'mobile')};
      justify-content: center;
      opacity: 0;
      position: absolute;
      pointer-events: none;
      right: ${vw(grid.mobile.columns.gutter.width + (grid.mobile.columns.gutter.width * .5) + grid.mobile.columns.width, 'mobile')};
      top: 0;
      transition: 300ms opacity ease-out;
      user-select: none;
      width: ${vw(50, 'mobile')};

      ${mq.greaterThan('tablet')`
        height: ${vw(50, 'desktop')};
        right: ${vw(grid.desktop.columns.width, 'desktop')};
        width: ${vw(50, 'desktop')};
      `}

      ${mq.greaterThan('desktop')`
        height: 50px;
        width: 50px;
      `}

      &:hover {
        figure {
          opacity: .8;
        }
      }

      figure {
        opacity: .2;
        transition: 300ms opacity ease-out;
        width: ${vw(26, 'mobile')};

        ${mq.greaterThan('tablet')`
          width: ${vw(26, 'desktop')};
        `}

        ${mq.greaterThan('desktop')`
          width: 26px;
        `}
      }
    }

    a {
      opacity: 0;
      display: none;
      pointer-events: none;
      transition: 300ms opacity ease-out;
      user-select: none;

      ${mq.greaterThan('tablet')`
        align-items: center;
        display: flex;
        justify-content: center;
        position: absolute;
        right: 0;
      `}

      figure {
        ${mq.greaterThan('tablet')`
          transition: 300ms transform ease-out;
          width: ${vw(50, 'desktop')};
        `}

        ${mq.greaterThan('desktop')`
          width: 50px;
        `}
      }

      &:hover {
        figure {
          transform: scale(1.1);
        }
      }
    }

    &.dirty {
      button,
      a {
        opacity: 1;
        pointer-events: auto;
        user-select: auto;
      }
    }
  }

  label {
    padding: 0;
    margin-bottom: 0;
  }
`

const dialogVariants = (isActive: boolean) => ({
  initial: { y: '-100%' },
  animate: { y: isActive ? 0 : '-100%' },
  exit: { y: '-100%' },
  transition: { duration: .3 }
})

type TypeFormInputs = {
  search: string,
}

type typeSearchBy = (str:string) => Promise<TypeSearchResults>
export const searchBy: typeSearchBy = async (str) => {
  if(str?.length < 2) return {}

  const [data, error] = await fetchAPI('search', { q: str })
  if (error) return {}
  return data
}

export const ModalSearch:FC<ModalSearchProps> = () => {
  const { isActive, toggle } = useModal(MODALS.SEARCH)
  const { register, handleSubmit, reset } = useForm<TypeFormInputs>()
  const [qValue, setQValue] = useState('')
  const [results, setResults] = useState(emptySearchResult)

  const onSubmit: SubmitHandler<TypeFormInputs> = async data => {
    const { search:searchValue } = data
    const res = await searchBy(searchValue)
    setResults(res as emptySearchResult)
  }

  const onChange = async ({ target }) => {
    const searchValue = target?.value
    if (typeof searchValue !== 'string') return
    setQValue(searchValue)
    const res = await searchBy(searchValue)
    setResults(res as emptySearchResult)
  }

  const onClickClearSearch = () => {
    console.info('Clearing')
    setQValue('')
    reset({ search: '' }, {
      keepErrors: false,
      keepDirty: true,
    })
  }

  return (
    <Modal isActive={isActive} close={toggle} title='Menu' xAlign='flex-start' variants={dialogVariants} dialogStyles={dialogStyles} size='full'>
      <Form onSubmit={handleSubmit(onSubmit)} className={`${qValue?.length > 2 ? 'dirty' : ''}`}>
        <Input type='search' name='search' label='' col={12} register={register} onChange={onChange} bgColor='white' />
        <button type='button' onClick={onClickClearSearch}>
          <Figure media={searchClearIcon} fill={false} />
        </button>
        <Link href={`/search?q=${qValue}`}>
          <Figure media={searchGoPageIcon} fill={false} />
        </Link>
      </Form>
      <AnimatePresence>
        <SearchResults key='results-list' data={results} />
        <SearchByCategory />
      </AnimatePresence>
    </Modal>
  )
}
