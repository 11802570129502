import { memo } from 'react'
import { parserMedia } from '@dy/commons/utils'
import { ImageContent } from '../contentStyles'

type ContentTypeImage = {
  col: { from: number, expand: number},
  row: { from: number, expand: number},
  media: any,
  alt: string,
  caption?: string,
  type: 'image' | 'media',
  media_caption?: string
  media_size?: 'small' | 'medium' | 'big'
}

interface IContentImage {
  data: ContentTypeImage
}

export const ContentImage = memo(({ data }:IContentImage) => {
  const { media, media_caption, media_size } = data

  return (
    <ImageContent media={parserMedia({...media, media_caption})} size={media_size} />
  )
})
