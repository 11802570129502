import {memo, useEffect, useMemo, useCallback, useState } from 'react'
import { useRouter } from 'next/router'
import { useInView } from 'react-intersection-observer'

import { Figure } from '@dy/commons/components'
import { useDeviceType } from '@dy/commons/hooks'
import { getTransition } from '@dy/commons/styles'
import { Question } from './Question'
import { faqListVariants } from './faqVariants'
import { Section, Chevron, List } from './faqStyles'

type Seo = {
  title: string,
  description: string
}

type Slug = {
  [locale: string]: string
}

type TypeFaqQuestion = {
  question: string,
  answer: string,
  hash: string
}

type TypeQuestionList = {
  data: TypeFaqCategory,
  idx: number,
  setActiveIndex: any,
  setActiveCategory: any,
  setActiveQuestion: any,
}

export type TypeFaqCategory = {
  name: string,
  seo: Seo,
  slug: Slug,
  faqs: TypeFaqQuestion[]
}

const mediaChevronIcon = {
  type: 'image' as const,
  alt: 'Icon chevron',
  default: {
    src: '/images/svg/i--chevron--lightred.svg',
    size: { width: 6, height: 8 }
  }
}



const getFaqs = (faqs, setActiveQuestion) => faqs.map((faq, idx) => <Question key={idx} data={faq} setActiveQuestion={setActiveQuestion} />)

export const SectionQuestionsList = memo(({ data, idx, setActiveIndex, setActiveCategory, setActiveQuestion }:TypeQuestionList) => {
  const { locale, query } = useRouter()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const Faqs = useMemo(() => getFaqs(data.faqs, setActiveQuestion), [data])
  const [isExpanded, setIsExpanded] = useState(true)
  const { isMobile } = useDeviceType()

  const [ref, inView] = useInView({
    rootMargin: '100px 0px -50% 0px',
    threshold: 1,
    triggerOnce: false
  })

  useEffect(() => {
    if (inView && data?.slug) {
      setActiveIndex(idx)
      setActiveCategory(data.slug[locale.split('-')[0]])
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView])


  useEffect(() => {
    if (isMobile) {
      setIsExpanded(false)
    }
  }, [isMobile])

  const handleOnClick = () => {
    setIsExpanded(!isExpanded)
  }
  const sectionRef = useCallback(node => {
    if (typeof window !== 'object') return

    // @ts-ignore
    if (node !== null && query?.slug && query.slug[0] === data.slug[locale.split('-')[0]]) {
      setTimeout(() => { node.scrollIntoView({ behavior: 'smooth' }) }, 1200)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Section initial={{ opacity: 0, y: -15 }} animate={{ opacity: 1, y: 0 }} exit={{ opacity: 0, y: 0 }} transition={{ duration: 1.2 }}>
      <div ref={sectionRef} className='idslug'></div>
      <h2 ref={ref}>
        {data.name}
        <Chevron aria-hidden={true} animate={{ rotate: isExpanded ? 90 : 0}} transition={getTransition()} onClick={handleOnClick}>
          <Figure media={mediaChevronIcon}/>
        </Chevron>
      </h2>
      <List {...(idx && { id: idx })} aria-expanded={isExpanded} tabIndex={-1} variants={faqListVariants} initial='initial' exit='exit' animate={isExpanded ? 'animate' : 'exit'}>
        {Faqs}
      </List>
    </Section>
  )
})
