import { memo, useMemo, useState, useEffect } from 'react'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import styled from 'styled-components'
import { useSession } from 'next-auth/react'
import { Navbar } from './nav/Navbar'
import { Footer } from './footer'
import { CartItemsAll, CartItemsNew } from '../cart'
import { cookie } from '@dy/commons/utils'
import { useModal } from '@dy/commons/hooks'
import { MODALS } from '@dy/commons/context'
import { NewCartItemsContext } from '@dy/commons/components'
// DEV ONLY
import { Susy } from '@dy/commons/styles'
import { SlimBanners } from './SlimBanners'

const DynamicModalAuth = dynamic(() => import('@/components').then(mod => mod.ModalAuth))
const DynamicModalMenu = dynamic(() => import('@/components').then(mod => mod.ModalMenu))
const DynamicModalSearch = dynamic(() => import('@/components').then(mod => mod.ModalSearch))
const DynamicModalRegion = dynamic(() => import('@/components').then(mod => mod.ModalRegion))
const DynamicModalCookies = dynamic(() => import('@/components').then(mod => mod.ModalCookies))
const DynamicModalRegionRegister = dynamic(() => import('@/components').then(mod => mod.ModalRegionRegister))
const DynamicModalComercialRegion = dynamic(() => import('@/components').then(mod => mod.ModalComercialRegion))
const DynamicModalNewsletter = dynamic(() => import('@/components').then(mod => mod.ModalNewsletter))
const DynamicModalAccount = dynamic(() => import('@/components').then(mod => mod.ModalAccount))


const LayoutStyled = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  min-height: 100vh;
`

export const Layout = memo<any>(({ children, data }) => {
  const { locale } = useRouter()
  const customerData = false
  const { data:session, status } = useSession()
  const [showCart, setShowCart] = useState(false)
  const [cartItems, setCartItems] = useState([])
  const hasBanners = useMemo(() => data?.slim_banners?.length > 0, [data?.slim_banners])
  const { toggle:toggleModalCookies } = useModal(MODALS.COOKIES)

  console.log(status, session)
  // const { open:openLanguages } = useModal(modalLanguagesId)
  useEffect(() => {
    setTimeout(() => {
      if(!cookie.get('acceptedCookie')) {
        toggleModalCookies()
        // cookie.set('acceptedCookie', 'true')
      }
    }, 1000)
  }, [])


  return (
    <LayoutStyled>
      {locale !== 'en-EU' && <>
        {data?.slim_banners?.length > 0 && <SlimBanners banners={data?.slim_banners} />}
        <NewCartItemsContext.Provider value={{ cartItems, setCartItems }}>
          <Navbar firstCategory={data?.header?.categories?.[0] ?? {}} setShowCart={setShowCart} hasBanners={hasBanners}/>
          <CartItemsNew products={cartItems} hasBanners={hasBanners}/>
          <CartItemsAll showCart={showCart} setShowCart={setShowCart} hasBanners={hasBanners}/>
        </NewCartItemsContext.Provider>
      </>}
      {children}
      <DynamicModalMenu />
      <DynamicModalAuth />
      <DynamicModalSearch />
      <DynamicModalRegion />
      {data?.footer && <Footer services={data?.services} key='footer' pages={data?.footer} firstCategory={data?.header?.categories?.[0]}/>}
      {/* <ModalComercialRegion data={{}}/>
      <ModalWelcome />
      <ModalRegionRegister /> */}
      {!customerData && <DynamicModalNewsletter />}
      <DynamicModalComercialRegion />
      <DynamicModalCookies />
      <DynamicModalRegionRegister />
      <DynamicModalAccount />
      <Susy />
    </LayoutStyled>
  )
})
