import styled from 'styled-components'
import { mq, vw, createUnderline, designGrid, getP53, getP26_1, getP20_Mobile, getP26, getP15_1, getP15_1B, getP15_3 } from '@dy/commons/styles'

export const Div = styled.div`
  color: ${({ theme }) => theme.colors.background};
  font-family: ${({ theme }) => theme.fonts.tertiaryFont};
  ${getP20_Mobile()}
  grid-column: 1 / span 4;
  letter-spacing: -.23px;
  margin-bottom: ${vw(50, 'mobile')};

  ${mq.greaterThan('tablet')`
    ${getP26_1()}
    grid-column: 9 / span 4;
    letter-spacing: -.3px;
    margin-bottom: ${vw(60, 'desktop')};
    `}

  a {
    ${createUnderline({ color: 'background' })}

    &.phone {
      ${createUnderline({ color: 'creme', height: 3 })}
    }
  }

  .phone {
    ${getP53()}
    color: ${({ theme }) => theme.colors.creme};
    font-family: ${({ theme }) => theme.fonts.primaryFont};
    display: inline-block;
    margin-top: ${vw(10, 'mobile')};

    ${mq.greaterThan('tablet')`
      margin-top: ${vw(15, 'desktop')};
    `}
  }
`

export const FooterStyled = styled.footer`
  ${designGrid({})}
  background-color: ${({ theme }) => theme.colors.red};
  padding: ${vw(70, 'mobile')} ${vw(20, 'mobile')} ${vw(50, 'mobile')};
  position: relative;
  z-index: 2;

  ${mq.greaterThan('tablet')`
    padding: ${vw(100, 'desktop')} 0 ${vw(60, 'desktop')};
  `}
`

export const Aside = styled.aside`
  overflow-x: hidden;
`

export const Section = styled.section`
  grid-column: 1 / span 6;
  margin-bottom: ${vw(60, 'mobile')};

  ${mq.greaterThan('tablet')`
    grid-column: 9 / span 3;
    margin-bottom: 0;
  `}

  h2 {
    ${getP26()}
    color: ${({ theme }) => theme.colors.creme};
    font-family: ${({ theme }) => theme.fonts.tertiaryFont};
    text-align: left;
    margin-bottom: 0;

  }

  .input--newsletter input {
    ${getP15_1()}
    border-bottom: 1px solid ${({ theme }) => theme.colors.creme};
  }

  button {
    ${getP15_1B()}
    cursor: pointer;
  }
`

export const PagesSection = styled.section`
  grid-column: 4 / span 3;
  padding: 0;

  ${mq.greaterThan('tablet')`
    align-items: flex-end;
    grid-column: 6 / span 2;
    grid-row: 1 / span 2;
  `}

  .list--pages {
    align-self: flex-end;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    list-style-type: none;
    margin-bottom: ${vw(10, 'mobile')};

    ${mq.greaterThan('tablet')`
      margin-bottom: 0;
    `}

    li {
      align-items: center;
      display: flex;
      margin-bottom: ${vw(15, 'mobile')};

      ${mq.greaterThan('tablet')`
        margin-bottom: ${vw(15, 'desktop')};
      `}

      &:last-child {
        margin-bottom: 0;
      }
    }

    a {
      color: ${({ theme }) => theme.colors.background};
      display: inline-block;
      font-family: ${({ theme }) => theme.fonts.tertiaryFont};
      ${getP15_1()}
      font-size: ${vw(15, 'mobile')};
      opacity: 1;
      text-decoration: none;
      transition: 300ms opacity ease-out;

      &:hover {
        opacity: .4;
      }
    }
  }
`


export const RssSection = styled.section`
  ${designGrid({})}
  grid-column: 1 / span 6;
  padding: 0;
  width: auto;

  ${mq.greaterThan('tablet')`
    align-items: flex-end;
    grid-column: 1 / span 12;
    grid-row: 3 / span 1;
  `}

  .stamp {
    grid-column: 1 / auto;
    grid-row: 1 / span 1;
    height: ${vw(100, 'mobile')};
    position: relative;
    width: ${vw(100, 'mobile')};

    ${mq.greaterThan('tablet')`
      grid-column: 2 / auto;
      height: ${vw(100, 'desktop')};
      margin-top: ${vw(45, 'desktop')};
      width: ${vw(100, 'desktop')};
    `}

    img {
      color: ${({ theme }) => theme.colors.creme};
      mix-blend-mode: multiply;
    }
  }

  .list--rrss {
    align-self: flex-end;
    grid-column: 4 / span 3;
    grid-row: 1 / span 1;
    display: flex;
    list-style-type: none;
    margin-bottom: ${vw(10, 'mobile')};


    ${mq.greaterThan('tablet')`
      grid-column: 6 / span 3;
      grid-row: 2 / span 1;
      margin-bottom: 0;
    `}

    li {
      align-items: center;
      display: flex;
      margin-right: ${vw(15, 'mobile')};

      ${mq.greaterThan('tablet')`
        margin-right: ${vw(15, 'desktop')};
      `}

      &:last-child {
        margin-right: 0;
      }
    }

    a {
      display: inline-block;
      opacity: .4;
      text-decoration: none;
      transition: 300ms opacity ease-out;

      &:hover {
        opacity: 1;
      }

      figure {
        height: ${vw(30, 'mobile')};
        width: ${vw(30, 'mobile')};

        ${mq.greaterThan('tablet')`
          height: ${vw(30, 'desktop')};
          min-height: 30px;
          min-width: 30px;
          width: ${vw(30, 'desktop')};
        `}
      }
    }
  }

  .row-copyright {
    display: flex;
    flex-direction: column;
    grid-column: 1 / span 3;
    grid-row: 2 / span 1;
    margin-top: ${vw(60, 'mobile')};

    ${mq.greaterThan('tablet')`
      flex-direction: row;
      grid-column: 2 / span 4;
      margin-top: ${vw(45, 'desktop')};
    `}

    > a,
    > span {
      ${getP15_1()}
      color: ${({ theme }) => theme.colors.white};
      margin-bottom: 0;
    }

    > span {
      margin-bottom: ${vw(5, 'mobile')};
      margin-right: 0;

      ${mq.greaterThan('tablet')`
        margin-bottom: 0;
        margin-right: ${vw(30, 'desktop')};
      `}
    }

    > a {
      transition: 300ms opacity ${({ theme }) => theme.ease};
      &:hover {
        opacity: 1;
      }
    }
  }

  .btn--country-and-lang {
    ${getP15_3()}
    border-radius: 90px;
    color: ${({ theme }) => theme.colors.background};
    grid-column: 4 / span 3;
    height: ${vw(50, 'mobile')};
    margin: auto auto 0 0;
    padding: 0 ${vw(20, 'mobile')};

    ${mq.greaterThan('tablet')`
      grid-column: 9 / span 2;
      grid-row: 2 / span 1;
      height: ${vw(40, 'desktop')};
      padding: 0  ${vw(20, 'desktop')};
      letter-spacing: -0.5px;
    `}

    .button__bg + div {
      ${mq.lessThan('tablet')`
        white-space: break-spaces;
      `}
    }
  }
`