"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Accordion = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const AccordionItem_1 = require("./AccordionItem");
const useAccordion_1 = require("./useAccordion");
const accordionStyles_1 = require("./accordionStyles");
const getAccordionItems = (items, activeIndex, activeCategory, activeQuestion) => {
    const parsedItems = [];
    for (let item of items) {
        if (Array.isArray(item.content)) {
            const parsedCategory = {
                header: item.header,
                content: (0, jsx_runtime_1.jsx)(exports.Accordion, { items: item.content, activeIndex: activeIndex, activeCategory: activeCategory, activeQuestion: activeQuestion })
            };
            parsedItems.push(parsedCategory);
        }
        else {
            parsedItems.push(item);
        }
    }
    return parsedItems.map((parsedItem, idx) => {
        return ((0, jsx_runtime_1.jsx)(AccordionItem_1.AccordionItem, { data: parsedItem, index: idx, activeCategory: activeCategory, activeQuestion: activeQuestion }, idx));
    });
};
const Accordion = ({ items = [], activeIndex, activeCategory, activeQuestion }) => {
    const [activeItem, setActiveItem, collapseAll] = (0, useAccordion_1.useAccordion)();
    const AccordionItems = (0, react_1.useMemo)(() => getAccordionItems(items, activeIndex, activeCategory, activeQuestion), [items, activeCategory, activeQuestion]);
    const context = (0, react_1.useMemo)(() => ({ activeItem, setActiveItem, collapseAll }), [activeItem]);
    (0, react_1.useEffect)(() => {
        setActiveItem({
            index: activeIndex,
            slug: activeCategory,
            hash: activeQuestion,
        });
    }, [activeIndex, activeCategory, activeQuestion]);
    return ((0, jsx_runtime_1.jsx)(useAccordion_1.AccordionContext.Provider, Object.assign({ value: context }, { children: (0, jsx_runtime_1.jsx)(accordionStyles_1.AccordionWrapper, { children: AccordionItems }) })));
};
exports.Accordion = Accordion;
