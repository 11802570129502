import { getTransition } from '@dy/commons/styles'

export const dialogVariants = (isActive: boolean) => ({
  initial: { x: '-100%' },
  animate: { x: isActive ? 0 : '-100%' },
  exit: { x: '-100%' },
  transition: { duration: .3 }
})

const transition = {
  ...getTransition()
}

export const listVariants = {
  inactive: {
    opacity: 0,
    transition
  },
  active: {
    opacity: 1,
    transition: {
      ...getTransition(),
      staggerChildren: .01
    }
  },
  exit: {
    opacity: 0,
    transition
  }
}

export const listItemVariants = {
  inactive: {
    x: -10,
    opacity: 0,
    transition
  },
  active: {
    x: 0,
    opacity: 1,
    transition
  },
  exit: {
    opacity: 0
  }
}

export const detailUlvariants = {
  inactive: {
    height: 0,
    opacity: 0,
    transition: {
      ...getTransition()
    }
  },
  active: {
    height: 'auto',
    opacity: 1,
    transition: {
      ...getTransition(),
      delay: .1,
      staggerChildren: .01,
    }
  },
  exit: {
    height: '0px',
    opacity: 0,
    transition: {
      ...getTransition(),
      staggerDirection: -1
    }
  }
}

export const detailvariants = {
  inactive: {
    height: '25px',
    transition: {
      ...getTransition()
    }
  },
  active: {
    height: 'auto',
    transition: {
      ...getTransition(.1)
    }
  }
}