import { memo } from 'react'
import { Button } from '@dy/commons/components'
import { useTranslator } from 'hooks/useTranslator'
import { useModal } from '@dy/commons/hooks'
import { MODALS } from '@dy/commons/context'
import { WrapperSubscribe } from './formStyles'

export const Subscribe = memo(()=>{
  const { t } = useTranslator()
  const { toggle } = useModal(MODALS.AUTH)

  return(
    <WrapperSubscribe>
      <h1>{t('commons.subscribe.welcome')}</h1>
      <h2>{t('commons.subscribe.register')}</h2>
      <ul>
        <li>{t('commons.subscribe.open')}</li>
        <li>{t('commons.subscribe.order')}</li>
        <li>{t('commons.subscribe.offer')}</li>
        <li>{t('commons.subscribe.save_products')}</li>
        <li>{t('commons.subscribe.check_invoices')}</li>
        <li>{t('commons.subscribe.contact_commercial')}</li>
      </ul>
      <Button onClick={toggle} bgType='red' className='red'> {t('commons.subscribe.register_button')} </Button>
    </WrapperSubscribe>
  )
})