import { memo, useMemo } from 'react'
import { useRouter } from 'next/router'
import { currencyFormatter, getLang } from '@dy/commons/utils'
import { Button } from '@dy/commons/components'
import { Section, CardListItem } from './accountStyles'
import { useTranslator } from '@/hooks'

interface ISectionInvoices {
  data: any
}

const getOrders = (orders, locale) => orders?.map((order, idx) => {
  const { dateCreated, total, code } = order
  const priceCurreny = currencyFormatter({price: total, localeRegion: locale})

  return (
    <CardListItem key={idx}>
      <table className={'overview order'}>
        <tbody>
          <tr><td>{code}</td> <td>{dateCreated}</td> <td>{priceCurreny}</td></tr>
        </tbody>
      </table>
    </CardListItem>
  )
})

const getInvoices = (items, t ,locale) => items.map((item, idx) => {
  const { id, dateSent, total, orders, downloadLink } = item
  const OrderList = getOrders(orders, locale)
  const priceCurreny = currencyFormatter({price: total, localeRegion: locale})

  return (
    <CardListItem key={idx}>
      <table className={'overview'} >
        <tbody>
          <tr><td className={'headline mobile'}>{t('account.invoice')} nº{id}</td></tr>
          <tr>
            <td>{t('account.invoice_issued')}</td>
            <td>{t('account.order.total')}</td>
          </tr>
          <tr>
            <td>{dateSent}</td>
            <td>{priceCurreny}</td>
          </tr>
        </tbody>
      </table>
      <div className={'main-info'}>
        <div className={'products'}>
          <p className={'headline desktop'}>{t('account.invoice')} nº{id}</p>
          <ul>
            {OrderList}
          </ul>
        </div>
        <div className={'cta'}>
          <Button bgType={'red'} outline> <a href={downloadLink}>{t('account.invoice_download')}</a></Button>
        </div>
      </div>
    </CardListItem>
  )
})

export const SectionInvoices = memo<ISectionInvoices>(({ data }) => {
  const { locale: localeRouter } = useRouter() 
  const locale = getLang(localeRouter)
  const { t } = useTranslator()
  const InvoiceList = useMemo(() => getInvoices(data, t, locale), [data, t, locale])

  return (
    <Section>
      <ul>
        {InvoiceList}
      </ul>
    </Section>
  )
})