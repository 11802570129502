import { memo, useMemo } from 'react'
import type { FC } from 'react'
import styled, { css } from 'styled-components'
import { useModal } from '@dy/commons/hooks'
import { MODALS } from '@dy/commons/context'
import { Modal } from '.'
import { Button } from '@dy/commons/components'
import { mq, vw, getP20_4 } from '@dy/commons/styles'
import { useTranslator } from '@/hooks'

type MenuProps = {}


const dialogStyles = css`
  span {
    ${getP20_4()}
    color: ${({ theme }) => theme.colors.red};
    text-align: left;
  }
`
const Actions = styled.div<any>`
  column-gap: ${vw(20, 'mobile')};
  display: grid;
  grid-template-columns: calc(50% - ${vw(10, 'mobile')}) calc(50% - ${vw(10, 'mobile')});
  margin-top: ${vw(20, 'mobile')} ;
  pointer-events: none;
  width: 100%;

  ${mq.greaterThan('tablet')`
    column-gap: ${vw(20, 'desktop')};
    grid-template-columns: calc(50% - ${vw(10, 'desktop')}) calc(50% - ${vw(10, 'desktop')});
    margin-top: ${vw(40, 'desktop')} ;

  `}

  button {
    height: ${vw(60, 'mobile')};
    pointer-events: ${({ isActive }) => isActive ? 'auto' : 'none'};
    white-space: nowrap;
    width: 100%;

    ${mq.greaterThan('tablet')`
      height: ${vw(60, 'desktop')};
    `}
  }

  .button__bg {
    border: none;
  }
`
const ListItem = styled.li<any>`
  span {
    ${getP20_4()}
    display: block;
    color: ${({ theme }) => theme.colors.red};
  }
`

const FAKE_DATA_COMERCIAL = {
  'region': 'Extremadura',
  'comercials': [{
    'name': 'Comercial Catchot',
    'tel': 'T 971 363 052',
    'mail': 'pedidos@comercialcatchot.com'
  }]
}

const getComercials = (comercials) => comercials.map((comercial, idx) => {
  const { name, tel, mail } = comercial

  return (
    <ListItem key={idx}>
      <span>{name}</span>
      <span>{tel}</span>
      <span>{mail}</span>
    </ListItem>
  )
})


export const ModalComercialRegion: FC<MenuProps> = memo(() => {
  const { isActive, toggle } = useModal(MODALS.COMERCIAL)
  const { t } = useTranslator()
  const { region, comercials } = FAKE_DATA_COMERCIAL
  const ComercialsList = useMemo(() => getComercials(comercials), [comercials])


  return (
    <Modal isActive={isActive} close={toggle} title={t('commons.modal_commercial.title') as string} xAlign='flex-end' dialogStyles={dialogStyles} size={'s'}>
      <span>{t('commons.modal_commercial.in')} {region} {t('commons.modal_commercial.work_with')}</span>
      <ul>
        {ComercialsList}
      </ul>
      <Actions isActive={isActive}>
        <Button onClick={()=> console.log('change')} bgType={'red'} outline={true} >{t('commons.modal_commercial.action_change')}</Button>
        <Button onClick={()=> console.log('close')} bgType={'red'}>{t('commons.modal_commercial.ok')}</Button>
      </Actions>
    </Modal>
  )
})
