import { memo, useMemo } from 'react'
import { PagesSection } from './footerStyles'
import { Link } from '@dy/commons/components'
// import { useRouter } from 'next/router'

export type TypePage = {
  readonly type: 'simple' | 'external',
  readonly title: string,
  readonly url: {
    page: 'content' | 'page' | 'products' | 'product',
    slug: {
      en?: null | string,
      es?: null | string,
      fr?: null | string,
      it?: null | string
    }
  }
}

const pathGenerator = {
  'content': (slug) => `/page/${slug}`,
  'simple': (slug) => `/page/${slug}`,
  'faqs': (slug) => slug ? `/faqs/${slug}` : '/faqs',
  'product': (slug) => `/product/${slug}`,
  'shop': (slug) => `/shop/${slug}`,
  'category': (slug) => `/shop/${slug}`
}

interface ISectionPages {
  pages: TypePage[]
}

const getPagesLink = (pages, locale) => pages.map((page, idx) => {
  const { type, title, url: { page:pageType, slug } } = page
  if(!title || title.indexOf('translation missing') !== -1) return null
  const path = pathGenerator[pageType](slug?.[locale])

  return (
    <li key={`footer-pages-${idx}`}>
      {type === 'external' ?
        <a href={slug[locale]} rel='noreferrer' target='_blank' title={title}>{title}</a>
        :
        <Link href={path || '/'} title={title}>{title}</Link>
      }
    </li>
  )
})

export const SectionPages = memo(({ pages }:ISectionPages) => {
  // const { locale } = useRouter()
  //DEV Hardcoded
  const locale = 'es'
  const Pages = useMemo(() => getPagesLink(pages, locale), [pages, locale])

  return (
    <PagesSection>
      <ul className='list--pages'>
        {Pages}
      </ul>
    </PagesSection>
  )
})
