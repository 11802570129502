'use-client'
import { memo, useCallback, useEffect } from 'react'
import type { FC } from 'react'
import { css } from 'styled-components'
import { useTranslator } from '@/hooks'
import { useModal } from '@dy/commons/hooks'
import { MODALS } from '@dy/commons/context'
import { Modal } from './'
import { SectionUserAuth } from '@/components'
import type { TypeSectionAuthLevel } from '@/components'
import { mq, vw } from '@dy/commons/styles'

export interface iModalAuthPayload {
  payload: {
    level: TypeSectionAuthLevel
  }
}

const buttonBack = {
  'login': 'initial',
  'signup_cif': 'initial',
  'signup': 'signup_cif',
  'signup_existing_cif': 'signup_cif',
  'forgotPassword': 'login',
  'resetPassword': 'forgotPassword'
}

const dialogStyles = css`
  > .modal__body {
    padding-bottom: ${vw(30, 'mobile')};

    ${mq.greaterThan('tablet')`
      padding-bottom: ${vw(30, 'desktop')};

    `}

    ${mq.greaterThan('desktop')`
      padding-bottom: 30px;
    `}
  }
`


export const ModalAuth: FC = memo(() => {
  const { isActive, payload, toggle, updatePayload } = useModal(MODALS.AUTH)
  const { t } = useTranslator()

  const onClickBack = useCallback(() => {
    console.log('click button back payload?.level:', payload?.level)
    updatePayload({ level: buttonBack[payload?.level ?? 'initial']})
  }, [updatePayload, payload])

  const onClose = useCallback(() => {
    updatePayload({ level: 'initial' })
    toggle()
  }, [updatePayload, toggle])

  useEffect(() => {
    console.log('🟡 payload', payload)
    if(!payload) updatePayload({ level: 'initial' })
  }, [payload, updatePayload])

  return (
    <Modal isActive={isActive} close={onClose} title={t(`commons.modals.auth.${payload?.level as TypeSectionAuthLevel ?? 'initial'}.title`) as string} xAlign='flex-start' onClickBack={buttonBack[payload?.level] ? onClickBack : null} size='s' dialogStyles={dialogStyles}>
      <SectionUserAuth toggleModal={toggle} payload={payload} updatePayload={updatePayload} />
      {/* <button type='button' onClick={() => updatePayload({ level: 'forgot-password' })}>TEST PAYLOAD</button> */}
    </Modal>
  )
})
