import { GetServerSidePropsContext } from 'next'
import { useEffect, useMemo } from 'react'
import { ApolloClient, HttpLink, InMemoryCache, ApolloLink, concat } from '@apollo/client'
import type { NormalizedCacheObject } from '@apollo/client'

let apolloClient: ApolloClient<NormalizedCacheObject> | undefined

const httpLinkCMS = new HttpLink({ uri: `https://${process.env.DY_CMS}/graphql` })

const httpLinkBigCommerce = new HttpLink({ uri: `https://${process.env.NEXT_PUBLIC_BIGCOMMERCE_GRAPHQL_API_URL}/graphql` })

// useQuery(QUERY, { variables, context: { endpoint: 'BC' } })

function createApolloClient(initialState, context?:GetServerSidePropsContext) {
  console.log('🦄 context', context)
  const isPrivatePath = true
  // const isPrivatePath = context?.req?.url?.includes('/shop')

  const authCMS = new ApolloLink((operation, forward) => {
    operation.setContext({
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'DY-Access-Token': `${isPrivatePath ? process.env.DY_PRIVATE_STORE_ACCESS_TOKEN : process.env.DY_PUBLIC_CATALOG_ACCESS_TOKEN}`
      },
      fetch
    })

    return forward(operation)
  })

  const authBigCommerce = new ApolloLink((operation, forward) => {
    operation.setContext({
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${isPrivatePath ? process.env.BIGCOMMERCE_PRIVATE_CHANNEL_GRAPHQL_AUTH_TOKEN : process.env.BIGCOMMERCE_PUBLIC_CHANNEL_GRAPHQL_AUTH_TOKEN}`
      },
      fetch
    })

    return forward(operation)
  })


  return new ApolloClient({
    ssrMode: typeof window === 'undefined', // set to true for SSR
    link: ApolloLink.split(
      operation => operation.getContext().endpoint === 'BC',
      concat(authBigCommerce, httpLinkBigCommerce), // <= apollo will send to this if clientName is 'BC'
      concat(authCMS, httpLinkCMS) // <= otherwise will send to this
    ),
    cache: new InMemoryCache().restore(initialState),
  })
}

export function initializeApollo(initialState = null, context?: GetServerSidePropsContext) {
  const _apolloClient = apolloClient ?? createApolloClient(initialState, context)

  // If the page has data fetching methods that use Apollo Client, the initial state gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract()
    // Restore the cache using the data passed from getStaticProps/getServerSideProps combined with the existing cached data
    _apolloClient.cache.restore({ ...existingCache, ...initialState })
  }

  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient
  return _apolloClient
}

export function useApollo(initialState:any, context?: GetServerSidePropsContext) {
  const clientStore = useMemo(() => initializeApollo(initialState, context), [initialState, context])

  // Clear the client's store on unmount to prevent memory leaks
  useEffect(() => {
    return () => { clientStore.clearStore() }
  }, [clientStore])
  return clientStore
}
