import { memo } from 'react'
import { CategoriesAccordion } from '@dy/commons/components'
import { NavSection } from './shopStyles'

type TypeNavShop = {
  categories: any[],
  productDetailVisible: boolean
}

export const NavShop = memo(({ categories, productDetailVisible = false }:TypeNavShop) => {
  return (
    <NavSection $productDetailVisible={productDetailVisible}>
      <CategoriesAccordion categories={categories} />
    </NavSection>
  )
})
