import { memo, useEffect, useMemo, useState } from 'react'
import { useRouter } from 'next/router'
import dynamic from 'next/dynamic'
import { getFilters, getFilteredProducts, getFeaturedFilters, generateQueryParams, getFiltersFromQuery } from '@dy/commons/utils'
import { useScrollTracker, useMount } from '@dy/commons/hooks'
import { SectionFilters, SectionProductList, NavShop, SectionProductDetail, SectionFiltersFeatured } from '../shop'
import { ShopSection } from './contentStyles'
import type { IMarqueeSlider } from '@dy/commons/components'

const DynamicMarqueeSlider = dynamic<IMarqueeSlider>(() => import('@dy/commons/components').then((mod)=> mod.MarqueeSlider), { ssr: false })

interface ISectionShop {
  data: any
}
let firstLoad = true

const updateQueryParams = ({ query, activeFilters, push }) => {
  const slug = typeof query.slug === 'string' ? query.slug : query.slug.join('/')
  const { stringParams } = generateQueryParams(activeFilters)
  push('/page/[...slug]', `/page/${slug}?${stringParams}`, { shallow: true })
}

export const SectionShop = memo(({ data }:ISectionShop) => {
  const { categories, products } = data
  const { locale, asPath, query, push, isFallback } = useRouter()
  const isMounted = useMount()
  const [productDetailVisible, setProductDetailVisible] = useState(false)
  const [productDetailed, setProductDetailed] = useState(null)
  const [selectedCard, setSelectedCard] = useState(null)
  const [showLargeProductDetail, setShowLargeProductDetail] = useState(false)
  const [activeFilters, setActiveFilters] = useState({ sort: {recommended: true} })
  const [featuredFilters, setFeaturedFilters] = useState([])
  const [currentProducts, setCurrentProducts] = useState({
    all: Array.from(products),
    current: Array.from(products)
  })

  const scrollState = useScrollTracker(160)
  const isScrolling = useMemo(() => scrollState !== 'TOP', [scrollState])

  const filters = useMemo(() => getFilters(products, locale), [products, locale])

  const displayProductDetail = () => {
    if(!productDetailVisible) setProductDetailVisible(true)
  }

  const hideProductDetail = () => {
    if(productDetailVisible) {
      setProductDetailVisible(false)
      setSelectedCard(null)
    }
    if(typeof window !== 'undefined') {
      window.history.back()
    }
  }

  useEffect(() => {
    if (window.history.scrollRestoration !== 'manual') {
      window.history.scrollRestoration = 'manual'
    }

    return () => {
      window.history.scrollRestoration = 'auto'
    }
  }, [])

  useEffect(() => {
    if(isMounted) {
      if(firstLoad) { firstLoad = false }
      else {
        updateQueryParams({ query, activeFilters, push })
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMounted, activeFilters])

  useEffect(() => {
    // @ts-ignore
    if(!firstLoad) {
      setCurrentProducts({
        all: products,
        current: getFilteredProducts(products, activeFilters)
      })
      setFeaturedFilters(getFeaturedFilters(activeFilters, filters?.[0]))
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMounted, activeFilters])

  // Get param filters from URL
  useEffect(() => {
    if(filters) {
      const incomingActiveFiltersFromUrl = getFiltersFromQuery(asPath, filters[1])
      console.log('incomingActiveFiltersFromUrl', incomingActiveFiltersFromUrl)
      //@ts-ignore
      setActiveFilters(incomingActiveFiltersFromUrl)
    }
  }, [filters, asPath])

  if(isFallback) return <></>

  return (
    <>
      <DynamicMarqueeSlider data={'Productos destacados'} inverted={true} />
      <ShopSection>
        <span id='main-top'></span>
        <NavShop categories={categories} productDetailVisible={productDetailVisible}/>
        <SectionFilters productDetailVisible={productDetailVisible} isScrolling={isScrolling} filters={filters?.[0]} activeFilters={activeFilters} setActiveFilters={setActiveFilters} resultsLength={currentProducts?.current?.length} />
        <SectionFiltersFeatured filters={featuredFilters} productDetailVisible={productDetailVisible} />
        <SectionProductList key={`list-products-${asPath}`} products={currentProducts.current} productDetailVisible={productDetailVisible} usualPurchases={true} ga_list={{ id: 'list_grid_user_usual_purchases', name: 'List user Usual Purchases (grid)' }} categories={categories} displayProductDetail={displayProductDetail} setProductDetailed={setProductDetailed} selectedCard={selectedCard} setSelectedCard={setSelectedCard} setShowLargeProductDetail={setShowLargeProductDetail} />
        <SectionProductDetail className='plp-klk' productDetailVisible={productDetailVisible} hideProductDetail={hideProductDetail} variant={productDetailed} setSelectedCard={setSelectedCard} filters={filters?.[0]} showLargeProductDetail={showLargeProductDetail} />
      </ShopSection>
    </>
  )
})
