import { memo } from 'react'
import type { FC } from 'react'
import { useModal } from '@dy/commons/hooks'
import { MODALS } from '@dy/commons/context'
import { Modal } from '.'
import { useTranslator } from '@/hooks'
import { FormStore } from '..'
type MenuProps = {}

export const ModalRegion: FC<MenuProps> = memo(() => {
  const { isActive, toggle } = useModal(MODALS.REGION_LANG)
  const { t } = useTranslator()

  return isActive && (
    <Modal isActive={isActive} close={toggle} title={t('commons.modal_welcome.title') as string}  xAlign='flex-end' size='s' >
      <FormStore bgInputColor={'white'} textColorInput={'darkred'} textColorSubdividions={'darkred'} isModal={true} toggle={toggle} />
    </Modal>
  )
})

