import { gql } from '@apollo/client'

export const SIGNUP_MUTATION = gql`
  mutation SIGNUP_MUTATION($firstName: String!, $lastName: String!, $email: String!, $password: String!, $formFields: RegisterCustomerFormFieldsInput!, $addresses: [CustomerAddressesInput!]!) {
    registerCustomer(
      input: {
        customer: {
          firstName: $firstName
          lastName: $lastName
          credentials: {
            email: $email
            password: $password
          }
          formFields: $formFields
          addresses: $addresses
        }
      }
    ) {
      ok
      errors
    }
  }
`

export const LOGIN_MUTATION = gql`
  mutation LOGIN_MUTATION($email: String!, $password:String!) {
    login(email: $email, password: $password) {
      result
      customer {
        entityId
        id
        firstName
        lastName
      }
    }
  }
`

export const CUSTOMER_VAT_NUMBER = gql`
  mutation CUSTOMER_VAT_NUMBER($vatNumber: String!) {
    customerByVatNumber(vatNumber: $vatNumber) {
      result
      customer {
        entityId
        id
        firstName
        lastName
      }
    }
  }
`

