"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductCard = exports.getFilterLabels = exports.FILTER_LABELS = exports.getPricePerUnit = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_2 = require("react");
const framer_motion_1 = require("framer-motion");
const react_intersection_observer_1 = require("react-intersection-observer");
const components_1 = require("../components");
const utils_1 = require("../utils");
const router_1 = require("next/router");
const utils_2 = require("../utils");
const ProductCardStyles_1 = require("./ProductCardStyles");
const components_2 = require("../components");
const getPricePerUnit = (reference_price, reference_unit, round, locale) => {
    return reference_price && reference_unit ? ` • ${(0, utils_2.currencyFormatter)({ price: reference_price, localeRegion: locale, round })}/${reference_unit}` : null;
};
exports.getPricePerUnit = getPricePerUnit;
exports.FILTER_LABELS = {
    'sin-gluten': '/images/svg/label-sin-gluten.svg',
    'bio': '/images/svg/label-bio.svg',
    'fresh': '/images/svg/label-fresh.svg',
    'integral': '/images/svg/label-integral.svg',
    'vegano': '/images/svg/label-vegan.svg',
};
const calcDiscount = (price, retailPrice) => {
    return Math.round((!!retailPrice && price < retailPrice) ? (retailPrice - price) / retailPrice * 100 : 0);
};
const getFilterLabels = (filters, pathname, showTooltip, setShowTooltip) => filters.map((filter, idx) => {
    const isPDP = pathname === '/product/[slug]';
    return ((0, react_1.createElement)("figure", Object.assign({}, (filter.param === 'fresh' && { onMouseEnter: () => { setShowTooltip(true); }, onMouseLeave: () => { setShowTooltip(false); } }), { className: `filter-label ${filter.param === 'fresh' ? 'fresh' : ''}`, key: idx }),
        (0, jsx_runtime_1.jsx)("img", { src: exports.FILTER_LABELS[filter.param] }),
        (filter.param === 'fresh' && isPDP) ? (0, jsx_runtime_1.jsx)(components_1.ToolTip, { showTooltip: showTooltip }) : (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, {})));
});
exports.getFilterLabels = getFilterLabels;
const productCardSizes = '(min-width: 768px) 30vw, 50vw';
const transformLabels = labels => labels.map(label => label.name);
const getCMSLabels = labels => labels.map((label, idx) => {
    return (0, jsx_runtime_1.jsx)("span", Object.assign({ className: 'cms' }, { children: (0, jsx_runtime_1.jsx)("span", { children: label }) }), idx);
});
exports.ProductCard = (0, react_2.memo)(({ data, narrow = false, onClick = null, isLazy = true, selectedCard = false, pricePer, round = false }) => {
    var _a, _b;
    const { locale: localeRouter, pathname } = (0, router_1.useRouter)();
    const locale = (0, utils_1.getLang)(localeRouter);
    //dev HARDCODED
    let customerData = false;
    const [showTooltip, setShowTooltip] = (0, react_2.useState)(false);
    const { bigcommerce_id, name, brand, variant, slug, categories, ingredients, pack, labels, filters } = data || {};
    const { bigcommerce_id: variant_id, media, name: variant_name, prices = {}, stock, stock_warning } = variant || {};
    const [hasUnitsInCart, setHasUnitsInCart] = (0, react_2.useState)(false);
    // @ts-ignore
    const { retail_price_ex_tax, retail_price_inc_tax, price_ex_tax, price_inc_tax } = prices;
    // const { retailPrice:{exTax: retail_price_ex_tax, incTax: retail_price_inc_tax}, salePrice:{exTax: price_ex_tax,incTax:price_inc_tax} } = prices
    // console.log('ProductCard data',data,onClick)
    const discount = calcDiscount(price_inc_tax, retail_price_inc_tax);
    const FormattedLabels = (0, react_2.useMemo)(() => labels ? transformLabels(labels) : [], [labels]);
    const labelsAsTags = (labels === null || labels === void 0 ? void 0 : labels.length) > 1 || ((_a = FormattedLabels === null || FormattedLabels === void 0 ? void 0 : FormattedLabels[0]) === null || _a === void 0 ? void 0 : _a.length) > 4;
    const LabelCMS = (0, react_2.useMemo)(() => labelsAsTags ? getCMSLabels(FormattedLabels) : (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, {}), [FormattedLabels]);
    const LabelDiscount = (0, react_2.useMemo)(() => discount > 0 ? (0, jsx_runtime_1.jsxs)("span", Object.assign({ className: 'discount' }, { children: ["-", discount, "%"] })) : (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, {}), [discount]);
    const PreselectFilters = (0, react_2.useMemo)(() => filters === null || filters === void 0 ? void 0 : filters.filter(item => { var _a, _b; return exports.FILTER_LABELS[item.param] && ((_b = (_a = item === null || item === void 0 ? void 0 : item.values) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.visible); }), [filters]);
    const LabelFilters = (0, react_2.useMemo)(() => (PreselectFilters === null || PreselectFilters === void 0 ? void 0 : PreselectFilters.length) > 0 ? (0, exports.getFilterLabels)(PreselectFilters, pathname, showTooltip, setShowTooltip) : (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, {}), [PreselectFilters, pathname, showTooltip, setShowTooltip]);
    const emptyCard = Object.keys(data).length === 0 ? true : false;
    const [ref, inView] = (0, react_intersection_observer_1.useInView)({
        rootMargin: '0px',
        threshold: 0,
        triggerOnce: true,
    });
    return ((0, jsx_runtime_1.jsx)(ProductCardStyles_1.Article, Object.assign({ ref: ref, className: `product-card${pack ? ' pack' : ''}${!!selectedCard && selectedCard === variant_id ? ' selected' : ''}${hasUnitsInCart ? ' active' : ''}${stock < 1 ? ' no-stock' : ''}${false ? 'pdp-displayed' : ''}`, pack: pack, "$narrow": narrow }, { children: (0, jsx_runtime_1.jsx)(framer_motion_1.AnimatePresence, { children: (0, jsx_runtime_1.jsx)(framer_motion_1.motion.div, Object.assign({ variants: ProductCardStyles_1.variants, initial: 'on_exit', animate: 'on_load', exit: 'on_exit' }, { children: !emptyCard &&
                    (0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsxs)(ProductCardStyles_1.StyledLink, Object.assign({ href: `/product/${slug[locale] ? slug[locale] : ''}`, onClick: onClick, "aria-label": 'show product' }, { children: [((labels === null || labels === void 0 ? void 0 : labels.length) > 0 && !labelsAsTags) &&
                                        (0, jsx_runtime_1.jsx)(ProductCardStyles_1.Offer, { children: (0, jsx_runtime_1.jsx)(components_2.MarqueeSlider, { data: FormattedLabels.join('  •  ') }) }), (0, jsx_runtime_1.jsxs)(ProductCardStyles_1.LabelWrapper, Object.assign({ filterLabelCount: PreselectFilters === null || PreselectFilters === void 0 ? void 0 : PreselectFilters.length, hasDiscount: discount > 0, className: `${selectedCard ? 'narrow-card' : 'wide-card'}` }, { children: [(0, jsx_runtime_1.jsxs)("div", Object.assign({ className: 'right' }, { children: [LabelDiscount, LabelFilters] })), LabelCMS] })), media ?
                                        (0, jsx_runtime_1.jsx)(components_1.Figure, { lazy: isLazy, className: 'product-card-image', media: (0, utils_1.parserMedia)((_b = media === null || media === void 0 ? void 0 : media[0]) === null || _b === void 0 ? void 0 : _b.grid), sizes: productCardSizes, quality: 60 }, `card-img-${variant_id}`)
                                        :
                                            (0, jsx_runtime_1.jsx)("div", Object.assign({ className: 'product-card-image' }, { children: (0, jsx_runtime_1.jsx)("div", { className: 'no-img' }) })), (0, jsx_runtime_1.jsxs)(ProductCardStyles_1.ProductInfo, Object.assign({ className: 'product-card_info' }, { children: [(0, jsx_runtime_1.jsx)("span", { children: name }), !pack ?
                                                (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: typeof brand === 'object' && (0, jsx_runtime_1.jsx)("span", { children: brand === null || brand === void 0 ? void 0 : brand.name }) })
                                                :
                                                    (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: ingredients && (0, jsx_runtime_1.jsx)("span", { children: ingredients }) }), (0, jsx_runtime_1.jsx)("span", { children: variant_name })] })), customerData && (0, jsx_runtime_1.jsx)(components_1.Price, { type: 'card', price: { retailPrice: { exTax: retail_price_ex_tax, incTax: retail_price_inc_tax }, salePrice: { exTax: price_ex_tax, incTax: price_inc_tax } }, className: 'product__price', pricePer: pricePer, round: round, stockWarning: stock_warning, stock: stock })] })), (0, jsx_runtime_1.jsx)(components_1.AddToCart, { cartItemData: { name, brand, categories, variant }, variant_id: variant_id, bigcommerceId: bigcommerce_id, stock: stock, isPDP: false, setHasUnitsInCart: setHasUnitsInCart, selectedCard: selectedCard })] }) })) }) })));
});
