import { memo, useMemo } from 'react'
import { FAKE_ORDER_DATA } from '@/api'
import { useTranslator } from '@/hooks'
import { ProductsInfo, GridMyProducts } from '..'
import { Button, Link } from '@dy/commons/components'
import { ListOrders, SectionWrapper } from '../homeStyles'

export interface ISectionMyProducts {
  data: any
}


const getOrders = (data, t) => data.map((order, idx) => {
  const { orderLines, id, dateCreated } = order
  return (
    <ListOrders key={`${idx}-${id}`}>
      <h3>{dateCreated}</h3>
      <Button bgType={'red'} outline>{t('account.repeat_order')}</Button>
      <ProductsInfo products={orderLines}></ProductsInfo>
    </ListOrders>
  )
})

export const SectionMyProducts = memo<ISectionMyProducts>(({ data }) => {
  if(!data) console.warn('TODO SectionMyProduct, no data used')
  const { orders, comercial: { name, email, tel } } = FAKE_ORDER_DATA
  const { t } = useTranslator()
  // const lastThreeOrders = useMemo(() => orders.slice(-3), [orders])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const Orders = useMemo(() => getOrders(orders, t), [orders])

  return ( 
    <SectionWrapper onClick={()=>location.href='/account/usual-purchases'}>
      <div className='left'>
        <div className='cta'>
          <h1>{t('account.home_my_products.last_orders')}</h1>
          <Link href={'/account/orders'}>{t('account.home_my_products.see_all')}</Link>
        </div>
        <ul>
          {Orders}
        </ul>
      </div>
      <div className='right'>
        <div className='cta'>
          <h1>{t('account.home_my_products.my_usuals')}</h1>
          <Link href={'/account/usual-purchases'}>{t('account.home_my_products.see_all')}</Link>
        </div>
        <GridMyProducts/>
        <h1>{t('account.home_my_products.my_comercial')}</h1>
        <p>{name} · {email} · {tel}</p>
      </div>
    </SectionWrapper>
  )
})
