import { memo, useCallback, useMemo } from 'react'
import type { FC } from 'react'
import { AnimatePresence } from 'framer-motion'
import styled from 'styled-components'
import { FormUserInitial, FormUserPreLogin, FormUserLogIn, FormUserSignUpCIF, FormUserSignUpExistingCIF, FormUserSignUp, FormUserResetPassword } from '@/components'

type Props = {
  toggleModal: () => void,
  payload: any,
  updatePayload: any
}

const Section = styled.section`
`

const formByLevel = {
  'initial': FormUserInitial,
  'prelogin': FormUserPreLogin,
  'login': FormUserLogIn,
  'signup_cif': FormUserSignUpCIF,
  'signup': FormUserSignUp,
  'signup_existing_cif': FormUserSignUpExistingCIF,
  'signup_complete_feedback_message': null,
  'resetPassword': FormUserResetPassword
}

const stepVariants = {
  initial: {
    opacity: 0,
    x: -250,
    transition: {
      duration: 0.3
    }
  },
  animate: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.3
    }
  }
}

const animation = {
  initial: 'initial',
  animate: 'animate',
  exit: 'initial',
  variants: stepVariants
}

export type TypeSectionAuthLevel = 'initial' | 'prelogin' | 'login' | 'signup_cif' | 'signup' | 'signup_existing_cif' | 'forgotPassword' | 'resetPassword'

export const SectionUserAuth: FC<Props> = memo(({ toggleModal, payload, updatePayload }) => {
  const CurrentForm = useMemo(() => formByLevel[payload?.level ?? 'initial'], [payload])

  const updateLevel = useCallback((newLevel, close = false) => {
    updatePayload({ level: newLevel })
    if(close) toggleModal()
  }, [toggleModal, updatePayload])

  return (
    <Section>
      <AnimatePresence>
        <CurrentForm key={`form--${payload?.level}`} animation={animation} updateLevel={updateLevel}/>
      </AnimatePresence>
    </Section>
  )
})
