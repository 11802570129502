import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import type { FC } from 'react'
import { useRouter } from 'next/router'
import { motion, AnimatePresence } from 'framer-motion'
import { useModal } from '@dy/commons/hooks'
import { MODALS } from '@dy/commons/context'
import { Modal } from '..'
import { Link, Figure } from '@dy/commons/components'
import type { TypeMenuItems } from '@dy/commons/schema'
import { Ul, Li, Details, ButtonMenuBack, dialogStyles } from './modalMobileMenuStyles'
import { dialogVariants, listVariants, listItemVariants, detailUlvariants, detailvariants } from './modalMobileMenuVariants'
import { getRegion } from '@dy/commons/utils'
import { getCountry, getSubdivisions }  from '@/components'

type MenuProps = {}

const data:TypeMenuItems = [
  {
    name: 'Home',
    href: '/',
    type: 'link',
  },
  {
    name: 'Tienda',
    type: 'button-level',
    childrenAs: 'sublevel',
    children: [
      {
        name: 'Especial Navidad',
        href: '/',
        pathname: '/shop/[...slug]',
        type: 'link',
      },
      {
        name: 'Te recomendamos',
        href: '/shop/recommended',
        pathname: '/shop/[...slug]',
        type: 'link',
        childrenAs: 'accordion',
        children: [
          {
            name: 'Cena romántica',
            href: '/shop/recommended/cena-romantica',
            pathname: '/shop/[...slug]',
            type: 'link',
          },
          {
            name: 'Para regalar',
            href: '/shop/recommended/para-regalar',
            pathname: '/shop/[...slug]',
            type: 'link',
          },
          {
            name: 'Pasta lovers',
            href: '/shop/recommended/pasta-lovers',
            pathname: '/shop/[...slug]',
            type: 'link',
          },
          {
            name: 'Pizza lovers',
            href: '/shop/recommended/pizza-lovers',
            pathname: '/shop/[...slug]',
            type: 'link',
          },
        ]
      },
      {
        name: 'Sin gluten',
        href: '/shop/gluten',
        pathname: '/shop/[...slug]',
        type: 'link',
      },
      {
        name: 'Frescos',
        href: '/shop/frescos',
        pathname: '/shop/[...slug]',
        type: 'link',
      },
      {
        name: 'Top ventas',
        href: '/shop/top-ventas',
        pathname: '/shop/[...slug]',
        type: 'link',
      },
      {
        name: 'Nuovissimo!',
        href: '/shop/nuovissimo',
        pathname: '/shop/[...slug]',
        type: 'link',
      },
      {
        name: 'Vinos',
        href: '/shop/vinos',
        pathname: '/shop/[...slug]',
        type: 'link',
        childrenAs: 'accordion',
        children: [
          {
            name: 'Tinto',
            href: '/shop/vinos/tinto',
            type: 'link',
          },
          {
            name: 'Blanco',
            href: '/shop/vinos/blanco',
            type: 'link',
          },
          {
            name: 'Espumoso',
            href: '/shop/vinos/espumoso',
            type: 'link',
          }
        ]
      }
    ]
  },
  {
    name: 'Mis productos',
    href: '/',
    type: 'link',
  },
  {
    name: 'Packs',
    href: '/packs',
    type: 'link',
  },
  {
    name: 'Login',
    type: 'modal-trigger',
    id: 'modal_auth'
  }
]

const backIconLight = {
  type: 'svg' as const,
  src: '/images/svg/chevron--left--light.svg',
  alt: 'Go back icon',
  size: { width: 12, height: 12}
}

// const iconChevronRight = {
//   type: 'svg' as const,
//   src: '/images/svg/chevron--right--light.svg',
//   alt: 'Go back icon',
//   size: { width: 12, height: 12 }
// }
const locationIcon = {
  type: 'svg' as const,
  src: '/images/svg/i--location--white.svg',
  alt: 'Location icon',
  size: { width: 14, height: 18 }
}

const iconChevronAccordion = {
  type: 'svg' as const,
  src: '/images/svg/chevron--select--light.svg',
  alt: 'Go back icon',
  size: { width: 12, height: 8}
}
// COMMENT  DETAIL IS A DIV FOR NOW SINCE ITS NOT POSSIBLE TO ANIMATE CLOSING ATTRIBUTTE

const DetailDropdown = ({ item, setLevel, level }) => {
  const [open, setOpen] = useState(false)

  const onClick= (e) => {
    e.preventDefault()
    setOpen((prevState) => !prevState)
  }
  return (<Details {...(open && { open })} animate={open ? 'active' : 'inactive'} variants={detailvariants}>
    <summary>
      {item.name}
      <Figure media={iconChevronAccordion} onClick={onClick} fill={false} />
    </summary>
    <AnimatePresence mode='wait'>
      <motion.ul initial='inactive' animate={open ? 'active' : 'exit'} exit='exit' variants={detailUlvariants}>
        {getMenuItems(item.children, level, setLevel, true)}
      </motion.ul>
    </AnimatePresence>
  </Details>)
}

const itemByType = {
  'link': (item, setLevel, idx, level) => {
    if(!item.children) return (<Link href={item.href}>{item.name}</Link>)
    if(item.childrenAs === 'sublevel') {
      return (<><Link href={item.href}>{item.name}</Link><button onClick={() => { setLevel(prevLevel => [...prevLevel, idx]) }}>CHEVRON</button></>)
    } else if (item.childrenAs === 'accordion') {
      return <DetailDropdown item={item} setLevel={setLevel} level={level} />
    }
    return (<Link href={item.href}>{item.name}</Link>)
  },
  'button-level': (item, setLevel, idx) => <button onClick={() => { setLevel(prevLevel => [...prevLevel, idx]) }}>{item.name}</button>,
  'modal-trigger': (item) => `Modal trigger TODO ${item}`,
  'link-external': (item) => <a href={item.href} rel='noreferrer'>{item.name}</a>
}

const getMenuItems = (data, level, setLevel, accordionList = false) => {
  let list = data
  let reachLevel = 0

  if(!accordionList) {
    while(reachLevel < level.length) {
      list = list[level[reachLevel]]?.children
      reachLevel++
    }
  }

  return list.map((item, idx) => {
    return <Li key={`${level?.length}-${item.type}-${idx}`} variants={listItemVariants}>
      {itemByType[item.type](item, setLevel, idx, level)}
    </Li>
  })
}

export const ModalMenu: FC<MenuProps> = memo(() => {
  const { isActive, toggle } = useModal(MODALS.MENU)
  const { toggle:toggleModalRegion } = useModal(MODALS.REGION_LANG)
  const [level, setLevel] = useState([])
  const [storeLocation, setStoreLocation] = useState('')
  const [country, setCountry] = useState([])
  const [subdivisions, setSubdivisions] = useState([])
  const Items = useMemo(() => getMenuItems(data, level, setLevel), [data, level, setLevel])
  const { asPath, locale }  = useRouter()
  const goOneLevelBack = useCallback(() => {
    setLevel(prevLevel => {
      const incomingLevel = [...prevLevel]
      incomingLevel.pop()
      return incomingLevel
    })
  }, [setLevel])

  useEffect(() => {
    if(asPath && isActive) toggle()
  },[asPath])

  useEffect(() => {
    if(country?.length === 0) {
      getCountry().then((res)=>{
        setCountry(Array.from(res))
      })
    }
  }, [country])
  
  useEffect(() => {
    getSubdivisions('ES').then((res) => {
      setSubdivisions(Array.from(res))
    })
  
  }, [country])

  useEffect(()=> {
    if(locale.includes('es')){
      const region = subdivisions.find((el)=> el.value === locale.toUpperCase())
      setStoreLocation(region?.name)
    } else {
      const region = getRegion(locale)
      const countryLocalization = country.find((el)=> el.value === region)
      setStoreLocation(countryLocalization?.name)
    }      
   
  }, [locale, country, subdivisions])


  return (
    <Modal isActive={isActive} close={toggle} title='Menu' xAlign='flex-start' variants={dialogVariants} dialogStyles={dialogStyles} hasHeader={false} zIndex={100} isModalFilters={true}>
      {level.length > 0 && <ButtonMenuBack key={'menu-back-button'} onClick={goOneLevelBack}>
        <Figure media={backIconLight} fill={false} />
      </ButtonMenuBack>}
      <Ul key={`list-menu-${level?.length}`} $levelDepth={level?.length} initial='inactive' animate={'active'} exit='exit' variants={listVariants}>
        {Items}
      </Ul>
      <button onClick={toggleModalRegion} title={'Region'} className='store_localization'>
        <Figure media={locationIcon} fill={false} />
        <span>{storeLocation}</span>
      </button>
    </Modal>
  )
})
