import { memo } from 'react'
import { Figure } from '@dy/commons/components'
import { CheckboxCircle, CheckboxButton } from './shopStyles'

interface ISvg {
  readonly isActive: boolean
}

const Svg = memo(({ isActive }:ISvg) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='27' height='29' viewBox='0 0 27 29'><path d='M23.75 6.13l-8-4.54c-1.39-.79-3.11-.79-4.5 0l-8 4.54A4.4 4.4 0 0 0 1 9.96v9.08a4.4 4.4 0 0 0 2.25 3.83l8 4.54c1.39.79 3.11.79 4.5 0l8-4.54A4.4 4.4 0 0 0 26 19.04V9.96a4.4 4.4 0 0 0-2.25-3.83' fill={isActive ? '#4E1114' : '#D8C7C7'} stroke='#4E1114'/></svg>
  )
})

interface IButtonFilterCheckbox {
  data: any,
  onClick: () => void,
  isActive: boolean
}

const mediaTick = {
  type: 'image' as const,
  alt: 'Icon tick',
  default: {
    src: '/images/svg/i--tick.svg',
    size: { width: 9, height: 9 }
  }
}

export const ButtonFilterCheckbox = memo(({ data, onClick, isActive }:IButtonFilterCheckbox) => {
  return (
    <CheckboxButton onClick={onClick} isActive={isActive} isMultiple={data.type === 'multiple'}>
      {data.name}
      <div>
        <Svg isActive={isActive} />
        {data.type === 'multiple' && data.param !== 'sort' ?
          <Figure media={mediaTick} lazy={true} />
          :
          <CheckboxCircle isActive={isActive}/>
        }
      </div>
    </CheckboxButton>
  )
})
