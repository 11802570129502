import { gql } from '@apollo/client'

type TypeGraphQLPageInfo = {
  endCursor: string,
  startCursor: string,
  hasNextPage: boolean,
  hasPreviousPage: boolean
}

type TypeTempProduct = {
  sku: string,
  name: string
}

export interface iQueryProducts {
  productsQuery: TypeTempProduct[]
  pageInfo: TypeGraphQLPageInfo
}

// TODO FRAGMENTS NOT WORKING, NEED TO i+D proper structure when root level keyname
const PAGINATION = gql`
  fragment pageInfo on PageInfo {
    endCursor
    startCursor
    hasNextPage
    hasPreviousPage
  }
`

const BC_PRICE = gql`
  fragment prices on Prices {
    price
    salePrice
    basePrice
    retailPrice
    mapPrice
    priceRange
    retailPriceRange
    saved
    bulkPricing
  }
`

// Common Product PDP, ProductCard, Product-CartLine, Product-OrderLine
export const BC_PRODUCT_FIELDS = gql`
  fragment ProductFields on Product {
    ${BC_PRICE}
    sku
    path:slug
    name
    ...BC_PRICE
  }
`


// # ${ PAGINATION }
//       # ...PAGINATION
export const GET_PRODUCTS = gql`
  query productsQuery($countryCode: String!, $lang: String!, $first: Int, $after: String) {
    productsQuery(countryCode: $countryCode, lang: $lang, first: $first, after: $after) {
      edges {
        node {
          name
          sku
        }
      }
    }
  }
`
export const GET_SEARCH = gql`
  query productsQuery($countryCode: String!, $lang: String!, $q: String!, $first: Int, $after: String) {
    productsQuery(countryCode: $countryCode, lang: $lang, first: $first, after: $after) {
      pageInfo{
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          sku
        }
      }
    }
  }
`


// pageInfo {
//   startCursor
//   endCursor
//   hasNextPage
//   hasPreviousPage
// }

// # ${ PAGINATION }
//       # ...PAGINATION
export const GET_BC_PRODUCTS = gql`
  query getProducts($first: Int, $endCursor: String) {
    ${PAGINATION}
    site {
    settings {
      storeName
    }
    products(first: $first, after: $endCursor) {
      ...PAGINATION
        edges {
          cursor
            node {
            entityId
            name
          }
        }
      }
    }
  }
`

export const CURRENT_USER_QUERY = gql`
  query {
    me {
      id
      email
      name
      permissions
    }
  }
`

export const BC_ADDRESS_FIELDS = gql`
  fragment AddressFields on CustomerAddressesInput {
    address1
    address2
    addressType
    city
    countryCode
    firstName
    lastName
    email
    phone
    postalCode
    stateOrProvince
  }
`
