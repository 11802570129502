import { memo, useEffect, useMemo } from 'react'
import { useDeviceType, useScrollTracker } from '@dy/commons/hooks'
import { Figure } from '@dy/commons/components'
import { ProductDetailSection, CloseDetailsButton} from './shopStyles'
import ProductPage from '../../pages/product/[slug]'

export type TypeProductDetailVariant = {
  variant_id: number,
  slug: any,
  ga_list?: {
    id: string,
    name: string
  },
  idx: number
}

type TypeSectionProductDetail = {
  productDetailVisible?: boolean,
  className?: string,
  hideProductDetail: () => void,
  variant: TypeProductDetailVariant,
  setSelectedCard?: any,
  filters?: any,
  showLargeProductDetail?: boolean
}

const mediaCloseIcon = {
  type: 'image' as const,
  alt: 'Icon close',
  default: {
    src:'/images/svg/i--close.svg',
    size: { width: 40, height: 40 }
  }
}

export const SectionProductDetail = memo(({ className = '', productDetailVisible = false, hideProductDetail, variant = null, setSelectedCard, filters = {}, showLargeProductDetail = false}:TypeSectionProductDetail) => {
  const scrollState = useScrollTracker(80)
  const scrollTop = useMemo(() => scrollState === 'TOP', [scrollState])
  const filtersInSlider = typeof filters === 'object' ? Object.keys(filters)?.length >= 8 : false
  const { isMobile } = useDeviceType()

  useEffect(() => {
    if(!productDetailVisible || typeof window === 'undefined') return

    function onKeyUp(e) {
      if(e.code === 'Escape' || e.key === 'Escape' || e.keyCode === 27) {
        hideProductDetail()
      }
    }

    window.addEventListener('keyup', onKeyUp)
    return () => window.removeEventListener('keyup', onKeyUp)
  }, [productDetailVisible, hideProductDetail])

  const onMouseDown = ({ target }) => {
    if (!isMobile && typeof window !== 'undefined') {
      const section = document.getElementById('detail-page-wrapper')
      if(section) {
        const clickedInSection = section.contains(target)
        if(!clickedInSection) {
          if (target instanceof HTMLAnchorElement || target instanceof HTMLButtonElement) {
            if(!target.classList.contains('product-card') && !target.classList.contains('btn-add') && !target.classList.contains('btn-bin')) hideProductDetail()
          } else if (target instanceof HTMLImageElement) {
            console.log('.')
          } else {
            hideProductDetail()
          }
        }
      }
    }
  }

  useEffect(() => {
    if(!productDetailVisible || typeof window === 'undefined') return

    window.addEventListener('mousedown', onMouseDown)
    return () => window.removeEventListener('mousedown', onMouseDown)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productDetailVisible])

  // useEffect(() => {
  //   if(className !== 'packs-page-grid') {
  //     if(typeof window === 'object' && pathname !== '/') {
  //       const section = document.getElementById('detail-page-wrapper')
  //       if(section) section.scrollTo({ top: 0, behavior: 'smooth' })
  //     }
  //   }
  // }, [variant])

  if(!variant) return <ProductDetailSection className={className} id='detail-page-wrapper'></ProductDetailSection>

  return (
    <ProductDetailSection id='detail-page-wrapper' className={className} $showLargeProductDetail={showLargeProductDetail} $productDetailVisible={productDetailVisible} $filtersInSlider={filtersInSlider} $scrollTop={scrollTop} $scrollUp={scrollState === 'SCROLLING_UP'}>
      <CloseDetailsButton onClick={hideProductDetail} name='Close details' $filtersInSlider={filtersInSlider} $productDetailVisible={productDetailVisible} >
        <Figure media={mediaCloseIcon}/>
      </CloseDetailsButton>
      <ProductPage variant={variant} setSelectedCard={setSelectedCard} />
    </ProductDetailSection>
  )
})
