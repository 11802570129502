
import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'
import { mq, vw, createUnderline, getP26, getP18_1, getP15_1B, getP12_1, getP18_2, getP12, hex2Rgba, hideScrollbar, getTransition } from '@dy/commons/styles'

export const CartListItem = styled.li<any>`
  align-items: center;
  column-gap: unset;
  display: grid;
  grid-template-columns: ${vw(100, 'mobile')} repeat(2,1fr);
  grid-template-rows: repeat(2,1fr);
  padding:${vw(28, 'mobile')} ${vw(22, 'mobile')};

  ${mq.greaterThan('tablet')`
    column-gap: ${vw(20, 'desktop')};
    grid-template-columns: ${vw(320, 'desktop')} ${vw(200, 'desktop')} ${vw(100, 'desktop')};
    height: ${vw(156, 'desktop')};
    padding:${vw(28, 'desktop')} ${vw(22, 'desktop')};
  `}

  &:not(:last-of-type) {
    border-bottom: 1px solid ${({ theme }) => hex2Rgba(theme.colors.darkred, .1)};
  }

  .price_wrapper {
    grid-row: 2/3;
    grid-column: 3 / span 4;
    justify-self: flex-end;
    text-align: right;

    ${mq.greaterThan('tablet')`
      grid-row: unset;
      grid-column: unset;
    `}

    div:first-child {
      span:first-child {
        ${getP26()}
        color: ${({ theme }) => theme.colors.darkcreme};
      }
    }

    button:last-child {
      ${getP12_1()}
      ${createUnderline({ color: 'darkcreme' })}
      color: ${({ theme }) => theme.colors.darkcreme};
      margin-top: ${vw(10, 'desktop')};
    }
  }

  .counter {
    grid-column: 2/3;
    grid-row: 2/3;
    ${mq.greaterThan('tablet')`
      grid-column: unset;
      grid-row: unset;
    `}
  }
  .product {
    align-items: center;
    column-gap: ${vw(20, 'mobile')};
    display: grid;
    grid-template-columns: min-content ${vw(100, 'mobile')} auto;
    grid-column:1 / span 4;

    ${mq.greaterThan('tablet')`
      column-gap: ${vw(20, 'desktop')};
      grid-template-columns: min-content ${vw(221, 'desktop')} auto;
      grid-column: unset;
    `}

    figure {
      grid-column: 1 / 2;
      height: ${vw(80, 'mobile')};
      position: relative;
      width: ${vw(80, 'mobile')};
      ${mq.greaterThan('tablet')`
        grid-column: unset;
        height: ${vw(80, 'desktop')};
        width: ${vw(80, 'desktop')};
      `}
    }

    .info {
      grid-column: 2 / 4;

      ${mq.greaterThan('tablet')`
        grid-column: unset;
      `}

      span {
        ${getP15_1B()}
        color: ${({ theme }) => theme.colors.red};
        display: block;
      }

      span:first-child{
        ${getP18_1()}
      }
    }
  }
`

export const Section = styled.section<any>`
  background-color: white;
  border-radius: 20px;
  display: grid;
  grid-column: 1 / span 6;
  height: fit-content;
  margin: ${vw(20, 'mobile')};

  ${mq.greaterThan('tablet')`
    grid-column: 2 / span 6;
    margin: 0px;
  `}
`

export const MinimumOrder = styled.div<any>`
  background-color: ${({ theme }) => theme.colors.red};
  width: 50%;
  height: 100%;
  padding: ${vw(21, 'mobile')} ${vw(10, 'mobile')};
  position: relative;
  left: 0;
  top: ${vw(10, 'mobile')};
  margin: 0 auto;
  
  ${mq.greaterThan('tablet')`
    padding: ${vw(21, 'desktop')} ${vw(10, 'desktop')};
    width: 50%;
    height: 100%;
    top: ${vw(10, 'desktop')};
  `}

   &:after{
    content: "";
    display: block;
    border: ${vw(10, 'mobile')} solid transparent;
    border-bottom-color: ${({ theme }) => theme.colors.red};
    position: absolute;
    top: ${vw(-18, 'mobile')};
    left: 46%;

    ${mq.greaterThan('tablet')`
      top: ${vw(-18, 'desktop')};
      left: 45%;
      border: ${vw(10, 'desktop')} solid transparent;
      border-bottom-color: ${({ theme }) => theme.colors.red};

  `}
  }

  p {
    ${getP12_1()}
    background-color: none;
    color: ${({ theme }) => theme.colors.white};
    text-align: center;
  }

  
`

export const ModalAllOverlay = styled.div<any>`
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  position: fixed;
  user-select: none;
  pointer-events: none;
  transition: opacity 300ms ${({ theme }) => theme.ease};
  z-index: 400;

  ${({ $active }) => $active && css`pointer-events: initial; user-select: initial;`};
`

export const ModalAllListWrapper = styled(motion.div)<any>`
  position: fixed;
  right: ${vw(5, 'mobile')};
  top: 70px;
  transition: 300ms top ease-out;
  z-index: 5000;

  ${mq.greaterThan<{$top: boolean, $hasBanners: boolean}>('tablet')`
    right: ${vw(15, 'desktop')};
    top: ${({$top,  $hasBanners}) => vw($top ? $hasBanners ? 110 : 95 : 70, 'desktop')};
  `}

  &:before {
    border-bottom: ${vw(10, 'mobile')} solid ${({ theme }) => theme.colors.greylight};
    border-left: ${vw(9, 'mobile')} solid transparent;
    border-right: ${vw(9, 'mobile')} solid transparent;
    top: -${vw(8, 'mobile')};
    content: '';
    height: 0;
    position: absolute;
    right: ${vw(10, 'mobile')};
    width: 0;

    ${mq.greaterThan('tablet')`
      border-bottom: ${vw(10, 'desktop')} solid ${({ theme }) => theme.colors.greylight};
      border-left: ${vw(9, 'desktop')} solid transparent;
      border-right: ${vw(9, 'desktop')} solid transparent;
      top: -${vw(8, 'desktop')};
      right: ${vw(20, 'desktop')};
    `}
  }
`

export const ModalAllList = styled(motion.dl)`
  border-radius: 20px;
  box-shadow: 0 10px 20px 0 ${({ theme }) => hex2Rgba(theme.colors.text, .1)};
  display: flex;
  flex-wrap: wrap;
  height: auto;
  justify-content: flex-start;
  width: ${vw(308, 'mobile')};
  position: relative;
  overflow: scroll;
  ${hideScrollbar()}

  ${mq.greaterThan('tablet')`
    display: flex;
    width: ${vw(308, 'desktop')};
    max-height: ${vw(370, 'desktop')};
  `}
`

export const ModalNewList = styled(motion.dl)<any>`
  border-radius: 20px;
  box-shadow: 0 10px 20px 0 ${({ theme }) => hex2Rgba(theme.colors.text, .1)};
  display: flex;
  flex-wrap: wrap;
  height: auto;
  justify-content: flex-start;
  pointer-events: none;
  position: fixed;
  right: ${vw(5, 'mobile')};
  top: 70px;
  transition: 300ms top ease-out;
  user-select: none;
  width: ${vw(300, 'mobile')};
  z-index: 5000;


  ${mq.greaterThan<{$top: boolean, $hasBanners: boolean}>('tablet')`
    display: flex;
    right: ${vw(15, 'desktop')};
    top: 90px;
    width: ${vw(300, 'desktop')};
  `}

  > dt,
  > dd {
    background-color: ${({ theme }) => theme.colors.white};
    width: 100%;
  }
`

export const ModalListItem = styled<any>(motion.dd)`
  background-color: ${({ theme }) => theme.colors.greylight};
  padding-bottom: ${vw(10, 'mobile')};
  min-height: ${vw(55, 'mobile')};
  overflow-x: hidden;
  transition: 300ms opacity ${({ ease }) => ease};
  width: 100%;

  ${mq.greaterThan('tablet')`
    padding-bottom: ${vw(10, 'desktop')};
    min-height: ${vw(55, 'desktop')};
  `}

  &:first-of-type {
    padding-top: ${vw(20.5, 'mobile')};

    ${mq.greaterThan('tablet')`
      padding-top: ${vw(20.5, 'desktop')};
    `}

    ${({ $type }) => $type === 'new' && css`border-top-left-radius: 20px; border-top-right-radius: 20px;`};
  }

  &:last-child {
    padding-bottom: ${vw(30, 'mobile')};

    ${mq.greaterThan('tablet')`
      padding-bottom: ${vw(30, 'desktop')};
    `}
  }

  article {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    padding: 0 ${vw(20, 'mobile')};

    ${mq.greaterThan('tablet')`
      padding: 0 ${vw(20, 'desktop')};
    `}
  }

  .info {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding-left: ${vw(20, 'mobile')};
    width: 80%;

    ${mq.greaterThan('tablet')`
      padding-left: ${vw(20, 'desktop')};
    `}

    > div {
      display: flex;
      flex-wrap: nowrap;
      width: 100%;

      h5, .total {
        width: auto;
        max-width: 100%;
      }

      h5 {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
      }

      .total {
        font-weight: bold;
        flex-shrink: 0;
        margin-left: ${vw(20, 'mobile')};
        width: auto;

        ${mq.greaterThan('tablet')`
          margin-left: ${vw(20, 'desktop')};
        `}
      }
    }

    h5, .total, span {
      ${getP12()}
      font-family: ${({ theme }) => theme.fonts.basier};
      color: ${({ theme }) => theme.colors.red};
      text-align: left;
    }

    span {
      font-weight: 500;
      width: 100%;
    }
  }

  .product__price {
    p {
      ${getP12()}

      .value,
      .decimals,
      .unit {
        ${getP12()}
        font-family: ${({ theme }) => theme.fonts.basier};
        font-weight: 500;
      }
    }
  }

  .product_image {
    align-items: center;
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    height: ${vw(55, 'mobile')};
    overflow: hidden;
    width: ${vw(55, 'mobile')};

    ${mq.greaterThan('tablet')`
      height: ${vw(55, 'desktop')};
      width: ${vw(55, 'desktop')};
    `}

    img {
      height: ${vw(55, 'mobile')};
      object-fit: contain;
      object-position: center;
      width: ${vw(55, 'mobile')};


      ${mq.greaterThan('tablet')`
        height: ${vw(55, 'desktop')};
        width: ${vw(55, 'desktop')};
      `}
    }
  }

  .total {
    margin-bottom: 0;
  }
`

export const ModalSummary = styled<any>(motion.dt)`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.greylight};
  border-top: 1px solid ${({ theme }) => hex2Rgba(theme.colors.red, .1)};
  color: ${({ theme }) => theme.colors.darkred};
  display: flex;
  ${getP18_2()}
  font-weight: bold;
  padding: ${vw(18, 'mobile')} ${vw(18, 'mobile')} ${vw(22, 'mobile')};
  position: sticky;
  bottom: 0;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: ${vw(10, 'mobile')};
  grid-row-gap: ${vw(15, 'mobile')};

  ${mq.greaterThan('tablet')`
    padding: ${vw(18, 'desktop')} ${vw(18, 'desktop')} ${vw(22, 'desktop')};
    grid-column-gap: ${vw(10, 'desktop')};
    grid-row-gap: ${vw(15, 'desktop')};
  `}

  ${({ $type }) => $type === 'new' && css`border-bottom-left-radius: 20px; border-bottom-right-radius: 20px;`};

  > span:nth-child(2) {
    justify-self: end;
    color: ${({ theme }) => theme.colors.red};
  }

  > button {
    font-weight: 700;
    width: 100%;

    ${mq.greaterThan('tablet')`
      padding: 0;
      height: ${vw(39, 'desktop')};
      max-width: ${vw(130, 'desktop')};
    `}

    > div:last-of-type {
      ${getP12_1()}
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      width: 75%;
      height: auto;
      display: inline;
    }
  }
`

export const _Price = styled<any>(motion.div)`
  color: ${({ theme }) => theme.colors.red};
  ${getP12()}
`

export const Wrapper = styled.section<any>`
  grid-column: 1 / span 6;
  height: fit-content;
  margin: ${vw(20, 'mobile')};

  ${mq.greaterThan('tablet')`
    grid-column: 9 / 12;
    margin: 0px;
  `}

  .small {
    h1, span, td {
      color: ${({ theme }) => theme.colors.red};
    }

    span, td {
      ${getP15_1B()}
    }

    table {
      width:100%;
      padding: ${vw(30, 'mobile')};

      ${mq.greaterThan('tablet')`
        padding: ${vw(12, 'desktop')} ${vw(20, 'desktop')} ${vw(15, 'desktop')};
      `}

      tr {
        display: flex;
        justify-content: space-between;
        margin: 0;
        padding: ${vw(5, 'mobile')} 0px;

        ${mq.greaterThan('tablet')`
          padding: ${vw(10, 'desktop')} 0px;
        `}
      }
    }

    .summary_total {
      display: flex;
      justify-content: space-between;
      border-top: 1px solid ${({ theme }) => hex2Rgba(theme.colors.darkred, .1)};
      padding: ${vw(30, 'mobile')};

      ${mq.greaterThan('tablet')`
        padding: ${vw(12, 'desktop')} ${vw(20, 'desktop')} ${vw(15, 'desktop')};
      `}
    }
  }

  button {
    width: ${vw(300, 'mobile')};

    ${mq.greaterThan('tablet')`
      width: ${vw(350, 'desktop')};
    `}

    &.block {
      pointer-events: none;
      user-select: none;
      opacity: .5;
    }
  }
`

const transition = { ...getTransition(.5) }

export const listVariants = {
  inactive: {
    opacity: 0,
    transition
  },
  enter: {
    opacity: 1,
    transition: {
      ...getTransition(.5),
      staggerChildren: .01
    }
  },
  exit: {
    opacity: 0,
    transition
  }
}
