import { memo, useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import { QuestionTitle, QuestionAnswer } from './faqStyles'

type TypeFaqQuestion = {
  question: string,
  answer: string,
  hash: string
}

interface IQuestion {
  data: TypeFaqQuestion,
  setActiveQuestion: any
}

export const Question = memo(({ data, setActiveQuestion }:IQuestion) => {
  const [ref, inView] = useInView({
    rootMargin: '100px 0px -50% 0px',
    threshold: 1,
    triggerOnce: false
  })

  useEffect(() => {
    if (inView && data?.hash) setActiveQuestion(data.hash)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView])

  return (
    <>
      <QuestionTitle ref={ref}>
        <a href=''></a>
        <div id={`${data.hash}`} className='hash'></div>
        <h3>{data.question}</h3>
      </QuestionTitle>
      <QuestionAnswer dangerouslySetInnerHTML={{ __html: data.answer}} />
    </>
  )
})
