"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.designGrid = exports.grid = exports.getTransition = exports.getEasing = exports.rem = exports.vh = exports.vw = exports.mq = void 0;
const styled_components_1 = require("styled-components");
const styled_media_query_1 = require("styled-media-query");
const themeConfig_1 = require("./themeConfig");
const breakpoints = {
    'iphone6': '375px',
    'mobile': '414px',
    'phablet': '480px',
    'nexus7': '603px',
    'tablet': '768px',
    'desktop-xs': '992px',
    'container': '1170px',
    'desktop-md': '1200px',
    'desktop': '1440px',
    'large-screen': '1900px'
};
exports.mq = (0, styled_media_query_1.generateMedia)((0, styled_media_query_1.pxToRem)(breakpoints, themeConfig_1.themeProject.fonts.size));
const vw = (size, breakpoint, unit = 'vw') => {
    var _a;
    const screenWidth = (_a = breakpoints[breakpoint]) !== null && _a !== void 0 ? _a : breakpoint;
    return `${(100 * parseInt(size) / parseInt(screenWidth))}${unit}`;
};
exports.vw = vw;
const vh = (size, breakpoint) => {
    let screenWidth = breakpoint;
    return `${(100 * parseInt(size) / parseInt(screenWidth))}vh`;
};
exports.vh = vh;
const rem = (value) => {
    const unitlessValue = parseInt(value);
    if (unitlessValue == 0)
        return unitlessValue;
    return `${unitlessValue / themeConfig_1.themeProject.fonts.size}rem`;
};
exports.rem = rem;
const getEasing = (duration = 300) => ({
    duration,
    ease: [.25, .1, .25, 1]
});
exports.getEasing = getEasing;
const getTransition = (duration = .3) => ({
    duration,
    type: 'tween',
    ease: [.25, .1, .25, 1]
});
exports.getTransition = getTransition;
exports.grid = {
    mobile: {
        maxWidth: 414,
        columns: {
            gutter: { outside: true, width: 17 },
            number: 6,
            width: 52
        }
    },
    tablet: {
        maxWidth: 768,
        columns: {
            gutter: { outside: true, width: 12 },
            number: 12,
            width: 52
        }
    },
    desktop: {
        maxWidth: 1440,
        columns: {
            gutter: { outside: true, width: 50 },
            number: 12,
            width: 70
        }
    },
    withoutGutter: false
};
const designGrid = ({ mobile = exports.grid.mobile, tablet = exports.grid.tablet, desktop = exports.grid.desktop, withoutGutter = exports.grid.withoutGutter }) => (0, styled_components_1.css) `
  display: grid;
  grid-template-columns: ${`repeat(${mobile.columns.number}, ${(0, exports.vw)(withoutGutter ? mobile.columns.width + mobile.columns.gutter.width : mobile.columns.width, mobile.maxWidth, '%')})`};
  grid-column-gap: ${withoutGutter ? 'unset' : `${(0, exports.vw)(mobile.columns.gutter.width, mobile.maxWidth, '%')}`};
  grid-row-gap: 0;
  justify-content: center;
  width: 100%;

  ${exports.mq.greaterThan('tablet') `
    grid-column-gap: ${withoutGutter ? 'unset' : `${(0, exports.vw)(tablet.columns.gutter.width, tablet.maxWidth, '%')}`};
    grid-template-columns: ${`repeat(${tablet.columns.number}, ${(0, exports.vw)(withoutGutter ? tablet.columns.width + tablet.columns.gutter.width : tablet.columns.width, tablet.maxWidth, '%')})`};
    padding: 0;
  `}

  ${exports.mq.greaterThan('desktop') `
    grid-column-gap: ${withoutGutter ? 'unset' : `${(0, exports.vw)(desktop.columns.gutter.width, desktop.maxWidth, '%')}`};
    grid-template-columns: ${`repeat(${desktop.columns.number}, ${(0, exports.vw)(withoutGutter ? desktop.columns.width + desktop.columns.gutter.width : desktop.columns.width, desktop.maxWidth, '%')})`};
  `}
`;
exports.designGrid = designGrid;
