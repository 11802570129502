"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAccordionItem = void 0;
const react_1 = require("react");
const useAccordion_1 = require("./useAccordion");
const useAccordionItem = (index, slug, locale) => {
    const triggerRef = (0, react_1.useRef)(null);
    const accordionRef = (0, react_1.useRef)(null);
    const [expanded, setExpanded] = (0, react_1.useState)(false);
    const { activeItem, setActiveItem } = (0, react_1.useContext)(useAccordion_1.AccordionContext);
    const onToggle = (0, react_1.useCallback)(() => {
        setActiveItem({ index: index === (activeItem === null || activeItem === void 0 ? void 0 : activeItem.index) ? null : index, slug: index === (activeItem === null || activeItem === void 0 ? void 0 : activeItem.index) ? null : slug[locale], hash: null });
        setExpanded(!expanded);
    }, [activeItem, expanded]);
    (0, react_1.useEffect)(() => {
        setExpanded(index === (activeItem === null || activeItem === void 0 ? void 0 : activeItem.index));
    }, [activeItem]);
    const onKeyDown = e => {
        switch (e.key) {
            case ' ':
            case 'Enter':
                e.preventDefault();
                onToggle();
                break;
            case 'ArrowDown':
                e.preventDefault();
                if (triggerRef.current && triggerRef.current.nextElementSibling && triggerRef.current.nextElementSibling.nextElementSibling) {
                    triggerRef.current.nextElementSibling.nextElementSibling.focus();
                }
                break;
            case 'ArrowUp':
                e.preventDefault();
                if (triggerRef.current && triggerRef.current.previousElementSibling && triggerRef.current.previousElementSibling.previousElementSibling) {
                    triggerRef.current.previousElementSibling.previousElementSibling.focus();
                }
                break;
            case 'Home':
            case 'End':
                e.preventDefault();
                break;
            default:
                break;
        }
    };
    return [triggerRef, accordionRef, expanded, onToggle, onKeyDown, activeItem];
};
exports.useAccordionItem = useAccordionItem;
