import { memo } from 'react'
import styled, { css } from 'styled-components'
import { useModal } from '@dy/commons/hooks'
import { mq, vw } from '@dy/commons/styles'
import { MODALS } from '@dy/commons/context'
import { ContentVideo } from '../contents/content-types/ContentVideo'
import { Figure } from '@dy/commons/components'
import { Modal } from './'

interface IModalVideo {
  data: any
}

const dialogStyles = css<any>`
  background-color: transparent;

  ${mq.greaterThan('tablet')`
    margin: auto ${vw(80, 'desktop')};
  `}

  header {
    display: none;
  }

  .modal__body {
    padding: 0;
  }

  main {
    max-height: none;
    padding: 0;
  }

  .close figure {
    width: ${vw(16, 'mobile')};
    height: ${vw(16, 'mobile')};

    ${mq.greaterThan('tablet')`
      width: ${vw(16, 'desktop')};
      height: ${vw(16, 'desktop')};
    `}
  }
`

const ButtonClose = styled.button`
  align-items: center;
  display: flex;
  height: auto;
  justify-content: center;
  padding: ${vw(20, 'mobile')};
  right: ${vw(40, 'mobile')};
  top: 0;
  position: absolute;
  width: auto;
  z-index: 5;

  ${mq.greaterThan('tablet')`
    padding: ${vw(40, 'desktop')};
    right: ${vw(40, 'desktop')};
    top: ${vw(40, 'desktop')};
  `}

  img {
    display: inline-block;
    height: 20px;
    width: 20px;
  }
`

const mediaCrossIcon = {
  type: 'image' as const,
  alt: 'Cross icon',
  default: {
    src:'/images/svg/i--close--white.svg',
    size: { width: 16, height: 16 }
  }
}

export const ModalVideo = memo<IModalVideo>(({ data }) => {
  const { toggle, isActive } = useModal(MODALS.VIDEO)

  return (
    <Modal isActive={isActive} close={toggle} title={''} dialogStyles={dialogStyles}>
      <main>
        <ButtonClose className='close' onClick={toggle}>
          <Figure media={mediaCrossIcon}/>
        </ButtonClose>
        <ContentVideo data={data} longModal={true} modalActive={isActive}/>
      </main>
    </Modal>
  )
})
