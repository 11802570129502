"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.products = void 0;
/* eslint-disable quotes */
const _1 = require("./");
const products = ({ data, region = 'ESP', locale = 'es' }) => {
    const items = data.products;
    //DEV HARDCODED
    const productsPage = {
        "@context": "https://schema.org",
        "@type": "ItemList",
        "itemListElement": [
            items.map((productItem, idx) => {
                var _a;
                return ({
                    "@type": "ListItem",
                    "position": `${idx}`,
                    "item": (0, _1.product)({ data: productItem, region, locale, productPage: false, gridUrl: (_a = data === null || data === void 0 ? void 0 : data.slug) === null || _a === void 0 ? void 0 : _a[locale] })
                });
            })
        ]
    };
    return JSON.stringify(productsPage);
};
exports.products = products;
