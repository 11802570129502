"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useDeviceType = void 0;
const react_1 = require("react");
const BREAKPOINTS = {
    mobile: 375,
    tablet: 768,
    desktopXs: 992,
    desktop: 1440,
    getDeviceType(width) {
        return width < this.tablet ? 'mobile' :
            width < this.desktopXs ? 'tablet' :
                'desktop';
    }
};
const getInfo = (isClient) => {
    var _a;
    return isClient ? {
        width: window.innerWidth,
        height: window.innerHeight,
        deviceType: BREAKPOINTS.getDeviceType(window.innerWidth),
        mobileLandscape: ((_a = screen === null || screen === void 0 ? void 0 : screen.orientation) === null || _a === void 0 ? void 0 : _a.angle) === 90,
        isTouchDevice: (('ontouchstart' in window) || (navigator.maxTouchPoints > 0))
    } : { width: undefined, height: undefined, deviceType: 'desktop', mobileLandscape: false, isTouchDevice: false };
};
function debounce(func, miliseconds = 600) {
    let timer;
    return function (event) {
        if (timer)
            clearTimeout(timer);
        timer = setTimeout(func, miliseconds, event);
    };
}
const useDeviceType = () => {
    const isClient = typeof window !== 'undefined';
    const [deviceType, setDeviceType] = (0, react_1.useState)(getInfo(isClient).deviceType);
    const [size, setSize] = (0, react_1.useState)({ width: getInfo(isClient).width, height: getInfo(isClient).height });
    const handleResize = () => {
        const info = getInfo(isClient);
        if (info.deviceType !== deviceType)
            setDeviceType(info.deviceType);
        setSize({ width: info.width, height: info.height });
    };
    const debouncedHandler = debounce(handleResize);
    (0, react_1.useEffect)(() => {
        if (!isClient)
            return () => { };
        window.addEventListener('resize', debouncedHandler, { passive: true });
        handleResize();
        // Remove event listener on cleanup
        return () => window.removeEventListener('resize', debouncedHandler);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return { deviceType, isMobile: deviceType === 'mobile', size };
};
exports.useDeviceType = useDeviceType;
// TODO INJECT IN THE HOOK/getServerSideProps/getStaticProps FOR SSR DEVICE DETECTION
// Review blocks in the client were have different component base on device (must be a merge with the resize or something to avoid broken layouts when resize from desktop to mobile)
// const isMobile = (ctx = null) => {
//   const isClient = typeof window !== 'undefined'
//   let UA = null
//   if (isClient) {
//     UA = navigator.userAgent
//   } else {
//     const userAgentHeader = ctx?.req?.headers?.['user-agent'] ?? ctx?.headers?.['user-agent']
//     if (userAgentHeader) UA = userAgentHeader
//   }
//   return UA ? Boolean(UA.match(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i)) : 'desktop'
// }
