import { memo, ReactNode, useEffect, useState } from 'react'
import { getTransition } from '@dy/commons/styles'
import { Figure } from '@dy/commons/components'
import { accordionVariants } from './shopVariants'
import { Chevron, AccordionWrapper, AccordionButton } from './shopStyles'

interface IButtonFilterAccordion {
  activeValues: {
    [key: string]: boolean
  },
  children: ReactNode,
  data: any,
  clickedParam?: string | null
}

const getActiveNames = (values, activeValues) => {
  if(typeof activeValues !== 'object') return ''
  return Object.keys(activeValues).map((activeVal, idx) => {
    const name = values?.filter(val => val.value.includes(activeVal))[0]?.name
    return idx < 1 ? name : `, ${name}`
  })
}

const mediaChevron = {
  type: 'image' as const,
  alt: 'Icon chevron',
  default: {
    src: '/images/svg/i--chevron.svg',
    size: { width: 6, height: 8 }
  }
}

export const ButtonFilterAccordion = memo(({ activeValues, children, data, clickedParam = null }:IButtonFilterAccordion) => {
  const ActiveNames = getActiveNames(data.values, activeValues)
  const id = `btn-controls-${data.param}`
  const [isExpanded, setIsExpanded] = useState(clickedParam === data.param ?? false)

  const onClick = () => {
    setIsExpanded(!isExpanded)
  }
  useEffect(()=> {
    if(clickedParam === data.param) {
      setIsExpanded(true)
    } else {
      setIsExpanded(false)
    }
  },[clickedParam, data])


  return (
    <>
      <AccordionButton onClick={onClick} aria-controls={id} $isExpanded={isExpanded} >
        <div className='txt-wrapper'>
          {data.name}
          <span className={`values${!isExpanded ? ' visible' : ''}`}>{ActiveNames}</span>
        </div>
        <Chevron aria-hidden={true} animate={{ rotate: isExpanded ? 90 : 0}} transition={getTransition()}>
          <Figure media={mediaChevron} />
        </Chevron>
      </AccordionButton>
      <AccordionWrapper id={id} aria-expanded={isExpanded} tabIndex={-1} variants={accordionVariants} initial='initial' exit='exit' animate={isExpanded ? 'animate' : 'exit'}>
        {children}
      </AccordionWrapper>
    </>
  )
})
