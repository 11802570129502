import { memo, Dispatch, SetStateAction, useMemo } from 'react'
import { useRouter } from 'next/router'
import { Dropdown, Price, RadioCheckBoxes, getFilterLabels, FILTER_LABELS, ImageSlider, getPricePerUnit, MaxHeightContainer, AddToCart, Link } from '@dy/commons/components'
import { useTranslator } from 'hooks/useTranslator'
import type { TypeProductVariant } from '@dy/commons/schema'
import { Header, Brand, MediaWrapper, ProductInfo, FlexWrap, LabelWrapper } from './detailStyles'
import { AnimatePresence } from 'framer-motion'

interface IDetailHeader {
  data: any,
  labels?: any,
  isPack?: boolean
  currentVariant: any,
  setCurrentVariant: Dispatch<SetStateAction<TypeProductVariant>>,
  round?: boolean
}

const TRANSLATIONS = {
  'es-ES': {
    'brand': 'marca'
  },
  'en-EU': {
    'brand': 'brand'
  },
  'fr-FR': {
    'brand': 'brand'
  },
  'en-GB': {
    'brand': 'brand'
  },
  'de-DE': {
    'brand': 'brand'
  },
  'es-MD': {
    'brand': 'marca'
  },
  'es-CN': {
    'brand': 'marca'
  },
  'es-CT': {
    'brand': 'marca'
  }
}

const getAvailableVariants = (variants, currentVariant, setCurrentVariant) => variants.map((variant) => {
  const { name, reference_price, reference_unit } = variant
  const hasPricePerUnit = reference_unit !== null && reference_price > 0

  return {
    variant,
    currentVariantId: currentVariant.bigcommerce_id,
    setCurrentVariant,
    text: `${name}${hasPricePerUnit ? hasPricePerUnit : ''}`
  }
})

const getLabels = labels => labels.map((label, idx) => <span key={idx}>{label.name}</span>)

const calcDiscount = (price, retailPrice) => {
  return Math.round((!!retailPrice && price < retailPrice) ? (retailPrice - price) / retailPrice * 100 : 0)
}

export const DetailHeader = memo<IDetailHeader>(({ data, labels = [], isPack = false, currentVariant, setCurrentVariant, round }) => {
  //dev HARDCODED
  let customerData = true
  const { bigcommerce_id, name, brand, variants, description, categories, filters } = data
  const { bigcommerce_id:variant_id, media, name:variant_name, prices, reference_price, reference_unit, stock, stock_warning } = currentVariant
  const { locale, pathname } = useRouter()
  const { t } = useTranslator()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const availableVariants = useMemo(() => getAvailableVariants(variants, currentVariant, setCurrentVariant), [bigcommerce_id, variant_id, variants, currentVariant ])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const Variants = useMemo(() => availableVariants?.length <= 2 ? <RadioCheckBoxes data={availableVariants} /> : <Dropdown className='detail-dropdown' trigger={t('product_page.product_main.choose_size')} options={availableVariants} />, [availableVariants])

  const { retail_price_inc_tax, price_inc_tax } = prices
  const discount = useMemo(() => calcDiscount(price_inc_tax, retail_price_inc_tax), [price_inc_tax, retail_price_inc_tax])
  const DiscountLabel = useMemo(() => discount > 0 ? <span key={'discount'} className={'discount'}>{`-${discount}%`}</span> : null, [discount])

  const LabelItems = useMemo(() => getLabels(labels), [labels])
  const preselectFilters = useMemo(() => filters?.filter(item => FILTER_LABELS[item.param] && item?.values?.[0]?.visible), [])
  const LabelFilters = useMemo(() => preselectFilters?.length > 0 ? getFilterLabels(preselectFilters, pathname) : <></>, [preselectFilters, pathname])

  const displayStockWarning = stock >= 1 && stock_warning

  return (
    <Header isPack={isPack}>
      {!isPack &&
        <Brand>
          {brand !== null && <Link href={`/search?q=${encodeURI(brand.name)}&${TRANSLATIONS[locale].brand}=${brand.slug[locale]}`}>{brand.name}</Link> }
        </Brand>
      }
      <h1>{name}</h1>
      <MediaWrapper>
        {(LabelItems.length > 0 || DiscountLabel || LabelFilters?.length > 0) &&
          <LabelWrapper>
            {LabelItems}
            {DiscountLabel}
            {LabelFilters}
          </LabelWrapper>
        }
        <ImageSlider data={media} isPack={isPack} key={variant_id}/>
      </MediaWrapper>
      {displayStockWarning && <span className='last_units'>{t('product_page.last_units')}</span>}
      {!isPack &&
      <AnimatePresence mode='wait'>
        <ProductInfo key={availableVariants?.length} variants={variants} exit='exit' animate='animate' initial='initial' className={availableVariants?.length === 1 ? 'single-variant' : ''} >
          {availableVariants?.length === 1 ?
            <label>{variant_name}{getPricePerUnit(reference_price, reference_unit, false, locale)}</label>
            :
            <>{Variants}</>
          }
        </ProductInfo>
      </AnimatePresence>
      }
      <FlexWrap>
        {customerData && <Price type='pdp' price={{ retailPrice: { incTax: retail_price_inc_tax }, salePrice: { incTax: price_inc_tax } }} pricePer={t('product_page.product.price_per.unit') as string} round={round}/>}
        <AddToCart cartItemData={{ name, brand, categories, variant: currentVariant}} variant_id={variant_id} bigcommerceId={bigcommerce_id} stock={stock} isPDP={true} />
      </FlexWrap>
      <MaxHeightContainer force_link={true} className='description-clamp' elipsis={t('product_page.product_main.see_more')} data={description} />
    </Header>
  )
})
