"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAccordion = exports.AccordionContext = void 0;
const react_1 = require("react");
const initialState = {
    index: null,
    slug: null,
    hash: null,
};
exports.AccordionContext = (0, react_1.createContext)(null);
const useAccordion = () => {
    const [activeItem, setActiveItem] = (0, react_1.useState)(initialState);
    const collapseAll = () => {
        setActiveItem(initialState);
    };
    return [activeItem, setActiveItem, collapseAll];
};
exports.useAccordion = useAccordion;
