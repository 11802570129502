
import { memo, useEffect, useState } from 'react'
import dynamic from 'next/dynamic'
import { useInView } from 'react-intersection-observer'
import { useModal } from '@dy/commons/hooks'
import { MODALS } from '@dy/commons/context'
import { ModalVideo } from '../../modals'
import { VideoContent, PlayIcon } from '../contentStyles'

const ReactPlayer = dynamic(() => import('react-player/lazy'), { ssr: false })

type ContentTypeVideo = {
  col?: { from: number, expand: number},
  row?: { from: number, expand: number},
  media: {
    type: 'video' | string,
    alt?: string,
    short_video?: string | number,
    long_video?: string | number,
    platform: 'vimeo' | 'youtube' | string
  },
  caption?: string,
  type: 'media' | string,
}

interface IContentVideo {
  data: ContentTypeVideo,
  longModal?: boolean,
  playsinline?: boolean,
  modalActive?: boolean
}

export const ContentVideo = memo(({ data, longModal = false, modalActive = false, playsinline = false }:IContentVideo) => {
  const { media: { platform, long_video, short_video } } = data
  const [playing, setPlaying] = useState(!longModal)
  const [ref, inView] = useInView({
    rootMargin: '0px', // TOP  RIGHT
    threshold: 0,
  })

  const url = `https://www.${platform}.com/${platform === 'youtube' ? 'watch?v=' : ''}${longModal ? long_video : short_video}`

  const { toggle } = useModal(MODALS.VIDEO)

  useEffect(() => {
    if(longModal) return
    setPlaying(inView)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView])

  useEffect(() => {
    if(longModal) setPlaying(modalActive)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalActive])

  return (
    <>
      <VideoContent ref={ref} longModal={longModal} className='figure--video'>
        <ReactPlayer url={url} playsinline={playsinline} controls={longModal} volume={longModal ? .5 : 0} muted={!longModal} loop={!longModal}playing={playing} />
        {
          long_video && !longModal &&
          <PlayIcon onClick={toggle}>
            <img src='/images/svg/i--hexagon-rounded.svg' alt='Icon Hexagon' width='63' height='69' />
            <img className='xs' src='/images/svg/i--hexagon-rounded.svg' alt='Icon Hexagon small'  width='63' height='69'/>
            <span>Play</span>
          </PlayIcon>
        }
      </VideoContent>
      {long_video && !longModal && <ModalVideo data={data} />}
    </>
  )
})
