"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.product = void 0;
/* eslint-disable quotes */
const utils_1 = require("../../../utils");
const getColorVariants = (options) => {
    let colorVariants = [];
    if (!Array.isArray(options))
        return colorVariants;
    for (let option of options) {
        if (option.optionType === 'color') {
            colorVariants = Array.isArray(option.optionValues) ? option.optionValues : [];
            break;
        }
    }
    return colorVariants;
};
const product = ({ data, region = 'ES', locale = 'es', productPage = true, gridUrl = null }) => {
    var _a, _b;
    const { brand, name, description, variant, variants, sku, pack, slug, labels, filters } = data;
    const currentVariant = variant !== null && variant !== void 0 ? variant : variants[0];
    const image = (_b = (_a = currentVariant === null || currentVariant === void 0 ? void 0 : currentVariant.media) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.grid;
    const url = `https://mammafiore.eu/${productPage ? `product/${slug[locale]}` : `shop/${gridUrl}#${slug[locale]}`}`;
    const filter = filters === null || filters === void 0 ? void 0 : filters.map((item) => item.name);
    const keywords = labels.map((item) => item.name);
    const offers = (item) => {
        var _a, _b, _c;
        return (Object.assign(Object.assign(Object.assign(Object.assign({ "@type": "Offer" }, (slug && { "url": url })), { "priceCurrency": `${(_b = (_a = utils_1.currencyBasedOnRegion[region]) === null || _a === void 0 ? void 0 : _a.code) !== null && _b !== void 0 ? _b : 'EUR'}` }), (item.prices && { "price": `${item.prices.price_inc_tax}` })), { 
            // ...(item.price.validUntil && { "priceValidUntil": `${item.price.validUntil}` }),
            "itemCondition": "https://schema.org/NewCondition", "availability": `https://schema.org/${item.webStatus !== 'available' ? 'OutOfStock' : 'InStock'}`, "inventoryLevel": '100', 
            // "inventoryLevel": `${item.webStatus !== 'available' ? 0 : 100}`,
            "sku": `${Array.isArray(item.sku) ? (_c = item.sku) === null || _c === void 0 ? void 0 : _c[0] : item.sku}`, "seller": {
                "@type": "Organization",
                "name": "Mammafiore",
            } }));
    };
    const productSchema = Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, (productPage && { "@context": "https://schema.org/" })), { "@type": `${pack ? 'ProductGroup' : 'Product'}`, "name": `${name}` }), (image && { "image": `${image.default['2x'].regular}` })), (description && { "description": `${description}` })), (data.mpn && { "mpn": `${data.mpn}` })), (brand && { "brand": { "@type": "Brand", "name": `${brand.name}` } })), { "sku": `${sku}` }), ((filters === null || filters === void 0 ? void 0 : filters.length) > 0 && { "category": `${filter.toString()}` })), (labels.length > 0 && { "keywords": `${keywords.toString()}` })), (currentVariant && { "weight": `${currentVariant.name}` })), ((variants === null || variants === void 0 ? void 0 : variants.length) > 0 && { "hasVariant": variants.map((item) => `${item.sku}`) })), ((variants === null || variants === void 0 ? void 0 : variants.length) > 0 && { "offers": variants.map((item) => offers(item)) })), (variant && { "offers": offers(variant) }));
    return productPage ? JSON.stringify(productSchema) : productSchema;
};
exports.product = product;
