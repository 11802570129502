import styled from 'styled-components'
import { mq, vw, hideScrollbar, getP26_2, getP18_2, getP53 } from '@dy/commons/styles'
import { motion } from 'framer-motion'
export const QuestionTitle = styled.dt`
  margin-bottom: ${vw(10, 'mobile')};
  position: relative;

  ${mq.greaterThan('tablet')`
    margin-bottom: ${vw(10, 'desktop')};
  `}

  &:first-child {
    margin-top: 0;
  }

  h3 {
    color: ${({ theme }) => theme.colors.red};
    ${getP26_2()}
    font-family: ${({ theme }) => theme.fonts.basier};
    text-align: left;
  }

  .hash {
    height: 1px;
    opacity: 0;
    position: absolute;
    top: -110px;
    user-select: none;
    width: 100%;

    ${mq.greaterThan('tablet')`
      top: -140px;
    `}
  }
`

export const QuestionAnswer = styled.dd<any>`
  ${getP18_2()}
  color: ${({ theme }) => theme.colors.darkred};
  margin-bottom: ${vw(48, 'mobile')};

  ${mq.greaterThan('tablet')`
    margin-bottom: ${vw(60, 'desktop')};
  `}

  p, ul, dl, ol {
    font-size: inherit;
    font-family: inherit;
    font-weight: inherit;
    padding-right: ${vw(70, 'mobile')};

    ${mq.greaterThan('tablet')`
      padding-right: 0;
    `}
  }

  a {
    color: ${({ theme }) => theme.colors.red};
    text-decoration: underline;
  }
`

export const CategoriesSection = styled.section`
  display: none;

  ${mq.greaterThan('tablet')`
    display: block;
    grid-column: 1 / span 3;
    height: calc(100vh - 80px);
    min-height: calc(100vh - 80px);
    overflow: auto;
    padding: ${vw(20, 'desktop')} ${vw(50, 'desktop')} ${vw(50, 'desktop')} ${vw(30, 'desktop')};
    position: sticky;
    top: 80px;
    z-index: 60;
    ${hideScrollbar()}
  `}
`

export const Section = styled(motion.section)`
  grid-column: 1 / span 6;
  position: relative;

  ${mq.greaterThan('tablet')`
    grid-column: 5 / span 6;
    margin-bottom: ${vw(120, 'desktop')};
  `}

  &:last-child {
    margin-bottom: ${vw(70, 'mobile')};

    ${mq.greaterThan('tablet')`
      margin-bottom: ${vw(120, 'desktop')};
    `}
  }

  .idslug {
    height: 1px;
    opacity: 0;
    position: absolute;
    top: -80px;
    user-select: none;
    width: 100%;

    ${mq.greaterThan('tablet')`
      top: -140px;
    `}
  }

  h2 {
    height: ${vw(35, 'mobile')};
    margin-bottom: ${vw(30, 'mobile')};
    justify-content: flex-start;

    ${mq.greaterThan('tablet')`
      height: auto;
      margin-bottom: ${vw(30, 'desktop')};
    `}

    &,
    button {
      align-items: center;
      color: ${({ theme }) => theme.colors.red};
      display: flex;
      ${getP53()}
      width: 100%;
    }

    button {
      justify-content: space-between;
      pointer-events: auto;
      user-select: auto;

      ${mq.greaterThan('tablet')`
        pointer-events: none;
      `}
    }
  }
  figure {
    height: ${vw(8, 'mobile')};
    width: ${vw(8, 'mobile')};
  }
`

export const List = styled<any>(motion.dl)`
  height: 100%;

  &[aria-expanded='false'] {
    pointer-events: none;
    user-select: none;
  }

  &[aria-expanded='true'] {
    pointer-events: auto;
    user-select: auto;
  }
`

export const Chevron = styled(motion.span)`
  display: inline-block;
  margin-left: 10px;
  user-select: none;

  img {
    height: ${vw(13, 'mobile')};
    width: ${vw(13, 'mobile')};
  }

  ${mq.greaterThan('tablet')`
    display: none;
  `}
`