import { memo, useMemo } from 'react'
import { useRouter } from 'next/router'
import { useTranslator } from 'hooks/useTranslator'
import { Table, H2, SectionNutrition, PTable } from './detailStyles'

type ProductSpecification = {
  name: string,
  value: string | number,
  type: 'weight' | 'energy'
}

type TypeSectionNutrition = {
  data: ProductSpecification[]
}


const localeString = {
  'en': 'en-GB',
  'es': 'es-ES',
}

const getNutritional = (details, locale) => details.map((detail, idx) => {
  const value = detail.value < 1 ? '< 1' : detail.value.toLocaleString(localeString[locale])
  return (
    <tr key={idx}>
      <td>{detail.name}</td><td>{value}g</td>
    </tr>
  )
})

export const SectionNutritionChart = memo<TypeSectionNutrition>(({ data }) => {
  // const [currentGraph, setGraph] = useState(0)
  const { locale } = useRouter() || { locale: 'es' }
  const { t } = useTranslator()

  const energy = data.filter((item) => item.type === 'energy')[0]
  const chartData = data.filter((item) => item.type === 'weight')
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const dataWithPercentages = useMemo(() => getNutritional(chartData, locale), [chartData])
 
  return (
    <SectionNutrition>
      <H2>{t('product_page.product_description.nutritional_value.title')}</H2>
      <PTable>
        <span>{t('product_page.product_description.nutritional_value.average_value_per')} 100gr.</span>
        {energy && <span>{t('product_page.product_description.nutritional_value.energy')}: {energy.value}Kcal / {Math.round(typeof energy.value === 'string' ? parseInt(energy.value) : Number(energy.value) * 4.184)}kJ</span>}
      </PTable>
      <Table>
        <tbody>
          {dataWithPercentages}
        </tbody>
      </Table> 
    </SectionNutrition>
  )
})
