"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.home = void 0;
/* eslint-disable quotes */
const home = (data) => {
    return JSON.stringify({
        "@context": "https://schema.org",
        "@type": "WebPage",
        "@id": "",
        "name": "Mammafiore",
        "url": "https://mammafiore.eu",
        "isPartOf": {
            "@type": "WebSite"
        },
        "publisher": Object.assign(Object.assign(Object.assign({ "@type": "Store" }, (data.seo && { "name": `${data.seo.title}` })), (data.seo && { "description": `${data.seo.description}` })), { "brand": "Mammafioredio", "email": "support@mammafiore.eu", "url": "https://mammafiore.eu", "sameAs": [
                "https://www.instagram.com/mammafiore/",
                "https://www.facebook.com/Mammafiore",
                "https://twitter.com/mammafiore_",
                "https://www.youtube.com/channel/UCjCKgUwF1YaTev9ERHFL4zw",
                "https://www.linkedin.com/company/mammafiore",
            ], "image": "https://mammafiore.eu/images/products/contents/sunpower.jpg", "priceRange": "45 - 300", "contactPoint": [
                {
                    "@type": "ContactPoint",
                    "email": "supporto@mammafiore.eu",
                    "contactType": "customer service",
                    "availableLanguage": ["English", "Spanish", "Catalan", "French", "Italian", "Deutsch"]
                }
            ], "logo": "https://mammafiore.eu/images/logo--mammafiore.png", "openingHours": "Mo-Fr 09:00-18:30", "currenciesAccepted": "EUR", "paymentAccepted": "Credit Card, Visa" })
    });
};
exports.home = home;
