import { memo } from 'react'
import type { FC } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslator } from '@/hooks'
import { Input, Button } from '@dy/commons/components'
import { subscribe } from '@dy/commons/utils'
import { NewsletterForm } from './formStyles'

type Props = {
  hasLegend?: boolean,
  inputBgColor?: string,
  textColor?: string,
  buttonBgColor?: 'red' | 'darkred' | 'background' | 'white' | 'creme' | 'darkcreme'
}

export const FormNewsletter:FC<Props> = memo(({hasLegend = true, inputBgColor = 'red', textColor = 'white', buttonBgColor = 'creme'}) => {
  const { register, formState: { errors:formErrors }, handleSubmit } = useForm()
  const { t } = useTranslator()

  const onSubmit = async (data) => {
    const success = await subscribe({ body: data })
    if (success) console.info('✅ subscribed')
  }

  return (
    <NewsletterForm onSubmit={handleSubmit(onSubmit)}>
      {hasLegend &&<legend><h2>{t('layout.footer.newsletter.title')}</h2></legend>}
      <div>
        <Input type='email' name='email' className='input--newsletter' placeholder={t('layout.footer.newsletter.input_inactive') as string} register={register} required error={formErrors.email} placeholderVisible={true} textColor={textColor} bgColor={inputBgColor}/>
        <Button type='submit' bgType={buttonBgColor} className='creme'>{t('layout.footer.newsletter.button')}</Button>
      </div>
    </NewsletterForm>
  )
})
