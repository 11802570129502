import { SectionNewsletter } from './SectionNewsletter'
import { TypePage, SectionPages } from './SectionPages'
import { CallUs } from './CallUs'
import { List } from './List'
import { PreFooter } from './PreFooter'
import { FooterStyled } from './footerStyles'
import dynamic from 'next/dynamic'

const DynamicSectionRrss = dynamic(() => import('./SectionRrss'))

interface IFooter {
  pages?: TypePage[] | [],
  firstCategory: any | null,
  services: string,
}

export const Footer = ({ pages = [], firstCategory = null, services = '' }:IFooter) => {
  return (
    <>
      <PreFooter services={services} />
      <FooterStyled>
        <CallUs />
        <SectionNewsletter />
        <List firstCategory={firstCategory} />
        {pages?.length > 0 && <SectionPages pages={pages}/>}
        <DynamicSectionRrss />
      </FooterStyled>
    </>
  )
}
