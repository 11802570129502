import { memo, useMemo } from 'react'
import { SliderFlickity } from '@dy/commons/components'
import { featured_filters_variants } from './shopVariants'
import { Article, FeaturedFiltersSection } from './shopStyles'

type TypeFilterValue = {
  name: string,
  value: string,
  description?: string,
  media?: {
    type: 'svg',
    alt: string,
    url: string
  }
}


const getSlides = (filters) => filters.map((filter, idx) => (
  <li key={idx}>
    <Article className={`param-${filter.param}`}>
      <figure>
        <img src={filter.media.url} alt={filter.media.alt}/>
      </figure>
      <div className='text-wrapper'>
        <h2>{filter.name}</h2>
        <div dangerouslySetInnerHTML={{ __html: filter.description }} />
      </div>
    </Article>
  </li>
))

type TypeSectionFiltersFeatured = {
  filters?: TypeFilterValue[],
  productDetailVisible: boolean
}

export const SectionFiltersFeatured = memo(({ filters = [], productDetailVisible }:TypeSectionFiltersFeatured) => {
  const Slides = useMemo(() => getSlides(filters), [filters])
  return (
    <FeaturedFiltersSection variants={featured_filters_variants} initial='initial' exit='exit' animate={filters?.length < 1 ? 'exit' : 'animate'} $productDetailVisible={productDetailVisible}>
      <SliderFlickity className={'slider--product-card'} loop={true} freeScroll={false} pageDots={false} groupCells={'80%'} cellAlign={'left'} autoPlay={filters?.length > 1}>
        {Slides}
      </SliderFlickity>
    </FeaturedFiltersSection>
  )
})
