'use-client'
import type { FC } from 'react'
import { memo, useEffect, useMemo, useState } from 'react'
import { useRouter } from 'next/router'
import type { SubmitHandler } from 'react-hook-form'
import { useForm } from 'react-hook-form'
// import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'
import { useTranslator } from '@/hooks'
import { Form, Fieldset, DisplayError, Input, Select, Checkbox, Button } from '@dy/commons/components'
import type { TypeSectionAuthLevel } from '@/components'
import { autoComplete, getLocaleCountry, validate, inputPattern } from '@dy/commons/utils'
import { SIGNUP_MUTATION } from '@/api'

type Props = {
  updateLevel: (level: TypeSectionAuthLevel, close?: boolean) => void
  animation?: any
}

type TypeFormInputs = {
  firstName: string
  lastName: string,
  email: string,
  password: string
  companyName?: string
  vatNumber?: string,
  countryCode: string,
  provinceCode: string,
  city: string,
  streetLine: string,
  streetLine2?: string,
  postalCode: string,
  businessType?: 'restaurant' | 'commercial' | 'businessTypeoption1',
  sameShippingBillingAddress?: boolean,
  noCommercialContact?: boolean,
  directDebit?: boolean,
  iban?: string

}

export const FormUserSignUp:FC<Props> = memo(({ updateLevel, animation }) => {
  const { register, handleSubmit, watch, formState: { errors:formErrors }, setValue } = useForm<TypeFormInputs>()
  const { t } = useTranslator()
  const { locale } = useRouter()
  const countryCode = useMemo(() => getLocaleCountry(locale).split('-')[1], [locale])
  console.log('updateLevel', updateLevel)
  console.log('watch', watch)

  const [provinces, setProvinces] = useState([])

  const [registerCustomer, { loading, error:apolloError, data } ] = useMutation(SIGNUP_MUTATION)

  const onSubmit: SubmitHandler<TypeFormInputs> = async formData => {
    console.log('FormUserSignUp | onSubmit, formData:', formData)
    const { firstName, lastName, email, password } = formData
    const billingAddress = {
      firstName,
      lastName,
      address1: formData.streetLine,
      address2: formData.streetLine2,
      countryCode: formData.countryCode,
      stateOrProvince: 'provinciaAQUI',
      city: formData.city,
      postalCode: formData.postalCode,
      addressType: 'BILLING'
    }

    const addresses = [
      billingAddress,
      ...formData.sameShippingBillingAddress && [{
        ...billingAddress,
        addressType: 'SHIPPING'
      }]
    ]

    // const shippingAddress = formData.sameShippingBillingAddress ? {...billingAddress, addressType: 'SHIPPING' } : null
    // console.log('shippingAddres, pending TODO the logic for expanded shipping fields if sameAsBilling is unchecked', shippingAddress)

    const formFields = {
      companyName: formData.companyName,
      vatNumber: formData.vatNumber,
      businessType: formData.businessType,
      sameShippingBillingAddress: formData.sameShippingBillingAddress,
      noCommercialContact: formData.noCommercialContact,
      directDebit: formData.directDebit,
      iban: formData.iban
    }

    await registerCustomer({
      variables: {
        firstName,
        lastName,
        email,
        password,
        formFields,
        addresses
      }
      // refetchQueries: [
      //   { query: CURRENT_USER_QUERY }
      // ]
    })
    // Reset back the inputs
  }

  const onError = (errors, e) => {
    console.warn('🟡 Errors found before Submit')
    console.log(errors, e)
  }

  useEffect(() => {
    async function getProvinces(countryCode) {
      let data = await import(`/public/locales/countries/${countryCode}-provinces.json`)
      console.log('data', Array.from(data))
      if (data) setProvinces(Array.from(data))
    }

    if (provinces?.length === 0 && typeof locale === 'string') {
      if (typeof countryCode === 'string' && countryCode !== 'EU') getProvinces(countryCode)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale])

  useEffect(() => {
    if (data) console.log('🟢 useMutation registerCustomer data:', data)
  }, [data])

  return (
    <Form method='post' onSubmit={handleSubmit(onSubmit, onError)} {...animation && animation}>
      <Fieldset disabled={loading} ariaBusy={loading} legend={t('commons.modals.auth.signup.text') as string}>
        <DisplayError error={apolloError} />
        <Input type='email' name='email' register={register} label={t('commons.form.fields.email') as string} placeholder='email' autoComplete={autoComplete.email} bgColor={'white'} />
        <Input type='password' name='password' register={register} label={t('commons.form.fields.password') as string} autoComplete={autoComplete.password.new} placeholder='password' bgColor={'white'} />

        <Input name={'firstName'} col={6} register={register} label={t('commons.form.fields.first_name') as string} required error={formErrors.firstName} bgColor={'white'} />
        <Input name={'lastName'} col={6} register={register} label={t('commons.form.fields.last_name') as string} required error={formErrors.lastName} bgColor={'white'} />
        <Input name={'companyName'} register={register} label={t('commons.form.fields.company_name') as string} required error={formErrors.companyName} bgColor={'white'} />
        <Input name='vatNumber' col={6} register={register} label={t('commons.form.fields.cif') as string} required={{
          required: true,
          // cif validation only available for Spain by now
          ...countryCode === 'ES' && {
            validate: (value) => validate.cif(value) || (t('commons.form.errors.validate_cif') as string)
          }
        }} error={formErrors.vatNumber} bgColor={'white'} />
        {/* 3 SELECTS HERE */}
        <Input name='countryCode' col={6} register={register} label={t('commons.form.fields.country') as string} readOnly={true} defaultValue='ES' required bgColor={'white'} />
        <Select name='provinceCode' col={6} register={register} label={t('commons.form.fields.province') as string} options={provinces} setFormValue={setValue} bgColor={'white'} />
        <Input name='postalCode' col={6} register={register} label={t('commons.form.fields.postal_code') as string} required={{
          required: true,
          pattern: inputPattern.postalCode[countryCode]
        }} error={formErrors.postalCode} bgColor={'white'} autoComplete={autoComplete.address.postalCode} />
        <Input name='city' col={12} register={register} label={t('commons.form.fields.city') as string} bgColor={'white'} autoComplete={autoComplete.address.cityOrTown} />
        <Input name='streetLine' register={register} label={t('commons.form.fields.street_line1') as string} required error={formErrors.streetLine} bgColor={'white'} autoComplete={autoComplete.address.street} />
        <Input name='streetLine2' col={6} register={register} label={t('commons.form.fields.street_line2') as string} error={formErrors.streetLine2} bgColor={'white'} autoComplete={autoComplete.address.street2} />
        <Input name='businessType' col={6} register={register} label={'Business Type'} error={formErrors.businessType} bgColor={'white'} />
        <Checkbox name='sameShippingBillingAddress' col={12} register={register} label={t('commons.form.fields.shipping_as_billing') as string} bgColor={'white'} defaultChecked={true} />
        <Checkbox name='noCommercialContact' col={12} register={register} label={'No quiero ser contactado por un commercial'} bgColor={'white'} />
        <Checkbox name='directDebit' col={12} register={register} label={'Domiciliacion Bancaria (optional)'} bgColor={'white'} />
        <Input name='iban' col={12} register={register} label={'Bank account'} error={formErrors.iban} bgColor={'white'} required={{
          required: false,
          validate: (value) => validate.iban(value, countryCode) || (t('commons.form.errors.validate_iban') as string)
        }}/>
      </Fieldset>
      <Button type='submit'>{t('commons.actions.signup')}</Button>
    </Form>
  )
})
