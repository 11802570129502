import { memo, useMemo } from 'react'
import { useRouter } from 'next/router'
import { Button, Figure, Link } from '@dy/commons/components'
import { Section, CardListItem, ProductListItem } from './accountStyles'
import { currencyFormatter, getLang } from '@dy/commons/utils'
import { useTranslator } from '@/hooks'

interface ISectionOrders {
  data: any
}

const mediaPastaLeft = {
  type: 'image' as const,
  alt: 'Icon pasta 1',
  default: {
    src: '/images/svg/pasta-left.svg',
    size: { width: 30, height: 20 }
  }
}
const mediaNarrowIcon = {
  type: 'image' as const,
  alt: 'Icon pasta 1',
  default: {
    src: '/images/svg/i--account-narrow.svg',
    size: { width: 8, height: 13 }
  }
}

const getProducts = (products, t, locale) => products.map((product, idx) => {
  const { variantId, amount, brand, name, price, pricePerWeight } = product
  const priceCurreny = currencyFormatter({price: price, localeRegion: locale})
  const pricePerWeightCurrency = currencyFormatter({price: pricePerWeight, localeRegion: locale})
  return (
    <ProductListItem key={`${idx}-${variantId}`}>
      <Figure media={mediaPastaLeft}/>
      <div className={'info'}>
        <span>{amount} x {name}</span>
        <span>{brand} | {pricePerWeightCurrency}/Kg</span>
        <span>{priceCurreny}</span>
      </div>
      <button>{t('account.buy_again')}</button>
    </ProductListItem>
  )
})

const getOrders = (items, t, locale) => items.map((item, idx) => {
  const { id, dateCreated, total, deliveryDate, orderLines, type } = item
  const ProductList =  getProducts(orderLines, t, locale)
  const priceCurreny = currencyFormatter({price: total, localeRegion: locale})

  return (
    <CardListItem key={`${idx}-${id}`}>
      <div className='mobile'>
        <p className={'headline_mobile'}>{deliveryDate}</p> 
        <button className='icon__mobile'>
          <Link href={`/account/order?id=${id}`}>
            <Figure media={mediaNarrowIcon}/>   
          </Link>
        </button>
      </div> 
      <div className={'overview'} >
        <span>{t('account.order.purchase')}<span>{type}</span> </span> 
        <span>{t('account.order.date')} <span>{dateCreated}</span> </span>
        <span>{t('account.order.total')} <span>{priceCurreny}</span> </span>
        <span>{t('account.order.number')}<span>{id}</span> </span>
      </div>
      <div className={'main-info'}>
        <div className={'products'}>
          <p className={'headline'}>{deliveryDate}</p>        
          <ul>
            {ProductList}
          </ul>
        </div>
        <div className={'cta'}>
          <Button bgType={'red'} outline>{t('account.repeat_order')}</Button>
          <Link href={`/account/order?id=${id}`}>{t('account.details')}</Link>
        </div>
      </div>
    </CardListItem>
  )
})

export const SectionOrders = memo<ISectionOrders>(({ data }) => {
  const { locale:localeRouter } = useRouter()
  const locale =  getLang(localeRouter)
  const { t } = useTranslator()

  const OrderList = useMemo(() => getOrders(data, t, locale), [data, t, locale])

  return (
    <Section>
      <ul>
        {OrderList}
      </ul>
    </Section>
  )
})