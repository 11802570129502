import { memo,useMemo } from 'react'
import { useRouter } from 'next/router'
import { Button } from '@dy/commons/components'
import { getOrderSumary } from '../account'
import { Wrapper, MinimumOrder, listVariants } from '.'
import { useTranslator } from '@/hooks'
import { AnimatePresence } from 'framer-motion'
import { currencyFormatter } from '@dy/commons/utils'

interface ICartSummary {
  data: any,
}

export const CartSummary = memo<ICartSummary>(({ data }) => {
  const { locale } = useRouter()
  const { t } = useTranslator()
  // const { subtotalIncTax } = data
  const  subtotalIncTax = 20
  //DEV HARDCODED
  let minimumPurchase = '40'
  const blockPurchase = Number(minimumPurchase) > subtotalIncTax
  const OrderSummary = useMemo(() => getOrderSumary(data, locale, t), [data, locale, t])
  const minimumOrder = currencyFormatter({price: Number(minimumPurchase), localeRegion:locale})
  // const minimumPurchase = subtotalIncTax
  return (
    <AnimatePresence>
      <Wrapper>
        {OrderSummary}
        <Button bgType='red' className={blockPurchase ? 'block' : ''}>{t('account.order.purchase_order')}</Button>
        {blockPurchase && 
          <MinimumOrder variants={listVariants} initial='inactive' animate={'enter'} exit={'exit'}>
            <p>{t('cart.minimum_order')} {minimumOrder}</p>
          </MinimumOrder>
        }
      </Wrapper>
    </AnimatePresence>
  )
})
