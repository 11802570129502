import styled from 'styled-components'
import { mq, vw, hex2Rgba, getP18InputLabel, getP12_1, getP15_1B, getP26_1, getP10 } from '@dy/commons/styles'
import { Form as FormStyled } from '@dy/commons/components'

export const AccountNav = styled.nav`
  display:none;

  ${mq.greaterThan('tablet')`
    display: inline;
    grid-column: 1 / span 3;
    padding: 0 ${vw(50, 'desktop')} 0 ${vw(30, 'desktop')};
  `}
`

export const AccountNavListItem = styled.li<any>`
  margin-bottom: ${vw(20, 'mobile')};

  ${mq.greaterThan('tablet')`
    margin-bottom: ${vw(20, 'desktop')};
  `}

  a {
    ${getP18InputLabel()}
    color: ${({ theme, $active }) => $active ? theme.colors.darkred : hex2Rgba(theme.colors.darkred, .4)};
  }
`

export const Section = styled.section`
  grid-column: 1 / span 6;
  margin: ${vw(20, 'mobile')} 0;
  padding: 0;

  ${mq.greaterThan('tablet')`
    grid-column: 4 / span 8;
    margin: 0;
  `}

  p {
    ${getP26_1()}
    color: ${({ theme }) => theme.colors.red};
    margin-bottom:${vw(20, 'mobile')};

    ${mq.greaterThan('tablet')`
      margin-bottom:${vw(20, 'desktop')};
    `}
  }
`

export const Wrapper = styled.ul`
  display: block;

  ${mq.greaterThan('tablet')`
    display: flex;
    place-content: space-between;
  `}

    .big {
      height: unset;
      width: unset;

      ${mq.greaterThan('tablet')`
        height: 100%;
        width: 63%;
      `}

      h1, span{
        color: ${({ theme }) => theme.colors.darkred};
      }
      h1 {
      margin-top: ${vw(30, 'mobile')};

      ${mq.greaterThan('tablet')`
        margin-top: ${vw(53, 'desktop')}
      `}
    }

    span {
      ${getP15_1B()}
    }

    figure {
      display: inline-block;
      height: ${vw(30, 'mobile')};
      width: ${vw(30, 'mobile')};

      ${mq.greaterThan('tablet')`
        height: ${vw(30, 'desktop')};
        width: ${vw(30, 'desktop')};
      `}

      + span {
        display: inline;
        padding-left: ${vw(10, 'mobile')};

        ${mq.greaterThan('tablet')`
          padding-left: ${vw(10, 'desktop')};
        `}
      }
    }
    .detail {
      div > span { display: block;}
    }
  }

    .small {
      height: unset;
      width: unset;

      ${mq.greaterThan('tablet')`
        height: 90%;
        width: 33%;
      `}

      h1, td, span {
        color: ${({ theme }) => theme.colors.red};
      }
      td, span {
        ${getP15_1B()}
      }

      table {
        width:100%;
        padding: ${vw(30, 'mobile')};

        ${mq.greaterThan('tablet')`
          padding: ${vw(12, 'desktop')} ${vw(20, 'desktop')} ${vw(15, 'desktop')};
        `}

        tr {
          display: flex;
          justify-content: space-between;
          margin: 0;
          padding: ${vw(5, 'mobile')} 0px;

          ${mq.greaterThan('tablet')`
            padding: ${vw(10, 'desktop')} 0px;
          `}

        }
      }

      .summary_total{
        display: flex;
        justify-content: space-between;
        border-top: 1px solid ${({ theme }) => hex2Rgba(theme.colors.darkred, .1)};
        padding: ${vw(30, 'mobile')};

        ${mq.greaterThan('tablet')`
          padding: ${vw(12, 'desktop')} ${vw(20, 'desktop')} ${vw(15, 'desktop')};
        `}
      }
    }
`

export const CardListItem = styled.li`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: ${vw(20, 'mobile')};
  margin-bottom: ${vw(10, 'mobile')};

  ${mq.greaterThan('tablet')`
    border-radius: ${vw(20, 'desktop')};
    margin-bottom: ${vw(30, 'desktop')};
  `}

  > ul {
    display: flex;
  }
  .mobile {
    display: block;

    ${mq.greaterThan('tablet')`
      display: none;
    `}
  }

  .desktop {
    display: none;

    ${mq.greaterThan('tablet')`
      display: block;
    `}
  }

  .headline_mobile {
    ${getP26_1()}
    color: ${({ theme }) => theme.colors.red};
    padding: ${vw(20, 'mobile')}  ${vw(20, 'mobile')}  ${vw(0, 'mobile')};
    &:first-child {
      margin-bottom:0;
    }
  }

  .icon__mobile {
    display:inline;
    position: relative;
    top: ${vw(30, 'mobile')};
    left: calc(100% - ${vw(45, 'mobile')});
    
    figure {
      height: ${vw(13, 'mobile')};
      width: ${vw(8, 'mobile')};
    }
  }

  .overview {
    border-bottom: none;
    padding: ${vw(10, 'mobile')} ${vw(20, 'mobile')};
    width:100%;
    ${mq.greaterThan('tablet')`
      border-bottom: 1px solid ${({ theme }) => hex2Rgba(theme.colors.darkred, .1)};
      display: flex;
      padding: ${vw(12, 'desktop')} ${vw(40, 'desktop')} ${vw(15, 'desktop')};
    `}
    
    &.order {
    display:none;

    ${mq.greaterThan('tablet')`
      display: block;
      padding: ${vw(12, 'desktop')} 0px ${vw(15, 'desktop')};
    `}
    } 

    tr, td {
      ${getP15_1B()}
      color: ${({ theme }) => theme.colors.darkred};
      margin-bottom: ${vw(5, 'mobile')};
      
      ${mq.greaterThan('tablet')`
        ${getP12_1()}
        margin-bottom: 0px;
        width: 100px;
      `}

      &.headline.mobile {
        padding: ${vw(20, 'mobile')} 0;
        ${mq.greaterThan('tablet')`
          padding: 0;
        `}
      }
    }

    > span {
      ${getP12_1()}
      display: block;
      padding-left:0px;

      ${mq.greaterThan('tablet')`
        padding-left: ${vw(90, 'desktop')};
      `}

      &:first-of-type {
        ${mq.greaterThan('tablet')`
          padding-left: 0;
        `}
      }

       > span:last-of-type {
        display: inline;
        padding-left: ${vw(3, 'mobile')};
        ${mq.greaterThan('tablet')`
          display: block;
          padding-left: 0px;
        `}
      }
    }
  }

  .main-info {
    padding-bottom: ${vw(30, 'mobile')};

    ${mq.greaterThan('tablet')`
      display: grid;
      grid-template-columns: ${vw(445, 'desktop')} 1fr;
      grid-column-gap: ${vw(115, 'desktop')};
      padding: ${vw(30, 'desktop')} ${vw(40, 'desktop')} ${vw(40, 'desktop')};
    `}

    .products {
      ${mq.greaterThan('tablet')`
        grid-area: 1 / 1 / span 1 / span 1;
      `}
    }

    .headline {
      ${getP26_1()}
      color: ${({ theme }) => theme.colors.red};
      display:none;
      ${mq.greaterThan('tablet')`
        display:block;
        margin-bottom: ${vw(34, 'desktop')};
      `}
    }

    .cta {
      > button {
        margin: auto 0 0 ${vw(20, 'mobile')};
      }
      a:not(button > div > a ) {
        display:none;
      }

      ${mq.greaterThan('tablet')`
        a:not(button > div > a ) {
          ${getP15_1B()}
          color: ${({ theme }) => theme.colors.red};
          display: block;
          text-align: center;
        }

        > button {
          width: 100%;
          margin: 0 0 ${vw(30, 'desktop')} 0;
        }
        `}
    }
  }

  .detail {
    color: ${({ theme }) => theme.colors.darkred};
    padding: ${vw(30, 'mobile')};

    ${mq.greaterThan('tablet')`
      padding: ${vw(12, 'desktop')} ${vw(20, 'desktop')} ${vw(15, 'desktop')};
    `}

    h1 {
      ${getP26_1()};
      text-align:left;
      margin: ${vw(20, 'mobile')} 0px;

      ${mq.greaterThan('tablet')`
        margin: ${vw(20, 'desktop')} 0;
      `}
    }
   
  }
  .mobile {
    display: block;
    ${mq.greaterThan('tablet')`
       display: none;

    `}
  }
  .order_title {
    border-bottom: 1px solid ${({ theme }) => hex2Rgba(theme.colors.darkred, .1)};
    padding: ${vw(30, 'mobile')};

    ${mq.greaterThan('tablet')`
      padding:${vw(20, 'desktop')};
    `}

    h1 {
      ${getP26_1()};
      text-align:left;
    }
  }

`

export const ProductListItem = styled.li`
display:none;

${mq.greaterThan('tablet')`
    display: grid;
    align-items: center;
    column-gap: ${vw(20, 'desktop')};
    grid-template-columns: min-content ${vw(221, 'desktop')} auto;
    margin-bottom: ${vw(20, 'desktop')};
    padding-bottom: ${vw(20, 'desktop')};
  `}

  &:not(:last-of-type) {
    border-bottom: 1px solid ${({ theme }) => hex2Rgba(theme.colors.darkred, .1)};
  }

  figure {
    position: relative;

    ${mq.greaterThan('tablet')`
      height: ${vw(55, 'desktop')};
      width: ${vw(55, 'desktop')};
    `}
  }

  span {
    ${getP12_1()}
    color: ${({ theme }) => theme.colors.darkred};
    display: block;
  }

  button {
    ${getP15_1B()}
    color: ${({ theme }) => theme.colors.red};
  }
`

export const Form = styled(FormStyled)`
  grid-column: 1 / span 6;
  padding: ${vw(12, 'mobile')};

  ${mq.greaterThan('tablet')`
    grid-column: 6 / span 8;
    padding: 0;
  `}

  fieldset {
    width: 100%;

    label {
      ${mq.greaterThan('tablet')`
        width:70%
      `}
    }
  }

  button {
    &[type='submit'] {
      grid-column: 1 / span 6;

      ${mq.greaterThan('tablet')`
        grid-column: 6 / span 8;
        margin-bottom: 5px;
        margin-left: 0px;
      `}
    }

    &[type='button'] {
      img {
        bottom: ${vw(17, 'mobile')};
        height: ${vw(13, 'mobile')};
        position: absolute;
        right: ${vw(15, 'mobile')};
        width: ${vw(23, 'mobile')};

        ${mq.greaterThan('tablet')`
          bottom: ${vw(17, 'desktop')};
          height: ${vw(12, 'desktop')};
          right: ${vw(15, 'desktop')};
          width: ${vw(17, 'desktop')};
        `}
      }
    }
  }

`

export const FigureIcon = styled.picture`

`

export const OrderLine = styled.div`
    display:grid;
    grid-template-columns: ${vw(100, 'mobile')} auto;
    height:${vw(300, 'mobile')};
    margin: 0 0 ${vw(30, 'mobile')};

    ${mq.greaterThan('tablet')`
     display:block;
     grid-template-columns: unset;
     grid-template-rows: unset;
     height:unset;
  `}

  .progress_bar , .order_status {
    display:grid;

    ${mq.greaterThan('tablet')`
      grid-template-columns: repeat(4, 1fr);
    `}
  }

  .progress_bar {
    grid-template-rows: repeat(4,25%);
    justify-items: center;

    ${mq.greaterThan('tablet')`
      grid-template-rows: unset;
      justify-items: unset;
    `}
    > div {
      align-items: center;
      background-color: ${({ theme }) => hex2Rgba(theme.colors.red, .2)};
      display:grid;
      height:100%;
      justify-content: center;
      text-align: center;
      width: 10px;
      z-index: -20;
      ${mq.greaterThan('tablet')`
        display:block;
        height: ${vw(5, 'desktop')};
        width: 100%;
      `}

      &.completed {
        background-color: ${({ theme }) => theme.colors.red};
      }

      &:first-child {
        border-top-left-radius:  ${vw(10, 'mobile')};
        border-top-right-radius:  ${vw(10, 'mobile')};
        border-bottom-left-radius: 0;
        ${mq.greaterThan('tablet')`
         border-top-left-radius:  ${vw(10, 'desktop')};
         border-top-right-radius: 0;
         border-bottom-left-radius:  ${vw(10, 'desktop')};
        `}
      }

      &:last-child {
        border-bottom-left-radius:  ${vw(10, 'mobile')};
        border-bottom-right-radius:  ${vw(10, 'mobile')};
        border-top-right-radius: 0;
        ${mq.greaterThan('tablet')`
          border-bottom-left-radius: 0;
         border-top-right-radius:  ${vw(10, 'desktop')};
         border-bottom-right-radius:  ${vw(10, 'desktop')};
        `}
      }

      span.completed:after {
        ${getP26_1()}
        color: white;
        content:'✓';
        left:${vw(3, 'mobile')};
        position: relative;
        top:${vw(-18, 'mobile')};
        z-index: 1;
        ${mq.greaterThan('tablet')`
         ${getP10()}
         left:0;
         top:${vw(-16, 'desktop')};
      `}
      }

      span.completed:before {
        background: ${({ theme }) => theme.colors.red};
        border-radius: 50%;
        content:'';
        display: block;
        height: ${vw(20, 'mobile')};
        margin: ${vw(20, 'mobile')} 0 0;
        width: ${vw(20, 'mobile')};
        ${mq.greaterThan('tablet')`
        height: ${vw(15, 'desktop')};
        margin: ${vw(-5, 'desktop')} auto;
        width: ${vw(15, 'desktop')};
      `}
     }
    }

  }
  .order_status {
    align-items: center;
    grid-template-rows: repeat(4,25%);
    justify-items:unset;
    margin: 0;
    ${mq.greaterThan('tablet')`
        align-items: unset;
        grid-template-rows: unset;
        justify-items: center;
        margin-bottom: ${vw(55, 'desktop')};
        margin-top: ${vw(30, 'desktop')};
    `}

    > span {
      ${getP12_1()}
      color: ${({ theme }) => theme.colors.darkred};
      display:block;
      text-align:left;
      ${mq.greaterThan('tablet')`
        display: inline-grid;
        text-align: center;
      `}
    }
}
`
