import { memo, useMemo, useState, useRef, useEffect } from 'react'
import { useRouter } from 'next/router'
import styled from 'styled-components'
import { useTranslator } from '@/hooks'
import { useModal, useScrollTracker } from '@dy/commons/hooks'
import { MODALS } from '@dy/commons/context'
import { vw, mq, hex2Rgba, getP18NavLinks, getP18_2, createUnderline, getTransition } from '@dy/commons/styles'
import { Link, Figure } from '@dy/commons/components'
import { ButtonTriggerSearch } from '@/components'
import { Burger } from './Burger'
import { AnimatePresence, motion } from 'framer-motion'
import { useDeviceType } from '@dy/commons/hooks'
import { userLoggedInVariants } from '../layoutVariants'
import { getRegion } from '@dy/commons/utils'
import { getCountry, getSubdivisions }  from '@/components'

interface INavbar {
  firstCategory: any,
  setShowCart: (boolean) => void,
  hasBanners?: boolean
}

const accountIcon = (fillColor) => ({
  type: 'svg' as const,
  src: `/images/svg/i--account${fillColor === 'white' ? '--white' : ''}.svg`,
  alt: 'User account icon',
  size: { width: 18, height: 18 }
})

const cartIcon = (fillColor) => ({
  type: 'svg' as const,
  src: `/images/svg/i--cart${fillColor === 'white' ? '--white' : ''}.svg`,
  alt: 'Cart icon',
  size: { width: 21, height: 18 }
})

const logo = (fillColor = 'darkred') => ({
  type: 'svg' as const,
  src: `/images/svg/logo${fillColor === 'white' ? '--white' : ''}.svg`,
  alt: 'Logo',
  size: { width: 261, height: 66 }
})

export const locationIcon = {
  type: 'svg' as const,
  src: '/images/svg/i--location.svg',
  alt: 'Location icon',
  size: { width: 14, height: 18 }
}

const CartLink = styled.a<any>`
  &.loading {
    pointer-events: none;
    user-select: none;
  }
`

const NavStyled = styled(motion.nav)<{ $scrollTop: boolean, $isMenuActive: boolean, $boxShadow:boolean }>`
  background-color: ${({ $isMenuActive, theme }) => ($isMenuActive) ? theme.colors.red : theme.colors.background};
  box-shadow: ${({ $isMenuActive, $scrollTop, $boxShadow, theme: { colors } }) => ($boxShadow && !$scrollTop && !$isMenuActive) ? `0 4px 40px 0 ${hex2Rgba(colors.black, .1)}` : 'none'};
  color: ${({ $isMenuActive, theme }) => theme.colors[$isMenuActive ? 'white' : 'red']};
  height: ${vw(60, 'mobile')};
  position: fixed;
  transition: 300ms background-color ease-out, 300ms box-shadow ease-out;
  width: 100vw;
  z-index: 600;
  font-family: ${({ theme }) => theme.fonts.tertiaryFont};

  ${mq.greaterThan<{ $scrollTop: boolean }>('tablet')`
    background-color: ${({ theme }) => theme.colors.background};
    color: ${({ theme }) => theme.colors.red};
    height: ${vw(120, 'desktop')};
  `}

  ul {
    margin: 0;
  }

  > ul {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: flex-start;
    margin: 0;
    position: relative;
    width: 100%;

    &:before {
      background-color: ${({ theme }) => theme.colors.red};
      content: '';
      height: 100%;
      pointer-events: none;
      position: absolute;
      user-select: none;
      width: ${({ $scrollTop, $isMenuActive }) => (!$isMenuActive || (!$scrollTop && $isMenuActive)) ? 0 : '100vw'};
      transition: 300ms width ease-out;
      z-index: -1;
    }

    li:not(.li--links li) {
      &:first-child {
        ${mq.greaterThan('tablet')`
          display: none;
        `}
      }
    }
  }

  li {
    align-items: center;
    display: flex;
    height: 100%;
  }

  .li--logo {
    margin: ${vw(12, 'mobile')} auto 0;

    ${mq.greaterThan('tablet')`
      margin: ${vw(12, 'desktop')} ${vw(45, 'desktop')} 0 ${vw(50, 'desktop')};
    `}

    ${mq.greaterThan('desktop')`
      margin: 12px 45px 0 50px;
    `}
  }

  .li--links {
    display: none;

    ${mq.greaterThan('tablet')`
      align-items: center;
      display: flex;
      justify-content: flex-start;
    `}

    ul, li {
      align-items: center;
      display: flex;
      justify-content: flex-start;
    }

    li {
      ${mq.greaterThan('tablet')`
        margin: 0 ${vw(15, 'desktop')};
      `}

      ${mq.greaterThan('desktop')`
        margin: 0 15px;
      `}
    }

    a, button {
      ${createUnderline({ color: 'red', height:2 })}
      ${getP18NavLinks}
    }
  }

  .li--location {
    display: none;

    ${mq.greaterThan('tablet')`
      display: flex;
      margin: 0 ${vw(32, 'desktop')};
    `}

    ${mq.greaterThan('desktop')`
      margin: 0 32px;
    `}

    button {
      font-weight: 500;
    }

    figure {
      margin-right: ${vw(10, 'mobile')};
      width: ${vw(14, 'mobile')};

      ${mq.greaterThan('tablet')`
        margin-right: ${vw(10, 'desktop')};
        width: ${vw(14, 'desktop')};
      `}

      ${mq.greaterThan('desktop')`
        margin-right: 10px;
        width: 14px;
      `}
    }
  }

  .li--account,
  .li--cart {
    button {
      align-items: center;
      display: flex;
      height: 100%;
      justify-content: center;
      width: ${vw(39, 'mobile')};

      ${mq.greaterThan('tablet')`
        width: unset;
      `}
    }
  }

  .li--location,
  .li--account,
  .li--cart {
    ${mq.greaterThan('tablet')`
      order: 2;
    `}

    span {
      ${getP18_2()}
    }
  }

  .li--account {
    button {
      margin-right:  unset;
      ${mq.greaterThan('tablet')`
        margin-right:  ${vw(20, 'desktop')};
      `}

      ${mq.greaterThan('desktop')`
        margin-right: 20px;
      `}

    }
    figure {
      margin-right: ${vw(10, 'mobile')};
      width: ${vw(21, 'mobile')};

      ${mq.greaterThan('tablet')`
        width: ${vw(18, 'desktop')};
        margin-right: ${vw(10, 'desktop')};
      `}

      ${mq.greaterThan('desktop')`
        width: 18px;
        margin-right: 10px;
      `}
    }
    .account_s {
      display: none;

      ${mq.greaterThan('tablet')`
       display: block;
       font-weight: 500;
     `}

      ${mq.greaterThan('desktop')`
        display: block;
        font-weight: 500;
      `}
    }
  }

  .li--cart {
    ${mq.greaterThan('tablet')`
      margin-right: ${vw(40, 'desktop')};
    `}

    ${mq.greaterThan('desktop')`
      margin-right: 40px;
    `}

    figure {
      width: ${vw(25, 'mobile')};
      margin-right: ${vw(10, 'mobile')};

      ${mq.greaterThan('tablet')`
        margin-right: 0;

        width: ${vw(21.7, 'desktop')};
      `}

      ${mq.greaterThan('desktop')`
        width: 20.7px;
        margin-right: 0;
      `}
    }
  }

  .li--logo {
    figure {
      width: ${vw(123, 'mobile')};

      ${mq.greaterThan('tablet')`
        width: ${vw(184, 'desktop')};
      `}

      ${mq.greaterThan('desktop')`
        width: 184px;
      `}
    }
  }

  .li--search {
    ${mq.greaterThan('tablet')`
      margin: 0 ${vw(15, 'desktop')} 0 auto;
      order: 1;
    `}

    ${mq.greaterThan('desktop')`
      margin: 0 15px 0 auto;
    `}
  }

  p {
    margin: 0;
  }

  button,
  a {
    align-items: center;
    color: inherit;
    display: flex;
    text-decoration-color: transparent;
    transition: 300ms color ease-out, 300ms text-decoration-color ease-out;
    &:hover {
      /* color: white; */
      text-decoration-color: white;
    }
  }
`

const navVariants = ({ mq, hasBanners }) => ({
  mobile: {
    height: vw(hasBanners ? 100 : 80, 'mobile'),
    paddingTop: hasBanners ? '40px' : 0,
    transition: {
      ...getTransition()
    }
  },
  mobileCompact: {
    height: vw(60, 'mobile'),
    paddingTop: 0,
    transition: {
      ...getTransition()
    }
  },
  initial: {
    height: mq > 1440 ? '120px' : vw(120, 'desktop'),
    paddingTop: hasBanners ? mq > 1440 ? '30px' : vw(30, 'desktop') : 0,
    transition: {
      ...getTransition()
    }
  },
  desktopCompact: {
    height: mq > 1440 ? '90px' : vw(90, 'desktop'),
    paddingTop: 0,
    transition: {
      ...getTransition(),
    }
  }
})

const NavLinkAccount = ({ fillColor, customerData }) => {
  const { t } = useTranslator()
  const { toggle:toggleAuth } = useModal(MODALS.AUTH)
  const { toggle:toggleModalMenuAccount } = useModal(MODALS.MENU_ACCOUNT)
  const { isMobile } = useDeviceType()

  const clickAction = useMemo(() => customerData && isMobile ? toggleModalMenuAccount : toggleAuth, [isMobile, customerData, toggleModalMenuAccount, toggleAuth])

  return (
    <AnimatePresence>
      {
        // urlHref && mediaType !== 'mobile' && customerData ?
        //   <motion.a href={urlHref} title={'Account'} className={`account ${customerData ? 'log' : ' '}`} variants={userLoggedInVariants} initial='initial' exit='exit' animate={'animate'}>
        //     <img src={`/images/svg/i--account--${color}.svg`} alt='Icon account' width='18' height='18'/>
        //     <span className='user_name'>{userData?.firstName?.toString()}</span>
        //   </motion.a> :
        <motion.button onClick={clickAction} title={'Account'} className='account' variants={userLoggedInVariants} initial='initial' exit='exit' animate={'animate'}>
          <Figure media={accountIcon(fillColor)} fill={false} />
          <span className='account_s'>{t('layout.nav.account')}</span>
        </motion.button>
      }
    </AnimatePresence>
  )
}

const NavLinkCart = ({ fillColor = 'red', isMenuActive = false }) => {
  //to avoid build errors
  // const { data: dataCart } = useCart()

  //DEV HARDCODED
  const urlHref='/cart'
  console.log('isMenuActive',isMenuActive)
  // const { cartItems } = useContext(NewCartItemsContext)

  // useEffect(() => {
  //   getCartUrl(dataCart?.id, setUrl)
  // }, [dataCart])

  return (
    // <CartLink href={urlHref} title={'Cart icon'} className={`cart ${!dataCart && cartItems?.length > 0 ? 'loading' : ''}`}>
    <CartLink href={urlHref} title={'Cart icon'} className='cart'>
      <Figure media={cartIcon(fillColor)} fill={false} />
      {/* <ItemsInCartCircle visible={dataCart} fav={false} isMenuActive={isMenuActive} /> */}
    </CartLink>
  )
}


export const Navbar = memo<INavbar>(({ firstCategory, setShowCart, hasBanners }) => {
  const { pathname, locale } = useRouter()
  const { t } = useTranslator()
  const scrollPos = useScrollTracker(1)

  const [customerData, setcustomerData] = useState(true)
  const [storeLocation, setStoreLocation] = useState('')
  const [country, setCountry] = useState([])
  const [subdivisions, setSubdivisions] = useState([])
  const navbarRef= useRef(null)
  const { isMobile, size:deviceSize } = useDeviceType()
  const scrollTop = useMemo(() => scrollPos === 'TOP', [scrollPos])
  console.log('scrollTop', scrollTop)
  const animate = useMemo(() => isMobile ? scrollTop ? 'mobile' : 'mobileCompact' : scrollTop ? 'initial' : 'desktopCompact', [isMobile, scrollTop])

  const { isActive:isActiveModalMenu, toggle: toggleModalMenu } = useModal(MODALS.MENU)
  const { toggle: toggleRegion } = useModal(MODALS.REGION_LANG)
  const fillColor = useMemo(() => isActiveModalMenu ? 'white' : 'darkred', [isActiveModalMenu])
  //DEV HARDCODED
  const { slug } = firstCategory
  const hardcoded =  slug.es

  useEffect(() => {
    if(country?.length === 0) {
      getCountry().then((res)=>{
        setCountry(Array.from(res))
      })
    }
  }, [country])

  useEffect(() => {
    getSubdivisions('ES').then((res) => {
      setSubdivisions(Array.from(res))
    })

    console.log('setcustomerData', setcustomerData)
  }, [country])

  useEffect(()=> {
    if(locale.includes('es')){
      const region = subdivisions.find((el)=> el.value === locale.toUpperCase())
      setStoreLocation(region?.name)
    } else {
      const region = getRegion(locale)
      const countryLocalization = country.find((el)=> el.value === region)
      setStoreLocation(countryLocalization?.name)
    }

  }, [locale, country, subdivisions])


  return (
    <NavStyled role='navigation' ref={navbarRef} $isMenuActive={isActiveModalMenu} $scrollTop={scrollPos === 'TOP'} variants={navVariants({ mq: deviceSize?.width, hasBanners })} initial={'initial'} animate={animate} $boxShadow={pathname !== '/shop/[...slug]'}>
      <ul>
        <li>
          <Burger onClick={toggleModalMenu} menuOpen={isActiveModalMenu} />
        </li>
        <li className='li--search'>
          <ButtonTriggerSearch fillColor={fillColor} />
        </li>
        <li className='li--logo'>
          <Link href='/' title={t('layout.nav.home') as string}>
            <Figure media={logo(fillColor)} fill={false} />
          </Link>
        </li>
        <li className='li--links'>
          <ul>
            <li>
              <Link href={`/shop/${hardcoded}`}>
                {t('layout.nav.shop')}
                <span></span>
              </Link>
            </li>
            <li>
              <Link href='/vi-enology'>
                Vi-enology
                <span></span>
              </Link>
            </li>
            <li>
              <Link href='/account/usual-purchases'>
                Mis productos
                <span></span>
              </Link>
            </li>
          </ul>
        </li>
        <li className='li--location'>
          <button onClick={toggleRegion} title={'Region'} >
            <Figure media={locationIcon} fill={false} />
            <span>{storeLocation}</span>
          </button>
        </li>
        <li className='li--account'>
          <NavLinkAccount fillColor={fillColor} customerData={customerData}/>
        </li>
        <li className='li--cart' onMouseEnter={() => setShowCart(true)}>
          <NavLinkCart isMenuActive={isActiveModalMenu} fillColor={fillColor} />
        </li>
      </ul>
    </NavStyled>
  )
})
