
import { memo } from 'react'
import dynamic from 'next/dynamic'
import { FormStore } from '@/components'
import { Logo } from '../layout'
import type { IMarqueeSlider } from '@dy/commons/components'
import { Main } from './homeStyles'
const DynamicMarqueeSlider = dynamic<IMarqueeSlider>(() => import('@dy/commons/components').then((mod)=> mod.MarqueeSlider), { ssr: false })

export const SectionStorePicker = memo(() => {
  return (
    <Main>
      <Logo fillColor='white'></Logo>
      <DynamicMarqueeSlider data={'Benvenutto a la famiglia'} />
      <FormStore dropdownTextColor='darkred' textColorInput={'white'}/>
    </Main>
  )
})
