import { memo, useMemo } from 'react'
import styled from 'styled-components'
import { useRouter } from 'next/router'
import { mq, vw } from '@dy/commons/styles'
import dynamic from 'next/dynamic'
import type { IMarqueeSlider } from '@dy/commons/components'
import { getLang } from '@dy/commons/utils'

const DynamicMarqueeSlider = dynamic<IMarqueeSlider>(() => import('@dy/commons/components').then((mod)=> mod.MarqueeSlider), { ssr: false })
interface IHeaderShop {
  data: any
}

const Header = styled.header`
  margin-top: 60px;
  overflow-x: hidden;
  
  ${mq.greaterThan('tablet')`
    margin-top: ${vw(120, 'desktop')};
  `}

  .marquee {
    ${mq.greaterThan('tablet')`
      padding: ${vw(30, 'desktop')} 0 ${vw(60, 'desktop')};
    `}
  }
`

export const HeaderShop = memo<IHeaderShop>(({ data }) => {
  const { asPath, locale:localeRouter } = useRouter()
  const locale = getLang(localeRouter)

  const currentPage = useMemo(() => data.find(category => asPath.includes(category.slug[locale])), [data, asPath])

  return currentPage && (
    <Header>
      <DynamicMarqueeSlider data={currentPage?.name} inverted />
    </Header>
  )
})
