import { memo, useMemo, useEffect } from 'react'
import { AnimatePresence } from 'framer-motion'
import { useRouter } from 'next/router'
import { useInView } from 'react-intersection-observer'
import { getLang, pushAnalyticsEvent } from '@dy/commons/utils'
import { useDeviceType } from '@dy/commons/hooks'
import { CategoryCard, ProductCard } from '@dy/commons/components'
import { useTranslator } from '@/hooks'
import { ProductListSection, ProductList, NoProductsMessage } from './shopStyles'
import { listVariants } from './shopVariants'

interface ISectionProductList {
  className?: string,
  products: any,
  readonly setProductDetailed: any,
  readonly productDetailVisible: boolean,
  displayProductDetail: () => void,
  readonly usualPurchases?: boolean,
  readonly packsGrid?: boolean,
  readonly setSelectedCard?: any,
  readonly selectedCard?: string | number | null,
  readonly ga_list?: {
    id: string,
    name: string
  },
  readonly categories?: any
  readonly setShowLargeProductDetail?: any
}

const getProducts = (products, selectedCard, onClick, ga_list, categories) => products.map((product, idx) => {
  if(product.category_card) return <li key={idx}> <CategoryCard data={categories}></CategoryCard></li>
  else {
    return (
      <li key={idx}>
        <ProductCard isLazy={idx > 5} data={{...product, variant: {...product.variant, ga_list, idx: idx + 1}}} selectedCard={selectedCard} onClick={e => {
          e.preventDefault()
          onClick(product, idx)
        }}/>
      </li>
    )
  }
})

const scrollToTopGrid = () => {
  if(typeof window !== 'undefined') {
    const main = document.getElementById('main-top')
    if(main) setTimeout(() => { main.scrollIntoView({ behavior: 'smooth' }) }, 300)
  }
}

export const SectionProductList = memo(({ className = '', products = [], productDetailVisible, setProductDetailed, displayProductDetail, usualPurchases = false, packsGrid = false, selectedCard, setSelectedCard, ga_list = { id: 'list_grid_shop', name: 'List Shop (grid)' }, categories=[], setShowLargeProductDetail}:ISectionProductList) => {
  const { asPath, locale:localeRouter } = useRouter()
  const locale = getLang(localeRouter)
  const { t } = useTranslator()
  const { isMobile } = useDeviceType()
  const [ref, inView] = useInView({
    rootMargin: '-60px 0px 0px',
    threshold: 1,
    initialInView: true
  })

  const onClick = (product, idx) => {
    if(selectedCard !== product.variant.bigcommerce_id) {
      setSelectedCard && setSelectedCard(product.variant.bigcommerce_id)
      setProductDetailed({
        variant_id: product.variant.bigcommerce_id,
        slug: product.slug,
        ga_list,
        idx: idx + 1
      })

      if (!isMobile && idx < 4) {
        scrollToTopGrid()
      }

      displayProductDetail()
    }

    if(typeof window !== 'undefined' && window.location.pathname !== `/product/${product.slug[locale]}`) {
      window.history.pushState('', '', `/product/${product.slug[locale]}`)
    }

    pushAnalyticsEvent('select_item', {...product, idx: idx + 1, ga_list, locale })
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const Products = useMemo(() => getProducts(products, selectedCard, onClick, ga_list, categories), [products, selectedCard, categories])

  useEffect(() => {
    setShowLargeProductDetail && setShowLargeProductDetail(!inView)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView])

  return (
    <ProductListSection className={className} $productDetailVisible={productDetailVisible} $packsGrid={packsGrid} $usualPurchases={usualPurchases}>
      <span ref={ref}></span>
      <AnimatePresence mode={'wait'}>
        {products.length > 0 ?
          <ProductList initial={'inactive'} animate={'active'} exit={'inactive'} variants={listVariants} key={asPath} $productDetailVisible={productDetailVisible} $packsGrid={packsGrid}>
            {Products}
          </ProductList> :
          !usualPurchases ?
            <NoProductsMessage initial={'inactive'} animate={'active'} exit={'inactive'} variants={listVariants} key={asPath}>
              {!usualPurchases ? t('shop.no_filter') : t('shop.no_products')}
            </NoProductsMessage>
            : <></>
        }
      </AnimatePresence>
    </ProductListSection>
  )
})
