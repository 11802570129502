import { getLocaleCountry } from '@dy/commons/utils'

const DY_API_BASE_URL = 'https://cms.hellomamma.eu/api/v1'
// const DY_API_BASE_URL = process.env.DY_API
const NEXT_PUBLIC_LOCALE = process.env.NEXT_PUBLIC_LOCALE
const localeCountry = getLocaleCountry(NEXT_PUBLIC_LOCALE)
const LOCALE = localeCountry.split('-')[0]
const REGION = localeCountry.split('-')[1]
const DY_API_URL = `${DY_API_BASE_URL}/${REGION}/${LOCALE}`

export const endpoints = {
  'layout': (timestamp) => `${DY_API_URL}/layout.json?timestamp=${timestamp}`,
  'search': (query) => `${DY_API_URL}/search?q=${query.q}`,
  'search-products': (query) =>  `${DY_API_URL}/products/search?q=${query.q}`,
  'home': (timestamp) => `${DY_API_URL}/pages/home.json?timestamp=${timestamp}`,
  'categories': (timestamp) => `${DY_API_URL}/categories/product.json?timestamp=${timestamp}`,
  'category' : (query, timestamp) => `${DY_API_URL}/categories/product/${query.slug}.json?timestamp=${timestamp}`,
  'products': (timestamp) => `${DY_API_URL}/products.json?timestamp=${timestamp}`,
  'product': (query, timestamp) => `${DY_API_URL}/products/${query.slug}.json?timestamp=${timestamp}`,
  'relateds': (query, timestamp) => `${DY_API_URL}/products/${query.slug}/related.json?timestamp=${timestamp}`,
  'faqs': (timestamp) => `${DY_API_URL}/faqs.json?timestamp=${timestamp}`,
  'pages': (timestamp) => `${DY_API_URL}/pages.json?timestamp=${timestamp}`,
  'page': (query, timestamp) => `${DY_API_URL}/pages/${query.slug}.json?timestamp=${timestamp}`,
  'user-usual-purchases': (query) => `${DY_API_URL}/customers/${query.customerId}/products`,
}
