import styled from 'styled-components'
import { mq, vw, designGrid, hex2Rgba, getP30, getP26_4, getP93, getP10, getP26_1, getP18_1, getP15, getP20, getP53, getP12_1 } from '@dy/commons/styles'
import { Figure } from '@dy/commons/components'

export const ContentHeader = styled.header`
  ${designGrid({ withoutGutter: true })}
  align-content: flex-end;
  align-items: center;
  padding-top: ${vw(60, 'mobile')};

  ${mq.greaterThan('tablet')`
    padding-top: ${vw(120, 'desktop')};
  `}

  h1, p {
    grid-column: 1 / span 6;
    text-align: center;
    width: 100%;
  }

  h1 {
    ${getP26_4()}
    color: ${({ theme }) => theme.colors.red};
    font-weight: 400;
    margin-bottom: ${vw(30, 'mobile')};
    padding-top: ${vw(50, 'mobile')};

    ${mq.greaterThan('tablet')`
      grid-column: 3 / span 8;
      margin-bottom: ${vw(30, 'desktop')};
      padding-top: ${vw(60, 'desktop')};
    `}
  }

  p {
    ${getP93(50,100)}
    color: ${({ theme }) => theme.colors.darkred};
    margin-bottom: ${vw(50, 'mobile')};

    ${mq.greaterThan('tablet')`
      grid-column: 2 / span 10;
      margin-bottom: ${vw(60, 'desktop')};
    `}
  }
`

export const ContentSection = styled.section`
  display: flex;
  flex-wrap: wrap;
  grid-column: 1 / span 6;
  margin-bottom: ${vw(53, 'mobile')};
  padding: ${vw(20, 'mobile')};

  ${mq.greaterThan('tablet')`
    grid-column: 3 / span 8;
    margin-bottom: ${vw(60, 'desktop')};
    padding: 0;
  `}

  > article {
    &.media {
      width: 100%;
    }

    &.text {
      ${mq.greaterThan('tablet')`
        padding: 0 ${vw(120, 'desktop')};
      `}
    }
  }

  .spacer {
    height: ${vw(40, 'mobile')};

    ${mq.greaterThan('tablet')`
      height: ${vw(50, 'desktop')};
    `}
  }
`

export const ContentArticle = styled.article`
  display: flex;
  justify-content: center;

  &.media {
    margin: 0 auto;
  }
`

export const ImageContent = styled(Figure)<any>`
  height: auto;
  width: ${({ size }) => size === 'small' ? `${vw(69 * 4, 'mobile')}` : size === 'medium' ? `${vw(69 * 6, 'mobile')}` : '100%'};

  ${mq.greaterThan<any>('tablet')`
    width: ${({ size }) => size === 'small' ? `${vw(120 * 4, 'desktop')}` : size === 'medium' ? `${vw(120 * 8, 'desktop')}` : '100%'};
  `}

  > span {
    position: relative !important;
    height: auto;
    width: 100%;
  }

  img {
    position: relative !important;
    height: 100% !important;
    width: 100% !important;
  }

  figcaption {
    color: ${({ theme }) => theme.colors.darkred};
    font-family: ${({ theme }) => theme.fonts.platform};
    font-style: normal;
    ${getP10()}
    opacity: .5;
    text-align: center;
    text-transform: uppercase;
  }
`

export const TextContent = styled.div<any>`
  font-family: ${({ theme }) => theme.fonts.basier};
  color: ${({ color, theme }) => color ? color : theme.colors.darkred};
  text-align: ${({ align }) => align && align.h ? align.h : 'left'};
  word-break: break-word;
  width: 100%;

  h2, h3, h4,
  p, ul, ol, dl,
  q, a {
    color: inherit;
    text-align: inherit;
    word-break: inherit;
  }

  p, ul, ol, dl, q, a {
    color: ${({ theme }) => theme.colors.darkred};
  }

  h2, h3, h4, h5, h6 {
    color: ${({ theme }) => theme.colors.red};
  }

  h2, h3, h4 {
    font-weight: 500;
    margin-bottom: ${vw(16, 'mobile')};

    ${mq.greaterThan('tablet')`
      margin-bottom: ${vw(20, 'desktop')};
    `}

    + p {
      margin-top: -${vw(10, 'mobile')};

      ${mq.greaterThan('tablet')`
        margin-top: -${vw(10, 'desktop')};
      `}
    }
  }

  h1, h2 {
    ${getP30()}

    br {
      + p, ul, ol, dl, h2, h3, h4, h5, h6, a {
        margin-top:  ${vw(15, 'mobile')};

        ${mq.greaterThan('tablet')`
          margin-top: ${vw(20, 'desktop')};
        `}
      }
    }
  }

  h3 {
    ${getP26_1()}

    br {
      + p, ul, ol, dl, h2, h3, h4, h5, h6, a {
        margin-top:  ${vw(15, 'mobile')};

        ${mq.greaterThan('tablet')`
          margin-top: ${vw(15, 'desktop')};
        `}
      }
    }
  }

  h4 {
    ${getP18_1()}
  }

  h5 {
    ${getP15()}
  }

  h4, h5 {
    br {
      + p, ul, ol, dl, h2, h3, h4, h5, h6, a {
        margin-top:  ${vw(10, 'mobile')};

        ${mq.greaterThan('tablet')`
          margin-top: ${vw(10, 'desktop')};
        `}
      }
    }
  }

  h6 {
    ${getP12_1()}

    br {
      + p, ul, ol, dl, h2, h3, h4, h5, h6, a {
        margin-top:  ${vw(8, 'mobile')};

        ${mq.greaterThan('tablet')`
          margin-top: ${vw(8, 'desktop')};
        `}
      }
    }
  }

  p {
    ${getP18_1()}

    br {
      content: '';
      display: block;
      margin-bottom:  ${vw(10, 'mobile')};

      ${mq.greaterThan('tablet')`
        margin-bottom: ${vw(15, 'desktop')};
      `}
    }
  }

  ul, ol, dl {
    ${getP20()}
    font-weight: normal;
    margin-bottom: ${vw(48, 'mobile')};

    ${mq.greaterThan('tablet')`
      margin-bottom: ${vw(60, 'desktop')};
    `}

    &:last-child {
      margin-bottom: 0;
    }

    br {
      + p, ul, ol, dl, h2, h3, h4, h5, h6, a {
        margin-top:  ${vw(10, 'mobile')};

        ${mq.greaterThan('tablet')`
          margin-top: ${vw(15, 'desktop')};
        `}
      }
    }
  }




  br {
    + p, ul, ol, dl, h2, h3, h4, h5, h6, a {
      ${getP20()}
    }
  }

  ul, ol {
    padding-left: 18px;
  }

  ul {
    li {
      list-style-type: disc;
    }
  }

  ol {
    li {
      list-style-type: decimal;
    }
  }

  q, blockquote {
    border-left: 0;
    color: ${({ theme }) => theme.colors.red};
    ${getP53()}
    display: block;
    font-weight: bold;
    font-style: normal;
    margin: auto;
    padding: ${vw(12, 'mobile')} ${vw(20, 'mobile')} 0;
    position: relative;
    text-align: center;
    width: 100%;

    ${mq.greaterThan('tablet')`
      padding: ${vw(20, 'desktop')} 0 ${vw(20, 'desktop')};
      transform: translateX(-${vw(120, 'desktop')});
      width: calc(100% + ${vw(120 * 2, 'desktop')});
    `}

    &:before,
    &:after {
      content: '';
      background-image: url('/images/svg/i--double-quotes.svg');
      background-repeat: no-repeat;
      display: inline-block;
      height: ${vw(33, 'mobile')};
      opacity: .2;
      position: absolute;
      user-select: none;
      width: ${vw(47, 'mobile')};

      ${mq.greaterThan('tablet')`
        height: ${vw(66, 'desktop')};
        width: ${vw(94, 'desktop')};
      `}
    }

    &:before {
      left: ${vw(20, 'mobile')};
      top: -${vw(10, 'mobile')};
      transform: rotate(180deg);

      ${mq.greaterThan('tablet')`
        left: ${vw(30, 'desktop')};
        top: -${vw(10, 'desktop')};
      `}
    }

    &:after {
      right: ${vw(20, 'mobile')};
      bottom: -${vw(10, 'mobile')};

      ${mq.greaterThan('tablet')`
        bottom: 0;
        right: ${vw(30, 'desktop')};
      `}
    }

    > p {
      color: ${({ theme }) => theme.colors.red};
      display: inline;
      ${getP53()}
      font-family: ${({ theme }) => theme.fonts.platform};
      font-style: normal;
      text-align: center;
    }
  }

  table {
    border-collapse: collapse;
    width: 100%;

    th,
    td {
      ${getP20()}
      padding: ${vw(20, 'mobile')} ${vw(5, 'mobile')};

      ${mq.greaterThan('tablet')`
        padding: ${vw(30.5, 'desktop')} ${vw(10, 'desktop')};
      `}

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }

    th {
      color: ${({ theme }) => theme.colors.text};
      border-bottom: 1px solid ${({ theme }) => hex2Rgba(theme.colors.text, .6)};
    }

    tbody, tfoot {
      td {
        border-bottom: 1px solid ${({ theme }) => hex2Rgba(theme.colors.text, .1)};
        color: ${({ theme }) => hex2Rgba(theme.colors.text, .6)};

        ${mq.greaterThan('tablet')`
          max-width: ${vw(490, 'desktop')};
          vertical-align: initial;
        `}
      }

      tr {
        &:last-child {
          td {
            border-color: ${({ theme }) => hex2Rgba(theme.colors.text, 0)};
          }
        }
      }
    }
  }
`

export const VideoContent = styled.figure<any>`
  height: auto;
  position: relative;
  width: 100%;

  img {
    width: 100%;
  }

  figcaption {
    ${getP12_1()}
    border-bottom: ${({ theme }) => hex2Rgba(theme.colors.black, .1)};
    padding-bottom: ${vw(20, 'mobile')};

    ${mq.greaterThan('tablet')`
      padding-bottom: ${vw(20, 'desktop')};
    `}
  }

  > div {
    height:  ${({ longModal }) => longModal ? '56.25vw' : `${vw(232, 'mobile')}`} !important;
    pointer-events: ${({ longModal }) => longModal ? 'auto' : 'none'};
    width: 100% !important;

    ${mq.greaterThan<any>('tablet')`
      height:  ${({ longModal }) => longModal ? '56.25vw' : `${vw(540, 'desktop')}`} !important;
      max-height: 100vh;
    `}
  }
`

export const PlayIcon = styled.button<any>`
  align-items: center;
  border-radius: 100%;
  display: flex;
  height: ${vw(90, 'mobile')};
  justify-content: center;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: height 300ms ${({ theme }) => theme.ease}, width 300ms ${({ theme }) => theme.ease};
  width: ${vw(80, 'mobile')};
  z-index: 2;

  ${mq.greaterThan('tablet')`
    height: ${vw(120, 'desktop')};
    width: ${vw(110, 'desktop')};

    &:hover {
      img {
        &:first-child {
          width: ${vw(110, 'desktop')};
        }
        &:nth-child(2) {
          width: ${vw(82, 'desktop')};
        }
      }
    }
  `}

  span {
    ${getP10()}
    color: #ffffff;
    left: 50%;
    position: absolute;
    text-transform: uppercase;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
  }

  img {
    left: 50%;
    opacity: .5;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: 300ms width ${({ ease }) => ease };
    user-select: none;
    width: ${vw(73, 'mobile')};

    &:first-child {
      transition-delay: 150ms;
    }

    ${mq.greaterThan('tablet')`
      width: ${vw(91, 'desktop')};
    `}

    &.xs {
      opacity: 1;
      width: ${vw(50, 'mobile')};
      z-index: 2;

      ${mq.greaterThan('tablet')`
        width: ${vw(63, 'desktop')};
      `}
    }
  }
`

export const ShopSection = styled.section<any>`
  ${designGrid({ withoutGutter: true })}
  padding: 0;
  position: relative;
  grid-column: 1 / span 6;

  #main-top {
    display: block;
    height: 2px;
    opacity: .01;
    position: absolute;
    top: 0;
    transform: translateY(-80px);
    user-select: none;
    width: 100%;
  }

  ${mq.greaterThan('tablet')`
    grid-column: 1 / span 12;
  `}
`