import { getTransition } from '@dy/commons/styles'

export const filtersFeaturedVariants = {
  initial: {
    height: 0,
    opacity: 0,
    transition: {
      ...getTransition(),
      delay: 0,
    }
  },
  animate: {
    height: 'auto',
    opacity: 1,
    transition: {
      ...getTransition(),
      duration: .5,
      delay: .4,
    }
  },
  exit: {
    height: 0,
    opacity: 0,
    transition: {
      ...getTransition(),
      duration: .2,
      delay: 0,
    }
  },
}

export const listVariants = {
  active: {
    opacity: 1,
    transition: {
      ...getTransition()
    }
  },
  inactive: {
    opacity: 0,
    transition: {
      ...getTransition()
    }
  }
}

export const accordionVariants = {
  initial: {
    height: '0px',
    opacity: 0,
    transition: {
      ...getTransition(),
      delay: 0,
    }
  },
  animate: {
    height: 'auto',
    opacity: 1,
    transition: {
      ...getTransition(),
      duration: .3,
      delay: .25,
    }
  },
  exit: {
    height: '0px',
    opacity: 0,
    transition: {
      ...getTransition(),
      duration: .2,
      delay: 0,
    }
  },
}

export const featured_filters_variants = {
  initial: {
    height: '0px',
    opacity: 0,
    transition: {
      ...getTransition(),
      delay: 0,
    }
  },
  animate: {
    height: 'auto',
    opacity: 1,
    transition: {
      ...getTransition(),
      duration: .5,
      delay: .4,
    }
  },
  exit: {
    height: '0px',
    opacity: 0,
    transition: {
      ...getTransition(),
      duration: .2,
      delay: 0,
    }
  },
}