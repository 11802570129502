import { memo, useMemo } from 'react'
import { useInView } from 'react-intersection-observer'
import { useRouter } from 'next/router'
import { SliderFlickity, ProductCard } from '@dy/commons/components'
import { useTranslator } from '@/hooks'
import { Dt, SectionRel } from './detailStyles'

interface ISectionRelated {
  data: any,
}


const getSlides = (products) => products.map((product, idx) => {
  return (
    <dd key={idx}>
      <ProductCard data={{...product, variant: {...product.variant, idx: idx + 1}}} narrow />
    </dd>
  )
})

export const SectionRelated = memo<ISectionRelated>(({ data }) => {
  const Slides = useMemo(() => getSlides(data), [data])
  const { t } = useTranslator()
  const router = useRouter() || { push: () => {} }
  // const [ref, inView] = useInView({
  const [ref,] = useInView({
    rootMargin: '20px', // TOP  RIGHT
    threshold: 0,
    triggerOnce: true
  })

  const staticClick = (event, pointer, cellElement, cellIndex) => {
    console.log(`${event}, ${pointer}, ${cellElement}`)
    router.push(`/product${data[cellIndex].bigcommerce_slug}`)
  }

  return (
    <SectionRel ref={ref}>
      <dl>
        <Dt><h2>{t('product_page.bottom-page.related')}</h2></Dt>
        <SliderFlickity className={'slider--product-card'} loop={false} pageDots={false} groupCells={'90%'} cellAlign={'left'} onStaticClick={staticClick} draggable={'>2'} buttons={true}>
          {Slides}
        </SliderFlickity>
      </dl>
    </SectionRel>
  )
})
