import { useMemo } from 'react'
import { ContentImage, ContentText, ContentVideo } from './content-types'
import { ContentArticle } from './contentStyles'

const MediaTypes = {
  'image': (data) => (<ContentImage data={data} />),
  'video': (data) => (<ContentVideo data={data} />)
}

const ContentsTypes = {
  'text': (data) => (<ContentText data={data} />),
  'media': (data) => (MediaTypes[data.media.type](data)),
  'empty': () => (<div className='empty'></div>),
  'spacer': () => (<div className='spacer'></div>)
}

const getContentByType = (content) => {
  const { type } = content || {}
  if (!content || !type || !ContentsTypes[type]) return <></>
  return (ContentsTypes[type](content))
}

export const Content = ({ content }) => {
  const ContentByType = useMemo(() => getContentByType(content), [content])

  return (
    <ContentArticle className={content.type}>
      {ContentByType}
    </ContentArticle>
  )
}
