"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModalsProvider = exports.ModalsContext = exports.MODALS = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
var MODALS;
(function (MODALS) {
    MODALS["ACCOUNT"] = "modal_account";
    MODALS["ADD_TO_CART"] = "modal_add_to_cart";
    MODALS["AUTH"] = "modal_auth";
    MODALS["CART"] = "modal_cart";
    MODALS["COOKIES"] = "modal_cookies";
    MODALS["MENU_ACCOUNT"] = "modal_account";
    MODALS["MENU"] = "modal_menu";
    MODALS["PROMO"] = "modal_promo";
    MODALS["REGION_LANG"] = "modal_region_lang";
    MODALS["SEARCH"] = "modal_search";
    MODALS["WELCOME"] = "modal_welcome";
    MODALS["COMERCIAL"] = "modal_comercial_region";
    MODALS["NEWSLETTER"] = "modal_newsletter";
    MODALS["FILTER"] = "modal_filters";
    MODALS["VIDEO"] = "modal_video";
})(MODALS = exports.MODALS || (exports.MODALS = {}));
const initialModalsState = {
    modals: {},
    setModal: () => { },
    someModalIsActive: false
    //someModalIsActive: boolean at least one modal is Active ?
};
exports.ModalsContext = (0, react_1.createContext)(initialModalsState);
const ModalsProvider = ({ children }) => {
    const [modals, setModals] = (0, react_1.useState)({});
    const [someModalIsActive, setSomeModalIsActive] = (0, react_1.useState)(false);
    const setModal = (modal) => {
        // console.log('☀️ PROVIDER | new arriving modal', modal)
        setModals(prevModals => (Object.assign(Object.assign({}, prevModals), modal)));
        let someActive = false;
        if (modal.isActive)
            someActive = true;
        else {
            for (const [, m] of Object.entries(modals)) {
                if (m.isActive) {
                    someActive = true;
                    break;
                }
            }
        }
        setSomeModalIsActive(someActive);
    };
    return ((0, jsx_runtime_1.jsx)(exports.ModalsContext.Provider, Object.assign({ value: { modals, setModal, someModalIsActive } }, { children: children })));
};
exports.ModalsProvider = ModalsProvider;
