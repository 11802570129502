'use-client'
import { useEffect, useMemo, useState } from 'react'
import { useRouter } from 'next/router'
import type { SubmitHandler } from 'react-hook-form'
import { useForm } from 'react-hook-form'
import styled from 'styled-components'
import { Input, Select, Checkbox, Button, TextArea } from '@dy/commons/components'
import { Form as FormStyled } from '@dy/commons/components'
import { useTranslator } from 'hooks/useTranslator'
import { getLocaleCountry, inputPattern} from '@dy/commons/utils'

type TypeFormAddressInputs = {
  firstName: string
  lastName: string,
  countryCode: string,
  province: string,
  city: string,
  streetLine: string,
  streetLine2?: string,
  postalCode: string,
  phone: string,
  comments?: string
  cif?: string,
  companyName?: string
}

const Form = styled(FormStyled)`
  fieldset {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }

  button[type='submit'] {
    margin-bottom: 5px;
  }
`

export const FormShippingAddress = () => {
  const { register, handleSubmit, formState: { errors:formErrors }, setValue } = useForm<TypeFormAddressInputs>()
  const { t } = useTranslator()
  const { locale } = useRouter()

  const countryCode = useMemo(() => getLocaleCountry(locale).split('-')[1], [locale])

  const [provinces, setProvinces] = useState([])

  const onSubmit: SubmitHandler<TypeFormAddressInputs> = async (data, e) => {
    console.log('formShippingAddress onSubmit - data:', data)
    console.log('formShippingAddress onSubmit - e:', e)
  }

  const onSubmitError = (errors, e) => {
    console.warn('🟡 Errors found before Submit')
    console.log(errors, e)
  }

  useEffect(() => {
    async function getProvinces (countryCode) {
      let data = await import(`/public/locales/countries/${countryCode}-provinces.json`)
      console.log('data', Array.from(data))
      if(data) setProvinces(Array.from(data))
    }

    if(provinces?.length === 0 && typeof locale === 'string') {
      if(typeof countryCode === 'string' && countryCode !== 'EU') getProvinces(countryCode)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale])

  return (
    <Form onSubmit={handleSubmit(onSubmit, onSubmitError)}>
      <fieldset>
        <Input name={'firstName'} col={6} register={register} label={t('commons.form.fields.first_name') as string} required error={formErrors.firstName} />
        <Input name={'lastName'} col={6} register={register} label={t('commons.form.fields.last_name') as string} required error={formErrors.lastName} />
        {/* 3 SELECTS HERE */}
        <Input name='countryCode' col={2} label={t('commons.form.fields.country') as string} readOnly={true} defaultValue='ES' required />
        <Select name='provinceCode' col={5} label={t('commons.form.fields.province') as string} options={provinces} register={register} setFormValue={setValue}/>

        <Input name='cityCode' col={5} register={register} label={t('commons.form.fields.city') as string} />
        <Input name='postalCode' col={2} register={register} label={t('commons.form.fields.postal_code') as string} required={{
          required: true,
          pattern: inputPattern.postalCode[countryCode]
        }} error={formErrors.postalCode} />
        <Input name='streetLine' col={5} register={register} label={t('commons.form.fields.street_line1') as string} required error={formErrors.streetLine} />
        <Input name='streetLine2' col={5} register={register} label={t('commons.form.fields.street_line2') as string} error={formErrors.streetLine2} />
        <Input name='phone' col={3} register={register} label={t('commons.form.fields.phone') as string}  required error={formErrors.phone} />
        {/* 1 TEXT AREA HERE (PENDING IMPLEMENTATION) */}
        <TextArea name='comments' col={12} register={register}label='Comentarios'/>
        {/* <Input type='text' name='comments' register={register} label={t('commons.form.fields.phone') as string}  required error={formErrors.phone} /> */}
        <Checkbox name='sameForBilling' col={12} register={register} label={t('commons.form.fields.shipping_as_billing') as string} />
        <Checkbox name='im_a_company' col={12} register={register} label={t('commons.form.fields.im_a_company') as string} />

        {/* <Input name='cif' col={3} register={register} label={t('commons.form.fields.cif') as string} required={{
          required: true,
          // cif validation only available for Spain by now
          ...countryCode === 'ES' && {
            validate: (value) => validate.cif(value) || (t('commons.form.errors.validate_cif') as string)
          }
        }} error={formErrors.cif} />
        <Input name='companyName' col={3} register={register} label={t('commons.form.fields.company_name') as string} required error={formErrors.companyName} /> */}
      </fieldset>
      <Button type='submit' bgType={'darkred'}>{t('commons.actions.continue')}</Button>
    </Form>
  )
}
