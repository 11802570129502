import { FC, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { Link } from '@dy/commons/components'
import { mq, vw } from '@dy/commons/styles'

const fakeData = [
  {
    name: 'Nuovissimo',
    href: {
      pathname: '/shop/[...slug]',
      query: { slug: 'shop-slug-here' }
    }
  },
  {
    name: 'Promociones',
    href: {
      pathname: '/shop/[...slug]',
      query: { slug: 'shop-slug-here' }
    }
  },
  {
    name: 'Top ventas',
    href: {
      pathname: '/shop/[...slug]',
      query: { slug: 'shop-slug-here' }
    }
  },
  {
    name: 'Te recomendamos',
    href: {
      pathname: '/shop/[...slug]',
      query: { slug: 'shop-slug-here' }
    }
  },
  {
    name: 'Despensa',
    href: {
      pathname: '/shop/[...slug]',
      query: { slug: 'shop-slug-here' }
    }
  },
  {
    name: 'Frescos',
    href: {
      pathname: '/shop/[...slug]',
      query: { slug: 'shop-slug-here' }
    }
  },
  {
    name: 'Bebidas y cafés',
    href: {
      pathname: '/shop/[...slug]',
      query: { slug: 'shop-slug-here' }
    }
  },
  {
    name: 'Vinos',
    href: {
      pathname: '/shop/[...slug]',
      query: { slug: 'shop-slug-here' }
    }
  },
  {
    name: 'Libros',
    href: {
      pathname: '/shop/[...slug]',
      query: { slug: 'shop-slug-here' }
    }
  },
  {
    name: 'Quesos',
    href: {
      pathname: '/shop/[...slug]',
      query: { slug: 'shop-slug-here' }
    }
  }
]

const Dl = styled.dl`
  display: none;
  grid-column: 1 / span 6;
  margin-bottom: 0;
  max-height: 100%;
  padding: ${vw(20, 'mobile')} 0;

  ${mq.greaterThan('tablet')`
    align-items: flex-start;
    align-self: flex-start;
    display: flex;
    flex-wrap: wrap;
    grid-column: 7 / span 5;
    grid-row: 2 / span 1;
    justify-content: center;
    overflow: auto;
    padding: ${vw(50, 'desktop')} 0;
  `}

  ${mq.greaterThan('desktop')`
    padding: 50px 0;
  `}

  dt {
    color: ${({ theme }) => theme.colors.darkred};
    font-family: ${({ theme }) => theme.fonts.secondaryFont};
    font-weight: 300;
    margin-bottom: ${vw(60, 'mobile')};
    text-align: center;
    width: 100%;

    ${mq.greaterThan('tablet')`
      margin-bottom: ${vw(60, 'desktop')};
    `}

    ${mq.greaterThan('desktop')`
      margin-bottom: 60px;
    `}
  }
`

const Dd = styled.dd`
  margin-bottom: ${vw(20, 'mobile')};

  ${mq.greaterThan('tablet')`
    margin-bottom: ${vw(20, 'desktop')};
  `}

  ${mq.greaterThan('desktop')`
    margin-bottom: 20px;
  `}

  a {
    align-items: center;
    color: ${({ theme }) => theme.colors.red};
    display: flex;
    font-weight: bold;
    height: ${vw(40, 'mobile')};
    padding: 0 ${vw(25, 'mobile')};
    text-decoration: none;
    width: auto;

    ${mq.greaterThan('tablet')`
      height: ${vw(40, 'desktop')};
      padding: 0 ${vw(25, 'desktop')};
    `}

    ${mq.greaterThan('desktop')`
      height: 40px;
      padding: 0 25px;
    `}
  }
`

const getListItems = (data) => data.map((item, idx) => (
  <Dd key={`${idx}-result`}>
    <Link href={item.href}>
      {item.name}
    </Link>
  </Dd>
))

export const SearchByCategory: FC = () => {
  const [data, setData] = useState([])
  const ListItems = useMemo(() => getListItems(data), [data])

  useEffect(() => {
    setData(fakeData)
  }, [])

  return (
    <Dl>
      <dt>Buscar por categoría:</dt>
      {ListItems}
    </Dl>
  )
}
