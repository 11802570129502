import { memo, useMemo } from 'react'
import { useRouter } from 'next/router'
import { currencyFormatter } from '@dy/commons/utils'
import { ProductInfoWrapper } from '../homeStyles'

interface ProductsInfo {
    products: any
  }


const getProduct = (products, locale) => products.map((product, idx)=> {
  const {variantId, name, amount, brand, pricePerWeight, price } = product
  const currencyPrice = currencyFormatter({price: price, localeRegion:locale})

  return (
    <span key={idx-variantId}>{amount} {name} {brand} {pricePerWeight} {currencyPrice}</span>
  )
})


export const ProductsInfo = memo<ProductsInfo>(({ products }) => {
  const { locale } = useRouter()
  const Products = useMemo(() => getProduct(products, locale), [products, locale])
  return (
    <>
      <ProductInfoWrapper>
        <ul>
          {Products}
        </ul>
      </ProductInfoWrapper>
    </>
  )})