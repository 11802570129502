import { memo, useEffect, useMemo, useState } from 'react'
import type { FC } from 'react'
import type { NextPage } from 'next'
import { useRouter } from 'next/router'
import styled from 'styled-components'
import { fetchAPI } from '@/api'
import { mq, vw, designGrid } from '@dy/commons/styles'
import { Layout, DetailHeader, SectionNutritionChart, SectionIngredients, SectionMoreInfo, SectionRelated, NavShop } from '@/components'
import type { TypeProductPage } from '@dy/commons/schema'
import { HeadTag } from '@dy/commons/components'
import { getLang } from '@dy/commons/utils'

const ProductWrapper = styled.div<any>`
  background-color: ${({ theme }) => theme.colors.white};
  margin: ${({ isPDP }) => isPDP ? '60px 20px 200px' : '60px auto 200px'};
  padding: ${vw(20, 'mobile')};
  width: 90vw;

  ${mq.greaterThan<{isPDP: boolean}>('tablet')`
    border-radius: 20px;
    margin: ${({ isPDP }) => isPDP ? `${vw(120, 'desktop')} ${vw(90, 'desktop')} 200px` : `${vw(120, 'desktop')} auto 200px`};
    padding: ${vw(30, 'desktop')};
    width: ${vw(580, 'desktop')};
  `}
`

const Main = styled.main`
  ${designGrid({withoutGutter:true})}
  padding: 0;
  position: relative;

  #main-top {
    background-color: ${({ theme }) => theme.colors.background};
    display: block;
    height: 2px;
    opacity: .01;
    position: absolute;
    top: 120px;
    user-select: none;
    width: 100%;

    ${mq.greaterThan('tablet')`
      transform: translateY(-${vw(20, 'desktop')});
    `}
  }
  section:not( div > section) {
    margin: unset;
    ${mq.greaterThan('tablet')`
      margin: ${vw(120, 'desktop')} 0;
    `}
  }
`

export async function getStaticPaths({ locales }) {
  const [data, error] = await fetchAPI('products')
  if (error) return { paths: [], fallback: true }

  const paths = []
  for(let locale of locales) {
    if (locale === 'en-EU') continue
    if (locale === 'es-MD' || locale === 'es-CT' || locale === 'ca-CT' || locale === 'es-CN') continue
    const lang = locale.split('-')[0]

    for(let product of data) {
      const slug = product.slug[lang]
      const params = { slug, locale: lang }
      if(slug) paths.push({ params })
    }
  }

  return { paths, fallback: 'blocking' }
}

export async function getStaticProps({ params }) {
  const { slug } = params
  const [data, error] = await fetchAPI('product', { slug })
  const [layout, layoutError] = await fetchAPI('layout')

  if(error || layoutError) return { notFound: true }

  return {
    props: {
      data,
      layout
    },
    revalidate: 300, // In seconds
  }
}
const ConditionalWrapper = ({ condition, wrapper, children }) => condition ? wrapper(children) : children

const ProductPage: NextPage<TypeProductPage> & { Layout?:FC } = memo(({ data, variant = null, setSelectedCard = null }) => {
  const { locale:localeQuery, pathname, isFallback } = useRouter()
  const locale = getLang(localeQuery)

  const isPDP = pathname === '/product/[slug]'
  const round = false
  const [productData, setProductData] = useState(data)
  const [currentVariant, setCurrentVariant] = useState(isPDP ? data?.variants?.[0] : null)
  const [relateds, setRelateds] = useState([])
  const [categoriesMenu, setCategoriesMenu] = useState([])

  const { bigcommerce_id, name, brand, pack, slug, filters, description, nutrition_specifications:nutrition, ingredients, info_specifications:info, variants, labels, seo } = productData || {}

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const header = useMemo(() => ({ bigcommerce_id, name, brand, filters, description, variant: currentVariant, variants }), [productData, currentVariant])
  // useEffect for PDP nested in the grid page
  useEffect(() => {
    async function getProductData() {
      const [data, error] = await fetchAPI('product', { slug: variant.slug[locale] })
      if(!error) setProductData(data)
    }

    if(variant) getProductData()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variant])

  // updating currentVariant
  useEffect(() => {
    if(productData) {
      setCurrentVariant(variant ? variants.filter((variantItem => variantItem.bigcommerce_id === variant.variant_id))[0] : variants[0])
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productData])

  useEffect(() => {
    async function getCategoriesMenu() {
      const [data, error] = await fetchAPI('categories')
      if(!error) setCategoriesMenu(data)
    }

    getCategoriesMenu()
  }, [])

  useEffect(() => {
    async function getRelateds() {
      const [data, error] = await fetchAPI('relateds', { slug: productData.slug[locale] })
      if(!error) setRelateds(data)
    }

    if(productData) getRelateds()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productData])

  useEffect(() => {
    if(variant && currentVariant) {
      setSelectedCard && setSelectedCard(currentVariant.bigcommerce_id)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentVariant])

  if(isFallback) return <></>
  if(!data && isPDP || !data && !variant && !isPDP) return null
  if(!productData || !currentVariant) return null

  return (
    <>
      {data && <HeadTag seo={seo} jsonld={{ data:data, pageType: 'product' }} slug={slug['es']} pageType={'product'}/>}
      <ConditionalWrapper condition={isPDP} wrapper={children =>
        <Main>
          <span id='main-top'></span>
          <NavShop categories={categoriesMenu} productDetailVisible={false}/>
          {children}
        </Main>}>
        <ProductWrapper {...(slug && { id: typeof slug === 'string' ? slug : slug['es'] })} isPack={pack} isPDP={isPDP}>
          <DetailHeader data={header} labels={labels} currentVariant={currentVariant} setCurrentVariant={setCurrentVariant} isPack={pack} round={round} />
          <ConditionalWrapper condition={!isPDP} wrapper={children =>
            <main>
              {children}
            </main>
          }>
            {!pack && nutrition?.length > 0 && <SectionNutritionChart data={nutrition} />}
            {!pack && ingredients?.length > 0 && <SectionIngredients data={ingredients} />}
            {info?.length > 0  && <SectionMoreInfo data={info} />}
            {relateds?.length > 0 && <SectionRelated data={relateds}/>}
            {currentVariant?.products?.length > 0 && <SectionRelated data={currentVariant.products} />}
          </ConditionalWrapper>
        </ProductWrapper>
      </ConditionalWrapper>
    </>
  )
})

ProductPage.Layout = Layout
export default ProductPage
