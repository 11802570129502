import styled from 'styled-components'
import { mq, vw, createUnderline, getP30, getP20_1, getP20_2, getP18_1, getP15_1B, getP18_3, getP12, getP12_1, getP53, getP15_1, hex2Rgba} from '@dy/commons/styles'
import { motion } from 'framer-motion'

export const Header = styled.header<any>`
  h1 {
    ${getP30()}
    color: ${({ theme }) => theme.colors.red};
    font-family: ${({ theme }) => theme.fonts.secondaryFont};
    margin-bottom: ${({ isPack }) => vw(isPack ? 42 : 19 , 'mobile')};
    text-align: left;

    ${mq.greaterThan<any>('tablet')`
      margin-bottom: ${({ isPack }) => isPack ? `${vw(10, 'desktop')}` : '0'};
    `}
  }

  .description-clamp {
    ${getP18_3()}
    color: ${({ theme }) => theme.colors.red};
    height: ${vw(154, 'mobile')};
    margin: ${vw(40, 'mobile')} 0 ${vw(60, 'mobile')};
    overflow: hidden;

    ${mq.greaterThan('tablet')`
      ${getP20_1()}
      height: ${vw(182, 'desktop')};
      margin: ${vw(40, 'desktop')} 0 ${vw(80, 'desktop')};
    `}

    button {
      ${getP18_3()}
      color: ${({ theme }) => theme.colors.red};
      transition: 200ms opacity ease-in-out;
      ${createUnderline({ reverse: true, color: 'red' })}

      &:hover {
        opacity: 1;
      }

      ${mq.greaterThan('tablet')`
        ${getP20_1()}
      `}
    }
  }

  .last_units {
    ${getP20_2()}
    color: ${({ theme }) => theme.colors.red};
    display: block;
    margin-bottom: ${vw(10, 'mobile')};

    ${mq.greaterThan('tablet')`
      margin-bottom: ${vw(10, 'desktop')};
  `}
  }
`

export const Brand = styled.span`
  ${getP18_1()}
  ${createUnderline({ height: 0.5, color: 'red' })}
  color: ${({ theme }) => theme.colors.red};
  margin-bottom: ${vw(20, 'mobile')};

  ${mq.greaterThan('tablet')`
    ${getP20_1()}
    margin-bottom: ${vw(10, 'desktop')};
  `}
`

export const ProductInfo = styled(motion.div)`
  display: flex;
  font-family: ${({ theme }) => theme.fonts.basier};
  justify-content: center;
  margin-bottom: ${vw(20, 'mobile')};

  ${mq.greaterThan('tablet')`
    margin-bottom: ${vw(20, 'desktop')};
  `}

  &.single-variant {
    display: block;

    label {
      ${getP15_1B()}
      font-weight: 600;
    }
  }

  label {
    ${getP12()}
    font-weight: 500;
  }

  div {
    display: flex;
    align-items: center;
    position: relative;
    margin: 0 ${vw(25, 'mobile')};

    ${mq.greaterThan('tablet')`
      margin: 0 ${vw(25, 'desktop')};
    `}
  }

  input {
    position: absolute;
    margin: 0;
    opacity: 0;
  }
`

export const FlexWrap = styled.div`
  align-items: stretch;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 0 ${vw(40, 'mobile')};
  row-gap: ${vw(20, 'mobile')};

  ${mq.greaterThan('tablet')`
    margin: 0 0 ${vw(40, 'desktop')};
    row-gap: ${vw(20, 'desktop')};
  `}

  button {
    ${getP15_1B()}
  }
`

export const MediaWrapper = styled.div`
  position: relative;
`

export const LabelWrapper = styled.div`
  display: flex;
  position: absolute;
  top: ${vw(15, 'mobile')};

  ${mq.greaterThan('tablet')`
    top: ${vw(15, 'desktop')};
  `}

  span {
    ${getP12_1()}
    background-color: ${({ theme }) => theme.colors.darkcreme};
    border-radius: ${vw(12, 'mobile')};
    color: ${({ theme }) => theme.colors.white};
    margin-right: ${vw(5, 'mobile')};
    padding: ${vw(5, 'mobile')} ${vw(8, 'mobile')};
    text-transform: uppercase;
    z-index: 1;

    ${mq.greaterThan('tablet')`
      border-radius: ${vw(12, 'desktop')};
      margin-right: ${vw(5, 'desktop')};
      padding: ${vw(5, 'desktop')} ${vw(8, 'desktop')};
    `}
  }

  .fresh {
    text-transform: none;
  }

  .discount {
    background-color: ${({ theme }) => theme.colors.red};
  }

  .filter-label {
    background-color: unset;
    height: ${vw(24, 'mobile')};
    margin-right: ${vw(5, 'mobile')};
    width: ${vw(24, 'mobile')};
    z-index: 1;

    ${mq.greaterThan<any>('tablet')`
      height: ${vw(24, 'desktop')};
      margin-right: ${vw(5, 'desktop')};
      width: ${vw(24, 'desktop')};
    `}
  }
`

export const H2 = styled.h2`
  ${getP53()}
  color: ${({ theme }) => theme.colors.darkred};
  margin: ${vw(60, 'mobile')} 0 ${vw(20, 'mobile')};

  ${mq.greaterThan('tablet')`
    margin: ${vw(80, 'desktop')} 0 ${vw(20, 'desktop')};
  `}
`

export const P = styled.p`
  ${getP18_1()}
  color: ${({ theme }) => theme.colors.red};

  ${mq.greaterThan('tablet')`
    ${getP20_1()}
  `}
`

export const Table = styled.table`
  border-collapse: collapse;
  width: 100%;

  tr:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => hex2Rgba(theme.colors.red, .2)};
  }

  td {
    ${getP15_1()}
    padding: ${vw(16.5, 'mobile')} 0;
    vertical-align: top;
    width: 50%;

    ${mq.greaterThan('tablet')`
      padding: ${vw(16.5, 'desktop')} 0;
    `}

    &:nth-child(odd) {
      color: ${({ theme }) => theme.colors.darkred};
    }
  }
`

export const Dt = styled.dt`
  margin: ${vw(60, 'mobile')} 0 ${vw(24, 'mobile')};

  ${mq.greaterThan('tablet')`
    margin: ${vw(60, 'desktop')} 0 ${vw(10, 'desktop')};
  `}

  h2 {
    ${getP30()}
    color: ${({ theme }) => theme.colors.creme};
    font-family: ${({ theme }) => theme.fonts.secondaryFont};
    text-align: left;
  }
`

export const SectionNutrition = styled.section`
  table td {
    &:nth-child(even) {
     text-align: right;
     padding-right: ${vw(25, 'mobile')};

    ${mq.greaterThan('tablet')`
      padding-right: ${vw(25, 'desktop')};
    `}
    
    }
  }
`

export const PTable = styled.p`
  align-items: center;
  display: flex;
  height: ${vw(25, 'mobile')};
  justify-content: center;

  ${mq.greaterThan('tablet')`
    height: ${vw(25, 'desktop')};
  `}

  span {
    align-items: center;
    color: ${({ theme }) => theme.colors.red};
    display: flex;
    ${getP12_1()}
    font-family: ${({ theme }) => theme.fonts.secondaryFont};
    height: 100%;
    justify-content: center;
    padding: 0 ${vw(25, 'mobile')};

    ${mq.greaterThan('tablet')`
      padding: 0 ${vw(25, 'desktop')};
    `}

    &:nth-child(2) {
      border-left: 1px solid ${({ theme }) => theme.colors.red};
    }
  }
`

export const SectionRel = styled.section`
  margin-right: -${vw(20, 'mobile')};
  margin-left: -${vw(20, 'mobile')};

  ${mq.greaterThan('tablet')`
    margin-right: -${vw(30, 'desktop')};
    margin-left: -${vw(30, 'desktop')};
  `}

  .flickity-slider dd:first-of-type, h2 {
    padding-left: ${vw(20, 'mobile')};

    ${mq.greaterThan('tablet')`
      padding-left: ${vw(30, 'desktop')};
    `}
  }

  .slider--flickity .previous {
    svg { display: none; }
    ::after {
      position: absolute;
      top: 0;
      left: 0;
      content: url('/images/svg/i--arrow-left.svg');
      z-index: 100;
    }
  }

  .slider--flickity .next {
    svg { display: none; }
    ::after {
      position: absolute;
      top: 0;
      left: 0;
      content: url('/images/svg/i--arrow-right.svg');
      z-index: 100;
    }
  }

`