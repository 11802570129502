import { endpoints } from './endpoints'

type TypeFetchOptions = {
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE' // *GET
  mode?: 'cors' | 'no-cors' | 'same-origin', // no-cors, *cors, same-origin
  cache?: 'default' | 'no-cache' | 'no-cache' | 'reload' | 'force-cache' | 'only-if-cached', // *default
  credentials?: 'same-origin' | 'include' | 'omit', // *same-origin
  headers?: {
    'Accept'?: 'application/json',
    'Content-Type': 'application/json' | 'application/x-www-form-urlencoded' | 'multipart/form-data' | 'text/plain'
    [key:string]: any
  },
  redirect?: 'follow' | 'manual' | 'error', // *follow
  referrerPolicy?: 'no-referrer' | 'no-referrer-when-downgrade' | 'origin' | 'origin-when-cross-origin' | 'same-origin' | 'strict-origin' | 'strict-origin-when-cross-origin' | 'unsafe-url' // *no-referrer-when-downgrade
  body?: any // body data type must match "Content-Type" header
}
// Any body that you want to add to your request: this can be a Blob, an ArrayBuffer, a TypedArray, a DataView, a FormData, a URLSearchParams, string object or literal

const defaultOptions:TypeFetchOptions = {
  method: 'GET',
  mode: 'cors',
  cache: 'default',
  credentials: 'same-origin',
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  },
  redirect: 'follow',
  referrerPolicy: 'no-referrer',
  body: {}
}

type TypeQuery = {
  q?: string,
  slug?: string,
  customerId?: string | number
}


type TypeFetchApi = (urlNamespace: keyof typeof endpoints, query?: TypeQuery, options?:TypeFetchOptions) => Promise<any>

export const fetchAPI:TypeFetchApi = async (endpointNamespace, query = {}, fetchOptions) => {
  if(!endpointNamespace || !endpoints[endpointNamespace]) {
    throw new Error(`api/endpoints.ts -> No endpoint with namespace: '${endpointNamespace}'`)
  }

  const timestamp = Math.floor((Date.now() / (1000*60*60*24)))
  const url = endpoints[endpointNamespace](query, timestamp)

  const options = !fetchOptions ? defaultOptions : {...defaultOptions, ...fetchOptions }

  const contentType = options?.method === 'POST' ? 'application/x-www-form-urlencoded' : 'application/json'
  options.headers['Content-Type'] = contentType

  const body = (!options.method || options.method === 'GET') ? null : JSON.stringify(options.body)

  try {
    const res:Response = await fetch(url, {...options, body })
    const data = await res.json() // parses JSON response into native JavaScript objects
    if (res.status === 204) return [{ status: res.status, message: 'DY | Cannot parse a response with no content as json'}, true]
    return [data, !res.ok] // [data, error]
  } catch (error) {
    return [{...error, type: 'server_error' }, true]
  }
}
