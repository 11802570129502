import { Accordion } from '@dy/commons/components'
import { CategoriesSection } from './faqStyles'

export const SectionFaqCategories = ({ categories, activeIndex, activeCategory, activeQuestion }) => {
  return (
    <CategoriesSection>
      <Accordion items={categories} activeIndex={activeIndex} activeCategory={activeCategory} activeQuestion={activeQuestion} />
    </CategoriesSection>
  )
}
