import { memo, useMemo } from 'react'
import { useRouter } from 'next/router'
import Link from 'next/link'
import { motion } from 'framer-motion'
import { useTranslator } from '@/hooks'
import { menuUlVariants, menuListVariants } from '../layoutVariants'
import styled from 'styled-components'
import { mq, vw, getP53 } from '@dy/commons/styles'


interface IList {
  firstCategory?: any | null,
}

const NavItemList = styled(motion.ul)<any>`
  display: flex;
  flex-direction: column;

  ${mq.greaterThan('tablet')`
    grid-column: 2 / span 3;
    grid-row: 1 / span 2;
  `}

  li {
    margin-bottom: ${vw(15, 'mobile')};

    ${mq.greaterThan('tablet')`
      margin-bottom: ${vw(15, 'desktop')};
    `}
  }

  a {
    ${getP53()}
    color: ${({ theme }) => theme.colors.creme};
    display: inline-block;
    font-family: ${({ theme }) => theme.fonts.primaryFont};
    padding-bottom: 4px;

    ${mq.greaterThan('tablet')`
      font-weight: bold;
      padding: 0 ${vw(15, 'desktop')};
      position: relative;
    `}
    
    &.active {
      border-bottom: 0;
      color: ${({ theme }) => theme.colors.white};
      text-decoration: none;
    }

    > span {
      right: ${vw(30, 'desktop')};
      max-width: calc(100% - ${vw(30, 'desktop')});
    }
  } 

   
`

const getListItems = (links, pathname, locale, firstCategory) => {
  const Links = []

  for (let idx = 0; idx < links.length; idx++) {
    const linkData = links[idx]
    const { name, slug } = linkData
    const isShopLink = slug === '/shop'


    if (isShopLink && firstCategory && typeof window !== 'undefined') {
      //@ts-ignore
      window.NO_URL_KLK = `/shop/${firstCategory.slug?.[locale]}`
    }

    Links.push(
      <motion.li key={idx} variants={menuListVariants}>
        <Link href={isShopLink && firstCategory ? `/shop/${firstCategory.slug?.[locale]}` : slug} >
          <a className={pathname.includes(slug) ? 'active' : ''}>{name}<span></span></a>
        </Link>
      </motion.li>
    )
  }
  return Links
}

export const List = memo(({  firstCategory = null }:IList) => {
  const { t } = useTranslator()
  const { locale, pathname } = useRouter() || { pathname: '', locale: 'es' }
  
  const locales = locale.split('-')[0]
    
  const ListItems = useMemo(() => {
    const fakeList = [{ name: t('layout.nav.shop'), slug: '/shop'}, { name: t('layout.nav.habituales'), slug: '/account/usual-purchases'}]
    return getListItems(fakeList, pathname, locales, firstCategory)
  }, [pathname, firstCategory, t])
  
  return (
    <NavItemList className='nav-list' initial='inactive' animate='active' exit='exit' variants={menuUlVariants}>
      <motion.li variants={menuListVariants}>
        <Link href='/'>
          <a className={pathname === '/' ? 'active' : ''}>{t('layout.nav.home')}</a>
        </Link>
      </motion.li>
      {ListItems}
    </NavItemList>
  )
})
