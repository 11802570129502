import { memo } from 'react'
import { FormNewsletter } from '../../forms/FormNewsletter'
import { Section } from './footerStyles'

export const SectionNewsletter = memo(() => {
  return (
    <Section>
      <FormNewsletter  />
    </Section>
  )
})
