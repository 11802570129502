"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TextArea = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const styled_components_1 = __importStar(require("styled-components"));
const styles_1 = require("../../styles");
const __1 = require("../");
const hasContentState = () => (0, styled_components_1.css) `
  ${(0, styles_1.getP18InputHasContentLabel)()}
  opacity: 1;
  transform: translateY(-${(0, styles_1.vw)(35, 'mobile')});

  ${styles_1.mq.greaterThan('tablet') `
    transform: translateY(-${(0, styles_1.vw)(35, 'desktop')});
  `}
`;
const Label = styled_components_1.default.label `
  display: block;
  flex-direction: column;
  height: ${({ $height }) => $height ? `${(0, styles_1.vw)($height, 'mobile')}` : `${(0, styles_1.vw)(60, 'mobile')}`};
  justify-content: flex-end;
  margin-bottom: ${(0, styles_1.vw)(20, 'mobile')};
  min-height: ${(0, styles_1.vw)(69, 'mobile')};
  padding: 0 ${(0, styles_1.vw)(4.25, 'mobile')};
  position: relative;
  width: 100%;

  ${styles_1.mq.greaterThan('tablet') `
    height: ${({ $height }) => $height ? `${(0, styles_1.vw)($height, 'desktop')}` : `${(0, styles_1.vw)(60, 'desktop')}`};
    margin-bottom: ${(0, styles_1.vw)(30, 'desktop')};
    min-height: ${(0, styles_1.vw)(47, 'desktop')};
    padding: 0 ${(0, styles_1.vw)(12.5, 'desktop')};
    width: ${({ $col }) => typeof $col === 'number' ? `${100 * $col / 12}%` : $col};
  `}
  ${({ theme, $placeholderVisible, $textColor }) => (0, styles_1.parsePlaceHolder)(`
      color: ${$placeholderVisible ? theme.colors[$textColor] : 'rgba(0, 0, 0 ,0)'};
      opacity: ${$placeholderVisible ? .4 : 0};
  `)}

  > span {
    ${(0, styles_1.getP18InputLabel)()}
    bottom: ${(0, styles_1.vw)(2, 'mobile')};
    position: relative;
    transform: translateY(-${(0, styles_1.vw)(35, 'mobile')});
    transition: 300ms font-size ease, 300ms transform ease, 300ms opacity ease;

    ${styles_1.mq.greaterThan('tablet') `
      bottom: ${(0, styles_1.vw)(12, 'desktop')};
      transform: translateY(-${(0, styles_1.vw)(35, 'desktop')});
    `}


  }
  textarea {
    ${(0, styles_1.getP18_3)()}
    background-color: ${({ theme, $bgColor }) => theme.colors[$bgColor]};
    border-radius: 0;
    border-left: none;
    border-right: none;
    border-top: none;
    border-bottom: 1px solid;
    border-color: ${({ theme, $textColor }) => (0, styles_1.hex2Rgba)(theme.colors[$textColor], .4)};
    display: block;
    height: ${({ $height }) => $height ? `${(0, styles_1.vw)($height, 'mobile')}` : `${(0, styles_1.vw)(60, 'mobile')}`};
    outline: none;
    min-height: ${(0, styles_1.vw)(47, 'mobile')};
    max-height:${(0, styles_1.vw)(148, 'mobile')};
    padding:  ${(0, styles_1.vw)(25, 'mobile')} 0  ${(0, styles_1.vw)(12, 'mobile')};
    position: absolute;
    resize: none;
    top: 0;
    width:98%;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;

    ${styles_1.mq.greaterThan('tablet') `
      height: ${({ $height }) => $height ? `${(0, styles_1.vw)($height, 'desktop')}` : `${(0, styles_1.vw)(60, 'desktop')}`};
      min-height: ${(0, styles_1.vw)(47, 'desktop')};
      max-height:${(0, styles_1.vw)(148, 'desktop')};
      padding:  ${(0, styles_1.vw)(25, 'desktop')} 0 0;
    `}

    &:focus {
      border-color: ${({ theme, $textColor }) => (0, styles_1.hex2Rgba)(theme.colors[$textColor], 1)};
      + span {
        ${hasContentState()}

      }
    
    }
  }
`;
//Requested by design, text area componet should occupy  a whole column due to his dynamic height behaviour
exports.TextArea = (0, react_1.memo)(({ name, label, required = false, onChange = null, col, register = __1.noRegister, className, textColor = 'darkred', bgColor = 'background', error, disabled, readOnly, placeholder, placeholderVisible = false }) => {
    const requireProps = typeof required === 'boolean' ? { required } : required;
    const { onChange: registerOnChange, name: registerName, ref: registerRef } = register(name, Object.assign({}, requireProps));
    const [heightTextArea, setHeightTextArea] = (0, react_1.useState)(60);
    const handleChange = e => {
        registerOnChange && registerOnChange(e);
        onChange && onChange(e);
        if (e.target.value.trim().length === 0) {
            setHeightTextArea(60);
        }
    };
    const onKeyUp = (e) => {
        const maxheigth = 148;
        if (e.key === 'Enter') {
            if (heightTextArea <= maxheigth)
                setHeightTextArea(heightTextArea + 22);
        }
    };
    return ((0, jsx_runtime_1.jsxs)(Label, Object.assign({ "$col": col, "$textColor": textColor, "$bgColor": bgColor }, className && { className }, { "$placeholderVisible": placeholderVisible, "$height": heightTextArea }, { children: [(0, jsx_runtime_1.jsx)("textarea", { name: registerName !== null && registerName !== void 0 ? registerName : name, required: required, "aria-invalid": !!error, onChange: handleChange, ref: registerRef, disabled: disabled, readOnly: readOnly, placeholder: placeholderVisible ? placeholder : '', onKeyDown: onKeyUp }), label && (0, jsx_runtime_1.jsx)("span", { children: label })] })));
});
