'use-client'
import type { FC } from 'react'
import { memo} from 'react'
import type { SubmitHandler } from 'react-hook-form'
import { useForm } from 'react-hook-form'
import gql from 'graphql-tag'
import { useMutation } from '@apollo/client'
import { useTranslator } from '@/hooks'
import { Form, Fieldset, DisplayError, Input, Checkbox, Button } from '@dy/commons/components'
import type { TypeSectionAuthLevel } from '@/components'
import { autoComplete } from '@dy/commons/utils'
import { CURRENT_USER_QUERY } from '@/api'

const SIGNUP_MUTATION = gql`
  mutation SIGNUP_MUTATION($email: String!, $name: String!, $password: String!) {
    signUp(email: $email, name: $name, password: $password) {
      id
      email
      name
    }
  }
`

type Props = {
  updateLevel: (level: TypeSectionAuthLevel, close?: boolean) => void
  animation?: any
}

type TypeFormInputs = {
  firstName: string
  lastName: string,
  email: string,
  password: string
  cif?: string,
  countryCode: string,
  province: string,
  city: string,
  streetLine: string,
  streetLine2?: string,
  postalCode: string,
  // phone: string,
  // companyName?: string
}

export const FormUserSignUpExistingCIF:FC<Props> = memo(({ updateLevel, animation }) => {
  const { register, handleSubmit, watch, formState: { errors:formErrors } } = useForm<TypeFormInputs>()
  const { t } = useTranslator()
  const [signUp, { loading, error:apolloError } ] = useMutation(SIGNUP_MUTATION)

  console.log('updateLevel', updateLevel)
  console.log('watch', watch)
  console.log('formErrors', formErrors)

  const onSubmit: SubmitHandler<TypeFormInputs> = async data => {
    console.log('FormUserSignUpExistingCIF | onSubmit, data:', data)
    const { email, password } = data

    await signUp({
      variables: {
        email,
        password
      },
      refetchQueries: [
        { query: CURRENT_USER_QUERY }
      ]
    })
    // Reset back the inputs
  }

  return (
    <Form method='post' onSubmit={handleSubmit(onSubmit)} {...animation && animation}>
      <Fieldset disabled={loading} ariaBusy={loading} legend={t('commons.modals.auth.signup.text') as string}>
        <DisplayError error={apolloError} />
        <Input type='email' name='email' label={t('commons.form.fields.email') as string} placeholder='email' autoComplete={autoComplete.email} bgColor={'white'} />
        <Input type='password' name='password' label={t('commons.form.fields.password') as string} autoComplete={autoComplete.password.new} placeholder='password' bgColor={'white'} />


        <Input name={'firstName'} col={6} register={register} label={t('commons.form.fields.first_name') as string} required error={formErrors.firstName} />
        <Input name={'lastName'} col={6} register={register} label={t('commons.form.fields.last_name') as string} required error={formErrors.lastName} />
        {/* 3 SELECTS HERE */}
        <Input name='countryCode' col={2} label={t('commons.form.fields.country') as string} readOnly={true} defaultValue='ES' required />
        {/* 1 TEXT AREA HERE (PENDING IMPLEMENTATION) */}
        {/* <Input type='text' name='comments' register={register} label={t('commons.form.fields.phone') as string}  required errors={formErrors.phone} /> */}
        <Checkbox name='sameForBilling' col={12} register={register} label={t('commons.form.fields.shipping_as_billing') as string} />
        <Checkbox name='im_a_company' col={12} register={register} label={t('commons.form.fields.im_a_company') as string} />
      </Fieldset>
      <Button type='submit'>{t('commons.actions.signup')}</Button>
    </Form>
  )
})
