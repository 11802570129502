export const apiBrands = [
  {
    'slug': {
      'es': 'rummo'
    },
    'logo': {
      'type': 'svg',
      'alt': 'Rummo | HelloMamma',
      'url': 'https://staging.cms.hellomamma.eu/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBL0VqQVE9PSIsImV4cCI6bnVsbCwicHVyIjoiYmxvYl9pZCJ9fQ==--6bf6575ea2815feaa93dd3a404de0873b58ef7bd/Rummo.svg'
    }
  },
  {
    'slug': {
      'es': 'de-cecco'
    },
    'logo': {
      'type': 'svg',
      'alt': 'DE CECCO | HelloMamma',
      'url': 'https://staging.cms.hellomamma.eu/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBL0lqQVE9PSIsImV4cCI6bnVsbCwicHVyIjoiYmxvYl9pZCJ9fQ==--670c8a0f2b9ec6143e2db861ffdeae64c556ae7a/DeCecco.svg'
    }
  },
  {
    'slug': {
      'es': 'sori'
    },
    'logo': {
      'type': 'svg',
      'alt': 'SORI | HelloMamma',
      'url': 'https://staging.cms.hellomamma.eu/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBa0VJIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--393ae0fb409784805ebcde0f1461fccdc85406ba/sori-03.svg'
    }
  },
  {
    'slug': {
      'es': 'la-torrente'
    },
    'logo': {
      'type': 'svg',
      'alt': 'LA TORRENTE | HelloMamma',
      'url': 'https://staging.cms.hellomamma.eu/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBa0lJIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--e86ef3ddcbf0cde7a531ae53e64ea8555268dfd5/torrente-01.svg'
    }
  },
  {
    'slug': {
      'es': 'clai'
    },
    'logo': {
      'type': 'svg',
      'alt': 'CLAI | HelloMamma',
      'url': 'https://staging.cms.hellomamma.eu/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBa01JIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--4ee05a566b26198c2ccb5362b2aad5815c63b939/clai-04.svg'
    }
  },
  {
    'slug': {
      'es': 'divella'
    },
    'logo': {
      'type': 'svg',
      'alt': 'DIVELLA | HelloMamma',
      'url': 'https://staging.cms.hellomamma.eu/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBL01qQVE9PSIsImV4cCI6bnVsbCwicHVyIjoiYmxvYl9pZCJ9fQ==--69b58ba9b50a73692554ae1133ead8de924fd25a/Divella.svg'
    }
  }
]

export const ACCOUNT_FAKE_DATA = {
  'nav': [
    {
      'name': 'Datos de acceso',
      'url': '/account'
    },
    {
      'name': 'Mis pedidos',
      'url': '/account/orders'
    },
    {
      'name': 'Facturación',
      'url': '/account/invoices'
    },
    {
      'name': 'Cerrar sesión',
      'url': '/'
    }
  ],
  'orders': [
    {
      'dateCreated': '22 agosto de 2020',
      'id': '407-1234567-1234567',
      'total': 45,
      'code': 'string',
      'deliveryDate': '24 agosto 2021',
      'type': 'online',
      'orderLines': [
        {
          'variantId': '1234',
          'name': 'Pasta',
          'amount': 7,
          'brand': 'Divella',
          'price': 123,
          'weight': '500g',
          'pricePerWeight': 5.30,
          'thumbnail': '/images/svg/pasta-left.svg'
        },
        {
          'variantId': '12333',
          'name': 'Pasta',
          'amount': 7,
          'brand': 'Divella',
          'price': 230,
          'weight': '500g',
          'pricePerWeight': 5.30,
          'thumbnail': '/images/svg/pasta-left.svg'
        }
      ],
      'shippingAddress': {
        'firstName': 'Mario',
        'lastName': 'Rossi',
        'street1': 'Bruc 45 3-1',
        'street2': 'string',
        'city': 'Barcelona',
        'province': 'Barcelona',
        'countryCode': 'string',
        'postalCode': '08009',
        'phone': '666908873'
      },
      'billingAddress': {
        'firstName': 'Mario',
        'lastName': 'Rossi',
        'street1': 'Bruc 45 3-1',
        'street2': 'string',
        'city': 'Barcelona',
        'province': 'Barcelona',
        'countryCode': 'string',
        'postalCode': '08009',
        'phone': '666908873'
      },
      'paymentMethod': 'visa',
      'summary': {
        'totalUnits': 4,
        'subtotalIncTax': 35,
        'discountsApplied': 0,
        'coupon': {
          'code': 'string',
          'value': 0,
        },
        'shippingCost': 0,
        'totalIncTax': 45
      },
      'orderStatus': {//to be discussed
      },
      //invoice
      'number': 'string',
      'orders': [
        {
          'variantId': '1234',
          'name': 'Pasta',
          'amount': 7,
          'brand': 'Divella',
          'price': 123,
          'weight': '500g',
          'pricePerWeight': 5.30,
          'thumbnail': '/images/svg/pasta-left.svg'
        },
        {
          'variantId': '12333',
          'name': 'Pasta',
          'amount': 7,
          'brand': 'Divella',
          'price': 230,
          'weight': '500g',
          'pricePerWeight': 5.30,
          'thumbnail': '/images/svg/pasta-left.svg'
        }
      ],
      'downloadLink': 'string',
      'dateSent': 'string',
    },
    {
      'dateCreated': '23 agosto de 2020',
      'id': '407-1234567-1234568',
      'total': 45,
      'code': 'string',
      'deliveryDate': '24 agosto 2021',
      'type': 'online',
      'orderLines': [
        {
          'variantId': '1234',
          'name': 'Pasta',
          'amount': 7,
          'brand': 'Divella',
          'price': 123,
          'weight': '500g',
          'pricePerWeight': 5.30,
          'thumbnail': '/images/svg/pasta-left.svg'
        },
        {
          'variantId': '12333',
          'name': 'Pasta',
          'amount': 7,
          'brand': 'Divella',
          'price': 230,
          'weight': '500g',
          'pricePerWeight': 5.30,
          'thumbnail': '/images/svg/pasta-left.svg'
        }
      ],
      'shippingAddress': {
        'firstName': 'Mario',
        'lastName': 'Rossi',
        'street1': 'Bruc 45 3-1',
        'street2': 'string',
        'city': 'Barcelona',
        'province': 'Barcelona',
        'countryCode': 'string',
        'postalCode': '08009',
        'phone': '666908873'
      },
      'billingAddress': {
        'firstName': 'Mario',
        'lastName': 'Rossi',
        'street1': 'Bruc 45 3-1',
        'street2': 'string',
        'city': 'Barcelona',
        'province': 'Barcelona',
        'countryCode': 'string',
        'postalCode': '08009'
      },
      'paymentMethod': 'visa',
      'summary': {
        'totalUnits': 4,
        'subtotalIncTax': 35,
        'discountsApplied': 0,
        'coupon': {
          'code': 'string',
          'value': 0,
        },
        'shippingCost': 0,
        'totalIncTax': 45
      },
      'orderStatus': {//to be discussed
      }
    },
  ],
  'invoice': [
    {
      'id': '400-4444-444-4-4',
      'number': 'string',
      'total': 656,
      'orders': [
        {
          'id': '407-1234567-1234567',
          'code': 'string',
          'dateCreated': '22 agosto de 2020',
          'total': 330
        },
        {
          'id': '407-1234567-1234567',
          'code': 'string',
          'dateCreated': '20 agosto de 2020',
          'total': 326
        }
      ],
      'downloadLink': 'string',
      'dateSent': '30 de agosto 2022',
    }
  ],
  'fake_email': 'dy@digital.you'
}

export const FAKE_ORDER_DATA = {
  'orders': [
    {
      'dateCreated': '22 agosto de 2020',
      'code': 'string',
      'id': '407-1234567-1234567',
      'total': 45,
      'type': 'online',
      'shippingAddress': {
        'firstName': 'Garfield',
        'lastName': 'Davis',
        'street1': 'Edgware Road',
        'street2': 'string',
        'city': 'London',
        'province': 'London',
        'countryCode': 'string',
        'postalCode': 'W2 2EA',
        'phone': '778877787'
      },
      'billingAddress': {
        'firstName': 'Garfield',
        'lastName': 'Davis',
        'street1': 'Edgware Road',
        'street2': 'string',
        'city': 'London',
        'province': 'London',
        'countryCode': 'string',
        'postalCode': 'W2 2EA'
      },
      'paymentMethod': 'visa',
      'summary': {
        'totalUnits': '10',
        'subtotalIncTax': 53.05,
        'discountsApplied': 0,
        'coupon': {
          'code': 'string',
          'value': 0,
        },
        'shippingCost': 0,
        'totalIncTax': 58.80,   // BC => grand_total
      },
      'orderStatus': {
        //to be discussed
      },
      'orderLines': [
        {
          'variantId': '1234',
          'name': 'Pasta',
          'amount': '7',
          'brand': 'Divella',
          'price': 123,
          'weight': 'string',
          'pricePerWeight': '500gr · 5,30€/kg',
          'thumbnail': 'images/pasta.jpeg'
        },
        {
          'variantId': '1234',
          'name': 'Cheese',
          'amount': '3',
          'brand': 'Divellllllla',
          'price': 235,
          'weight': 'string',
          'pricePerWeight': '500gr · 5,30€/kg',
          'thumbnail': 'images/pasta.jpeg'

        }
      ]
    },
    {
      'dateCreated': '18 agosto de 2020',
      'code': 'string',
      'id': '407-1234567-1234444',
      'total': 45,
      'type': 'online',
      'shippingAddress': {
        'firstName': 'Garfield',
        'lastName': 'Davis',
        'street1': 'Edgware Road',
        'street2': 'string',
        'city': 'London',
        'province': 'London',
        'countryCode': 'string',
        'postalCode': 'W2 2EA',
        'phone': '778877787'
      },
      'billingAddress': {
        'firstName': 'Garfield',
        'lastName': 'Davis',
        'street1': 'Edgware Road',
        'street2': 'string',
        'city': 'London',
        'province': 'London',
        'countryCode': 'string',
        'postalCode': 'W2 2EA'
      },
      'paymentMethod': 'visa',
      'summary': {
        'totalUnits': '10',
        'subtotalIncTax': 53.05,
        'discountsApplied': 0,
        'coupon': {
          'code': 'string',
          'value': 0,
        },
        'shippingCost': 0,
        'totalIncTax': 58.80,   // BC => grand_total
      },
      'orderStatus': {
        //to be discussed
      },
      'orderLines': [
        {
          'variantId': '1234',
          'name': 'Pasta',
          'amount': '7',
          'brand': 'Divella',
          'price': 123,
          'weight': 'string',
          'pricePerWeight': '500gr · 5,30€/kg',
          'thumbnail': 'images/pasta.jpeg'
        },
        {
          'variantId': '1234',
          'name': 'Cheese',
          'amount': '3',
          'brand': 'Divellllllla',
          'price': 235,
          'weight': 'string',
          'pricePerWeight': '500gr · 5,30€/kg',
          'thumbnail': 'images/pasta.jpeg'

        }
      ]
    },
    {
      'dateCreated': '2 agosto de 2020',
      'code': 'string',
      'id': '407-1234567-1234555',
      'total': 45,
      'type': 'online',
      'shippingAddress': {
        'firstName': 'Garfield',
        'lastName': 'Davis',
        'street1': 'Edgware Road',
        'street2': 'string',
        'city': 'London',
        'province': 'London',
        'countryCode': 'string',
        'postalCode': 'W2 2EA',
        'phone': '778877787'
      },
      'billingAddress': {
        'firstName': 'Garfield',
        'lastName': 'Davis',
        'street1': 'Edgware Road',
        'street2': 'string',
        'city': 'London',
        'province': 'London',
        'countryCode': 'string',
        'postalCode': 'W2 2EA'
      },
      'paymentMethod': 'visa',
      'summary': {
        'totalUnits': '10',
        'subtotalIncTax': 53.05,
        'discountsApplied': 0,
        'coupon': {
          'code': 'string',
          'value': 0,
        },
        'shippingCost': 0,
        'totalIncTax': 58.80,   // BC => grand_total
      },
      'orderStatus': {
        //to be discussed
      },
      'orderLines': [
        {
          'variantId': '1234',
          'name': 'Pasta',
          'amount': '7',
          'brand': 'Divella',
          'price': 123,
          'weight': 'string',
          'pricePerWeight': '500gr · 5,30€/kg',
          'thumbnail': 'images/pasta.jpeg'
        },
        {
          'variantId': '1234',
          'name': 'Cheese',
          'amount': '3',
          'brand': 'Divellllllla',
          'price': 235,
          'weight': 'string',
          'pricePerWeight': '500gr · 5,30€/kg',
          'thumbnail': 'images/pasta.jpeg'

        }
      ]
    }
  ],
  'comercial': {
    'name': 'Marta Pérez',
    'email': 'martaperez@mammafiore.eu',
    'tel': '657 983 234'
  },
  'myproducts': [
    { img: 'images/pasta.jpeg' },
    { img: 'images/pasta.jpeg' },
    { img: 'images/pasta.jpeg' },
    { img: 'images/pasta.jpeg' },
    { img: 'images/pasta.jpeg' },
    { img: 'images/pasta.jpeg' },
    { img: '/images/pasta.jpeg' },
    { img: '/images/pasta.jpeg' }
  ]
}

export const CART_FAKE_DATA = {
  'cart_items': [
    {
      'bigcommerce_id': 283,
      'bigcommerce_slug': '/calamarata-n-41/',
      'name': 'Calamarata Nº41',
      'slug': {
        'es': 'calamarata-n-41'
      },
      'brand': 'string',
      'ingredients': 'Sémola de trigo duro, agua.',
      'labels': [
        {
          'name': 'TESTTESTTESTTEST'
        },
        {
          'name': 'Vegan'
        }
      ],
      'filters': [
        {
          'name': 'Marca',
          'param': 'marca',
          'type': 'multiple',
          'values': [
            {
              'name': 'Rummo',
              'value': 'rummo',
              'description': '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque blandit rutrum elit mattis, metus id pharetramattis. Nulla facilisi. Sed mattis, metus id pharetra porttitor, nisi lacus scelerisque quam, ac pretium elit purus id turpis. Praesent ultrices libero turpis, vitae feugiat ante commodo non. Donec et odio quis mauattis, metus id pharetra.</p>',
              'media': {
                'type': 'svg',
                'alt': 'Rummo | HelloMamma',
                'url': 'https://staging.cms.hellomamma.eu/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBL0VqQVE9PSIsImV4cCI6bnVsbCwicHVyIjoiYmxvYl9pZCJ9fQ==--6bf6575ea2815feaa93dd3a404de0873b58ef7bd/Rummo.svg'
              }
            }
          ]
        },
        {
          'name': 'Sin gluten',
          'param': 'sin-gluten',
          'type': 'simple',
          'values': [
            {
              'name': '',
              'value': '',
              'description': '',
              'media': null,
              'visible': true
            }
          ]
        },
        {
          'name': 'Bio',
          'param': 'bio',
          'type': 'simple',
          'values': [
            {
              'name': '',
              'value': '',
              'description': '',
              'media': null,
              'visible': true
            }
          ]
        },
        {
          'name': 'Integral',
          'param': 'integral',
          'type': 'simple',
          'values': [
            {
              'name': '',
              'value': '',
              'description': '',
              'media': null,
              'visible': true
            }
          ]
        }
      ],
      'categories': [
        {
          'id': 64,
          'name': 'Pasta',
          'slug': {
            'es': 'pasta'
          }
        },
        {
          'id': 69,
          'name': 'Pasta seca',
          'slug': {
            'es': 'pasta/pasta-seca'
          }
        }
      ],
      'variant': [
        {
          'bigcommerce_id': 33,
          'sku': 'string',
          'name': 'string',
          'description': 'string',
          'media': 'any',
          'stock': 33232,
          'prices': {
            'retailPrice': { 'exTax': 222, 'incTax': 333 },
            'salePrice': { 'exTax': 323, 'incTax': 333 }
          },
          'variant': [
            {
              'bigcommerce_id': 310,
              'name': '500gr',
              'sku': '1000001170',
              'prices': {
                'price_inc_tax': 2.53,
                'retail_price_inc_tax': 2.77,
                'price_ex_tax': 2.3,
                'retail_price_ex_tax': 2.52
              },
              'stock': 149208,
              'reference_price': 2.86,
              'reference_unit': 'kg',
              'media': [
                {
                  'pdp': {
                    'type': 'image',
                    'alt': '500gr | HelloMamma',
                    'default': {
                      'size': {
                        'width': 520,
                        'height': 430
                      },
                      'mq': {
                        'min': 721
                      },
                      '1x': {
                        'compressed': 'https://staging.cms.hellomamma.eu/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBdmdOIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--d56ad453a42b017743c84fef90f2a5ee1d7215dd/medium_109_image_pdp_high.webp',
                        'regular': 'https://staging.cms.hellomamma.eu/rails/active_storage/representations/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBdjBLIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--2c266e88d55a0dbd38083d88b590a690dd4edc0c/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdCem9MWm05eWJXRjBTU0lKYW5CbFp3WTZCa1ZVT2hKeVpYTnBlbVZmZEc5ZlptbDBXd2RwQWdnQ2FRS3VBUT09IiwiZXhwIjpudWxsLCJwdXIiOiJ2YXJpYXRpb24ifX0=--6ce729c9e05cc405a5d8221272d89a4bfeaa8ea9/300768'
                      },
                      '2x': {
                        'compressed': 'https://staging.cms.hellomamma.eu/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBdkFOIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--66721d6b36993eb949b4a1ccbbc362070f9d79c3/medium_109_image_pdp_high2x.webp',
                        'regular': 'https://staging.cms.hellomamma.eu/rails/active_storage/representations/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBdjBLIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--2c266e88d55a0dbd38083d88b590a690dd4edc0c/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdCem9MWm05eWJXRjBTU0lKYW5CbFp3WTZCa1ZVT2hKeVpYTnBlbVZmZEc5ZlptbDBXd2RwQWhBRWFRSmNBdz09IiwiZXhwIjpudWxsLCJwdXIiOiJ2YXJpYXRpb24ifX0=--05933b2581eea43909bcda8d32f62993d89e70b1/300768'
                      }
                    },
                    'sources': [
                      {
                        'size': {
                          'width': 520,
                          'height': 430
                        },
                        'mq': {
                          'min': 721
                        },
                        '1x': {
                          'compressed': 'https://staging.cms.hellomamma.eu/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBdmdOIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--d56ad453a42b017743c84fef90f2a5ee1d7215dd/medium_109_image_pdp_high.webp',
                          'regular': 'https://staging.cms.hellomamma.eu/rails/active_storage/representations/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBdjBLIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--2c266e88d55a0dbd38083d88b590a690dd4edc0c/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdCem9MWm05eWJXRjBTU0lKYW5CbFp3WTZCa1ZVT2hKeVpYTnBlbVZmZEc5ZlptbDBXd2RwQWdnQ2FRS3VBUT09IiwiZXhwIjpudWxsLCJwdXIiOiJ2YXJpYXRpb24ifX0=--6ce729c9e05cc405a5d8221272d89a4bfeaa8ea9/300768'
                        },
                        '2x': {
                          'compressed': 'https://staging.cms.hellomamma.eu/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBdkFOIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--66721d6b36993eb949b4a1ccbbc362070f9d79c3/medium_109_image_pdp_high2x.webp',
                          'regular': 'https://staging.cms.hellomamma.eu/rails/active_storage/representations/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBdjBLIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--2c266e88d55a0dbd38083d88b590a690dd4edc0c/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdCem9MWm05eWJXRjBTU0lKYW5CbFp3WTZCa1ZVT2hKeVpYTnBlbVZmZEc5ZlptbDBXd2RwQWhBRWFRSmNBdz09IiwiZXhwIjpudWxsLCJwdXIiOiJ2YXJpYXRpb24ifX0=--05933b2581eea43909bcda8d32f62993d89e70b1/300768'
                        }
                      },
                      {
                        'size': {
                          'width': 728,
                          'height': 602
                        },
                        'mq': {
                          'max': 720
                        },
                        '1x': {
                          'compressed': 'https://staging.cms.hellomamma.eu/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBZ0FPIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--c2b234adcd91b405e1d57af0371323f8b282a9a9/medium_109_image_pdp_low2x.webp',
                          'regular': 'https://staging.cms.hellomamma.eu/rails/active_storage/representations/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBdjBLIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--2c266e88d55a0dbd38083d88b590a690dd4edc0c/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdCem9MWm05eWJXRjBTU0lKYW5CbFp3WTZCa1ZVT2hKeVpYTnBlbVZmZEc5ZlptbDBXd2RwQXRnQ2FRSmFBZz09IiwiZXhwIjpudWxsLCJwdXIiOiJ2YXJpYXRpb24ifX0=--ad525c4169b46bd2a6c2887b5334127e37549199/300768'
                        },
                        '2x': {
                          'compressed': 'https://staging.cms.hellomamma.eu/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBZ0FPIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--c2b234adcd91b405e1d57af0371323f8b282a9a9/medium_109_image_pdp_low2x.webp',
                          'regular': 'https://staging.cms.hellomamma.eu/rails/active_storage/representations/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBdjBLIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--2c266e88d55a0dbd38083d88b590a690dd4edc0c/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdCem9MWm05eWJXRjBTU0lKYW5CbFp3WTZCa1ZVT2hKeVpYTnBlbVZmZEc5ZlptbDBXd2RwQXRnQ2FRSmFBZz09IiwiZXhwIjpudWxsLCJwdXIiOiJ2YXJpYXRpb24ifX0=--ad525c4169b46bd2a6c2887b5334127e37549199/300768'
                        }
                      }
                    ]
                  },
                  'grid': {
                    'type': 'image',
                    'alt': '500gr | HelloMamma',
                    'default': {
                      'size': {
                        'width': 221,
                        'height': 200
                      },
                      'mq': {
                        'min': 721
                      },
                      '1x': {
                        'compressed': 'https://staging.cms.hellomamma.eu/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBZzBPIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--9aa8c4348b6120b657932d25e0ff9c5b4c489034/medium_109_image_grid_high.webp',
                        'regular': 'https://staging.cms.hellomamma.eu/rails/active_storage/representations/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBdjBLIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--2c266e88d55a0dbd38083d88b590a690dd4edc0c/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdCem9MWm05eWJXRjBTU0lKYW5CbFp3WTZCa1ZVT2hKeVpYTnBlbVZmZEc5ZlptbDBXd2RwQWQxcEFjZz0iLCJleHAiOm51bGwsInB1ciI6InZhcmlhdGlvbiJ9fQ==--0bd48e19f8c958a9dc74db5820f8555b4807a341/300768'
                      },
                      '2x': {
                        'compressed': 'https://staging.cms.hellomamma.eu/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBZ2NPIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--ef410a1c6f154174c4c2750b1362ea8d173086cd/medium_109_image_grid_high2x.webp',
                        'regular': 'https://staging.cms.hellomamma.eu/rails/active_storage/representations/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBdjBLIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--2c266e88d55a0dbd38083d88b590a690dd4edc0c/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdCem9MWm05eWJXRjBTU0lKYW5CbFp3WTZCa1ZVT2hKeVpYTnBlbVZmZEc5ZlptbDBXd2RwQXJvQmFRS1FBUT09IiwiZXhwIjpudWxsLCJwdXIiOiJ2YXJpYXRpb24ifX0=--a4042b1c27fccde84008c28f4e80a1cb5acb0d92/300768'
                      }
                    },
                    'sources': [
                      {
                        'size': {
                          'width': 221,
                          'height': 200
                        },
                        'mq': {
                          'min': 721
                        },
                        '1x': {
                          'compressed': 'https://staging.cms.hellomamma.eu/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBZzBPIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--9aa8c4348b6120b657932d25e0ff9c5b4c489034/medium_109_image_grid_high.webp',
                          'regular': 'https://staging.cms.hellomamma.eu/rails/active_storage/representations/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBdjBLIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--2c266e88d55a0dbd38083d88b590a690dd4edc0c/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdCem9MWm05eWJXRjBTU0lKYW5CbFp3WTZCa1ZVT2hKeVpYTnBlbVZmZEc5ZlptbDBXd2RwQWQxcEFjZz0iLCJleHAiOm51bGwsInB1ciI6InZhcmlhdGlvbiJ9fQ==--0bd48e19f8c958a9dc74db5820f8555b4807a341/300768'
                        },
                        '2x': {
                          'compressed': 'https://staging.cms.hellomamma.eu/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBZ2NPIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--ef410a1c6f154174c4c2750b1362ea8d173086cd/medium_109_image_grid_high2x.webp',
                          'regular': 'https://staging.cms.hellomamma.eu/rails/active_storage/representations/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBdjBLIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--2c266e88d55a0dbd38083d88b590a690dd4edc0c/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdCem9MWm05eWJXRjBTU0lKYW5CbFp3WTZCa1ZVT2hKeVpYTnBlbVZmZEc5ZlptbDBXd2RwQXJvQmFRS1FBUT09IiwiZXhwIjpudWxsLCJwdXIiOiJ2YXJpYXRpb24ifX0=--a4042b1c27fccde84008c28f4e80a1cb5acb0d92/300768'
                        }
                      },
                      {
                        'size': {
                          'width': 309,
                          'height': 280
                        },
                        'mq': {
                          'max': 720
                        },
                        '1x': {
                          'compressed': 'https://staging.cms.hellomamma.eu/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBaFVPIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--674c6d750aa3f40be8516e466b7176daba61730d/medium_109_image_grid_low2x.webp',
                          'regular': 'https://staging.cms.hellomamma.eu/rails/active_storage/representations/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBdjBLIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--2c266e88d55a0dbd38083d88b590a690dd4edc0c/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdCem9MWm05eWJXRjBTU0lKYW5CbFp3WTZCa1ZVT2hKeVpYTnBlbVZmZEc5ZlptbDBXd2RwQWpVQmFRSVlBUT09IiwiZXhwIjpudWxsLCJwdXIiOiJ2YXJpYXRpb24ifX0=--4da0327a3c465d81779968a0c4f41c580e109896/300768'
                        },
                        '2x': {
                          'compressed': 'https://staging.cms.hellomamma.eu/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBaFVPIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--674c6d750aa3f40be8516e466b7176daba61730d/medium_109_image_grid_low2x.webp',
                          'regular': 'https://staging.cms.hellomamma.eu/rails/active_storage/representations/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBdjBLIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--2c266e88d55a0dbd38083d88b590a690dd4edc0c/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdCem9MWm05eWJXRjBTU0lKYW5CbFp3WTZCa1ZVT2hKeVpYTnBlbVZmZEc5ZlptbDBXd2RwQWpVQmFRSVlBUT09IiwiZXhwIjpudWxsLCJwdXIiOiJ2YXJpYXRpb24ifX0=--4da0327a3c465d81779968a0c4f41c580e109896/300768'
                        }
                      }
                    ]
                  }
                },
                {
                  'pdp': {
                    'type': 'image',
                    'alt': '500gr | HelloMamma',
                    'default': {
                      'size': {
                        'width': 520,
                        'height': 430
                      },
                      'mq': {
                        'min': 721
                      },
                      '1x': {
                        'compressed': 'https://staging.cms.hellomamma.eu/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBZ3NPIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--c9476c50cae4fd8a7270f31a86313344f023f2bd/medium_110_image_pdp_high.webp',
                        'regular': 'https://staging.cms.hellomamma.eu/rails/active_storage/representations/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBdjRLIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--00265866b81ff642d90dd1eb1a87b52aa3bb1e96/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdCem9MWm05eWJXRjBTU0lKYW5CbFp3WTZCa1ZVT2hKeVpYTnBlbVZmZEc5ZlptbDBXd2RwQWdnQ2FRS3VBUT09IiwiZXhwIjpudWxsLCJwdXIiOiJ2YXJpYXRpb24ifX0=--6ce729c9e05cc405a5d8221272d89a4bfeaa8ea9/300768'
                      },
                      '2x': {
                        'compressed': 'https://staging.cms.hellomamma.eu/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBZ2dPIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--cf342d761a1555515b01880298d3884e788d920e/medium_110_image_pdp_high2x.webp',
                        'regular': 'https://staging.cms.hellomamma.eu/rails/active_storage/representations/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBdjRLIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--00265866b81ff642d90dd1eb1a87b52aa3bb1e96/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdCem9MWm05eWJXRjBTU0lKYW5CbFp3WTZCa1ZVT2hKeVpYTnBlbVZmZEc5ZlptbDBXd2RwQWhBRWFRSmNBdz09IiwiZXhwIjpudWxsLCJwdXIiOiJ2YXJpYXRpb24ifX0=--05933b2581eea43909bcda8d32f62993d89e70b1/300768'
                      }
                    },
                    'sources': [
                      {
                        'size': {
                          'width': 520,
                          'height': 430
                        },
                        'mq': {
                          'min': 721
                        },
                        '1x': {
                          'compressed': 'https://staging.cms.hellomamma.eu/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBZ3NPIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--c9476c50cae4fd8a7270f31a86313344f023f2bd/medium_110_image_pdp_high.webp',
                          'regular': 'https://staging.cms.hellomamma.eu/rails/active_storage/representations/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBdjRLIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--00265866b81ff642d90dd1eb1a87b52aa3bb1e96/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdCem9MWm05eWJXRjBTU0lKYW5CbFp3WTZCa1ZVT2hKeVpYTnBlbVZmZEc5ZlptbDBXd2RwQWdnQ2FRS3VBUT09IiwiZXhwIjpudWxsLCJwdXIiOiJ2YXJpYXRpb24ifX0=--6ce729c9e05cc405a5d8221272d89a4bfeaa8ea9/300768'
                        },
                        '2x': {
                          'compressed': 'https://staging.cms.hellomamma.eu/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBZ2dPIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--cf342d761a1555515b01880298d3884e788d920e/medium_110_image_pdp_high2x.webp',
                          'regular': 'https://staging.cms.hellomamma.eu/rails/active_storage/representations/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBdjRLIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--00265866b81ff642d90dd1eb1a87b52aa3bb1e96/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdCem9MWm05eWJXRjBTU0lKYW5CbFp3WTZCa1ZVT2hKeVpYTnBlbVZmZEc5ZlptbDBXd2RwQWhBRWFRSmNBdz09IiwiZXhwIjpudWxsLCJwdXIiOiJ2YXJpYXRpb24ifX0=--05933b2581eea43909bcda8d32f62993d89e70b1/300768'
                        }
                      },
                      {
                        'size': {
                          'width': 728,
                          'height': 602
                        },
                        'mq': {
                          'max': 720
                        },
                        '1x': {
                          'compressed': 'https://staging.cms.hellomamma.eu/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBaElPIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--b47b5a997e891a354d1af020421e9efcb5da1f12/medium_110_image_pdp_low2x.webp',
                          'regular': 'https://staging.cms.hellomamma.eu/rails/active_storage/representations/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBdjRLIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--00265866b81ff642d90dd1eb1a87b52aa3bb1e96/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdCem9MWm05eWJXRjBTU0lKYW5CbFp3WTZCa1ZVT2hKeVpYTnBlbVZmZEc5ZlptbDBXd2RwQXRnQ2FRSmFBZz09IiwiZXhwIjpudWxsLCJwdXIiOiJ2YXJpYXRpb24ifX0=--ad525c4169b46bd2a6c2887b5334127e37549199/300768'
                        },
                        '2x': {
                          'compressed': 'https://staging.cms.hellomamma.eu/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBaElPIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--b47b5a997e891a354d1af020421e9efcb5da1f12/medium_110_image_pdp_low2x.webp',
                          'regular': 'https://staging.cms.hellomamma.eu/rails/active_storage/representations/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBdjRLIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--00265866b81ff642d90dd1eb1a87b52aa3bb1e96/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdCem9MWm05eWJXRjBTU0lKYW5CbFp3WTZCa1ZVT2hKeVpYTnBlbVZmZEc5ZlptbDBXd2RwQXRnQ2FRSmFBZz09IiwiZXhwIjpudWxsLCJwdXIiOiJ2YXJpYXRpb24ifX0=--ad525c4169b46bd2a6c2887b5334127e37549199/300768'
                        }
                      }
                    ]
                  },
                  'grid': {
                    'type': 'image',
                    'alt': '500gr | HelloMamma',
                    'default': {
                      'size': {
                        'width': 221,
                        'height': 200
                      },
                      'mq': {
                        'min': 721
                      },
                      '1x': {
                        'compressed': 'https://staging.cms.hellomamma.eu/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBaG9PIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--ebd6497b55e6ee19f4d00d7e0374f07a6b895535/medium_110_image_grid_high.webp',
                        'regular': 'https://staging.cms.hellomamma.eu/rails/active_storage/representations/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBdjRLIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--00265866b81ff642d90dd1eb1a87b52aa3bb1e96/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdCem9MWm05eWJXRjBTU0lKYW5CbFp3WTZCa1ZVT2hKeVpYTnBlbVZmZEc5ZlptbDBXd2RwQWQxcEFjZz0iLCJleHAiOm51bGwsInB1ciI6InZhcmlhdGlvbiJ9fQ==--0bd48e19f8c958a9dc74db5820f8555b4807a341/300768'
                      },
                      '2x': {
                        'compressed': 'https://staging.cms.hellomamma.eu/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBaFlPIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--9c9203102251598f5ff6475284e1789a59a9051a/medium_110_image_grid_high2x.webp',
                        'regular': 'https://staging.cms.hellomamma.eu/rails/active_storage/representations/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBdjRLIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--00265866b81ff642d90dd1eb1a87b52aa3bb1e96/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdCem9MWm05eWJXRjBTU0lKYW5CbFp3WTZCa1ZVT2hKeVpYTnBlbVZmZEc5ZlptbDBXd2RwQXJvQmFRS1FBUT09IiwiZXhwIjpudWxsLCJwdXIiOiJ2YXJpYXRpb24ifX0=--a4042b1c27fccde84008c28f4e80a1cb5acb0d92/300768'
                      }
                    },
                    'sources': [
                      {
                        'size': {
                          'width': 221,
                          'height': 200
                        },
                        'mq': {
                          'min': 721
                        },
                        '1x': {
                          'compressed': 'https://staging.cms.hellomamma.eu/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBaG9PIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--ebd6497b55e6ee19f4d00d7e0374f07a6b895535/medium_110_image_grid_high.webp',
                          'regular': 'https://staging.cms.hellomamma.eu/rails/active_storage/representations/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBdjRLIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--00265866b81ff642d90dd1eb1a87b52aa3bb1e96/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdCem9MWm05eWJXRjBTU0lKYW5CbFp3WTZCa1ZVT2hKeVpYTnBlbVZmZEc5ZlptbDBXd2RwQWQxcEFjZz0iLCJleHAiOm51bGwsInB1ciI6InZhcmlhdGlvbiJ9fQ==--0bd48e19f8c958a9dc74db5820f8555b4807a341/300768'
                        },
                        '2x': {
                          'compressed': 'https://staging.cms.hellomamma.eu/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBaFlPIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--9c9203102251598f5ff6475284e1789a59a9051a/medium_110_image_grid_high2x.webp',
                          'regular': 'https://staging.cms.hellomamma.eu/rails/active_storage/representations/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBdjRLIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--00265866b81ff642d90dd1eb1a87b52aa3bb1e96/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdCem9MWm05eWJXRjBTU0lKYW5CbFp3WTZCa1ZVT2hKeVpYTnBlbVZmZEc5ZlptbDBXd2RwQXJvQmFRS1FBUT09IiwiZXhwIjpudWxsLCJwdXIiOiJ2YXJpYXRpb24ifX0=--a4042b1c27fccde84008c28f4e80a1cb5acb0d92/300768'
                        }
                      },
                      {
                        'size': {
                          'width': 309,
                          'height': 280
                        },
                        'mq': {
                          'max': 720
                        },
                        '1x': {
                          'compressed': 'https://staging.cms.hellomamma.eu/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBaDBPIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--92e8e8d01e636019ccedde527836225aebb661e6/medium_110_image_grid_low2x.webp',
                          'regular': 'https://staging.cms.hellomamma.eu/rails/active_storage/representations/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBdjRLIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--00265866b81ff642d90dd1eb1a87b52aa3bb1e96/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdCem9MWm05eWJXRjBTU0lKYW5CbFp3WTZCa1ZVT2hKeVpYTnBlbVZmZEc5ZlptbDBXd2RwQWpVQmFRSVlBUT09IiwiZXhwIjpudWxsLCJwdXIiOiJ2YXJpYXRpb24ifX0=--4da0327a3c465d81779968a0c4f41c580e109896/300768'
                        },
                        '2x': {
                          'compressed': 'https://staging.cms.hellomamma.eu/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBaDBPIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--92e8e8d01e636019ccedde527836225aebb661e6/medium_110_image_grid_low2x.webp',
                          'regular': 'https://staging.cms.hellomamma.eu/rails/active_storage/representations/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBdjRLIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--00265866b81ff642d90dd1eb1a87b52aa3bb1e96/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdCem9MWm05eWJXRjBTU0lKYW5CbFp3WTZCa1ZVT2hKeVpYTnBlbVZmZEc5ZlptbDBXd2RwQWpVQmFRSVlBUT09IiwiZXhwIjpudWxsLCJwdXIiOiJ2YXJpYXRpb24ifX0=--4da0327a3c465d81779968a0c4f41c580e109896/300768'
                        }
                      }
                    ]
                  }
                }
              ]
            }
          ],
          'slug': 'any',
          'products': 'any'
        }
      ],
    },
    {
      'bigcommerce_id': 283,
      'bigcommerce_slug': '/calamarata-n-41/',
      'name': 'Calamarata Nº41',
      'slug': {
        'es': 'calamarata-n-41'
      },
      'brand': 'string',
      'ingredients': 'Sémola de trigo duro, agua.',
      'labels': [
        {
          'name': 'TESTTESTTESTTEST'
        },
        {
          'name': 'Vegan'
        }
      ],
      'filters': [
        {
          'name': 'Marca',
          'param': 'marca',
          'type': 'multiple',
          'values': [
            {
              'name': 'Rummo',
              'value': 'rummo',
              'description': '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque blandit rutrum elit mattis, metus id pharetramattis. Nulla facilisi. Sed mattis, metus id pharetra porttitor, nisi lacus scelerisque quam, ac pretium elit purus id turpis. Praesent ultrices libero turpis, vitae feugiat ante commodo non. Donec et odio quis mauattis, metus id pharetra.</p>',
              'media': {
                'type': 'svg',
                'alt': 'Rummo | HelloMamma',
                'url': 'https://staging.cms.hellomamma.eu/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBL0VqQVE9PSIsImV4cCI6bnVsbCwicHVyIjoiYmxvYl9pZCJ9fQ==--6bf6575ea2815feaa93dd3a404de0873b58ef7bd/Rummo.svg'
              }
            }
          ]
        },
        {
          'name': 'Sin gluten',
          'param': 'sin-gluten',
          'type': 'simple',
          'values': [
            {
              'name': '',
              'value': '',
              'description': '',
              'media': null,
              'visible': true
            }
          ]
        },
        {
          'name': 'Bio',
          'param': 'bio',
          'type': 'simple',
          'values': [
            {
              'name': '',
              'value': '',
              'description': '',
              'media': null,
              'visible': true
            }
          ]
        },
        {
          'name': 'Integral',
          'param': 'integral',
          'type': 'simple',
          'values': [
            {
              'name': '',
              'value': '',
              'description': '',
              'media': null,
              'visible': true
            }
          ]
        }
      ],
      'categories': [
        {
          'id': 64,
          'name': 'Pasta',
          'slug': {
            'es': 'pasta'
          }
        },
        {
          'id': 69,
          'name': 'Pasta seca',
          'slug': {
            'es': 'pasta/pasta-seca'
          }
        }
      ],
      'variant': [
        {
          'bigcommerce_id': 33,
          'sku': 'string',
          'name': 'string',
          'description': 'string',
          'media': 'any',
          'stock': 33232,
          'prices': {
            'retailPrice': { 'exTax': 222, 'incTax': 333 },
            'salePrice': { 'exTax': 323, 'incTax': 333 }
          },
          'variant': [
            {
              'bigcommerce_id': 310,
              'name': '500gr',
              'sku': '1000001170',
              'prices': {
                'price_inc_tax': 2.53,
                'retail_price_inc_tax': 2.77,
                'price_ex_tax': 2.3,
                'retail_price_ex_tax': 2.52
              },
              'stock': 149208,
              'reference_price': 2.86,
              'reference_unit': 'kg',
              'media': [
                {
                  'pdp': {
                    'type': 'image',
                    'alt': '500gr | HelloMamma',
                    'default': {
                      'size': {
                        'width': 520,
                        'height': 430
                      },
                      'mq': {
                        'min': 721
                      },
                      '1x': {
                        'compressed': 'https://staging.cms.hellomamma.eu/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBdmdOIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--d56ad453a42b017743c84fef90f2a5ee1d7215dd/medium_109_image_pdp_high.webp',
                        'regular': 'https://staging.cms.hellomamma.eu/rails/active_storage/representations/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBdjBLIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--2c266e88d55a0dbd38083d88b590a690dd4edc0c/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdCem9MWm05eWJXRjBTU0lKYW5CbFp3WTZCa1ZVT2hKeVpYTnBlbVZmZEc5ZlptbDBXd2RwQWdnQ2FRS3VBUT09IiwiZXhwIjpudWxsLCJwdXIiOiJ2YXJpYXRpb24ifX0=--6ce729c9e05cc405a5d8221272d89a4bfeaa8ea9/300768'
                      },
                      '2x': {
                        'compressed': 'https://staging.cms.hellomamma.eu/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBdkFOIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--66721d6b36993eb949b4a1ccbbc362070f9d79c3/medium_109_image_pdp_high2x.webp',
                        'regular': 'https://staging.cms.hellomamma.eu/rails/active_storage/representations/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBdjBLIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--2c266e88d55a0dbd38083d88b590a690dd4edc0c/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdCem9MWm05eWJXRjBTU0lKYW5CbFp3WTZCa1ZVT2hKeVpYTnBlbVZmZEc5ZlptbDBXd2RwQWhBRWFRSmNBdz09IiwiZXhwIjpudWxsLCJwdXIiOiJ2YXJpYXRpb24ifX0=--05933b2581eea43909bcda8d32f62993d89e70b1/300768'
                      }
                    },
                    'sources': [
                      {
                        'size': {
                          'width': 520,
                          'height': 430
                        },
                        'mq': {
                          'min': 721
                        },
                        '1x': {
                          'compressed': 'https://staging.cms.hellomamma.eu/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBdmdOIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--d56ad453a42b017743c84fef90f2a5ee1d7215dd/medium_109_image_pdp_high.webp',
                          'regular': 'https://staging.cms.hellomamma.eu/rails/active_storage/representations/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBdjBLIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--2c266e88d55a0dbd38083d88b590a690dd4edc0c/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdCem9MWm05eWJXRjBTU0lKYW5CbFp3WTZCa1ZVT2hKeVpYTnBlbVZmZEc5ZlptbDBXd2RwQWdnQ2FRS3VBUT09IiwiZXhwIjpudWxsLCJwdXIiOiJ2YXJpYXRpb24ifX0=--6ce729c9e05cc405a5d8221272d89a4bfeaa8ea9/300768'
                        },
                        '2x': {
                          'compressed': 'https://staging.cms.hellomamma.eu/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBdkFOIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--66721d6b36993eb949b4a1ccbbc362070f9d79c3/medium_109_image_pdp_high2x.webp',
                          'regular': 'https://staging.cms.hellomamma.eu/rails/active_storage/representations/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBdjBLIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--2c266e88d55a0dbd38083d88b590a690dd4edc0c/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdCem9MWm05eWJXRjBTU0lKYW5CbFp3WTZCa1ZVT2hKeVpYTnBlbVZmZEc5ZlptbDBXd2RwQWhBRWFRSmNBdz09IiwiZXhwIjpudWxsLCJwdXIiOiJ2YXJpYXRpb24ifX0=--05933b2581eea43909bcda8d32f62993d89e70b1/300768'
                        }
                      },
                      {
                        'size': {
                          'width': 728,
                          'height': 602
                        },
                        'mq': {
                          'max': 720
                        },
                        '1x': {
                          'compressed': 'https://staging.cms.hellomamma.eu/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBZ0FPIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--c2b234adcd91b405e1d57af0371323f8b282a9a9/medium_109_image_pdp_low2x.webp',
                          'regular': 'https://staging.cms.hellomamma.eu/rails/active_storage/representations/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBdjBLIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--2c266e88d55a0dbd38083d88b590a690dd4edc0c/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdCem9MWm05eWJXRjBTU0lKYW5CbFp3WTZCa1ZVT2hKeVpYTnBlbVZmZEc5ZlptbDBXd2RwQXRnQ2FRSmFBZz09IiwiZXhwIjpudWxsLCJwdXIiOiJ2YXJpYXRpb24ifX0=--ad525c4169b46bd2a6c2887b5334127e37549199/300768'
                        },
                        '2x': {
                          'compressed': 'https://staging.cms.hellomamma.eu/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBZ0FPIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--c2b234adcd91b405e1d57af0371323f8b282a9a9/medium_109_image_pdp_low2x.webp',
                          'regular': 'https://staging.cms.hellomamma.eu/rails/active_storage/representations/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBdjBLIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--2c266e88d55a0dbd38083d88b590a690dd4edc0c/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdCem9MWm05eWJXRjBTU0lKYW5CbFp3WTZCa1ZVT2hKeVpYTnBlbVZmZEc5ZlptbDBXd2RwQXRnQ2FRSmFBZz09IiwiZXhwIjpudWxsLCJwdXIiOiJ2YXJpYXRpb24ifX0=--ad525c4169b46bd2a6c2887b5334127e37549199/300768'
                        }
                      }
                    ]
                  },
                  'grid': {
                    'type': 'image',
                    'alt': '500gr | HelloMamma',
                    'default': {
                      'size': {
                        'width': 221,
                        'height': 200
                      },
                      'mq': {
                        'min': 721
                      },
                      '1x': {
                        'compressed': 'https://staging.cms.hellomamma.eu/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBZzBPIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--9aa8c4348b6120b657932d25e0ff9c5b4c489034/medium_109_image_grid_high.webp',
                        'regular': 'https://staging.cms.hellomamma.eu/rails/active_storage/representations/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBdjBLIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--2c266e88d55a0dbd38083d88b590a690dd4edc0c/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdCem9MWm05eWJXRjBTU0lKYW5CbFp3WTZCa1ZVT2hKeVpYTnBlbVZmZEc5ZlptbDBXd2RwQWQxcEFjZz0iLCJleHAiOm51bGwsInB1ciI6InZhcmlhdGlvbiJ9fQ==--0bd48e19f8c958a9dc74db5820f8555b4807a341/300768'
                      },
                      '2x': {
                        'compressed': 'https://staging.cms.hellomamma.eu/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBZ2NPIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--ef410a1c6f154174c4c2750b1362ea8d173086cd/medium_109_image_grid_high2x.webp',
                        'regular': 'https://staging.cms.hellomamma.eu/rails/active_storage/representations/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBdjBLIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--2c266e88d55a0dbd38083d88b590a690dd4edc0c/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdCem9MWm05eWJXRjBTU0lKYW5CbFp3WTZCa1ZVT2hKeVpYTnBlbVZmZEc5ZlptbDBXd2RwQXJvQmFRS1FBUT09IiwiZXhwIjpudWxsLCJwdXIiOiJ2YXJpYXRpb24ifX0=--a4042b1c27fccde84008c28f4e80a1cb5acb0d92/300768'
                      }
                    },
                    'sources': [
                      {
                        'size': {
                          'width': 221,
                          'height': 200
                        },
                        'mq': {
                          'min': 721
                        },
                        '1x': {
                          'compressed': 'https://staging.cms.hellomamma.eu/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBZzBPIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--9aa8c4348b6120b657932d25e0ff9c5b4c489034/medium_109_image_grid_high.webp',
                          'regular': 'https://staging.cms.hellomamma.eu/rails/active_storage/representations/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBdjBLIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--2c266e88d55a0dbd38083d88b590a690dd4edc0c/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdCem9MWm05eWJXRjBTU0lKYW5CbFp3WTZCa1ZVT2hKeVpYTnBlbVZmZEc5ZlptbDBXd2RwQWQxcEFjZz0iLCJleHAiOm51bGwsInB1ciI6InZhcmlhdGlvbiJ9fQ==--0bd48e19f8c958a9dc74db5820f8555b4807a341/300768'
                        },
                        '2x': {
                          'compressed': 'https://staging.cms.hellomamma.eu/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBZ2NPIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--ef410a1c6f154174c4c2750b1362ea8d173086cd/medium_109_image_grid_high2x.webp',
                          'regular': 'https://staging.cms.hellomamma.eu/rails/active_storage/representations/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBdjBLIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--2c266e88d55a0dbd38083d88b590a690dd4edc0c/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdCem9MWm05eWJXRjBTU0lKYW5CbFp3WTZCa1ZVT2hKeVpYTnBlbVZmZEc5ZlptbDBXd2RwQXJvQmFRS1FBUT09IiwiZXhwIjpudWxsLCJwdXIiOiJ2YXJpYXRpb24ifX0=--a4042b1c27fccde84008c28f4e80a1cb5acb0d92/300768'
                        }
                      },
                      {
                        'size': {
                          'width': 309,
                          'height': 280
                        },
                        'mq': {
                          'max': 720
                        },
                        '1x': {
                          'compressed': 'https://staging.cms.hellomamma.eu/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBaFVPIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--674c6d750aa3f40be8516e466b7176daba61730d/medium_109_image_grid_low2x.webp',
                          'regular': 'https://staging.cms.hellomamma.eu/rails/active_storage/representations/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBdjBLIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--2c266e88d55a0dbd38083d88b590a690dd4edc0c/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdCem9MWm05eWJXRjBTU0lKYW5CbFp3WTZCa1ZVT2hKeVpYTnBlbVZmZEc5ZlptbDBXd2RwQWpVQmFRSVlBUT09IiwiZXhwIjpudWxsLCJwdXIiOiJ2YXJpYXRpb24ifX0=--4da0327a3c465d81779968a0c4f41c580e109896/300768'
                        },
                        '2x': {
                          'compressed': 'https://staging.cms.hellomamma.eu/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBaFVPIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--674c6d750aa3f40be8516e466b7176daba61730d/medium_109_image_grid_low2x.webp',
                          'regular': 'https://staging.cms.hellomamma.eu/rails/active_storage/representations/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBdjBLIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--2c266e88d55a0dbd38083d88b590a690dd4edc0c/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdCem9MWm05eWJXRjBTU0lKYW5CbFp3WTZCa1ZVT2hKeVpYTnBlbVZmZEc5ZlptbDBXd2RwQWpVQmFRSVlBUT09IiwiZXhwIjpudWxsLCJwdXIiOiJ2YXJpYXRpb24ifX0=--4da0327a3c465d81779968a0c4f41c580e109896/300768'
                        }
                      }
                    ]
                  }
                },
                {
                  'pdp': {
                    'type': 'image',
                    'alt': '500gr | HelloMamma',
                    'default': {
                      'size': {
                        'width': 520,
                        'height': 430
                      },
                      'mq': {
                        'min': 721
                      },
                      '1x': {
                        'compressed': 'https://staging.cms.hellomamma.eu/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBZ3NPIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--c9476c50cae4fd8a7270f31a86313344f023f2bd/medium_110_image_pdp_high.webp',
                        'regular': 'https://staging.cms.hellomamma.eu/rails/active_storage/representations/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBdjRLIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--00265866b81ff642d90dd1eb1a87b52aa3bb1e96/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdCem9MWm05eWJXRjBTU0lKYW5CbFp3WTZCa1ZVT2hKeVpYTnBlbVZmZEc5ZlptbDBXd2RwQWdnQ2FRS3VBUT09IiwiZXhwIjpudWxsLCJwdXIiOiJ2YXJpYXRpb24ifX0=--6ce729c9e05cc405a5d8221272d89a4bfeaa8ea9/300768'
                      },
                      '2x': {
                        'compressed': 'https://staging.cms.hellomamma.eu/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBZ2dPIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--cf342d761a1555515b01880298d3884e788d920e/medium_110_image_pdp_high2x.webp',
                        'regular': 'https://staging.cms.hellomamma.eu/rails/active_storage/representations/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBdjRLIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--00265866b81ff642d90dd1eb1a87b52aa3bb1e96/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdCem9MWm05eWJXRjBTU0lKYW5CbFp3WTZCa1ZVT2hKeVpYTnBlbVZmZEc5ZlptbDBXd2RwQWhBRWFRSmNBdz09IiwiZXhwIjpudWxsLCJwdXIiOiJ2YXJpYXRpb24ifX0=--05933b2581eea43909bcda8d32f62993d89e70b1/300768'
                      }
                    },
                    'sources': [
                      {
                        'size': {
                          'width': 520,
                          'height': 430
                        },
                        'mq': {
                          'min': 721
                        },
                        '1x': {
                          'compressed': 'https://staging.cms.hellomamma.eu/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBZ3NPIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--c9476c50cae4fd8a7270f31a86313344f023f2bd/medium_110_image_pdp_high.webp',
                          'regular': 'https://staging.cms.hellomamma.eu/rails/active_storage/representations/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBdjRLIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--00265866b81ff642d90dd1eb1a87b52aa3bb1e96/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdCem9MWm05eWJXRjBTU0lKYW5CbFp3WTZCa1ZVT2hKeVpYTnBlbVZmZEc5ZlptbDBXd2RwQWdnQ2FRS3VBUT09IiwiZXhwIjpudWxsLCJwdXIiOiJ2YXJpYXRpb24ifX0=--6ce729c9e05cc405a5d8221272d89a4bfeaa8ea9/300768'
                        },
                        '2x': {
                          'compressed': 'https://staging.cms.hellomamma.eu/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBZ2dPIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--cf342d761a1555515b01880298d3884e788d920e/medium_110_image_pdp_high2x.webp',
                          'regular': 'https://staging.cms.hellomamma.eu/rails/active_storage/representations/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBdjRLIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--00265866b81ff642d90dd1eb1a87b52aa3bb1e96/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdCem9MWm05eWJXRjBTU0lKYW5CbFp3WTZCa1ZVT2hKeVpYTnBlbVZmZEc5ZlptbDBXd2RwQWhBRWFRSmNBdz09IiwiZXhwIjpudWxsLCJwdXIiOiJ2YXJpYXRpb24ifX0=--05933b2581eea43909bcda8d32f62993d89e70b1/300768'
                        }
                      },
                      {
                        'size': {
                          'width': 728,
                          'height': 602
                        },
                        'mq': {
                          'max': 720
                        },
                        '1x': {
                          'compressed': 'https://staging.cms.hellomamma.eu/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBaElPIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--b47b5a997e891a354d1af020421e9efcb5da1f12/medium_110_image_pdp_low2x.webp',
                          'regular': 'https://staging.cms.hellomamma.eu/rails/active_storage/representations/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBdjRLIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--00265866b81ff642d90dd1eb1a87b52aa3bb1e96/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdCem9MWm05eWJXRjBTU0lKYW5CbFp3WTZCa1ZVT2hKeVpYTnBlbVZmZEc5ZlptbDBXd2RwQXRnQ2FRSmFBZz09IiwiZXhwIjpudWxsLCJwdXIiOiJ2YXJpYXRpb24ifX0=--ad525c4169b46bd2a6c2887b5334127e37549199/300768'
                        },
                        '2x': {
                          'compressed': 'https://staging.cms.hellomamma.eu/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBaElPIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--b47b5a997e891a354d1af020421e9efcb5da1f12/medium_110_image_pdp_low2x.webp',
                          'regular': 'https://staging.cms.hellomamma.eu/rails/active_storage/representations/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBdjRLIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--00265866b81ff642d90dd1eb1a87b52aa3bb1e96/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdCem9MWm05eWJXRjBTU0lKYW5CbFp3WTZCa1ZVT2hKeVpYTnBlbVZmZEc5ZlptbDBXd2RwQXRnQ2FRSmFBZz09IiwiZXhwIjpudWxsLCJwdXIiOiJ2YXJpYXRpb24ifX0=--ad525c4169b46bd2a6c2887b5334127e37549199/300768'
                        }
                      }
                    ]
                  },
                  'grid': {
                    'type': 'image',
                    'alt': '500gr | HelloMamma',
                    'default': {
                      'size': {
                        'width': 221,
                        'height': 200
                      },
                      'mq': {
                        'min': 721
                      },
                      '1x': {
                        'compressed': 'https://staging.cms.hellomamma.eu/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBaG9PIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--ebd6497b55e6ee19f4d00d7e0374f07a6b895535/medium_110_image_grid_high.webp',
                        'regular': 'https://staging.cms.hellomamma.eu/rails/active_storage/representations/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBdjRLIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--00265866b81ff642d90dd1eb1a87b52aa3bb1e96/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdCem9MWm05eWJXRjBTU0lKYW5CbFp3WTZCa1ZVT2hKeVpYTnBlbVZmZEc5ZlptbDBXd2RwQWQxcEFjZz0iLCJleHAiOm51bGwsInB1ciI6InZhcmlhdGlvbiJ9fQ==--0bd48e19f8c958a9dc74db5820f8555b4807a341/300768'
                      },
                      '2x': {
                        'compressed': 'https://staging.cms.hellomamma.eu/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBaFlPIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--9c9203102251598f5ff6475284e1789a59a9051a/medium_110_image_grid_high2x.webp',
                        'regular': 'https://staging.cms.hellomamma.eu/rails/active_storage/representations/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBdjRLIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--00265866b81ff642d90dd1eb1a87b52aa3bb1e96/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdCem9MWm05eWJXRjBTU0lKYW5CbFp3WTZCa1ZVT2hKeVpYTnBlbVZmZEc5ZlptbDBXd2RwQXJvQmFRS1FBUT09IiwiZXhwIjpudWxsLCJwdXIiOiJ2YXJpYXRpb24ifX0=--a4042b1c27fccde84008c28f4e80a1cb5acb0d92/300768'
                      }
                    },
                    'sources': [
                      {
                        'size': {
                          'width': 221,
                          'height': 200
                        },
                        'mq': {
                          'min': 721
                        },
                        '1x': {
                          'compressed': 'https://staging.cms.hellomamma.eu/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBaG9PIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--ebd6497b55e6ee19f4d00d7e0374f07a6b895535/medium_110_image_grid_high.webp',
                          'regular': 'https://staging.cms.hellomamma.eu/rails/active_storage/representations/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBdjRLIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--00265866b81ff642d90dd1eb1a87b52aa3bb1e96/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdCem9MWm05eWJXRjBTU0lKYW5CbFp3WTZCa1ZVT2hKeVpYTnBlbVZmZEc5ZlptbDBXd2RwQWQxcEFjZz0iLCJleHAiOm51bGwsInB1ciI6InZhcmlhdGlvbiJ9fQ==--0bd48e19f8c958a9dc74db5820f8555b4807a341/300768'
                        },
                        '2x': {
                          'compressed': 'https://staging.cms.hellomamma.eu/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBaFlPIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--9c9203102251598f5ff6475284e1789a59a9051a/medium_110_image_grid_high2x.webp',
                          'regular': 'https://staging.cms.hellomamma.eu/rails/active_storage/representations/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBdjRLIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--00265866b81ff642d90dd1eb1a87b52aa3bb1e96/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdCem9MWm05eWJXRjBTU0lKYW5CbFp3WTZCa1ZVT2hKeVpYTnBlbVZmZEc5ZlptbDBXd2RwQXJvQmFRS1FBUT09IiwiZXhwIjpudWxsLCJwdXIiOiJ2YXJpYXRpb24ifX0=--a4042b1c27fccde84008c28f4e80a1cb5acb0d92/300768'
                        }
                      },
                      {
                        'size': {
                          'width': 309,
                          'height': 280
                        },
                        'mq': {
                          'max': 720
                        },
                        '1x': {
                          'compressed': 'https://staging.cms.hellomamma.eu/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBaDBPIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--92e8e8d01e636019ccedde527836225aebb661e6/medium_110_image_grid_low2x.webp',
                          'regular': 'https://staging.cms.hellomamma.eu/rails/active_storage/representations/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBdjRLIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--00265866b81ff642d90dd1eb1a87b52aa3bb1e96/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdCem9MWm05eWJXRjBTU0lKYW5CbFp3WTZCa1ZVT2hKeVpYTnBlbVZmZEc5ZlptbDBXd2RwQWpVQmFRSVlBUT09IiwiZXhwIjpudWxsLCJwdXIiOiJ2YXJpYXRpb24ifX0=--4da0327a3c465d81779968a0c4f41c580e109896/300768'
                        },
                        '2x': {
                          'compressed': 'https://staging.cms.hellomamma.eu/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBaDBPIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--92e8e8d01e636019ccedde527836225aebb661e6/medium_110_image_grid_low2x.webp',
                          'regular': 'https://staging.cms.hellomamma.eu/rails/active_storage/representations/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBdjRLIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--00265866b81ff642d90dd1eb1a87b52aa3bb1e96/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdCem9MWm05eWJXRjBTU0lKYW5CbFp3WTZCa1ZVT2hKeVpYTnBlbVZmZEc5ZlptbDBXd2RwQWpVQmFRSVlBUT09IiwiZXhwIjpudWxsLCJwdXIiOiJ2YXJpYXRpb24ifX0=--4da0327a3c465d81779968a0c4f41c580e109896/300768'
                        }
                      }
                    ]
                  }
                }
              ]
            }
          ],
          'slug': 'any',
          'products': 'any'
        }
      ],
    }
  ],
  'summary': {
    'totalUnits': 4,
    'subtotalIncTax': 435,
    'discountsApplied': 0,
    'coupon': {
      'code': 'string',
      'value': 0,
    },
    'shippingCost': 20, // BC => cost_inc_tax
    'totalIncTax': 450    // BC => grand_total
  }
}


export const apiSearchResults = {
  products: [
    {
      name: 'Spumante Falanghina',
      media: {
        type: 'image' as const,
        alt: 'product thumbnail',
        default: {
          src: '',
          size: {
            width: 40,
            height: 40
          }
        }
      },
      href: {
        pathname: '/product/[slug]',
        query: {
          slug: 'product-slug-here'
        }
      }
    },
    {
      name: 'Spumante Falanghina',
      media: {
        type: 'image' as const,
        alt: 'product thumbnail',
        default: {
          src: '',
          size: {
            width: 40,
            height: 40
          }
        }
      },
      href: {
        pathname: '/product/[slug]',
        query: {
          slug: 'product-slug-here'
        }
      }
    },
    {
      name: 'Spumante Falanghina',
      media: {
        type: 'image' as const,
        alt: 'product thumbnail',
        default: {
          src: '',
          size: {
            width: 40,
            height: 40
          }
        }
      },
      href: {
        pathname: '/product/[slug]',
        query: {
          slug: 'product-slug-here'
        }
      }
    },
    {
      name: 'Spumante Falanghina',
      media: {
        type: 'image' as const,
        alt: 'product thumbnail',
        default: {
          src: '',
          size: {
            width: 40,
            height: 40
          }
        }
      },
      href: {
        pathname: '/product/[slug]',
        query: {
          slug: 'product-slug-here'
        }
      }
    },
    {
      name: 'Spumante Falanghina',
      media: {
        type: 'image' as const,
        alt: 'product thumbnail',
        default: {
          src: '',
          size: {
            width: 40,
            height: 40
          }
        }
      },
      href: {
        pathname: '/product/[slug]',
        query: {
          slug: 'product-slug-here'
        }
      }
    },
    {
      name: 'Spumante Falanghina',
      media: {
        type: 'image' as const,
        alt: 'product thumbnail',
        default: {
          src: '',
          size: {
            width: 40,
            height: 40
          }
        }
      },
      href: {
        pathname: '/product/[slug]',
        query: {
          slug: 'product-slug-here'
        }
      }
    },
    {
      name: 'Spumante Falanghina',
      media: {
        type: 'image' as const,
        alt: 'product thumbnail',
        default: {
          src: '',
          size: {
            width: 40,
            height: 40
          }
        }
      },
      href: {
        pathname: '/product/[slug]',
        query: {
          slug: 'product-slug-here'
        }
      }
    },
    {
      name: 'Spumante Falanghina',
      media: {
        type: 'image' as const,
        alt: 'product thumbnail',
        default: {
          src: '',
          size: {
            width: 40,
            height: 40
          }
        }
      },
      href: {
        pathname: '/product/[slug]',
        query: {
          slug: 'product-slug-here'
        }
      }
    },
    {
      name: 'Spumante Falanghina',
      media: {
        type: 'image' as const,
        alt: 'product thumbnail',
        default: {
          src: '',
          size: {
            width: 40,
            height: 40
          }
        }
      },
      href: {
        pathname: '/product/[slug]',
        query: {
          slug: 'product-slug-here'
        }
      }
    },
    {
      name: 'Spumante Falanghina',
      media: {
        type: 'image' as const,
        alt: 'product thumbnail',
        default: {
          src: '',
          size: {
            width: 40,
            height: 40
          }
        }
      },
      href: {
        pathname: '/product/[slug]',
        query: {
          slug: 'product-slug-here'
        }
      }
    },
    {
      name: 'Spumante Falanghina',
      media: {
        type: 'image' as const,
        alt: 'product thumbnail',
        default: {
          src: '',
          size: {
            width: 40,
            height: 40
          }
        }
      },
      href: {
        pathname: '/product/[slug]',
        query: {
          slug: 'product-slug-here'
        }
      }
    },
    {
      name: 'Spumante Falanghina',
      media: {
        type: 'image' as const,
        alt: 'product thumbnail',
        default: {
          src: '',
          size: {
            width: 40,
            height: 40
          }
        }
      },
      href: {
        pathname: '/product/[slug]',
        query: {
          slug: 'product-slug-here'
        }
      }
    },
    {
      name: 'Spumante Falanghina',
      media: {
        type: 'image' as const,
        alt: 'product thumbnail',
        default: {
          src: '',
          size: {
            width: 40,
            height: 40
          }
        }
      },
      href: {
        pathname: '/product/[slug]',
        query: {
          slug: 'product-slug-here'
        }
      }
    },
    {
      name: 'Spumante Falanghina',
      media: {
        type: 'image' as const,
        alt: 'product thumbnail',
        default: {
          src: '',
          size: {
            width: 40,
            height: 40
          }
        }
      },
      href: {
        pathname: '/product/[slug]',
        query: {
          slug: 'product-slug-here'
        }
      }
    },
    {
      name: 'Spumante Falanghina',
      media: {
        type: 'image' as const,
        alt: 'product thumbnail',
        default: {
          src: '',
          size: {
            width: 40,
            height: 40
          }
        }
      },
      href: {
        pathname: '/product/[slug]',
        query: {
          slug: 'product-slug-here'
        }
      }
    },
    {
      name: 'Spumante Falanghina',
      media: {
        type: 'image' as const,
        alt: 'product thumbnail',
        default: {
          src: '',
          size: {
            width: 40,
            height: 40
          }
        }
      },
      href: {
        pathname: '/product/[slug]',
        query: {
          slug: 'product-slug-here'
        }
      }
    },
    {
      name: 'Spumante Falanghina',
      media: {
        type: 'image' as const,
        alt: 'product thumbnail',
        default: {
          src: '',
          size: {
            width: 40,
            height: 40
          }
        }
      },
      href: {
        pathname: '/product/[slug]',
        query: {
          slug: 'product-slug-here'
        }
      }
    },
    {
      name: 'Spumante Falanghina',
      media: {
        type: 'image' as const,
        alt: 'product thumbnail',
        default: {
          src: '',
          size: {
            width: 40,
            height: 40
          }
        }
      },
      href: {
        pathname: '/product/[slug]',
        query: {
          slug: 'product-slug-here'
        }
      }
    },
    {
      name: 'Spumante Falanghina',
      media: {
        type: 'image' as const,
        alt: 'product thumbnail',
        default: {
          src: '',
          size: {
            width: 40,
            height: 40
          }
        }
      },
      href: {
        pathname: '/product/[slug]',
        query: {
          slug: 'product-slug-here'
        }
      }
    },
    {
      name: 'Spumante Falanghina',
      media: {
        type: 'image' as const,
        alt: 'product thumbnail',
        default: {
          src: '',
          size: {
            width: 40,
            height: 40
          }
        }
      },
      href: {
        pathname: '/product/[slug]',
        query: {
          slug: 'product-slug-here'
        }
      }
    },
    {
      name: 'Spumante Falanghina',
      media: {
        type: 'image' as const,
        alt: 'product thumbnail',
        default: {
          src: '',
          size: {
            width: 40,
            height: 40
          }
        }
      },
      href: {
        pathname: '/product/[slug]',
        query: {
          slug: 'product-slug-here'
        }
      }
    },
    {
      name: 'Spumante Falanghina',
      media: {
        type: 'image' as const,
        alt: 'product thumbnail',
        default: {
          src: '',
          size: {
            width: 40,
            height: 40
          }
        }
      },
      href: {
        pathname: '/product/[slug]',
        query: {
          slug: 'product-slug-here'
        }
      }
    },
    {
      name: 'Spumante Falanghina',
      media: {
        type: 'image' as const,
        alt: 'product thumbnail',
        default: {
          src: '',
          size: {
            width: 40,
            height: 40
          }
        }
      },
      href: {
        pathname: '/product/[slug]',
        query: {
          slug: 'product-slug-here'
        }
      }
    },
    {
      name: 'Spumante Falanghina',
      media: {
        type: 'image' as const,
        alt: 'product thumbnail',
        default: {
          src: '',
          size: {
            width: 40,
            height: 40
          }
        }
      },
      href: {
        pathname: '/product/[slug]',
        query: {
          slug: 'product-slug-here'
        }
      }
    },
    {
      name: 'Spumante Falanghina',
      media: {
        type: 'image' as const,
        alt: 'product thumbnail',
        default: {
          src: '',
          size: {
            width: 40,
            height: 40
          }
        }
      },
      href: {
        pathname: '/product/[slug]',
        query: {
          slug: 'product-slug-here'
        }
      }
    },
    {
      name: 'Spumante Falanghina',
      media: {
        type: 'image' as const,
        alt: 'product thumbnail',
        default: {
          src: '',
          size: {
            width: 40,
            height: 40
          }
        }
      },
      href: {
        pathname: '/product/[slug]',
        query: {
          slug: 'product-slug-here'
        }
      }
    },
    {
      name: 'Spumante Falanghina',
      media: {
        type: 'image' as const,
        alt: 'product thumbnail',
        default: {
          src: '',
          size: {
            width: 40,
            height: 40
          }
        }
      },
      href: {
        pathname: '/product/[slug]',
        query: {
          slug: 'product-slug-here'
        }
      }
    },
    {
      name: 'Spumante Falanghina',
      media: {
        type: 'image' as const,
        alt: 'product thumbnail',
        default: {
          src: '',
          size: {
            width: 40,
            height: 40
          }
        }
      },
      href: {
        pathname: '/product/[slug]',
        query: {
          slug: 'product-slug-here'
        }
      }
    }
  ]
}
