'use-client'
import type { FC } from 'react'
import { useEffect, memo } from 'react'
import type { SubmitHandler } from 'react-hook-form'
import { useForm } from 'react-hook-form'
import { useMutation } from '@apollo/client'
import { LOGIN_MUTATION } from '@/api'
// import { CURRENT_USER_QUERY } from './User'
import { Form, Fieldset, Input, Button } from '@dy/commons/components'
import type { TypeSectionAuthLevel,  } from '@/components'
import { useTranslator } from '@/hooks'
import { autoComplete } from '@dy/commons/utils'
import type { TypeThemeColors } from '@dy/commons/components'




type Props = {
  updateLevel?: (level: TypeSectionAuthLevel, close?: boolean) => void
  animation?: any,
  legend?: boolean,
  bgColor?: TypeThemeColors
}

type TypeFormInputs = {
  email: string,
  password: string
}

// type CustomerLoginResponse = {
//   customerLogin: {
//     token: string
//     customer: {
//       id: string
//       firstName: string
//       lastName: string
//     }
//   }
// }

export const FormUserLogIn:FC<Props> = memo(({ updateLevel, animation, legend = true, bgColor = 'white' }) => {
  const { t } = useTranslator()
  const { register, handleSubmit, watch, formState: { errors:formErrors, isValid } } = useForm<TypeFormInputs>()

  const [login, { loading, error: apolloError, data }] = useMutation(LOGIN_MUTATION, { context: { endpoint: 'BC' }})

  console.log(watch('email'))

  const onSubmit: SubmitHandler<TypeFormInputs> = async (formData, e) => {
    e.preventDefault()
    console.log('⭕️ LogIn onSubmit - formData:', formData)
    console.log('⭕️ LogIn onSubmit - isValid:', isValid)
    const { email, password } = formData

    try {
      const { data } = await login({ variables: { email, password } })

      console.table('🟣🟣🟣 data BC RES', data.login)
      // TODO -
      // Reset FORM & close modal
    } catch(e) {
      console.error('Login failed:', e)
    }
  }

  const onSubmitError = (errors, e) => console.log(errors, e)

  useEffect(() => {
    if(Object.keys(formErrors).length > 0) console.log('LogIn formErrors:', formErrors)
  }, [formErrors])

  useEffect(() => {
    console.log('apolloError', apolloError)
    if (data) console.log('🟢 useMutation loginCustomer data:', data)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return (
    <Form onSubmit={handleSubmit(onSubmit, onSubmitError)} {...animation && animation}>
      <Fieldset disabled={loading} ariaBusy={loading} legend={legend ? t('commons.modals.auth.login.text') as string : ''}>
        <Input type='email' name='email' register={register} label={t('commons.form.fields.email') as string} placeholder='email' autoComplete={autoComplete.email} required error={formErrors.email} bgColor={bgColor}/>
        <Input type='password' name='password' register={register} label={t('commons.form.fields.password') as string} placeholder='password' autoComplete={autoComplete.password.current} required icon={'password'} bgColor={bgColor}/>
      </Fieldset>
      <Button type='submit'>{t('commons.actions.login')}</Button>
      <Button type='button' onClick={() => { updateLevel('signup') }}>{t('commons.actions.signup')}</Button>
    </Form>
  )
})

