'use-client'
import type { FC } from 'react'
import { useEffect, memo, useMemo } from 'react'
import { useRouter } from 'next/router'
import type { SubmitHandler } from 'react-hook-form'
import { useForm } from 'react-hook-form'
import { useMutation } from '@apollo/client'
import { CUSTOMER_VAT_NUMBER } from '@/api'
import { Form, Fieldset, Input, Button } from '@dy/commons/components'
import type { TypeSectionAuthLevel,  } from '@/components'
import { useTranslator } from '@/hooks'
import { getLocaleCountry, validate } from '@dy/commons/utils'
import type { TypeThemeColors } from '@dy/commons/components'

type Props = {
  updateLevel?: (level: TypeSectionAuthLevel, close?: boolean) => void
  animation?: any,
  legend?: boolean,
  bgColor?: TypeThemeColors
}

type TypeFormInputs = {
  vatNumber: string,
}

export const FormUserSignUpCIF:FC<Props> = memo(({ updateLevel, animation, legend = true }) => {
  const { t } = useTranslator()
  const { register, handleSubmit, formState: { errors:formErrors, isValid } } = useForm<TypeFormInputs>()
  const { locale } = useRouter()
  const countryCode = useMemo(() => getLocaleCountry(locale).split('-')[1], [locale])

  const [customerByVatNumber, { loading, error: apolloError, data }] = useMutation(CUSTOMER_VAT_NUMBER, { context: { endpoint: 'BC' }})

  const onSubmit: SubmitHandler<TypeFormInputs> = async (formData, e) => {
    e.preventDefault()
    console.log('⭕️ customerByVatNumber onSubmit - data:', data)
    console.log('⭕️ customerByVatNumber onSubmit - isValid:', isValid)

    try {
      const { data } = await customerByVatNumber({
        variables: {
          vatNumber: formData.vatNumber
        }
      })

      console.log('>> data.customerByVatNumber: ', data.customerByVatNumber)
      if (data.customerByVatNumber?.valid) updateLevel('signup')
      // TODO -
      // Reset FORM & close modal
    } catch(e) {
      console.error('customerByVatNumber failed:', e)
    }
  }

  const onSubmitError = (errors, e) => console.log(errors, e)

  useEffect(() => {
    if(Object.keys(formErrors).length > 0) console.log('customerByVatNumber formErrors:', formErrors)
  }, [formErrors])

  useEffect(() => {
    console.log('apolloError', apolloError)
    if (data) console.log('🟢 useMutation loginCustomer data:', data)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return (
    <Form onSubmit={handleSubmit(onSubmit, onSubmitError)} {...animation && animation}>
      <Fieldset disabled={loading} ariaBusy={loading} legend={legend ? t('commons.modals.auth.signup_cif.text') as string : ''}>
        <Input name='vatNumber' register={register} label={t('commons.form.fields.cif') as string} required={{
          required: true,
          // cif validation only available for Spain by now
          ...countryCode === 'ES' && {
            validate: (value) => validate.cif(value) || (t('commons.form.errors.validate_cif') as string)
          }
        }} error={formErrors.vatNumber} bgColor={'white'} />
      </Fieldset>
      <Button type='submit'>{t('commons.actions.continue')}</Button>
      <p className='subtext'>{t('commons.modals.auth.signup_cif.subtext')} <button onClick={() => { updateLevel('login')}}>{t('commons.modals.auth.signup_cif.subtext_action')}</button>.</p>
    </Form>
  )
})

