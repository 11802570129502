'use-client'
import type { FC } from 'react'
import { useEffect, memo } from 'react'
import type { SubmitHandler } from 'react-hook-form'
import { useForm } from 'react-hook-form'

import { Form, Fieldset, Input, Button } from '@dy/commons/components'
import type { TypeSectionAuthLevel } from '@/components'
import { useTranslator } from '@/hooks'
import { autoComplete } from '@dy/commons/utils'

type Props = {
  updateLevel: (level: TypeSectionAuthLevel, close?: boolean) => void
  animation?: any
}

type TypeFormInputs = {
  email: string
}

// temp loading until useMutation updated
let loading = false

export const FormUserPreLogin:FC<Props> = memo(({ updateLevel, animation }) => {
  const { t } = useTranslator()
  const { register, handleSubmit, formState: { errors:formErrors, isValid } } = useForm<TypeFormInputs>()

  const onSubmit: SubmitHandler<TypeFormInputs> = async (data, e) => {
    console.log('⭕️ Pre Login onSubmit - data:', data)
    console.log('⭕️ Pre Login onSubmit - e:', e)
    console.log('⭕️ Pre Login onSubmit - isValid:', isValid)


    const customerExist = true
    updateLevel(customerExist ? 'login' : 'signup')
  }

  const onSubmitError = (errors, e) => console.log(errors, e)

  useEffect(() => {
    if(Object.keys(formErrors).length > 0) console.log('LogIn formErrors:', formErrors)
  }, [formErrors])

  return (
    <Form onSubmit={handleSubmit(onSubmit, onSubmitError)} {...animation && animation}>
      <Fieldset disabled={loading} ariaBusy={loading} legend={t('commons.modals.auth.initial.text') as string}>
        <Input type='email' name='email' register={register} label='Email' placeholder='email' autoComplete={autoComplete.email} required error={formErrors.email} bgColor={'white'} />
      </Fieldset>
      <Button type='submit'>{t('commons.actions.continue')}</Button>
    </Form>
  )
})

