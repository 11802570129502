import { memo, useMemo } from 'react'
import type { FC } from 'react'
import { css } from 'styled-components'
import { useModal } from '@dy/commons/hooks'
import { MODALS } from '@dy/commons/context'
import { mq, vw } from '@dy/commons/styles'
import { Modal } from '.'
import { useRouter } from 'next/router'

import { useTranslator } from '@/hooks'
import { navItems, getNavItems } from '..'

type MenuProps = {}


export const dialogStyles = css`
  background: ${({ theme }) => theme.colors.white};
  margin: 0;
  min-height: 100vh;
  width: 100vw;

  ${mq.greaterThan('tablet')`
    max-width: ${vw(400, 'desktop')};
    width: auto;
  `}

  ${mq.greaterThan('desktop')`
    max-width: 400px;
  `}

`


export const dialogVariants = (isActive: boolean) => ({
  initial: { x: '-100%' },
  animate: { x: isActive ? 0 : '-100%' },
  exit: { x: '-100%' },
  transition: { duration: .3 }
})

export const ModalAccount: FC<MenuProps> = memo(() => {
  const { isActive, toggle } = useModal(MODALS.MENU_ACCOUNT)
  const { t } = useTranslator()
  const { asPath } = useRouter()

  const NavItems = useMemo(() => getNavItems(navItems, asPath, toggle), [asPath, toggle])


  return (
    <Modal isActive={isActive} close={toggle} title={t('layout.nav.account') as string} xAlign='flex-end' variants={dialogVariants} dialogStyles={dialogStyles} hasHeader={true} zIndex={10000000}>      
      {NavItems}
    </Modal>
  )
})
