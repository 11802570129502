import { useMemo } from 'react'
import styled from 'styled-components'
import { AnimatePresence, motion } from 'framer-motion'
import { useSlider } from '@dy/commons/hooks'
import { mq, vw, getTransition } from '@dy/commons/styles'

const Aside = styled.aside`
  height: 40px;
  background-color: ${({ theme }) => theme.colors.creme};
  position: relative;
  top: 0;
  width: 100%;
  z-index: 9998;

  ${mq.greaterThan('tablet')`
    height: ${vw(30, 'desktop')};
  `}

  div {
    display: flex;
    align-items: center;
    height: 100%;
  }

  /* Don't use flex in the paragraph, it removes the spaces between links when comes from CMS */
  p {
    width: 100%;
  }

  a {
    text-decoration: underline;
  }

  p, a {
    font-size: 10px;
    line-height: 14px;
    font-family: ${({ theme }) => theme.fonts.secondaryFont};
    text-align: center;
    color: ${({ theme }) => theme.colors.white};

    ${mq.greaterThan('iphone6')`
      font-size: 13px;
      line-height: 16px;
    `}

    ${mq.greaterThan('tablet')`
      font-size: ${vw(13, 'desktop')};
      line-height: ${vw(16, 'desktop')};
    `}
  }

  ~ .pdp-back-btn {
    ${mq.greaterThan('tablet')`
      top: ${vw(200, 'desktop')};
    `}
  }

  ~ div.main {

  }
`

const bannerVariants = {
  inactive: {
    y: '-10px',
    opacity: 0,
    ...getTransition()
  },
  active: {
    y: '0',
    opacity: 1,
    ...getTransition()
  },
  exit: {
    y: '10px',
    opacity: 0,
    ...getTransition()
  }
}

export const SlimBanners = ({ banners }) => {
  const { currentSlide } = useSlider(banners.length, true)
  const Slide = useMemo(() => {
    return banners[currentSlide]?.title
  }, [currentSlide, banners])

  return <Aside key={'slims'}>
    <AnimatePresence mode='wait'>
      <motion.div key={currentSlide} variants={bannerVariants} initial='inactive' exit='exit' animate='active' dangerouslySetInnerHTML={{ __html: Slide }} />
    </AnimatePresence>
  </Aside>
}
