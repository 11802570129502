import { useMemo, memo } from 'react'
import { useRouter } from 'next/router'
import { AnimatePresence } from 'framer-motion'
import { currencyFormatter, getLang } from '@dy/commons/utils'
import { useScrollTracker } from '@dy/commons/hooks'
import { ModalSummary, ModalNewList } from '.'
import { CartItem } from './CartItem'
import { itemVariants, listVariants } from './cartVariants'
// import useCart from '@custom-bigcommerce/storefront-data-hooks/cart/use-cart'

interface ICartItemsNew {
  products: any,
  hasBanners: boolean
}

const TRANSLATIONS = {
  'en': {
    product_added: 'Total',
  },
  'es': {
    product_added: 'Total',
  },
  'ca': {
    product_added: 'Total',
  },
  'fr': {
    product_added: 'Total',
  },
  'de': {
    product_added: 'Total',
  }
}

const getProducts = (products) => products.map((product, idx) => (
  <CartItem key={`cartitem-${idx}`} product={product} id={`cartitem-${idx}`} type={'new'} />
))

// TODO: remove fake data
const dataCart = {
  cart_amount: 100,
  line_items: {
    physical_items: [
      {
        name: 'Test Product', 
        quantity: 1, 
        image_url: 'https://cms.hellomamma.eu/rails/active_storage/representations/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBNEtrQWc9PSIsImV4cCI6bnVsbCwicHVyIjoiYmxvYl9pZCJ9fQ==--f3baa1404d66bf4f4b4d71fce67199e35035c261/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdCem9MWm05eWJXRjBTU0lJYW5CbkJqb0dSVlE2RW5KbGMybDZaVjkwYjE5bWFYUmJCMmtDdWdGcEFwQUIiLCJleHAiOm51bGwsInB1ciI6InZhcmlhdGlvbiJ9fQ==--1354cc82b2730267a5c770e087038e98ca9a46b3/piennolo.jpg', 
        price: 10
      }
    ]
  }
}

export const CartItemsNew = memo<ICartItemsNew>(({ products = [], hasBanners }) => {
  const scrollState = useScrollTracker(1)
  const { locale:localeRouter } = useRouter()
  const locale = getLang(localeRouter)
  // const { data: dataCart } = useCart()

  // const Products = useMemo(() => getProducts(products.slice(0, 5), locale), [products, locale])
  const Products = useMemo(() => getProducts(dataCart.line_items.physical_items.slice(0, 5)), [dataCart])
  if(!Products) return null

  return (
    <AnimatePresence mode={'wait'}>
      {products.length > 0 && (
        <ModalNewList key='itemsincart' variants={listVariants} initial='inactive' animate={'enter'} exit={'exit'} hasBanners={hasBanners} $top={scrollState === 'TOP'}>
          {Products}
          <ModalSummary key={'itemincart-dt'} variants={itemVariants} $type={'new'}>
            <span>{TRANSLATIONS[locale].product_added} ({dataCart?.line_items?.physical_items?.length})</span>
            <span>{currencyFormatter({ price: dataCart?.cart_amount, localeRegion: locale, round: false, trailingZeros: true })}</span>
          </ModalSummary>
        </ModalNewList>
      )}
    </AnimatePresence>
  )
})
