import { memo, ReactNode } from 'react'
import { ContentHeader } from './contentStyles'

interface IHeaderContents {
  children: ReactNode
}

export const HeaderContents = memo<IHeaderContents>(({ children }) => {
  return (
    <ContentHeader>
      {children}
    </ContentHeader>
  )
})
