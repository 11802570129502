import { memo, useEffect, useMemo, useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import { useDeviceType, useMount } from '@dy/commons/hooks'
import { useTranslator } from '@/hooks'
import { Figure } from '@dy/commons/components'
import { BrandWrapper, BrandSection } from './homeStyles'
import { logoVariants, wrapperVariants } from './homeVariants'
interface IBrandsSection {
  brands: any
}

const mediaBrandLogo = {
  type: 'image' as const,
  alt: 'Icon chevron',
  default: {
    src:'',
    size: { width: 6, height: 8 }
  }
}

const getLogos = (images) => images.map((img, idx) => {
  const mediaWithSource = {...mediaBrandLogo, default:{...mediaBrandLogo.default, src: img} }
  return (
    <AnimatePresence initial={false} key={idx} mode='wait'>
      <motion.article className='brand' key={img} variants={logoVariants} initial='initial' exit='exit' animate='animate'>
        <Figure media={mediaWithSource}/>
      </motion.article>
    </AnimatePresence>
  )
})

const getNewLogoArray = (prevLogoArray, images) => {
  const missingLogoArray = images.filter(img => {
    return !prevLogoArray.includes(img)
  })

  const newArrayLogo = Math.floor(Math.random() * missingLogoArray.length)
  const oldArrayLogo = Math.floor(Math.random() * prevLogoArray.length)

  const updatedArray = [...prevLogoArray]
  updatedArray[oldArrayLogo] = missingLogoArray[newArrayLogo]

  return updatedArray
}


export const BrandsSection = memo<IBrandsSection>(({ brands }) => {
  const images = brands.map(brand => brand.logo.url)
  const { isMobile } = useDeviceType()
  const initialArray = images.slice(0, isMobile ? 3 : 5)
  const [logoArray, changeLogoArray] = useState(initialArray)
  const isMounted = useMount()
  const { t } = useTranslator()

  const [ref, inView] = useInView({
    rootMargin: '0px', // TOP  RIGHT
    threshold: 1,
    triggerOnce: true
  })

  const Logos = useMemo(() => getLogos(logoArray), [logoArray])

  useEffect(() => {
    if (images.length > initialArray.length) {
      const interval = setInterval(() => {
        changeLogoArray(prevLogoArray => getNewLogoArray(prevLogoArray, images))
      }, 3200)

      return () => clearInterval(interval)
    }
  }, [isMounted, Logos, images, initialArray])

  return (
    <BrandSection ref={ref}>
      <h2>{t('home.brands.brands_title')}</h2>
      <BrandWrapper variants={wrapperVariants} animate={inView ? 'active' : 'inactive'} initial='inactive'>
        {Logos}
      </BrandWrapper>
    </BrandSection>
  )
})
