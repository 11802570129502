"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Select = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const framer_motion_1 = require("framer-motion");
const styled_components_1 = __importStar(require("styled-components"));
const styles_1 = require("../../styles");
const Input_1 = require("./Input");
const dropdown_1 = require("../dropdown");
const media_1 = require("../media");
const hasContentState = () => (0, styled_components_1.css) `
  ${(0, styles_1.getP18InputHasContentLabel)()}
  opacity: 1;
  transform: translateY(-${(0, styles_1.vw)(35, 'mobile')});

  ${styles_1.mq.greaterThan('tablet') `
    transform: translateY(-${(0, styles_1.vw)(35, 'desktop')});
  `}
`;
const Div = styled_components_1.default.div `
  padding: 0 ${(0, styles_1.vw)(4.25, 'mobile')};
  position: relative;
  width: 100%;

  ${styles_1.mq.greaterThan('tablet') `
    padding: 0 ${(0, styles_1.vw)(12.5, 'desktop')};
    width: ${({ $col }) => typeof $col === 'number' ? `${100 * $col / 12}%` : $col};
  `}
`;
const Ul = (0, styled_components_1.default)(framer_motion_1.motion.ul) `
  background: white;
  margin-top: 1px;
  max-height: ${(0, styles_1.vw)(20 * 5.5 + 4.25 * 6, 'mobile')};
  overflow-y: auto;
  padding: ${(0, styles_1.vw)(8.5, 'mobile')} 0 ${(0, styles_1.vw)(12.5, 'mobile')};
  position: absolute;
  top: ${(0, styles_1.vw)(60, 'mobile')};
  width: calc(100% - ${(0, styles_1.vw)(8.5, 'mobile')});
  z-index: 5;

  ${styles_1.mq.greaterThan('tablet') `
    max-height: ${(0, styles_1.vw)(32 * 5.5 + 4.25 * 6, 'desktop')};
    padding: ${(0, styles_1.vw)(8.5, 'desktop')} 0 ${(0, styles_1.vw)(12.5, 'desktop')};
    top: ${(0, styles_1.vw)(60, 'desktop')};
    width: calc(100% - ${(0, styles_1.vw)(25, 'desktop')});
  `}

  li {
    margin-bottom: ${(0, styles_1.vw)(4.25, 'mobile')};
    ${styles_1.mq.greaterThan('tablet') `
      margin-bottom: ${(0, styles_1.vw)(4.25, 'desktop')};
    `}

    &.online-store {
      &.last-store {
        margin-bottom: ${(0, styles_1.vw)(8.25, 'mobile')};

        ${styles_1.mq.greaterThan('tablet') `
          margin-bottom: ${(0, styles_1.vw)(8.25, 'desktop')};
        `}

        + li {
          border-top: 1px solid ${({ theme, $textColor }) => (0, styles_1.hex2Rgba)(theme.colors[$textColor], .4)};
          margin-top: ${(0, styles_1.vw)(8.25, 'mobile')};
          padding-top: ${(0, styles_1.vw)(8.25, 'mobile')};

          ${styles_1.mq.greaterThan('tablet') `
            margin-top: ${(0, styles_1.vw)(8.25, 'desktop')};
            padding-top: ${(0, styles_1.vw)(8.25, 'desktop')};
          `}
        }
      }
    }

  }

  button {
    ${(0, styles_1.getP18_3)()}
    align-items: center;
    color: ${({ theme, $textColor, $dropdownTextColor }) => $dropdownTextColor ? theme.colors[$dropdownTextColor] : theme.colors[$textColor]};
    display: flex;
    justify-content: space-between;
    opacity: .4;
    overflow-x: hidden;
    padding: ${(0, styles_1.vw)(2, 'mobile')} ${(0, styles_1.vw)(4.25, 'mobile')};
    text-overflow: ellipsis;
    transition: 300ms opacity ease-out;
    white-space: nowrap;
    width: 100%;

    ${styles_1.mq.greaterThan('tablet') `
      padding: ${(0, styles_1.vw)(8, 'desktop')} ${(0, styles_1.vw)(12.5, 'desktop')};
    `}

    &:hover {
      opacity: 1;
    }

    .online-store {
      opacity: .7;
    }
  }
`;
const Label = styled_components_1.default.label `
  display: flex;
  flex-direction: column;
  margin-bottom: ${(0, styles_1.vw)(20, 'mobile')};
  position: relative;
  width: 100%;

  ${styles_1.mq.greaterThan('tablet') `
    margin-bottom: ${(0, styles_1.vw)(30, 'desktop')};
  `}

  > input {
    ${(0, styles_1.getP18_3)()}
    background-color: ${({ theme, $bgColor }) => theme.colors[$bgColor]};
    border-bottom: 1px solid;
    border-color: ${({ theme, $textColor }) => (0, styles_1.hex2Rgba)(theme.colors[$textColor], .4)};
    color: ${({ theme, $textColor }) => $textColor ? theme.colors[$textColor] : theme.colors.darkred};
    height: ${(0, styles_1.vw)(60, 'mobile')};
    padding: ${(0, styles_1.vw)(25, 'mobile')} 0 ${(0, styles_1.vw)(12, 'mobile')};
    transition: 300ms border-color ease-out;
    width: 100%;

    ${styles_1.mq.greaterThan('tablet') `
      height: ${(0, styles_1.vw)(60, 'desktop')};
      padding: ${(0, styles_1.vw)(25, 'desktop')} 0 ${(0, styles_1.vw)(12, 'desktop')};
    `}

    ${({ theme, $placeholderVisible, $textColor }) => (0, styles_1.parsePlaceHolder)(`
      color: ${$placeholderVisible ? theme.colors[$textColor] : 'rgba(0, 0, 0 ,0)'};
      opacity: ${$placeholderVisible ? .4 : 0};
    `)}

    ${({ $buttonIcon }) => $buttonIcon && (0, styled_components_1.css) `
      padding-right: ${(0, styles_1.vw)(40, 'iphone8')};

      ${styles_1.mq.greaterThan('tablet') `
        padding-right: ${(0, styles_1.vw)(40, 'desktop-lg')};
      `}

      > button {
        align-items: center;
        background: transparent;
        border-bottom: 1px solid rgba(0, 0, 0, 0);
        bottom: ${(0, styles_1.vw)(5, 'mobile')};
        display: flex;
        height: ${(0, styles_1.vw)(21, 'mobile')};
        position: absolute;
        right: ${(0, styles_1.vw)(4.25, 'mobile')};
        user-select: none;

        ${styles_1.mq.greaterThan('tablet') `
          bottom: ${(0, styles_1.vw)(5, 'desktop')};
          height: ${(0, styles_1.vw)(21, 'desktop')};
          right: ${(0, styles_1.vw)(12.5, 'desktop')};
        `}
      }
    `}

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      -webkit-text-fill-color: ${({ theme, $textColor }) => theme.colors[$textColor]};
      -webkit-box-shadow: 0 0 0 500px ${({ theme, $bgColor }) => theme.colors[$bgColor]} inset;
      box-shadow: 0 0 0 100px ${({ theme, $bgColor }) => theme.colors[$bgColor]} inset;
      color: inherit;
    }

    ${({ theme, $placeholderVisible, $textColor }) => (0, styles_1.parsePlaceHolder)(`
      opacity: ${$placeholderVisible ? .4 : 0};
      color: ${$placeholderVisible ? theme.colors[$textColor] : 'rgba(0, 0, 0 ,0)'};
    `)}

    &:focus {
      border-color: ${({ theme, $textColor }) => (0, styles_1.hex2Rgba)(theme.colors[$textColor], 1)};

      + span {
        ${hasContentState()}
      }
    }
  }

  > span {
    ${({ $placeholderVisible }) => $placeholderVisible ? hasContentState() : (0, styles_1.getP18InputLabel)()}
    border-bottom: 1px solid rgba(0, 0, 0, 0);
    bottom: ${(0, styles_1.vw)(12, 'mobile')};
    color: ${({ theme, $textColor }) => theme.colors[$textColor]};
    opacity: 1;
    position: absolute;
    transform: translateY(-${(0, styles_1.vw)(35, 'mobile')});
    transition: 300ms font-size ease, 300ms transform ease, 300ms opacity ease;
    white-space: nowrap;

    ${styles_1.mq.greaterThan('tablet') `
      bottom: ${(0, styles_1.vw)(12, 'desktop')};
      transform: translateY(-${(0, styles_1.vw)(35, 'desktop')});
    `}
  }

  figure {
    &.chevron {
      bottom: ${(0, styles_1.vw)(21, 'mobile')};
      height: ${(0, styles_1.vw)(8, 'mobile')};
      pointer-events: none;
      position: absolute;
      right: ${(0, styles_1.vw)(20, 'mobile')};

      ${styles_1.mq.lessThan('tablet') `
        width: ${(0, styles_1.vw)(12, 'mobile')};
      `}

      ${styles_1.mq.greaterThan('tablet') `
        bottom: ${(0, styles_1.vw)(21, 'desktop')};
        height: ${(0, styles_1.vw)(8, 'desktop')};
        right: ${(0, styles_1.vw)(20, 'desktop')};
        width: ${(0, styles_1.vw)(12, 'desktop')};
      `}

      svg {
        height: 100%;
        object-fit: contain;
        transition: 300ms transform ease-out;
        width: 100%;
      }
    }
  }
`;
const optionsListVariants = {
    collapsed: {
        height: '0',
        opacity: 0,
        transitionEnd: {
            display: 'none',
        },
        transition: Object.assign({}, (0, styles_1.getTransition)())
    },
    expanded: {
        height: 'auto',
        opacity: 1,
        display: 'block',
        transition: Object.assign({}, (0, styles_1.getTransition)())
    }
};
const getOptionsList = (data, onClickOption, currentValue) => data.map((option, idx) => {
    var _a;
    return ((0, jsx_runtime_1.jsx)("li", Object.assign({ className: `${currentValue === option.value ? 'current' : ''}${option.store ? ` online-store${((_a = data[idx + 1]) === null || _a === void 0 ? void 0 : _a.store) ? '' : ' last-store'}` : ''}` }, { children: (0, jsx_runtime_1.jsxs)("button", Object.assign({ type: 'button', onClick: () => { onClickOption(option); }, "data-dismiss": true }, { children: [option.name, option.store && (0, jsx_runtime_1.jsx)("span", Object.assign({ className: 'online-store' }, { children: "Tienda online" }))] })) }), `${option.value}-${idx}`));
});
const arrowIcon = {
    type: 'image',
    alt: 'Arrow icon',
    default: {
        src: '/images/svg/i--arrow-select.svg',
        size: { width: 12, height: 8 }
    }
};
const arrowIconWhite = {
    type: 'image',
    alt: 'Arrow icon',
    default: {
        src: '/images/svg/i--arrow-select--white.svg',
        size: { width: 12, height: 8 }
    }
};
let TIMEOUT_ID = null;
exports.Select = (0, react_1.memo)(({ options, name = 'country_code', defaultValue, label, autoComplete = 'off', placeholder, placeholderVisible = false, required = false, disabled = false, readOnly = null, onChange = null, col, register = Input_1.noRegister, icon = null, className, textColor = 'darkred', bgColor = 'background', error, setFormValue, dropdownTextColor = null }) => {
    var _a, _b;
    const requireProps = typeof required === 'boolean' ? { required } : required;
    const { onChange: registerOnChange, onBlur: registerOnBlur, name: registerName, ref: registerRef } = register(name, Object.assign({}, requireProps));
    const [fancyValue, setFancyValue] = (0, react_1.useState)((_b = (_a = options.filter(option => option.value === defaultValue)[0]) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : '');
    const [currentOptions, setCurrentOptions] = (0, react_1.useState)(options);
    const [currentValue, setCurrentValue] = (0, react_1.useState)(defaultValue !== null && defaultValue !== void 0 ? defaultValue : '');
    const [triggerRef, dropdownRef, expanded, onTriggerFocus, toggleExpanded] = (0, dropdown_1.useDropdown)();
    const handleChange = e => {
        registerOnChange && registerOnChange(e);
        onChange && onChange(e);
    };
    const handleBlur = e => {
        registerOnBlur && registerOnBlur(e);
        // setInputValue(e?.target?.value)
        // setFormValue(e?.target?.value)
    };
    const onClickOption = (option) => {
        setFormValue && setFormValue(name, option.value);
        setCurrentValue(option.value);
        setFancyValue(option.name);
        toggleExpanded();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const Options = (0, react_1.useMemo)(() => getOptionsList(currentOptions, onClickOption, currentValue), [currentOptions, currentValue]);
    const filterBySearch = (0, react_1.useCallback)(({ value, valid }) => __awaiter(void 0, void 0, void 0, function* () {
        if (valid) {
            const data = options.filter(option => option.name.toLowerCase().includes(value.toLowerCase()));
            setCurrentOptions(data);
        }
        else {
            setCurrentOptions(options);
        }
    }), [options]);
    const handleSearch = e => {
        var _a;
        const value = (_a = e === null || e === void 0 ? void 0 : e.target) === null || _a === void 0 ? void 0 : _a.value;
        setFancyValue(value);
        if (typeof value === 'string') {
            const valid = value.length > 1;
            TIMEOUT_ID && clearTimeout(TIMEOUT_ID);
            TIMEOUT_ID = setTimeout(() => filterBySearch({ value, valid }), 300);
        }
    };
    const onBlurSearch = () => {
        var _a;
        const value = fancyValue;
        let matchOption = null;
        if (typeof value === 'string' && value.length > 2) {
            matchOption = (_a = options.filter(option => option.name.toLowerCase().includes(value.toLowerCase()))) === null || _a === void 0 ? void 0 : _a[0];
        }
        setFormValue(matchOption ? matchOption.value : '');
        setFancyValue(matchOption ? matchOption.name : '');
        setCurrentOptions(options);
    };
    (0, react_1.useEffect)(() => {
        setCurrentOptions(options);
    }, [options]);
    return ((0, jsx_runtime_1.jsxs)(Div, Object.assign({ ref: triggerRef, "$col": col }, { children: [(0, jsx_runtime_1.jsx)("input", Object.assign({ type: 'hidden', name: name, required: required, "aria-invalid": !!error }, registerRef && { ref: registerRef }, defaultValue && { defaultValue }, autoComplete && { autoComplete })), (0, jsx_runtime_1.jsxs)(Label, Object.assign({ "$hasContent": !!fancyValue, "$placeholderVisible": placeholderVisible, "$textColor": textColor, "$bgColor": bgColor }, className && { className }, { children: [(0, jsx_runtime_1.jsx)("input", { type: 'text', name: `${name}_searcher`, disabled: disabled, readOnly: readOnly, placeholder: placeholder !== null && placeholder !== void 0 ? placeholder : label, onChange: handleSearch, onFocus: onTriggerFocus, onBlur: onBlurSearch, value: fancyValue }), label && (0, jsx_runtime_1.jsx)("span", { children: label }), icon ? icon : (0, jsx_runtime_1.jsx)(media_1.Figure, { media: textColor === 'white' ? arrowIconWhite : arrowIcon, fill: false, lazy: true, className: 'chevron' })] })), !readOnly && (0, jsx_runtime_1.jsx)(Ul, Object.assign({ ref: dropdownRef, initial: 'collapsed', variants: optionsListVariants, animate: expanded ? 'expanded' : 'collapsed', "$textColor": textColor, "$dropdownTextColor": dropdownTextColor }, { children: Options }))] })));
});
