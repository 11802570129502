import { memo } from 'react'
import type { FC } from 'react'
import { css } from 'styled-components'
import { useModal } from '@dy/commons/hooks'
import { MODALS } from '@dy/commons/context'
import { Modal } from '.'
import { FormNewsletter } from  '@/components'
import { getP35, getP26, vw, mq} from '@dy/commons/styles'
import { useTranslator } from '@/hooks'


type MenuProps = {}

const dialogStyles = css`
  background: url('/images/svg/background-newsletter.svg') no-repeat center;
  background-size: cover;
  h2 {
    ${getP26()}

  }
  p {
    ${getP35()}
    color: ${({ theme }) => theme.colors.creme};
    font-family: ${({ theme }) => theme.fonts.primary};
    text-align: center;
    margin: 20px ;

  }
  header {
    border-bottom: none;
  }

  .modal__body {
    padding: 0 ${vw(40 - 4.25, 'mobile')} ${vw(75, 'mobile')};

    ${mq.greaterThan('tablet')`
      padding: 0 ${vw(40 - 12.5, 'desktop')} ${vw(75, 'desktop')};
    `}

  }
`


export const ModalNewsletter: FC<MenuProps> = memo(() => {
  const { isActive, toggle } = useModal(MODALS.NEWSLETTER)
  const { t } = useTranslator()

  return (
    <Modal isActive={isActive} close={toggle} title={''} xAlign='flex-end' dialogStyles={dialogStyles} size='s' >
      <h2>{t('commons.modal_newsletter.title') as string}</h2>
      <p>{t('commons.modal_newsletter.intro')}</p>
      <FormNewsletter hasLegend={false} inputBgColor={'transparent'} textColor={'red'} buttonBgColor={'red'}/>
    </Modal>
  )
})
