'use-client'
import type { FC } from 'react'
import { memo } from 'react'

import { Form, Fieldset, Button } from '@dy/commons/components'
import type { TypeSectionAuthLevel } from '@/components'
import { useTranslator } from '@/hooks'

type Props = {
  updateLevel: (level: TypeSectionAuthLevel, close?: boolean) => void
  animation?: any
}

// temp loading until useMutation updated
let loading = false

export const FormUserInitial:FC<Props> = memo(({ updateLevel, animation }) => {
  const { t } = useTranslator()

  return (
    <Form key='form-user-initial' {...animation && animation}>
      <Fieldset disabled={loading} ariaBusy={loading} legend={t('commons.modals.auth.initial.text') as string}>
        {/* <Input type='email' name='email' register={register} label='Email' placeholder='email' autoComplete={autoComplete.email} required error={formErrors.email} bgColor={'white'} /> */}
      </Fieldset>
      <Button type='button' onClick={() => { updateLevel('prelogin') }} bgType={'red'}>{t('commons.modals.auth.initial.actions.primary')}</Button>
      <Button type='button' onClick={() => { updateLevel('signup_cif') }} bgType={'red'} outline={true}>{t('commons.modals.auth.initial.actions.secondary')}</Button>
    </Form>
  )
})

